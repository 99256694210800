import BaseTable from "@material-ui/core/Table";

import { TableProps } from "../types";
import Container from "./Container";
import Head from "../table-head/TableHead";
import LoadingBody from "../LoadingBody";
import Body from "../table-body/TableBody";
import Pagination from "../pagination/Pagination";

const Table = Container({
  Table: BaseTable,
  Head,
  LoadingBody,
  Body,
  Pagination
});

Table.propTypes = TableProps;
export default Table;
