import { put, all, call, takeEvery } from "redux-saga/effects";
import { destroy } from "redux-form";

import log from "../../services/logging/service";
import routeService from "../services/RouteService";
import fetchApiService from "../../rest/fetchApiService";
import { showError, showSuccess } from "../../shared/sagas/notifications";
import { closeAllModals, showNotification } from "../../app/uiActions";
import {
  EDIT_EMPLOYER_SUCCESS,
  FETCH_EMPLOYER_SUCCESS
} from "../employerActions";
import {
  TEST_REQUEST,
  testSuccess,
  testFailure,
  ENABLE_SYNC_REQUEST,
  enableSyncSuccess,
  enableSyncFailure,
  EDIT_REQUEST,
  editSuccess,
  editFailure,
  SYNC_NOW_REQUEST,
  syncNowSuccess,
  syncNowFailure,
  GET_LOGS_REQUEST,
  getLogsSuccess,
  getLogsFailure,
  GET_LOGS_CSV_REQUEST,
  getLogsCsvSuccess,
  getLogsCsvFailure
} from "../actions/ultiproActions";
import TestUltiproRequest from "../services/TestUltiproCredentialsRequest";
import TestUltiproResponse from "../services/TestUltiproCredentialsResponse";
import UltiproSyncNowRequest from "../services/UltiproSyncNowRequest";
import UltiproSyncNowResponse from "../services/UltiproSyncNowResponse";
import UltiproEnableSyncRequest from "../services/UltiproEnableSyncRequest";
import UltiproEnableSyncResponse from "../services/UltiproEnableSyncResponse";
import EditUltiproRequest from "../services/EditUltiproRequest";
import EditUltiproResponse from "../services/EditUltiproResponse";
import GetUltiproLogsRequest from "../services/GetUltiproLogsRequest";
import GetUltiproLogsResponse from "../services/GetUltiproLogsResponse";
import moment from "moment";

function resetEmployerTestStatus(employer) {
  const ultipro_integration = Object.assign({}, employer.ultipro_integration, {
    credentials_successfully_tested_at: null
  });

  return Object.assign({}, employer, { ultipro_integration });
}

function* testUltiproCredentials({ employer, api_url }) {
  try {
    const employerId = employer.id;
    const endpoint = routeService.testUltiproCredentials(employerId);
    const request = new TestUltiproRequest({
      url: endpoint,
      api_url
    });

    const response = yield fetchApiService.fetch(request, TestUltiproResponse);
    if (response.ok) {
      yield call(showSuccess, "Ultipro credentials are valid!");
      yield put(testSuccess(employerId));
    } else {
      const newEmployer = yield call(resetEmployerTestStatus, employer);
      yield put({ type: FETCH_EMPLOYER_SUCCESS, payload: newEmployer });
      yield call(showError, response.statusText);
      yield put(testFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    const newEmployer = yield call(resetEmployerTestStatus, employer);
    yield put({ type: FETCH_EMPLOYER_SUCCESS, payload: newEmployer });
    yield call(showError, e.message);
    yield put(testFailure(e.message));
    log.error(e.message);
  }
}

function* syncNow({ employerId }) {
  try {
    const endpoint = routeService.ultiproSyncNow(employerId);
    const request = new UltiproSyncNowRequest({
      url: endpoint
    });

    const response = yield fetchApiService.fetch(
      request,
      UltiproSyncNowResponse
    );
    if (response.ok) {
      yield call(showSuccess, "Syncing is processing...");
      yield put(syncNowSuccess());
    } else {
      yield call(showError, response.statusText);
      yield put(syncNowFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(syncNowFailure(e.message));
    log.error(e.message);
  }
}

function* editUltiproSettings({
  formName,
  employerId,
  id,
  api_url,
  api_username,
  api_password,
  api_key,
  prestart_interval
}) {
  try {
    const endpoint = routeService.update(employerId);
    const request = new EditUltiproRequest({
      url: endpoint,
      employerId,
      id,
      api_url,
      api_username,
      api_password,
      api_key,
      prestart_interval
    });

    const response = yield fetchApiService.update(request, EditUltiproResponse);
    if (response.ok) {
      const data = response.getData();
      yield put(destroy(formName));
      yield put(closeAllModals());
      yield put({ type: EDIT_EMPLOYER_SUCCESS, payload: data });
      yield call(showSuccess, "Ultipro credentials updated!");
      yield put(editSuccess());
    } else {
      yield put(editFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(editFailure(e.message));
    log.error(e.message);
  }
}

function* enableSync({ employerId, id, sync_enabled }) {
  try {
    const endpoint = routeService.update(employerId);
    const request = new UltiproEnableSyncRequest({
      url: endpoint,
      employerId,
      id,
      sync_enabled
    });

    const response = yield fetchApiService.update(
      request,
      UltiproEnableSyncResponse
    );
    if (response.ok) {
      const data = response.getData();
      yield put({ type: EDIT_EMPLOYER_SUCCESS, payload: data });
      yield call(showSuccess, "Ultipro sync enabled!");
      yield put(enableSyncSuccess(employerId));
    } else {
      yield put(enableSyncFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(enableSyncFailure(e.message));
    log.error(e.message);
  }
}

function* getLogs({ employerId, sort, page, query }) {
  try {
    const endpoint = routeService.ultiproLogs(employerId);
    const request = new GetUltiproLogsRequest({
      url: endpoint,
      employerId,
      params: { sort, pagination: { page } },
      query
    });

    const response = yield fetchApiService.fetch(
      request,
      GetUltiproLogsResponse
    );
    if (response.ok) {
      const data = response.getListData(employerId);

      yield put(getLogsSuccess(data));
    } else {
      yield put(getLogsFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getLogsFailure(e.message));
    log.error(e.message);
  }
}

async function requestGetLogsCsv(employerId) {
  return await fetchApiService.fetchFileAttachment(
    `${process.env.REACT_APP_API_HOST}/employers/${employerId}/ultipro_logs.csv`,
    `UltiproLogs_${moment().format()}.csv`
  );
}

function* getLogsCsv(action) {
  try {
    yield call(requestGetLogsCsv, action.employerId);
    yield put(getLogsCsvSuccess);
    yield put(showNotification("Logs downloaded successfully"));
  } catch (e) {
    yield put(getLogsCsvFailure(e.message));
    yield put(
      showNotification(
        "An error occurred while downloading your Ultipro logs",
        "warning"
      )
    );
    log.error(e.message);
  }
}

export default function* sagas() {
  yield all([
    takeEvery(SYNC_NOW_REQUEST, syncNow),
    takeEvery(ENABLE_SYNC_REQUEST, enableSync),
    takeEvery(EDIT_REQUEST, editUltiproSettings),
    takeEvery(TEST_REQUEST, testUltiproCredentials),
    takeEvery(GET_LOGS_REQUEST, getLogs),
    takeEvery(GET_LOGS_CSV_REQUEST, getLogsCsv)
  ]);
}
