import uuidv4 from "uuid/v4";

export const TOGGLE_SUB_NAV = "TOGGLE_SUB_NAV";

export const toggleSubNav = () => ({
  type: TOGGLE_SUB_NAV
});

export const TOGGLE_EMPLOYER_EDIT_GENERAL_MODAL =
  "TOGGLE_EMPLOYER_EDIT_GENERAL_MODAL";

export const toggleEmployerEditGeneralModal = () => ({
  type: TOGGLE_EMPLOYER_EDIT_GENERAL_MODAL
});

export const TOGGLE_EMPLOYER_EDIT_PAY_MODAL = "TOGGLE_EMPLOYER_EDIT_PAY_MODAL";

export const toggleEmployerEditPayModal = () => ({
  type: TOGGLE_EMPLOYER_EDIT_PAY_MODAL
});

export const TOGGLE_ADMIN_USER_CREATE_MODAL = "TOGGLE_ADMIN_USER_CREATE_MODAL";

export const toggleAdminUserCreateModal = () => ({
  type: TOGGLE_ADMIN_USER_CREATE_MODAL
});

export const TOGGLE_ADMIN_USER_EDIT_MODAL = "TOGGLE_ADMIN_USER_EDIT_MODAL";

export const toggleAdminUserEditModal = (id = null) => ({
  type: TOGGLE_ADMIN_USER_EDIT_MODAL,
  payload: id
});

export const TOGGLE_PARTNER_EDIT_CONTACT_MODAL =
  "TOGGLE_PARTNER_EDIT_CONTACT_MODAL";

export const togglePartnerEditContactModal = () => ({
  type: TOGGLE_PARTNER_EDIT_CONTACT_MODAL
});

export const TOGGLE_PARTNER_EDIT_BILLING_MODAL =
  "TOGGLE_PARTNER_EDIT_BILLING_MODAL";

export const togglePartnerEditBillingModal = () => ({
  type: TOGGLE_PARTNER_EDIT_BILLING_MODAL
});

export const TOGGLE_DELETE_CONFIRM_MODAL = "TOGGLE_DELETE_CONFIRM_MODAL";

export const toggleDeleteConfirmModal = (id = "") => ({
  type: TOGGLE_DELETE_CONFIRM_MODAL,
  payload: id
});

export const SET_ACTIVE_TAB_EMPLOYER = "SET_ACTIVE_TAB_EMPLOYER";

export const setActiveTabEmployer = (activeTab = "dashboard") => ({
  type: SET_ACTIVE_TAB_EMPLOYER,
  payload: activeTab
});

export const TOGGLE_EMPLOYEE_EDIT_MODAL = "TOGGLE_EMPLOYEE_EDIT_MODAL";

export const toggleEmployeeEditModal = (id = null) => ({
  type: TOGGLE_EMPLOYEE_EDIT_MODAL,
  payload: id
});

export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";

export const showNotification = (text, type = "info") => ({
  type: SHOW_NOTIFICATION,
  payload: { text, type }
});

export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION
});

export const OPEN_MODAL = "OPEN_MODAL";

export const openModal = content => {
  return {
    type: OPEN_MODAL,
    payload: { content, id: uuidv4() }
  };
};

export const CLOSE_MODAL = "CLOSE_MODAL";

export const closeModal = id => {
  return {
    type: CLOSE_MODAL,
    payload: { id }
  };
};

export const CLOSE_ALL_MODALS = "CLOSE_ALL_MODALS";

export const closeAllModals = () => {
  return {
    type: CLOSE_ALL_MODALS
  };
};
