import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { fetchPartner as fetchPartnerAction } from "./partnerActions";
import { fetchEmployers as fetchEmployersAction } from "../employers/employerActions";
import { selectCurrentPartner } from "./selectors";

export class PartnerSettings extends Component {
  componentDidMount() {
    const {
      fetchPartner,
      fetchEmployers,
      partner,
      match,
      employers
    } = this.props;
    const { partnerID } = match.params;

    if (!partner) {
      fetchPartner(partnerID);
    }
    if (!employers) {
      fetchEmployers(partnerID, true);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchPartner, match } = this.props;
    const currentPartnerID = match.params.partnerID;
    const nextPartnerID = nextProps.match.params.partnerID;
    if (currentPartnerID !== nextPartnerID) {
      fetchPartner(nextPartnerID);
    }
  }

  render() {
    const { partner, render } = this.props;
    return render(partner);
  }
}

PartnerSettings.propTypes = {
  partner: PropTypes.object,
  employers: PropTypes.arrayOf(PropTypes.objects),
  fetchEmployers: PropTypes.func.isRequired,
  fetchPartner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired
};

PartnerSettings.defaultProps = {
  employers: null,
  partner: null
};

function mapStateToProps(state) {
  return {
    partner: selectCurrentPartner(state)
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchPartner: fetchPartnerAction,
      fetchEmployers: fetchEmployersAction
    }
  )
);

export default enhance(PartnerSettings);
