import React from "react";
import styled from "styled-components";
import Card from "@material-ui/core/Card";

const StyledCard = styled(Card)`
  width: 100%;
`;

const ThemedCardContainer = props => {
  return <StyledCard {...props} />;
};

export default ThemedCardContainer;
