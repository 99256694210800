import React from "react";
import { eachMonthOfInterval, getMonth, format } from "date-fns";

import Select from "./selects/EnhancedSelect";

const options = eachMonthOfInterval({
  start: new Date(2019, 12, 1),
  end: new Date(2020, 11, 1)
}).map(v => {
  const d = new Date(v);
  const label = format(d, "LLLL");
  const value = getMonth(d);
  return { value, label };
});

const rules = {
  required: "required"
};

export default function MonthSelect(props) {
  return (
    <Select
      name="month"
      label="Month"
      required
      rules={rules}
      options={options}
      {...props}
    />
  );
}
