import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from "react-router-dom";
import moment from "moment";
import { get } from "lodash";

import { Card, CardText, CardHeader } from "material-ui/Card";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import AddIcon from "material-ui/svg-icons/content/add";
import RaisedButton from "material-ui/RaisedButton";

import { fetchEmployers as fetchEmployersAction } from "./employerActions";
import { fetchPartner as fetchPartnerAction } from "../partners/partnerActions";
import { theme } from "../app";
import { EmployerCreateModal } from "./modals";
import { openModal as openModalAction } from "../app/uiActions";
import { SLGTableColumn, SLGList } from "../components";
import { WithPermission } from "../auth/WithPermission";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  PARTNER_MANAGER,
  SORT_DESC
} from "../constants";
import { selectEmployersListByPartner } from "./selectors";
import SearchForm from "../components/SearchForm";
import {
  selectCurrentPartner,
  selectEntityIdentifiers
} from "../partners/selectors";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  rowFieldCenter: { textAlign: "center" },
  raisedButton: { borderRadius: "3px" },
  table: { minWidth: "100%", width: "auto" }
};

export class EmployersList extends Component {
  /**
   * Fetches Employers based on Search component query parameter.
   * TODO: Move all query parameters into redux, so we can compose ordering, pagination, etc. between queries and
   * regular employer fetches with ordering clauses.
   *
   * @param {string} query
   * @return {Promise}
   */
  fetchEmployersQuery = query => {
    const defaultSort = { field: "created_at", order: SORT_DESC };
    return this.props.fetchEmployers(
      this.props.partnerID,
      true,
      defaultSort,
      null,
      query
    );
  };

  static propTypes = {
    partner: PropTypes.object,
    employers: PropTypes.array.isRequired,
    fetchPartner: PropTypes.func.isRequired,
    total: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchEmployers: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired
  };

  static defaultProps = {
    employers: [],
    partner: {}
  };

  render() {
    const {
      fetchEmployers,
      employers,
      total,
      openModal,
      isLoading,
      partnerID,
      partner,
      entityIdentifiers
    } = this.props;

    const primaryColor = getMuiTheme(theme).palette["primary1Color"];

    return (
      <Card>
        <CardHeader
          title={
            <span
              style={{ fontSize: "1.5em" }}
            >{`All ${entityIdentifiers.employerIdentifiers}s`}</span>
          }
          style={styles.header}
        >
          <WithPermission
            value={[
              SYSTEM_ADMIN,
              PARTNER_ADMIN,
              EMPLOYERS_ADMIN,
              PARTNER_MANAGER
            ]}
          >
            <RaisedButton
              primary={true}
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              icon={<AddIcon />}
              onClick={() =>
                openModal(
                  <EmployerCreateModal
                    partnerID={partnerID}
                    partnerSSOenabled={get(partner, "sso_enabled")}
                  />
                )
              }
              label={`New ${entityIdentifiers.employerIdentifier}`}
              disabled={isLoading}
            />
          </WithPermission>
        </CardHeader>
        <CardText>
          <SearchForm onSubmit={this.fetchEmployersQuery} />

          <SLGList
            fetchData={fetchEmployers}
            params={[partnerID, null]}
            data={employers}
            total={total}
            tableStyle={styles.table}
            isLoading={isLoading}
          >
            <SLGTableColumn
              sortable={true}
              field="name"
              render={val => {
                return (
                  <Link
                    to={`/employers/${val.id}/dashboard`}
                    style={{
                      color: primaryColor,
                      textDecoration: "none"
                    }}
                  >
                    {val.name}
                  </Link>
                );
              }}
            />
            <SLGTableColumn
              field="pay_enrolled?"
              title="Pay Enrolled?"
              rowStyle={styles.rowField}
              headerStyle={styles.headerColumn}
              render={val => (val["pay_enabled?"] ? "Yes" : "No")}
            />
            <SLGTableColumn
              sortable={true}
              field="created_at"
              title="Created On"
              rowStyle={styles.rowField}
              headerStyle={styles.headerColumn}
              render={val => moment(val.created_at).format("MM/DD/YYYY")}
            />
          </SLGList>
        </CardText>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const partnerID = state.partners.selectedID;
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    partner: selectCurrentPartner(state),
    employers: selectEmployersListByPartner(state, partnerID),
    total: state.employers.total,
    isLoading: state.employers.isFetching,
    partnerID,
    entityIdentifiers
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchEmployers: fetchEmployersAction,
      fetchPartner: fetchPartnerAction,
      openModal: openModalAction
    }
  )
);

export default enhance(EmployersList);
