import { compose } from "ramda";

import spacing from "../../../theme/spacing";
import Grid from "../Grid";
import layVertical from "../layVertical";
import withSpacing from "../withSpacing";

export default compose(
  withSpacing(spacing.sm),
  layVertical
)(Grid);
