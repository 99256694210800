import React, { Component } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

import { Step, Stepper, StepLabel } from "material-ui/Stepper";

import { filterNullChildren } from "../helpers";

export class StepperForm extends Component {
  constructor(props) {
    super(props);
    this.state = { stepIndex: 0 };
  }

  handleNext = values => {
    const { children, onSubmit } = this.props;
    const { stepIndex } = this.state;

    if (stepIndex === filterNullChildren(children).length - 1 && !!onSubmit) {
      onSubmit(values);
    } else {
      this.setState({ stepIndex: stepIndex + 1 });
    }
  };

  handlePrev = () => {
    const { handlePrev } = this.props;
    const { stepIndex } = this.state;
    if (stepIndex === 0 && !!handlePrev) {
      handlePrev();
    } else {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  };

  buildStepperBody(stepIndex, steps) {
    const stepperBody = steps[stepIndex];
    return React.cloneElement(stepperBody, {
      onSubmit: this.handleNext,
      handlePrev: this.handlePrev
    });
  }

  render() {
    const { children } = this.props;
    const { stepIndex } = this.state;

    const cleanChildren = filterNullChildren(children);

    return (
      <div>
        <Stepper activeStep={stepIndex}>
          {map(cleanChildren, step => {
            if (React.isValidElement(step)) {
              return (
                <Step key={step.props.label}>
                  <StepLabel>{step.props.label}</StepLabel>
                </Step>
              );
            }
          })}
        </Stepper>
        <div>{this.buildStepperBody(stepIndex, cleanChildren)}</div>
      </div>
    );
  }
}

StepperForm.propTypes = {
  onSubmit: PropTypes.func,
  handlePrev: PropTypes.func
};

export default StepperForm;
