import BaseRequest from "../../shared/BaseRequest";

export default class UltiproEditRequest extends BaseRequest {
  method = "patch";

  constructor({
    url,
    employerId,
    id,
    api_url,
    api_username,
    api_password,
    api_key,
    prestart_interval
  }) {
    super({ url });

    let data = {
      type: "employers",
      id: employerId,
      ultipro_integration_attributes: {
        api_url,
        api_username,
        api_password,
        api_key,
        prestart_interval
      }
    };

    // id may not be present if this is for an employer that has not created an ultipro_integration record yet
    if (id) {
      data.ultipro_integration_attributes.id = id;
    }

    this.data = data;
  }
}
