import React from "react";

function Container({ Table, Head, LoadingBody, Body, Pagination }) {
  function TableComponent({
    rows,
    columns,
    total,
    loading,
    disabled,
    disableSort,
    defaultSort,
    defaultRowsPerPage = 25,
    headerActionHandlers,
    showSelection,
    onSelectChange,
    onSelectAllChange,
    onChangePage,
    selected,
    getRowId
  }) {
    const rowLength = rows.length;

    return (
      <>
        <div>
          <Table rows={rows}>
            <Head
              loading={loading}
              columns={columns}
              disableSort={disableSort}
              defaultSort={defaultSort}
              disabled={disabled || loading}
              showSelection={showSelection}
              onSelectChange={onSelectAllChange}
              selected={
                rowLength && selected && selected.length === rowLength
                  ? true
                  : false
              }
              {...headerActionHandlers}
            />

            {loading ? (
              <LoadingBody loading colSpan={columns.length} />
            ) : (
              <Body
                disabled={disabled}
                columns={columns}
                rows={rows}
                showSelection={showSelection}
                onRowClick={onSelectChange}
                selections={selected}
                getRowId={getRowId}
              />
            )}
          </Table>
        </div>
        <Pagination
          count={total}
          defaultRowsPerPage={defaultRowsPerPage}
          onChangePage={onChangePage}
        />
      </>
    );
  }

  return TableComponent;
}

export default Container;
