import React from "react";

export default function usePagination({ defaultRowsPerPage, onChangePage }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);

  function handleChangeRowsPerPage(value) {
    setRowsPerPage(value);
  }

  function handleChangePage(event, value) {
    setPage(value);
    if (onChangePage) onChangePage(value);
  }

  return {
    page,
    handleChangePage,
    rowsPerPage,
    handleChangeRowsPerPage
  };
}
