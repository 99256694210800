import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";

import ThemedCard from "./ThemedCard";

const styles = () => ({
  tile: { overflow: "visible" },
  gridList: {
    minHeight: 300
  }
});

class ListCard extends React.PureComponent {
  renderRow() {
    const {
      classes,
      withGrid: { data, getCellId, renderCell }
    } = this.props;
    return data.map((tile, index) => {
      const id = getCellId ? getCellId({ row: tile }) : index;
      return (
        <GridListTile key={id} classes={{ tile: classes.tile }}>
          {renderCell({ row: tile })}
        </GridListTile>
      );
    });
  }

  render() {
    const {
      classes,
      withHeader,
      withGrid: { data, cols }
    } = this.props;
    return (
      <ThemedCard withHeader={withHeader}>
        <GridList
          className={classes.gridList}
          cellHeight="auto"
          cols={cols}
          spacing={12}
        >
          {data && data.length ? this.renderRow() : <div />}
        </GridList>
      </ThemedCard>
    );
  }
}

ListCard.propTypes = {
  classes: PropTypes.object.isRequired,
  withHeader: PropTypes.shape({
    title: PropTypes.string.isRequired
  }),
  withGrid: PropTypes.shape({
    data: PropTypes.array.isRequired,
    cols: PropTypes.number.isRequired,
    getCellId: PropTypes.func,
    renderCell: PropTypes.func
  })
};

ListCard.defaultProps = {
  withGrid: {
    data: []
  }
};

export default withStyles(styles)(ListCard);
