import { centsToMoneyString } from "services/formatters";
import Panel, { withProps } from "./Panel";

const getRowId = r => r.id;
const formatAmount = v => centsToMoneyString(v.amount_cents);
const columns = [
  {
    name: "personal_ids",
    label: "Personal ID"
  },
  {
    name: "pulling_for_name",
    label: "Name"
  },
  {
    name: "amount_cents",
    label: "Amount",
    render: formatAmount
  }
];

export default withProps({ getRowId, columns, showSelection: false })(Panel);
