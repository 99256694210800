import contacts from "./contactsReducers";
import reportsReducer from "./reportsReducers";
import ultipro from "./ultiproReducers";
import bulkUpload from "./bulkUploadReducers";
import fullFileUploadReducers from "./fullFileUploadReducers";
import creareEmployeesReducers from "./creareEmployeesReducers";

export default {
  employerReports: reportsReducer,
  ultipro,
  bulkUpload,
  employerContacts: contacts,
  fullFileUploadReducers:fullFileUploadReducers,
  creareEmployeesReducers:creareEmployeesReducers
};
