import initialState from "./initialState";
import {
  RESET,
  //GET_SECOND_APPROVAL,
  GET_SECOND_APPROVAL_SUCCESS
} from "../actions/types";


export const tutionsReducer = (
  previousState = initialState.tuitionReimbursement,
  { type, payload }
) => {
  switch (type) {
    case RESET:
      return {
        ...previousState,
        tRStatus: payload 
      };
    case GET_SECOND_APPROVAL_SUCCESS:
      return {
        ...previousState,
        getSecondApproval: payload,
      }         
    default:
      return previousState;
  }
};
