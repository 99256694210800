import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiStepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default function Stepper({ steps, activeStep, onStepChange, children }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MuiStepper activeStep={activeStep}>
        {steps.map(step => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </MuiStepper>
      {React.Children.toArray(children)[activeStep]}
    </div>
  );
}
