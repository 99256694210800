import React from "react";

import Link from "../../components/links/Link";

function NameCell(value) {
  const employerId = value.id;
  const to = `/employers/${employerId}/dashboard`;
  return <Link to={to}>{value.name}</Link>;
}

export default NameCell;
