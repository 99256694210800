import React from "react";
import { reject, pathOr } from "ramda";
import { v4 as uuid } from "uuid";
import Grid from "@material-ui/core/Grid";

import DeleteButton from "../buttons/icon/Delete";
import AddButton from "../buttons/icon/Add";
import Field from "./Name";

function Row({ defaultValue, total, index, item, onAdd, onDelete, ...props }) {
  return (
    <Grid container spacing={2} direction="row" alignItems="center">
      <Grid item>
        <Field
          name={`federated_employers[${index}].foreign_id`}
          label="SSO ID"
          defaultValue={pathOr("", ["foreign_id"], defaultValue)}
          {...props}
        />
      </Grid>
      <Grid item>
        <Field
          name={`federated_employers[${index}].foreign_name`}
          label="SSO Name"
          defaultValue={pathOr("", ["foreign_name"], defaultValue)}
          {...props}
        />
      </Grid>
      {total > 1 ? (
        <Grid item>
          <DeleteButton onClick={() => onDelete(item.uid)} />
        </Grid>
      ) : null}
      <Grid item>
        <AddButton onClick={onAdd} />
      </Grid>
    </Grid>
  );
}

const rowItem = { foreign_id: "", foreign_name: "" };

export default function SSOField({ defaultValue, ...props }) {
  const [items, setItems] = React.useState([
    {
      ...rowItem,
      uid: uuid()
    }
  ]);

  function handleAdd() {
    setItems([
      ...items,
      {
        ...rowItem,
        uid: uuid()
      }
    ]);
  }

  function handleDelete(uid) {
    setItems(reject(v => v.uid === uid, items));
  }

  return (
    <Grid container>
      {items.map((item, index) => {
        return (
          <Grid key={item.uid} item>
            <Row
              total={items.length}
              item={item}
              index={index}
              onAdd={handleAdd}
              onDelete={handleDelete}
              defaultValue={pathOr({}, [index], defaultValue)}
              {...props}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
