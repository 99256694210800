import React from "react";
import { Controller } from "react-hook-form";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";

import FormControl from "./form-control/FormControl";
import withControls from "./enhancements/withControls";

function RadioGroupComponent({
  options = [],
  name,
  label,
  helperText,
  disabled,
  required,
  error,
  ...props
}) {
  return (
    <FormControl
      component="fieldset"
      disabled={disabled}
      required={required}
      error={error}
      fullWidth
    >
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup name={name} color="primary" {...props}>
        {options.map(({ value, label: fieldLabel, ...rest }) => {
          return (
            <FormControlLabel
              key={value}
              value={value}
              control={<Radio color="primary" />}
              label={fieldLabel}
              {...rest}
            />
          );
        })}
      </RadioGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

const Enhanced = withControls(RadioGroupComponent);

export default props => <Controller as={<Enhanced />} {...props} />;
