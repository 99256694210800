import * as React from "react";
import GetAppIcon from "material-ui/svg-icons/action/get-app";

import DashboardButton from "./Dashboard";

export default ({ children, ...props }) => {
  return (
    <DashboardButton style={{ color: "white" }} {...props}>
      <GetAppIcon style={{ marginRight: "3px", color: "white" }} />
      {children}
    </DashboardButton>
  );
};
