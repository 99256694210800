import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles({
  root: {
    minWidth: 450
  }
});

function DialogContainer({
  titleSlot,
  contentSlot,
  actionsSlot,
  open = false,
  onClose,
  ...props
}) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ root: classes.root }}
      open={open}
      onClose={onClose}
      {...props}
    >
      <DialogTitle>{titleSlot}</DialogTitle>
      <DialogContent dividers>{contentSlot}</DialogContent>
      <DialogActions>{actionsSlot}</DialogActions>
    </Dialog>
  );
}

DialogContainer.propTypes = {
  titleSlot: PropTypes.node.isRequired,
  contentSlot: PropTypes.node.isRequired,
  actionsSlot: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default DialogContainer;
