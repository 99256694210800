const TOKEN = "token";

export class TokenService {
  constructor({ storageService }) {
    this._storageService = storageService;
  }

  get() {
    return this._storageService.getItem(TOKEN);
  }
}

const storageService = localStorage;
export default new TokenService({ storageService });
