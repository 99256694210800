import React from "react";
import { compose, pathOr } from "ramda";

import withAutocompleteContainer from "../containers/withAutocompleteContainer";
import Autocomplete from "../inputs/autocomplete/TextFieldAutocomplete";

function getOptionLabel(option) {
  return pathOr("", ["name"], option);
}

const enhance = WC => ({ partnerId, ...props }) => {
  const [filter, setFilter] = React.useState({
    "0": {
      partner_id_eq: partnerId
    }
  });
  function handleInputChange(v) {
    function setNewFilter() {
      const newFilter = {
        ...filter,
        "1": { name_cont: v }
      };
      setFilter(newFilter);
    }

    setNewFilter();
  }

  return (
    <WC
      width={200}
      label="Employer"
      name="employer"
      path="/employers"
      filter={filter}
      onInputChange={handleInputChange}
      getOptionLabel={getOptionLabel}
      {...props}
    />
  );
};

export default compose(
  enhance,
  withAutocompleteContainer
)(Autocomplete);
