import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import includes from "lodash/includes";
import get from "lodash/get";
import filter from "lodash/filter";
import { withRouter } from "react-router-dom";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import Sidebar from "packages/mui-dashboard/components/Sidebar/Sidebar";
import Header from "packages/mui-dashboard/components/Header/Header.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import appStyle from "packages/mui-dashboard/assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx";
import cx from "classnames";
import PaymentsIcon from "@material-ui/icons/LocalAtm";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import EmployersIcon from "@material-ui/icons/LocationCity";
import PeopleIcon from "@material-ui/icons/People";
import GlobalIcon from "@material-ui/icons/Public";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Grid from "@material-ui/core/Grid";

import { ellipsis } from "../helpers/formattingHelpers";
import { openModal as openModalAction } from "app/uiActions";
import { fetchRefiVendors } from "../refiVendors/refiVendorActions";
import {
  fetchPartners as fetchPartnersAction,
  fetchPartner as fetchPartnerAction,
  setSelectedPartner as setSelectedPartnerAction
} from "partners/partnerActions";
import { fetchEmployers } from "../employers/employerActions";
import {
  selectPartnersList,
  selectCurrentPartner,
  selectEntityIdentifiers
} from "partners/selectors";
import { TwoFactor } from "twoFactor";
import ModalContainer from "components/modals/ModalContainer";
import { CustomNotification, Idle } from "components";
import { selectEmployerByID } from "employers/selectors";
import { PartnerCreateModal } from "partners/modals";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  BENEFITS_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SUPPORT_REP,
  ROLE,
  PARTNER_ID,
  ENTITY,
  PARTNER_MANAGER,
  SELF_SERVICE_ADMIN
} from "../constants";
import GlobalSearchForm from "../global-search/SearchForm";
import WrapWithLoading from "../components/loading/WrapWithLoading";
import {
  selectEmployersIsFetched,
  selectEmployerIDList,
  selectEmployers
} from "../employers/selectors";
import { isUndefined } from "lodash";

/**
 * Base layout for authenticated pages.
 */
class AuthedLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      ready: props.role === EMPLOYER_MANAGER ? false : true
    };
    this.resizeFunction = this.resizeFunction.bind(this);

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { role } = this.props;

    window.addEventListener("resize", this.resizeFunction);
    this.fetchPartnerData();

    /** scroll to top */
    const containerRef = this.containerRef.current;
    if (containerRef) {
      containerRef.scrollTop = 0;
    }

    if (role === EMPLOYER_MANAGER) {
      if (this.props.employersIsFetched) {
        this.ready();
        this.redirect();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { employersIsFetched, role } = this.props;

    /** scroll to top */
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const containerRef = this.containerRef.current;
      if (containerRef) {
        containerRef.scrollTop = 0;
      }
    }

    if (role === EMPLOYER_MANAGER) {
      if (employersIsFetched !== prevProps.employersIsFetched) {
        this.ready();
        this.redirect();
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  ready() {
    this.setState({ ready: true });
  }

  redirect() {
    const { history, role, employersIsFetched, employerIds } = this.props;
    if (role === EMPLOYER_MANAGER) {
      if (employersIsFetched && employerIds.length === 1) {
        const employerId = employerIds[0];
        history.push(`/employers/${employerId}/dashboard`);
      }
    }
  }

  handleDrawerToggle = () => {
    const { mobileOpen } = this.state;
    this.setState({ mobileOpen: !mobileOpen });
  };

  sidebarMinimize = () => {
    const { miniActive } = this.state;
    this.setState({ miniActive: !miniActive });
  };

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  generateSidebarRoutes() {
    const role = localStorage.getItem(ROLE);
    const {
      employer,
      partnerID,
      routing,
      employerIds,
      entityIdentifiers
    } = this.props;
    let routes;
    if (role === BENEFITS_ADMIN) {
      const employerID = routing.location.pathname.split("/")[2];
      const enableUltipro = employer && employer.ultipro_ready;
      const enableFundRequest =
        employer && (employer["pay_enabled?"] || employer["any_pdrs"]);

      return filter([
        {
          value: "dashboard",
          name: "Dashboard",
          icon: HomeIcon,
          path: `/employers/${employerID}/dashboard`
        },
        {
          name: "Reports",
          value: "reports",
          icon: AssessmentIcon,
          path: `/employers/${employerID}/reports`
        },
        {
          name: "Groups",
          value: "groups",
          icon: PeopleIcon,
          path: `/employers/${employerID}/groups`
        },
        {
          collapse: true,
          name: `${ellipsis(entityIdentifiers.employeeIdentifier + "s")}`,
          value: "employees",
          path: `/employers/${employerID}/employees`,
          mini: "E",
          views: [
            {
              path: `/employers/${employerID}/employees/list`,
              name: "List",
              value: "employeesList",
              mini: "EL"
            },
            {
              path: `/employers/${employerID}/employees/bulkHistory`,
              name: "Bulk History",
              value: "bulkHistory",
              mini: "BH"
            }
          ]
        },
        enableFundRequest && {
          collapse: true,
          name: "Invoices",
          value: "invoices",
          path: `/employers/${employerID}/invoices`,
          mini: "I",
          views: [
            {
              path: `/employers/${employerID}/invoices/list`,
              name: "List",
              value: "invoicesList",
              mini: "IL"
            },
            {
              path: `/employers/${employerID}/invoices/contributions`,
              name: "Contributions",
              value: "contributions",
              mini: "C"
            }
          ]
        },
        {
          name: `Settings`,
          value: "settings",
          icon: SettingsIcon,
          path: `/employers/${employerID}/settings/settings`
        },
        {
          path: `/employers/${employerID}/settings/contacts`,
          name: "Contacts",
          value: "contacts",
          mini: "C"
        },
        enableUltipro && {
          name: "Ultipro Settings",
          value: "ultiproSettings",
          icon: SettingsIcon,
          path: `/employers/${employerID}/settings/ultipro/settings`
        },
        enableUltipro && {
          name: "Ultipro Logs",
          value: "ultiproLogs",
          icon: PaymentsIcon,
          path: `/employers/${employerID}/settings/ultipro_logs`
        }
      ]);
    }

    if (role == SELF_SERVICE_ADMIN) {
      const employerID = routing.location.pathname.split('/')[2];
      const enableFundRequest =
        employer && (employer['pay_enabled?'] || employer['any_pdrs']);
      let employersViews = [
        {
          path: `/employers/${employerID}/employees/list`,
          name: "List",
          value: "employeesList",
          mini: "EL"
        },
        {
          path: `/employers/${employerID}/employees/bulkHistory`,
          name: "Bulk History",
          value: "bulkHistory",
          mini: "BH"
        }
      ]
      if (employer && employer.subscription && employer.subscription.tr_enabled) {
        employersViews = [
          {
            path: `/employers/${employerID}/employees/list`,
            name: "List",
            value: "employeesList",
            mini: "EL"
          },
          {
            path: `/employers/${employerID}/tr`,
            name: "Tuition Reimbursement",
            value: "tuitionReimbursement",
            mini: "TR"
          },
          {
            path: `/employers/${employerID}/employees/bulkHistory`,
            name: "Bulk History",
            value: "bulkHistory",
            mini: "BH"
          }
        ]
      }

      return filter([
        {
          value: "dashboard",
          name: "Dashboard",
          icon: HomeIcon,
          path: `/employers/${employerID}/dashboard`
        },
        {
          name: "Reports",
          value: "reports",
          icon: AssessmentIcon,
          path: `/employers/${employerID}/reports`
        },
        enableFundRequest && {
          collapse: true,
          name: "Invoices",
          value: "invoices",
          path: `/employers/${employerID}/invoices`,
          mini: "I",
          views: [
            {
              path: `/employers/${employerID}/invoices/list`,
              name: "List",
              value: "invoicesList",
              mini: "IL"
            },
            {
              path: `/employers/${employerID}/invoices/contributions`,
              name: "Contributions",
              value: "contributions",
              mini: "C"
            }
          ]
        },
        {
          collapse: true,
          name: `${ellipsis(entityIdentifiers.employeeIdentifier + "s")}`,
          value: "employees",
          path: `/employers/${employerID}/employees`,
          mini: "E",
          views: employersViews
        },
        {
          name: "Groups",
          value: "groups",
          icon: PeopleIcon,
          path: `/employers/${employerID}/groups`
        },
        {
          name: `Settings`,
          value: "settings",
          icon: SettingsIcon,
          path: `/employers/${employerID}/settings/settings`
        }
      ])
      return filter
    }

    // if (role === EMPLOYER_MANAGER && employerIds.length === 1) {
    //   const employerID = employerIds[0];
    //   const enableUltipro = employer && employer.ultipro_ready;
    //   const enableFundRequest =
    //     employer && (employer["pay_enabled?"] || employer["any_pdrs"]);

    //   return filter([
    //     {
    //       value: "dashboard",
    //       name: "Dashboard",
    //       icon: HomeIcon,
    //       path: `/employers/${employerID}/dashboard`
    //     },
    //     {
    //       name: "Reports",
    //       value: "reports",
    //       icon: AssessmentIcon,
    //       path: `/employers/${employerID}/reports`
    //     },
    //     {
    //       name: "Groups",
    //       value: "groups",
    //       icon: PeopleIcon,
    //       path: `/employers/${employerID}/groups`
    //     },
    //     {
    //       collapse: true,
    //       name: `${ellipsis(entityIdentifiers.employeeIdentifier + "s")}`,
    //       value: "employees",
    //       path: `/employers/${employerID}/employees`,
    //       mini: "E",
    //       views: [
    //         {
    //           path: `/employers/${employerID}/employees/list`,
    //           name: "List",
    //           value: "employeesList",
    //           mini: "EL"
    //         },
    //         {
    //           path: `/employers/${employerID}/employees/bulkHistory`,
    //           name: "Bulk History",
    //           value: "bulkHistory",
    //           mini: "BH"
    //         }
    //       ]
    //     },
    //     enableFundRequest && {
    //       collapse: true,
    //       name: "Invoices",
    //       value: "invoices",
    //       path: `/employers/${employerID}/invoices`,
    //       mini: "I",
    //       views: [
    //         {
    //           path: `/employers/${employerID}/invoices/list`,
    //           name: "List",
    //           value: "invoicesList",
    //           mini: "IL"
    //         },
    //         {
    //           path: `/employers/${employerID}/invoices/contributions`,
    //           name: "Contributions",
    //           value: "contributions",
    //           mini: "C"
    //         }
    //       ]
    //     },
    //     {
    //       collapse: true,
    //       name: `Settings`,
    //       value: "settings",
    //       path: `/employers/${employerID}/settings`,
    //       icon: SettingsIcon,
    //       views: filter([
    //         {
    //           path: `/employers/${employerID}/settings/settings`,
    //           name: "Settings",
    //           value: "settings",
    //           mini: "S"
    //         },
    //         enableUltipro && {
    //           path: `/employers/${employerID}/settings/ultipro/settings`,
    //           name: "Ultipro Settings",
    //           value: "ultiproSettings",
    //           mini: "US"
    //         },
    //         enableUltipro && {
    //           path: `/employers/${employerID}/settings/ultipro/logs`,
    //           name: "Ultipro Logs",
    //           value: "ultiproLogs",
    //           mini: "UL"
    //         }
    //       ])
    //     }
    //   ]);
    // }

    if (includes([EMPLOYERS_ADMIN, EMPLOYER_MANAGER, SUPPORT_REP], role)) {
      routes = [
        {
          path: `/partners/${partnerID}/employers`,
          name: `${ellipsis(entityIdentifiers.employerIdentifier + "s")}`,
          icon: EmployersIcon
        }
      ];
    } else {
      routes = [
        {
          path: `/partners/${partnerID}/dashboard`,
          name: "Dashboard",
          icon: HomeIcon
        },
        {
          path: `/partners/${partnerID}/reports`,
          name: "Reports",
          icon: AssessmentIcon
        },
        {
          path: `/partners/${partnerID}/employers`,
          name: `${ellipsis(entityIdentifiers.employerIdentifier + "s")}`,
          icon: EmployersIcon
        }
      ];
    }

    if (includes([PARTNER_ADMIN, SYSTEM_ADMIN], role)) {
      routes.push({
        collapse: true,
        path: `/partners/${partnerID}/settings`,
        state: "openPartnerSettings",
        name: "Partner Settings",
        icon: SettingsIcon,
        skipTransition: true,
        views: [
          {
            path: `/partners/${partnerID}/settings/general`,
            name: "General Settings",
            mini: "G"
          },
          {
            path: `/partners/${partnerID}/settings/refi`,
            name: "Refi Vendors",
            mini: "R"
          },
          {
            path: `/partners/${partnerID}/settings/users`,
            name: "Admin Users",
            mini: "AU"
          }
        ]
      });
    }
    if (role === SYSTEM_ADMIN) {
      routes.push({
        path: `/vault`,
        name: "Vault",
        icon: GlobalIcon
      });
    }

    if (employer) {
      const enableUltipro = employer && employer.ultipro_ready;
      const enableFundRequest =
        employer && (employer["pay_enabled?"] || employer["any_pdrs"]);

      routes.push({
        divider: true,
        isText: true,
        name: employer.name
      });
      routes.push({
        divider: true,
        name: "Dashboard",
        value: "dashboard",
        path: `/employers/${employer.id}/dashboard`,
        mini: "D"
      });
      routes.push({
        name: "Reports",
        value: "reports",
        path: `/employers/${employer.id}/reports`,
        mini: "R"
      });
      routes.push({
        name: "Groups",
        value: "groups",
        path: `/employers/${employer.id}/groups`,
        mini: "G"
      });
      routes.push({
        collapse: true,
        name: `${ellipsis(entityIdentifiers.employeeIdentifier + "s")}`,
        value: "employees",
        path: `/employers/${employer.id}/employees`,
        mini: "E",
        views: [
          {
            path: `/employers/${employer.id}/employees/list`,
            name: `${ellipsis(
              entityIdentifiers.employeeIdentifier + "s"
            )} List`,
            value: "employeesList",
            mini: "EL"
          },
          {
            path: `/employers/${employer.id}/tr`,
            name: "Tuition Reimbursement",
            value: "tuitionReimbursement",
            mini: "TR"
          },
          {
            path: `/employers/${employer.id}/employees/bulkHistory`,
            name: "Bulk History",
            value: "bulkHistory",
            mini: "BH"
          },

        ]
      });
      enableFundRequest &&
        routes.push({
          collapse: true,
          name: "Invoices",
          value: "invoices",
          path: `/employers/${employer.id}/invoices`,
          mini: "I",
          views: [
            {
              path: `/employers/${employer.id}/invoices/list`,
              name: "List",
              value: "invoicesList",
              mini: "IL"
            },
            {
              path: `/employers/${employer.id}/invoices/contributions`,
              name: "Contributions",
              value: "contributions",
              mini: "C"
            },
            {
              path: `/employers/${employer.id}/match_reports`,
              name: "Match Reports",
              value: "matchReports",
              mini: "MR"
            }
          ]
        });
      routes.push({
        collapse: true,
        name: `Settings`,
        value: "settings",
        path: `/employers/${employer.id}/settings`,
        icon: SettingsIcon,
        views: filter([
          {
            path: `/employers/${employer.id}/settings/settings`,
            name: "Settings",
            value: "settings",
            mini: "S"
          },
          [SYSTEM_ADMIN, PARTNER_ADMIN].includes(role)
            ? {
                path: `/employers/${employer.id}/settings/refi`,
                name: "Refi Vendors",
                value: "refiVendors",
                mini: "R"
              }
            : null,
          {
            path: `/employers/${employer.id}/settings/contacts`,
            name: "Contacts",
            value: "contacts",
            mini: "C"
          },
          enableUltipro && {
            path: `/employers/${employer.id}/settings/ultipro/settings`,
            name: "Ultipro Settings",
            value: "ultiproSettings",
            mini: "US"
          },
          enableUltipro && {
            path: `/employers/${employer.id}/settings/ultipro/logs`,
            name: "Ultipro Logs",
            value: "ultiproLogs",
            mini: "UL"
          }
        ])
      });
    }
    return routes;
  }

  fetchPartnerData() {
    const {
      fetchPartners,
      fetchPartner,
      fetchEmployers,
      setSelectedPartner,
      location,
      role
    } = this.props;
    const partnerID = localStorage.getItem(PARTNER_ID);
    const userRole = localStorage.getItem(ROLE);

    // Fetch necessary menu data
    if (userRole === SYSTEM_ADMIN) {
      let defaultPartner;
      if (location.pathname.indexOf("partners") === -1) {
        defaultPartner = partnerID;
      } else {
        [, , defaultPartner] = location.pathname.split("/");
      }
      fetchPartners(defaultPartner);
    } else if (includes([PARTNER_ADMIN, PARTNER_MANAGER], userRole)) {
      fetchPartner(partnerID);
      setSelectedPartner(partnerID);
    } else if (partnerID) {
      setSelectedPartner(partnerID);

      if (role === EMPLOYER_MANAGER) {
        fetchEmployers(partnerID);
      }
    }
  }

  openCreatePartnerModal = () => {
    const { openModal } = this.props;
    openModal(<PartnerCreateModal />);
  };

  assembleMenuEntity = userRole => {
    const { employer, selectedPartner } = this.props;
    switch (userRole) {
      case BENEFITS_ADMIN:
        return employer;
      case EMPLOYER_MANAGER:
      case SUPPORT_REP:
      case EMPLOYERS_ADMIN:
        return {
          name: localStorage.getItem(ENTITY),
          id: localStorage.getItem(PARTNER_ID)
        };
      default:
        return selectedPartner;
    }
  };

  renderName = () => {
    const { userName } = this.props;

    return (
      <Grid container>
        <Grid item xs={6}>
          <GlobalSearchForm />
        </Grid>
        <Grid item xs={6}>
          {userName}
        </Grid>
      </Grid>
    );
  };

  render() {
    const {
      theme,
      children,
      classes,
      partners,
      selectedPartner,
      setSelectedPartner,
      openModal,
      userName,
      employer,
      entity,
      ...rest
    } = this.props;
    const { mobileOpen, miniActive } = this.state;
    const muiTheme = getMuiTheme(theme);

    // We're using JSS here because material-ui-dashboard comes with pre-configured styling we're utilizing for layouts
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: miniActive
    })}`;
    const userRole = localStorage.getItem(ROLE);
    const sidebarRoutes = this.generateSidebarRoutes();
    const isSystemAdmin = userRole === SYSTEM_ADMIN;
    const menuEntity = this.assembleMenuEntity(userRole);
    const employerName = get(employer, "name");

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div className={classes.wrapper}>
          {!this.state.ready ? (
            <WrapWithLoading loading={true}>
              <div style={{ height: "100vh" }} />
            </WrapWithLoading>
          ) : (
            <>
              <Sidebar
                routes={sidebarRoutes}
                handleDrawerToggle={this.handleDrawerToggle}
                open={mobileOpen}
                color="blue"
                bgColor="black"
                miniActive={miniActive}
                partners={partners}
                menuEntity={menuEntity}
                setSelectedPartner={setSelectedPartner}
                createPartner={this.openCreatePartnerModal}
                isSystemAdmin={isSystemAdmin}
                userName={userName}
                handleItemClick={this.handleItemClick}
                {...rest}
              />
              <div className={mainPanel} ref={this.containerRef}>
                <Header
                  sidebarMinimize={this.sidebarMinimize}
                  miniActive={miniActive}
                  routes={sidebarRoutes}
                  searchForm={<GlobalSearchForm />}
                  userName={userName}
                  employerName={employerName}
                  handleDrawerToggle={this.handleDrawerToggle}
                  {...rest}
                />
                <div className={classes.content}>{children}</div>
              </div>
              <CustomNotification />
              <TwoFactor />
              <ModalContainer />
              <Idle />
            </>
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

AuthedLayout.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

function mapStateToProps(state, props) {
  const employerID = props.routing.location.pathname.split("/")[2];
  const employersIsFetched = selectEmployersIsFetched(state);
  const employerIds = selectEmployerIDList(state) || [];
  const employers = selectEmployers(state);
  const role = localStorage.getItem(ROLE);
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    employer: selectEmployerByID(state, employerID),
    partnerID: state.partners.selectedID,
    location: state.routing.location,
    partners: selectPartnersList(state),
    selectedPartner: selectCurrentPartner(state),
    userName: state.userContext.userName,
    employersIsFetched,
    role,
    employerIds,
    employers,
    entityIdentifiers
  };
}

const enhance = compose(
  withStyles(appStyle),
  connect(
    mapStateToProps,
    {
      fetchPartners: fetchPartnersAction,
      fetchPartner: fetchPartnerAction,
      fetchEmployers,
      fetchRefiVendors,
      setSelectedPartner: setSelectedPartnerAction,
      openModal: openModalAction
    }
  )
);

export default withRouter(enhance(AuthedLayout));
