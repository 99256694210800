import PropTypes from "prop-types";

const PlaceholderProp = { placeholder: PropTypes.string };
const LabelProp = { label: PropTypes.string };
const NameProp = { name: PropTypes.string };
const LoadingProp = { loading: PropTypes.bool };
const OnSubmitProp = { onSubmit: PropTypes.func };
const OnChangeProp = { onChange: PropTypes.func };

export const SearchFormProps = {
  ...LabelProp,
  ...PlaceholderProp,
  ...NameProp,
  ...LoadingProp,
  ...OnSubmitProp,
  ...OnChangeProp,
  searchFormProps: PropTypes.shape({
    ...LabelProp,
    ...NameProp,
    ...OnSubmitProp,
    ...OnChangeProp
  })
};
