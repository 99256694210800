import makeActionCreator from "../../helpers/makeActionCreator";

const nameSpace = "employers/ultipro";

export const TEST_REQUEST = `${nameSpace}/TEST_REQUEST`;
export const TEST_SUCCESS = `${nameSpace}/TEST_SUCCESS`;
export const TEST_FAILURE = `${nameSpace}/TEST_FAILURE`;

export const SYNC_NOW_REQUEST = `${nameSpace}/SYNC_NOW_REQUEST`;
export const SYNC_NOW_SUCCESS = `${nameSpace}/SYNC_NOW_SUCCESS`;
export const SYNC_NOW_FAILURE = `${nameSpace}/SYNC_NOW_FAILURE`;

export const ENABLE_SYNC_REQUEST = `${nameSpace}/ENABLE_SYNC_REQUEST`;
export const ENABLE_SYNC_SUCCESS = `${nameSpace}/ENABLE_SYNC_SUCCESS`;
export const ENABLE_SYNC_FAILURE = `${nameSpace}/ENABLE_SYNC_FAILURE`;

export const EDIT_REQUEST = `${nameSpace}/EDIT_REQUEST`;
export const EDIT_SUCCESS = `${nameSpace}/EDIT_SUCCESS`;
export const EDIT_FAILURE = `${nameSpace}/EDIT_FAILURE`;

export const GET_LOGS_REQUEST = `${nameSpace}/GET_LOGS_REQUEST`;
export const GET_LOGS_SUCCESS = `${nameSpace}/GET_LOGS_SUCCESS`;
export const GET_LOGS_FAILURE = `${nameSpace}/GET_LOGS_FAILURE`;

export const GET_LOGS_CSV_REQUEST = `${nameSpace}/GET_LOGS_CSV_REQUEST`;
export const GET_LOGS_CSV_SUCCESS = `${nameSpace}/GET_LOGS_CSV_SUCCESS`;
export const GET_LOGS_CSV_FAILURE = `${nameSpace}/GET_LOGS_CSV_FAILURE`;

export const testRequest = makeActionCreator(
  TEST_REQUEST,
  "employer",
  "api_url"
);
export const testSuccess = makeActionCreator(TEST_SUCCESS, "employerId");
export const testFailure = makeActionCreator(TEST_FAILURE, "error");

export const editRequest = makeActionCreator(
  EDIT_REQUEST,
  "formName",
  "employerId",
  "id",
  "api_url",
  "api_username",
  "api_password",
  "api_key",
  "prestart_interval"
);
export const editSuccess = makeActionCreator(EDIT_SUCCESS);
export const editFailure = makeActionCreator(EDIT_FAILURE, "error");

export const enableSyncRequest = makeActionCreator(
  ENABLE_SYNC_REQUEST,
  "employerId",
  "id",
  "sync_enabled"
);
export const enableSyncSuccess = makeActionCreator(
  ENABLE_SYNC_SUCCESS,
  "employerId"
);
export const enableSyncFailure = makeActionCreator(
  ENABLE_SYNC_FAILURE,
  "error"
);

export const syncNowRequest = makeActionCreator(SYNC_NOW_REQUEST, "employerId");
export const syncNowSuccess = makeActionCreator(SYNC_NOW_SUCCESS);
export const syncNowFailure = makeActionCreator(SYNC_NOW_FAILURE, "error");

export const getLogsRequest = makeActionCreator(
  GET_LOGS_REQUEST,
  "employerId",
  "sort",
  "page",
  "query"
);
export const getLogsSuccess = makeActionCreator(GET_LOGS_SUCCESS, "payload");
export const getLogsFailure = makeActionCreator(GET_LOGS_FAILURE, "error");

export const getLogsCsvRequest = makeActionCreator(GET_LOGS_CSV_REQUEST, "employerId");
export const getLogsCsvSuccess = makeActionCreator(GET_LOGS_CSV_SUCCESS);
export const getLogsCsvFailure = makeActionCreator(GET_LOGS_CSV_FAILURE, "error");
