import { useFormContext } from "react-hook-form";

import usePropsTransformer from "./usePropsTransformer";

const noop = () => {};
export default ({
  loading,
  name,
  helperText,
  disabled,
  validate = noop,
  shouldValidate,
  required,
  children,
  ...props
}) => {
  const methods = useFormContext();
  const validateWithProps =
    typeof validate === "function"
      ? validate({ shouldValidate, ...props })
      : validate;
  const register = methods.register({
    ...validateWithProps,
    required:
      validateWithProps && validateWithProps.required
        ? validateWithProps.required
        : required
        ? "required"
        : false
  });
  const {
    shouldDisabled,
    shouldRequired,
    error,
    helpText
  } = usePropsTransformer({
    ...methods,
    loading,
    name,
    helperText,
    disabled,
    validate: validateWithProps
  });

  return children({
    ...props,
    hookFormMethods: methods,
    id: name,
    name,
    disabled: shouldDisabled,
    required: shouldRequired,
    error,
    helpText,
    register
  });
};
