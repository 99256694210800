import React from "react";

import useMakeFetch from "../../../services/api/useMakeFetch";
import IconButton from "../../../components/buttons/icon/IconButton";
import GroupActions from "../../../components/layouts/list/Actions";
import ExcelIcon from "../../../components/icons/ExcelIcon";
import PdfIcon from "../../../components/icons/PdfIcon";
import createZipDownloadFiles from "./createZipDownloadFiles";

function ActionsCell({ value }) {
  const fetchCombined = useMakeFetch({ method: "fetchFile" });

  const { id, pdr_id, fee_id, invoice_type } = value;

  const createHandleClick = (type = "pdf") => () => {
    if (invoice_type === "combined") {
      fetchCombined.makeFetch({ path: `/invoices/${id}/download` });
    } else {
      return createZipDownloadFiles({
        pdr_id,
        fee_id
      })(type);
    }
  };

  return (
    <GroupActions>
      <IconButton onClick={createHandleClick("pdf")}>
        <PdfIcon />
      </IconButton>
      <IconButton onClick={createHandleClick("xls")}>
        <ExcelIcon />
      </IconButton>
    </GroupActions>
  );
}

export default ActionsCell;
