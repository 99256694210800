import React from "react";
import CardContent from "@material-ui/core/CardContent";

import { SearchProps } from "./types";
import SearchForm from "../../forms/search/SearchForm";

function Search(props) {
  return (
    <CardContent>
      <SearchForm {...props} />
    </CardContent>
  );
}

Search.propTypes = SearchProps;

export default Search;
