import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFilePdf, faFileExcel } from "@fortawesome/fontawesome-free-regular";
import { Card, CardText, CardHeader } from "material-ui/Card";
import Button from "@material-ui/core/Button";

import { fetchFundsRequests as fetchFundsRequestsAction } from "./employerActions";
import { downloadInvoice as downloadInvoiceAction } from "../invoices/invoiceActions";
import { SLGTableColumn, SLGList } from "../components";
import { formatCurrency } from "../helpers/formattingHelpers";
import { selectFundsRequestsByEmployer } from "./selectors";
import SearchForm from "../components/SearchForm";
import { SORT_DESC } from "../constants";
import { Grid } from "@material-ui/core";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  textLink: { cursor: "pointer", textDecoration: "none" },
  rightAlign: { textAlign: "right" },
  floatRight: { float: "right", paddingRight: "0px" }
};

const removeType = name => {
  if (!name) return name;

  return name.slice(0, name.lastIndexOf("-"));
};

export class FundsRequests extends Component {
  /**
   * Fetches funds requests along with search query parameter and default parameters.
   *
   * TODO: Abstract query params from all tables and query functions into the redux store so they become composable
   * and work together.
   *
   * @param {string} query
   * @return {Promise}
   */
  fetchFundsRequestsQuery = async query => {
    const { fetchFundsRequests, employerID } = this.props;
    const defaultSort = { field: "created_at", order: SORT_DESC };
    const defaultPage = 1;
    return await fetchFundsRequests(
      employerID,
      defaultSort,
      defaultPage,
      query
    );
  };

  static propTypes = {
    employerID: PropTypes.string,
    fundsRequests: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    fundsRequests: [],
    employerName: "",
    employerID: ""
  };

  createRenderDownloadColumn = type => val => {
    const { downloadInvoice } = this.props;

    return (
      <Button onClick={() => downloadInvoice(val.id, type)}>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            {/* <FontAwesomeIcon
              size="lg"
              icon={type === "pdf" ? faFilePdf : faFileExcel}
              color={type === "pdf" ? "red" : "green"}
            /> */}
          </Grid>
          <Grid item>{type}</Grid>
        </Grid>
      </Button>
    );
  };

  render() {
    const {
      fundsRequests,
      total,
      fetchFundsRequests,
      employerID,
      isLoading
    } = this.props;

    const params = employerID ? [employerID] : [];

    return (
      <Card>
        <CardHeader
          title={
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
              {`Funds Requests`}
            </span>
          }
          style={styles.header}
        />
        <CardText>
          <SearchForm onSubmit={this.fetchFundsRequestsQuery} />
          <SLGList
            fetchData={fetchFundsRequests}
            params={params}
            data={fundsRequests}
            total={total}
            isLoading={isLoading}
          >
            <SLGTableColumn
              field="invoice_name"
              title="Request #"
              render={val => removeType(val.invoice_name)}
            />
            <SLGTableColumn
              field="pdr_line_items_count"
              title="Line Items"
              render={val =>
                val.invoice_type === "pdr"
                  ? val.pdr_line_items_count
                  : val.fee_line_items_count
              }
              headerStyle={styles.rightAlign}
              rowStyle={styles.rightAlign}
              headerLabelStyle={styles.floatRight}
            />
            <SLGTableColumn
              sortable={true}
              field="total_pdrs_cents"
              title="Total Funds"
              render={val => formatCurrency(val.total_pdrs_cents, true)}
            />
            <SLGTableColumn
              sortable={true}
              field="total_fees_cents"
              title="Total Fees"
              render={val => formatCurrency(val.total_fees_cents, true)}
            />

            <SLGTableColumn
              field="total_cents"
              title="Total Due"
              render={val => formatCurrency(val.total_cents, true)}
            />
            <SLGTableColumn sortable={true} field="status" title="Status" />
            <SLGTableColumn
              sortable={true}
              field="created_at"
              title="Created"
              render={val => moment(val.created_at).format("YYYY-MM-DD")}
            />
            <SLGTableColumn render={this.createRenderDownloadColumn("pdf")} />
            <SLGTableColumn render={this.createRenderDownloadColumn("xls")} />
          </SLGList>
        </CardText>
      </Card>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    fundsRequests: selectFundsRequestsByEmployer(state, props.employerID),
    total: state.fundsRequests.total,
    isLoading: state.fundsRequests.isFetching
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchFundsRequests: fetchFundsRequestsAction,
      downloadInvoice: downloadInvoiceAction
    }
  )
);

export default enhance(FundsRequests);
