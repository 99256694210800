import BaseRequest from "../../shared/BaseRequest";

export default class ApproveInvoiceRequest extends BaseRequest {
  method = "patch";

  constructor({ url, invoiceId }) {
    super({ url });

    this.data = {
      data: {
        type: "invoices",
        id: invoiceId,
        attributes: {
          status: "approved"
        }
      }
    };
  }
}
