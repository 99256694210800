import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { TextField as TextInput } from "redux-form-material-ui";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

import { required } from "../../helpers/validation";
import EmailSuggest from "./EmailSuggest";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  halfWidth: { width: "50%" },
  formButtonsBar: { marginTop: "20px", float: "right" },
  row: { marginTop: 20, marginBottom: 20 }
};

export class EditForm extends Component {
  render() {
    const {
      employerId,
      handleSubmit,
      handlePrev,
      backButtonLabel,
      submitButtonLabel,
      onEmailChange,
      isAdminType,
      initialValues
    } = this.props;

    return (
      <div>
        <div style={styles.halfWidth}>
          <div style={styles.row}>
            <EmailSuggest
              employerId={employerId}
              onChange={onEmailChange}
              onSuggestionSelected={onEmailChange}
              initialValue={initialValues ? initialValues.email : ""}
            />
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="first_name"
              component={TextInput}
              floatingLabelText={`First Name${!isAdminType ? "*" : ""}`}
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={isAdminType ? [] : [required]}
              disabled={isAdminType}
            />
          </div>
          <div>
            <Field
              name="last_name"
              component={TextInput}
              floatingLabelText={`Last Name${!isAdminType ? "*" : ""}`}
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={isAdminType ? [] : [required]}
              disabled={isAdminType}
            />
          </div>

          <div style={styles.formButtonsBar}>
            {handlePrev ? (
              <FlatButton
                label={backButtonLabel}
                type="button"
                onClick={handlePrev}
                style={{ marginRight: "12px" }}
              />
            ) : null}
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

EditForm.propTypes = {
  formName: PropTypes.string.isRequired,
  handlePrev: PropTypes.func,
  backButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  onEmailChange: PropTypes.func,
  isAdminType: PropTypes.bool
};

EditForm.defaultProps = {
  backButtonLabel: "Back",
  submitButtonLabel: "Save"
};

function mapStateToProps(state, props) {
  return {
    form: props.formName
  };
}

EditForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(EditForm);

export default connect(mapStateToProps)(EditForm);
