import { createSelector } from "reselect";

const reducerName = "ultipro";

export const selectTestFetching = state => {
  return state[reducerName].testFetching;
};

export const selectTestErrors = state => {
  return state[reducerName].testErrors;
};

export const selectEnableSyncFetching = state => {
  return state[reducerName].enableSyncFetching;
};

export const selectEnableSyncErrors = state => {
  return state[reducerName].enableSyncErrors;
};

export const selectSyncNowFetching = state => {
  return state[reducerName].syncNowFetching;
};

export const selectSyncNowErrors = state => {
  return state[reducerName].syncNowErrors;
};

export const selectGetLogsFetching = state => {
  return state[reducerName].getLogsFetching;
};

export const selectGetLogsErrors = state => {
  return state[reducerName].getLogsErrors;
};

const selectLogsById = state => state[reducerName].logs.byEmployerId;

export const selectLogsByEmployerId = createSelector(
  [selectLogsById, (_, employerId) => employerId],
  (logs, employerId) => {
    return logs[employerId];
  }
);
