import BaseRequest from "../../shared/BaseRequest";

export default class DeleteContactRequest extends BaseRequest {
  method = "delete";

  constructor({ url, contactType, employerId, contactId }) {
    super({ url });

    if (contactType === "admin_user") {
      this.params = {
        type: "admin_user",
        contact_owner_id: employerId,
        contact_owner_type: "Employer"
      };
    } else {
      this.params = { type: "light_contact" };
    }
  }
}
