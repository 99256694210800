import React from "react";
import Link from "components/links/Link";
import { formatCurrency } from "helpers";

import Tooltip from "@material-ui/core/Tooltip";

const GroupCell = ({ employer_id, group_id, group_name, group_lifetime_cap, plan_details, group_contribution_amount }) => {
  const to = `/employers/${employer_id}/groups/${group_id}`;
  const [tooltipIsOpen, setTooltipIsOpen] = React.useState(false);
  const g_contribution_amount = group_contribution_amount? formatCurrency(group_contribution_amount, true): "N/A"
  const g_lifetime_cap = group_lifetime_cap ? formatCurrency(group_lifetime_cap, true) : "N/A"
  const p_details = plan_details? plan_details : "N/A"
  return (
    <div className="App">
      <Tooltip
        open={tooltipIsOpen}
        onOpen={() => setTooltipIsOpen(true)}
        onClose={() => setTooltipIsOpen(false)}
        title= {<span><p>Plan Name: {group_name}</p><p>Contribution Amount: {g_contribution_amount}</p><p>Lifetime Caps: {g_lifetime_cap}</p><p>plan details: {p_details}</p></span>}
      >
        <span><Link to={to}>{group_name}</Link></span>
      </Tooltip>
    </div>
  );

};

export default GroupCell;
