import React from "react";

export default WC => ({ onPageChange, ...props }) => {
  const [page, setPage] = React.useState(0);

  function handleChangePage(newPage) {
    if (onPageChange) onPageChange(newPage);
    setPage(newPage);
  }

  const stateProps = {
    ...props.stateProps,
    pageState: [page, setPage]
  };

  return (
    <WC
      {...props}
      stateProps={stateProps}
      page={page}
      onChangePage={handleChangePage}
    />
  );
};
