import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import UltiproSettingsForm from "./EditForm";
import { SLGModalForm } from "../../components/modals";
import { editRequest } from "../actions/ultiproActions";

const styles = {
  modalStyle: { width: "700px" }
};

export const EmployerEditUltipro = ({
  employerId,
  ultiproIntegrations,
  editRequest,
  onClose
}) => {
  const id = ultiproIntegrations && ultiproIntegrations.id;
  const initialFields = {
    ...ultiproIntegrations
  };

  return (
    <SLGModalForm
      title="Edit Ultipro Settings"
      formName="employerEditUltipro"
      submit={(values, formName) => {
        editRequest(
          formName,
          employerId,
          id,
          values.api_url,
          values.api_username,
          values.api_password,
          values.api_key,
          values.prestart_interval
        );
      }}
      close={onClose}
      contentStyle={styles.modalStyle}
    >
      <UltiproSettingsForm
        formName="ultiproIntegrations"
        label="Ultipro Settings"
        backButtonLabel="Cancel"
        submitButtonLabel="Save"
        enableReinitialize={true}
        initialValues={initialFields}
        wrapperStyle={{ marginTop: "1em" }}
      />
    </SLGModalForm>
  );
};

EmployerEditUltipro.propTypes = {
  employerId: PropTypes.string
};

export default connect(
  null,
  {
    editRequest
  }
)(EmployerEditUltipro);
