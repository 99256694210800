import {
  FULL_FILE_UPLOAD_SUCCESS
} from "../actions/types";

const initialState = {
  fullFileUploadData:{
    fullFileStatus:[]
  }
};

const fullFileUploadReducers = (
  previousState = initialState.fullFileUploadData,
  { type, payload }
) => {
  switch (type) {
    case FULL_FILE_UPLOAD_SUCCESS:
      return {
        ...previousState,
        fullFileStatus: payload 
      };        
    default:
      return previousState;
  }
};

export default fullFileUploadReducers;
/*import createReducer from "helpers/createReducer";
import {
  FULL_FILE_UPLOAD_EMPLOYEES_REQUEST,
  FULL_FILE_UPLOAD_EMPLOYEES_SUCCESS
} from "../actions/fullFileUploadActions";

const defaultState = {
  fullFileUploadData:[]
};

function fullFileUploadEmployeesSuccess(state) {
  return Object.assign({}, state);
}

export default createReducer(defaultState, {
  [FULL_FILE_UPLOAD_EMPLOYEES_SUCCESS]: fullFileUploadEmployeesSuccess
});*/