import React from "react";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import BaseSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { SelectProps } from "./types";
import withHookForm from "../containers/withHookForm";
import BaseFormControl from "../form-control/FormControl";
import withMinWidth from "../form-control/withMinWidth";
const FormControl = withMinWidth()(BaseFormControl);

const renderItems = options =>
  options.map((option, index) => (
    <MenuItem key={option.value + index} value={option.value}>
      {option.label}
    </MenuItem>
  ));

function SelectComponent({
  name,
  label,
  helpText,
  disabled,
  required,
  error,
  options,
  onChange,
  value,
  loading,
  ...props
}) {
  /** hack to hide dropdown for empty options */
  const inputProps =
    !options || options.length < 1
      ? { ...props.inputProps, readOnly: true }
      : props.inputProps;

  return (
    <FormControl disabled={disabled} required={required} error={error}>
      <InputLabel>{label}</InputLabel>
      <BaseSelect
        name={name}
        value={value}
        onChange={onChange}
        {...props}
        inputProps={inputProps}
      >
        {renderItems(options)}
      </BaseSelect>
      <FormHelperText>{helpText}</FormHelperText>
    </FormControl>
  );
}

const Select = withHookForm(SelectComponent);

Select.propTypes = SelectProps;

export default Select;
