import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { LoadingWrapperProps } from "./types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center"
  },
  wrapper: {
    width: "100%",
    position: "relative"
  },
  progressContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

function LoadingWrapper({ size = 24, loading = false, ...props }) {
  const classes = useStyles({});

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        {props.children}
        {loading && (
          <CircularProgress size={size} className={classes.progressContainer} />
        )}
      </div>
    </div>
  );
}

LoadingWrapper.propTypes = LoadingWrapperProps;

export default LoadingWrapper;
