import React from "react";

import TextField from "./text-field/Field";

const rules = {
  required: "required"
};

const attributes = {
  name: "name",
  label: "Name",
  required: true,
  rules
};

export default function NameField(props) {
  return <TextField {...attributes} {...props} />;
}
