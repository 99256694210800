import React from "react";
import styled from "styled-components";
import { isCitizens } from "../../helpers/utils";
import vaultLogo from "./vault-logo.svg";
import citizensLogo from "./citizens-logo.svg";

const LogoImg = styled.img`
  height: ${props => props.height}
  width: 100%;
`;

const ContainerDiv = styled.div`
  height: 200px;
  width: 300px;
`;

const Logo = () => {
  const isCitizensPartner = isCitizens();
  const logoSrc = isCitizensPartner ? citizensLogo : vaultLogo;
  const logoHeight = isCitizensPartner ? "275px" : "100%";

  return (
    <ContainerDiv>
      <LogoImg src={logoSrc} height={logoHeight} />
    </ContainerDiv>
  );
};

export default Logo;
