import React from "react";

import { DialogProps } from "./types";
import Container from "./Container";
import Title from "./ConfirmationTitle";
import Actions from "./Actions";

function Dialog({
  type,
  title,
  open,
  onClose,
  onSubmit,
  submitButtonLabel,
  children,
  ...props
}) {
  return (
    <Container
      open={open}
      onClose={onClose}
      titleSlot={
        <Title type={type} onClose={onClose}>
          {title}
        </Title>
      }
      contentSlot={children}
      actionsSlot={
        <Actions
          onSubmit={onSubmit}
          onCancel={onClose}
          submitLabel={submitButtonLabel}
        />
      }
      {...props}
    />
  );
}

Dialog.propTypes = DialogProps;

export default Dialog;
