import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { get, pick } from "lodash";
import moment from "moment";

import { EmployerPayForm } from "../forms";
import { SLGModalForm, DeleteConfirmationModal } from "../../components/modals";
import { openModal as openModalAction } from "../../app/uiActions";
import { editEmployer as editEmployerAction } from "../employerActions";
import { selectEntityIdentifiers } from "../../partners/selectors";

const styles = {
  modalStyle: { width: "700px" }
};

export const EmployerEditModalPay = ({
  employer,
  editEmployer,
  onClose,
  openModal,
  entityIdentifiers
}) => {
  const initialFields = pick(employer, [
    "id",
    "pay_enabled?",
    "payroll_cycle",
    "next_payroll_date",
    "billing_address"
  ]);

  if (get(initialFields, "next_payroll_date")) {
    initialFields["contribution_date"] = {
      month: moment(get(employer, "next_payroll_date.date")).format("MMMM"),
      year: moment(get(employer, "next_payroll_date.date")).year()
    };
  }

  return (
    <SLGModalForm
      title={`Edit ${entityIdentifiers.employerIdentifier}`}
      formName="employerEditGeneral"
      submit={(...args) => {
        const editAction = () => {
          editEmployer.apply(null, [...args, initialFields]);
        };

        return initialFields["pay_enabled?"] && !args[0]["pay_enabled?"]
          ? openModal(
              <DeleteConfirmationModal
                title="Edit Employer"
                deleteAction={() => editAction()}
                message={`Disabling Pay on ${entityIdentifiers.employerIdentifier} will also disable Pay on all Groups. Do you want to continue?`}
                isDeleteAction={false}
                buttonLabel="Update"
              />
            )
          : editAction();
      }}
      close={onClose}
      contentStyle={styles.modalStyle}
    >
      <EmployerPayForm
        formName="employerEditPay"
        label="Pay"
        backButtonLabel="Cancel"
        submitButtonLabel="Save"
        enableReinitialize={true}
        initialValues={initialFields}
        hasLockedGroups={get(employer, "any_locked_groups?")}
        wrapperStyle={{ marginTop: "1em" }}
      />
    </SLGModalForm>
  );
};

EmployerEditModalPay.propTypes = {
  employer: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  editEmployer: PropTypes.func
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return {
    entityIdentifiers
  };
}

export default connect(
  mapStateToProps,
  {
    openModal: openModalAction,
    editEmployer: editEmployerAction
  }
)(EmployerEditModalPay);
