import React from "react";
import { Controller } from "react-hook-form";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormHelperText from "@material-ui/core/FormHelperText";

import withControls from "../enhancements/withControls";
import FormControl from "../form-control/FormControl";

const Picker = withControls(KeyboardDatePicker);
const PickerField = ({ helperText, ...props }) => (
  <FormControl>
    <Picker {...props} />
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl>
);

function DatePicker({ loading, ...props }) {
  return (
    <Controller as={<PickerField />} format="MM/dd/yyyy" autoOk {...props} />
  );
}

export default DatePicker;
