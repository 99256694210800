import initialState from "./initialState";
import {
  CREATE_EMPLOYEE_NOTE_SUCCESS,
  DELETE_EMPLOYEE_NOTE_SUCCESS,
  EDIT_EMPLOYEE_NOTE_SUCCESS,
  FETCH_EMPLOYEE_NOTES,
  FETCH_EMPLOYEE_NOTES_FAILURE,
  FETCH_EMPLOYEE_NOTES_SUCCESS
} from "../actions/types";
import keyBy from "lodash/keyBy";
import concat from "lodash/concat";
import omit from "lodash/omit";

export const employeeNotesReducer = (
  previousState = initialState.employeeNotes,
  { type, payload }
) => {
  switch (type) {
    case FETCH_EMPLOYEE_NOTES:
      return { ...previousState, isFetching: true };
    case FETCH_EMPLOYEE_NOTES_FAILURE:
      return { ...previousState, isFetching: false };
    case FETCH_EMPLOYEE_NOTES_SUCCESS:
      return {
        ...previousState,
        byID: {
          ...previousState.byID,
          ...keyBy(payload.data, "id")
        },
        items: payload.data.map(item => item.id),
        total: payload.total,
        receivedAt: Date.now(),
        isFetching: false
      };
    case CREATE_EMPLOYEE_NOTE_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: concat(previousState.items, payload.id),
        total: previousState.total + 1
      };
    case EDIT_EMPLOYEE_NOTE_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload }
      };
    case DELETE_EMPLOYEE_NOTE_SUCCESS:
      return {
        ...previousState,
        byID: omit(previousState.byID, payload),
        items: previousState.items.filter(item => item !== payload),
        total: previousState.total - 1
      };
    default:
      return previousState;
  }
};
