import * as R from "ramda";

export const isEmptyOrNull = R.either(R.isEmpty, R.isNil);
const toMoneyString = R.ifElse(
  isEmptyOrNull,
  R.always("$0"),
  R.pipe(
    R.toString,
    R.concat("$")
  )
);

export const toMoneyStringFromProp = R.curryN(
  2,
  R.compose(
    toMoneyString,
    R.prop
  )
);
export default toMoneyString;
