import React from "react";
import PropTypes from "prop-types";

import CardTitleText from "../typography/CardTitleText";
import CardSubTitleText from "../typography/CardSubTitleText";
import CardHeader from "./CardHeader";

const ThemedCardHeader = ({
  avatar,
  title,
  subtitle,
  titleAlign,
  showAvatar,
  renderActions,
  ...props
}) => {
  return (
    <CardHeader
      title={
        <CardTitleText align={titleAlign}>{title}</CardTitleText>
      }
      subheader={
        subtitle && (
          <CardSubTitleText align={titleAlign}>
            {subtitle}
          </CardSubTitleText>
        )
      }
      action={renderActions}
      {...props}
    />
  );
};

ThemedCardHeader.propTypes = {
  avatar: PropTypes.node,
  title: PropTypes.node.isRequired,
  titleAlign: PropTypes.oneOf(["left", "right"]),
  renderActions: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  showAvatar: PropTypes.bool
};

ThemedCardHeader.defaultProps = {
  titleAlign: "left",
  showAvatar: true,
  renderActions: false
};

export default ThemedCardHeader;
