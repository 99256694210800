import React from "react";
import * as R from "ramda";
import Grid from "@material-ui/core/Grid";

import NameField from "../../components/inputs/Name";
import NumberField from "../../components/inputs/Number";
import CheckboxField from "../../components/inputs/checkbox/Field";
import SSOFields from "../../components/inputs/SSO";
import SecurityPicker from "../../components/inputs/SecurityPicker";
import PasswordExpirationSelect from "../../components/inputs/PasswordExpirationSelect";
import PasswordCountSelect from "../../components/inputs/PasswordCountSelect";
import LandingPageSlug from "../../components/inputs/LandingPageSlug";

const disableSSO = R.includes(R.__, ["two_factor", "password_expiration"]);
const enablePasswordExpiration = R.includes(R.__, ["password_expiration"]);

function renderSSOBypass({
  defaultValue,
  security,
  onSSOChange,
  ...fieldProps
}) {
  const disabled = disableSSO(security);
  return (
    <CheckboxField
      name="sso_bypass"
      label="SSO"
      onChange={onSSOChange}
      defaultValue={defaultValue}
      {...fieldProps}
      disabled={disabled}
      helperText={
        disabled
          ? "SSO cannot be paired with Two-Factor or PW Expiration"
          : null
      }
    />
  );
}

function renderNonSSO({
  defaultValues,
  landingRoot,
  security,
  onSecurityChange,
  ...fieldProps
}) {
  return (
    <>
      <Grid item>
        <LandingPageSlug
          defaultValue={defaultValues.landing_page_slug}
          helperText={landingRoot}
          {...fieldProps}
        />
      </Grid>
      <Grid item>
        <NameField
          name="whitelisted_domains"
          label="Email Whitelist"
          required={false}
          rules={{}}
          defaultValue={defaultValues.whitelisted_domains}
          helperText="Comma separated with no @ (e.g. vault.co, gmail.com)"
          {...fieldProps}
        />
      </Grid>
      <Grid item>
        <SecurityPicker
          defaultValue={defaultValues.employer_security}
          onChange={onSecurityChange}
          {...fieldProps}
        />
      </Grid>
      {enablePasswordExpiration(security) ? (
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <PasswordExpirationSelect
                defaultValue={defaultValues.password_expiration_term}
                {...fieldProps}
              />
            </Grid>
            <Grid item xs={6}>
              <PasswordCountSelect
                defaultValue={defaultValues.old_password_count}
                {...fieldProps}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default function GeneralForm({
  landingRoot,
  defaultValues,
  partnerSSOenabled,
  handleSubmit,
  loading,
  ...fieldProps
}) {
  const [security, setSecurity] = React.useState(
    defaultValues.employer_security
  );
  const [enableSSO, setEnableSSO] = React.useState(defaultValues.sso_bypass);

  function handleSSOChange([_, v]) {
    setEnableSSO(v);
    return v;
  }

  function handleSurityChange([_, v]) {
    setSecurity(v);
    return v;
  }

  return (
    <>
      <Grid item>
        <NameField
          name="name"
          label="Employer Name"
          defaultValue={defaultValues.name}
          {...fieldProps}
        />
      </Grid>
      <Grid item>
        <NumberField
          name="total_employees"
          label="Total Number of Employees"
          defaultValue={defaultValues.total_employees}
          {...fieldProps}
        />
      </Grid>
      {partnerSSOenabled ? (
        <Grid item>
          {renderSSOBypass({
            defaultValue: defaultValues.sso_bypass,
            security,
            onSSOChange: handleSSOChange,
            ...fieldProps
          })}
        </Grid>
      ) : null}
      {enableSSO ? (
        <Grid item>
          <SSOFields
            defaultValue={defaultValues.federated_employers}
            {...fieldProps}
          />
        </Grid>
      ) : null}
      {!enableSSO ? (
        <Grid item>
          {renderNonSSO({
            defaultValues,
            landingRoot,
            security,
            onSecurityChange: handleSurityChange,
            ...fieldProps
          })}
        </Grid>
      ) : null}
    </>
  );
}
