import React from "react";
import { toDisplayDateStringFromProp } from "services/formatters";
import FileNameColumn from "./FileNameColumn";

const formatCreatedAt = toDisplayDateStringFromProp("created_at");
export default WC => ({ createDownloadPathFromRow, ...props }) => {
  const columns = [
    {
      name: "file_name",
      label: "Name",
      render: row => (
        <FileNameColumn
          row={row}
          createDownloadPathFromRow={createDownloadPathFromRow}
        />
      )
    },
    {
      name: "created_at",
      label: "Created",
      format: formatCreatedAt
    }
  ];

  return <WC {...props} columns={columns} />;
};
