import createReducer from "../../helpers/createReducer";
import {
  GET_REFI_VENDORS_REQUEST,
  GET_REFI_VENDORS_SUCCESS,
  GET_REFI_VENDORS_FAILURE,
  TOGGLE_VISIBILITY_REQUEST,
  TOGGLE_VISIBILITY_SUCCESS,
  TOGGLE_VISIBILITY_FAILURE,
  TOGGLE_REQUEST,
  TOGGLE_SUCCESS,
  TOGGLE_FAILURE
} from "../actions/refiVendorActions";

const defaultState = {
  byEmployerId: {},
  isFetching: false,
  fetchingErrors: null,
  toggleVisibilityFetching: false,
  toggleVisibilityError: null,
  toggleFetching: {
    byVendorId: {}
  },
  toggleErrors: {
    byVendorId: {}
  }
};

/** vendors */
function requestVendors(state) {
  return Object.assign({}, state, { isFetching: true, fetchingErrors: null });
}

function requestVendorsSuccess(state, action) {
  return Object.assign({}, state, {
    isFetching: false,
    fetchingErrors: null,
    byEmployerId: Object.assign({}, state.byEmployerId, action.data)
  });
}

function requestVendorsError(state, action) {
  return Object.assign({}, state, {
    isFetching: false,
    fetchingErrors: action.errors
  });
}

/** visibility toggle */
function toggleVisibilityRequest(state) {
  return Object.assign({}, state, {
    toggleVisibilityFetching: true,
    toggleVisibilityError: null
  });
}

function toggleVisibilitySuccess(state) {
  return Object.assign({}, state, {
    toggleVisibilityFetching: false,
    toggleVisibilityError: null
  });
}
function toggleVisibilityFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    toggleVisibilityFetching: false,
    toggleVisibilityError: error
  });
}

/** vendor toggle */
function toggleRequest(state, action) {
  const vendorId = action.vendorId;
  const toggleFetching = {
    byVendorId: Object.assign({}, state.toggleFetching.byVendorId, {
      [vendorId]: true
    })
  };
  const toggleErrors = {
    byVendorId: Object.assign({}, state.toggleErrors.byVendorId, {
      [vendorId]: null
    })
  };
  return Object.assign({}, state, {
    toggleFetching,
    toggleErrors
  });
}

function toggleSuccess(state, action) {
  const vendorId = action.vendorId;
  const toggleFetching = {
    byVendorId: Object.assign({}, state.toggleFetching.byVendorId, {
      [vendorId]: false
    })
  };
  const toggleErrors = {
    byVendorId: Object.assign({}, state.toggleErrors.byVendorId, {
      [vendorId]: null
    })
  };
  return Object.assign({}, state, {
    toggleFetching,
    toggleErrors
  });
}

function toggleFailure(state, action) {
  const vendorId = action.vendorId;
  const error = action.error;
  const toggleFetching = {
    byVendorId: Object.assign({}, state.toggleFetching.byVendorId, {
      [vendorId]: false
    })
  };
  const toggleErrors = {
    byVendorId: Object.assign({}, state.toggleErrors.byVendorId, {
      [vendorId]: error
    })
  };
  return Object.assign({}, state, {
    toggleFetching,
    toggleErrors
  });
}

export default createReducer(defaultState, {
  [GET_REFI_VENDORS_REQUEST]: requestVendors,
  [GET_REFI_VENDORS_SUCCESS]: requestVendorsSuccess,
  [GET_REFI_VENDORS_FAILURE]: requestVendorsError,
  [TOGGLE_VISIBILITY_REQUEST]: toggleVisibilityRequest,
  [TOGGLE_VISIBILITY_SUCCESS]: toggleVisibilitySuccess,
  [TOGGLE_VISIBILITY_FAILURE]: toggleVisibilityFailure,
  [TOGGLE_REQUEST]: toggleRequest,
  [TOGGLE_SUCCESS]: toggleSuccess,
  [TOGGLE_FAILURE]: toggleFailure
});
