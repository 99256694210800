import React from "react";
import BasePagination from "@material-ui/core/TablePagination";

import { PaginationProps } from "../types";
import usePagination from "./usePagination";

function Pagination({
  count,
  defaultRowsPerPage = 25,
  rowsPerPageOptions = [],
  onChangePage
}) {
  const { rowsPerPage, page, handleChangePage } = usePagination({
    defaultRowsPerPage,
    onChangePage
  });

  return (
    <BasePagination
      count={count}
      page={page}
      rowsPerPageOptions={rowsPerPageOptions}
      rowsPerPage={rowsPerPage}
      component="div"
      backIconButtonProps={{
        "aria-label": "previous page"
      }}
      nextIconButtonProps={{
        "aria-label": "next page"
      }}
      onChangePage={handleChangePage}
    />
  );
}

Pagination.propTypes = PaginationProps;

export default Pagination;
