import React from "react";
import PropTypes from "prop-types";
import BaseLink from "@material-ui/core/Link";

function Link({ onClick, ...props }) {
  return (
    <BaseLink component="button" variant="body2" onClick={onClick} {...props} />
  );
}

Link.propTypes = {
  onClick: PropTypes.func
};

export default Link;
