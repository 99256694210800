import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import CardIcon from "packages/mui-dashboard/components/Card/CardIcon.jsx";
import CardHeader from "packages/mui-dashboard/components/Card/CardHeader.jsx";
import dashboardStyle from "packages/mui-dashboard/assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import { DownloadButton, Loading } from "components";
import { Tooltip } from "@material-ui/core";
/*
 * To be used with Card / Card Body (mui-dashboard)
 * <Card>
 *  <VaultCardHeaderIcon />
 *  <CardBody>
 *    Content
 *  </CardBody>
 * </Card>
 */

const VaultCardHeaderIcon = props => {
  const { classes, icon, title, data, condensed, isLoading, color, headerTooltip, downloadCsv, hasDownloadCsv } = props;

  return (
    <CardHeader
      className={classes.heightOverride}
      color={color}
      stats={condensed}
      icon
    >
      <CardIcon className={classes.headerOverride} color={color}>
        {React.cloneElement(icon, { classes: { root: classes.svgOverride } })}
      </CardIcon>
      {condensed ? (
        <p className={classes.cardCategory}>{title}</p>
      ) : (
    <Tooltip title={headerTooltip ? headerTooltip : ''} placement="bottom">

        <h4 style={{ fontSize: "1.3em", display:'flex', justifyContent:'space-between', position:'relative' }} className={classes.cardIconTitle}>
          {title} 
          {hasDownloadCsv && (
         <span style={{position:'absolute',top:'-100%',right:'0'}}> <DownloadButton recordID="topEmployerCsv" downloadAction={downloadCsv} /></span>
          )}
        </h4>
    </Tooltip>

      )}

      {!isLoading ? (
        data && (
          <h3 style={{ fontSize: "1.625em" }} className={classes.cardTitle}>
            {data}
          </h3>
        )
      ) : (
        <Loading />
      )}
    </CardHeader>
  );
};

VaultCardHeaderIcon.propTypes = {
  classes: PropTypes.shape({
    cardCategory: PropTypes.string.isRequired,
    cardIconTitle: PropTypes.string.isRequired,
    cardTitle: PropTypes.string.isRequired,
    svgOverride: PropTypes.string.isRequired,
    headerOverride: PropTypes.string
  }).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  condensed: PropTypes.bool
};

VaultCardHeaderIcon.defaultProps = {
  data: null,
  condensed: false,
  isLoading: false,
  title: ""
};

export default withStyles({
  ...dashboardStyle,
  svgOverride: {
    height: "32px !important",
    width: "32px !important"
  },
  heightOverride: {
    height: "68px"
  }
})(VaultCardHeaderIcon);
