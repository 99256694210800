import React, { createElement } from "react";
import PropTypes from "prop-types";
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { Provider } from "react-redux";
import { createHashHistory as createHistory } from "history";
import {
  ConnectedRouter,
  routerMiddleware,
  routerReducer
} from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import { all, fork } from "redux-saga/effects";
import { Route, Switch } from "react-router-dom";
import { merge } from "lodash";
import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme
} from "@material-ui/core/styles";
import * as Sentry from "@sentry/browser";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import notistackConfig from "../configs/notistack";
import NotFound from "./NotFound";
import Login from "../login/Login";
import reducers from "./reducers";
import sagas from "./sagas";
import AuthedLayout from "../layouts/AuthedLayout";
import { USER_LOGOUT } from "../auth/authActions";
import customRoutes from "./customRoutes";
import withAuth from "../auth/withAuth";

const AuthedRoute = withAuth(Route);

const slgTheme = {
  palette: {
    brand: "#ff7842",
    primary: { main: "#00acc1" },
    primary1Color: "#00acc1",
    primary2Color: "#0094FF",
    accent1Color: "#1FE58A"
  },
  raisedButton: {
    primaryColor: "#00acc1"
  },
  drawer: {
    width: "90px"
  },
  tableRow: {
    stripeColor: "#e0e0e0"
  }
};

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_ERROR_REPORTING_KEY
  });
}

const themeNext = createMuiTheme({
  palette: {
    brand: "#ff7842",
    primary: { main: "#00acc1" },
    secondary: { main: "#1FE58A" },
    action: {
      active: "#00acc1"
    }
  }
});

//TODO(ZDS): Figure out how we want to handle theme logic for prudential / slg / other partners
export const theme = merge({}, slgTheme);
const App = ({ children, history }) => {
  const appReducer = combineReducers({
    form: formReducer,
    routing: routerReducer,
    ...reducers
  });

  const resettableAppReducer = (state, action) =>
    appReducer(action.type !== USER_LOGOUT ? state : undefined, action);

  const saga = function* rootSaga() {
    yield all([...sagas].map(fork));
  };
  const sagaMiddleware = createSagaMiddleware();
  const routerHistory = createHistory();

  const store = createStore(
    resettableAppReducer,
    undefined,
    compose(
      applyMiddleware(sagaMiddleware, routerMiddleware(routerHistory)),
      typeof window !== "undefined" && window.devToolsExtension
        ? window.devToolsExtension()
        : f => f
    )
  );

  sagaMiddleware.run(saga);

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={themeNext}>
        <SnackbarProvider {...notistackConfig}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ConnectedRouter history={routerHistory}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  render={({ location }) =>
                    createElement(Login, {
                      location,
                      theme
                    })
                  }
                />

                {/* Render routes without SLG Layout */}
                {customRoutes
                  .filter(route => route.props.noLayout)
                  .map((route, index) => (
                    <Route
                      key={index}
                      exact={route.props.exact}
                      path={route.props.path}
                      render={({ location }) => {
                        if (route.props.render) {
                          return route.props.render({
                            location,
                            theme
                          });
                        }
                        if (route.props.component) {
                          return createElement(route.props.component, {
                            location,
                            theme
                          });
                        }
                      }}
                    />
                  ))}

                {/* Render routes with SLG Layout */}
                <AuthedRoute
                  path="/"
                  render={routing => {
                    return createElement(AuthedLayout, {
                      routing,
                      children: (
                        <Switch>
                          {customRoutes &&
                            customRoutes.map((route, index) => (
                              <Route
                                key={index}
                                exact={route.props.exact}
                                path={route.props.path}
                                component={route.props.component}
                                render={route.props.render}
                                children={route.props.children} // eslint-disable-line react/no-children-prop
                              />
                            ))}
                          <Route component={NotFound} />
                        </Switch>
                      ),
                      customRoutes: customRoutes.filter(
                        route => !route.props.noLayout
                      ),
                      theme
                    });
                  }}
                />
              </Switch>
            </ConnectedRouter>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

App.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.node
};

App.defaultProps = {};

export default App;
