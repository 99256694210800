import makeActionCreator from "../../helpers/makeActionCreator";

const nameSpace = "invoices";

export const GET_INVOICES_REQUEST = `${nameSpace}/GET_INVOICES_REQUEST`;
export const GET_INVOICES_SUCCESS = `${nameSpace}/GET_INVOICES_SUCCESS`;
export const GET_INVOICES_FAILURE = `${nameSpace}/GET_INVOICES_FAILURE`;

export const getInvoicesRequest = makeActionCreator(
  GET_INVOICES_REQUEST,
  "sort",
  "page",
  "query",
  "fields"
);
export const getInvoicesSuccess = makeActionCreator(
  GET_INVOICES_SUCCESS,
  "invoices"
);
export const getInvoicesFailure = makeActionCreator(
  GET_INVOICES_FAILURE,
  "error"
);

export const APPROVE_REQUEST = `${nameSpace}/APPROVE_REQUEST`;
export const APPROVE_SUCCESS = `${nameSpace}/APPROVE_SUCCESS`;
export const APPROVE_FAILURE = `${nameSpace}/APPROVE_FAILURE`;

export const approveRequest = makeActionCreator(APPROVE_REQUEST, "invoiceId");
export const approveSuccess = makeActionCreator(APPROVE_SUCCESS, "invoiceId");
export const approveFailure = makeActionCreator(APPROVE_FAILURE, "error");

export const SELECTED_INVOICE_UPDATE = `${nameSpace}/SELECTED_INVOICE_UPDATE`;
export const selectedInvoiceUpdate = makeActionCreator(
  SELECTED_INVOICE_UPDATE,
  "invoiceId"
);

export const GET_INVOICE_REQUEST = `${nameSpace}/GET_INVOICE_REQUEST`;
export const GET_INVOICE_SUCCESS = `${nameSpace}/GET_INVOICE_SUCCESS`;
export const GET_INVOICE_FAILURE = `${nameSpace}/GET_INVOICE_FAILURE`;

export const getInvoiceRequest = makeActionCreator(
  GET_INVOICE_REQUEST,
  "invoiceId"
);
export const getInvoiceSuccess = makeActionCreator(
  GET_INVOICE_SUCCESS,
  "invoiceId",
  "invoice"
);
export const getInvoiceFailure = makeActionCreator(
  GET_INVOICE_FAILURE,
  "error"
);

export const GET_COMBINED_INVOICE_REQUEST = `${nameSpace}/GET_COMBINED_INVOICE_REQUEST`;
export const GET_COMBINED_INVOICE_SUCCESS = `${nameSpace}/GET_COMBINED_INVOICE_SUCCESS`;
export const GET_COMBINED_INVOICE_FAILURE = `${nameSpace}/GET_COMBINED_INVOICE_FAILURE`;

export const getCombinedInvoiceRequest = makeActionCreator(
  GET_COMBINED_INVOICE_REQUEST,
  "id",
  "pdrInvoiceId",
  "feeInvoiceId"
);
export const getCombinedInvoiceSuccess = makeActionCreator(
  GET_COMBINED_INVOICE_SUCCESS,
  "id",
  "invoice"
);
export const getCombinedInvoiceFailure = makeActionCreator(
  GET_COMBINED_INVOICE_FAILURE,
  "error"
);

export const GET_EXCLUSION_REQUEST = `${nameSpace}/GET_EXCLUSION_REQUEST`;
export const GET_EXCLUSION_SUCCESS = `${nameSpace}/GET_EXCLUSION_SUCCESS`;
export const GET_EXCLUSION_FAILURE = `${nameSpace}/GET_EXCLUSION_FAILURE`;

export const getExclusionRequest = makeActionCreator(
  GET_EXCLUSION_REQUEST,
  "invoiceId"
);
export const getExclusionSuccess = makeActionCreator(
  GET_EXCLUSION_SUCCESS,
  "exclusion"
);
export const getExclusionFailure = makeActionCreator(
  GET_EXCLUSION_FAILURE,
  "error"
);

export const GET_COMBINED_EXCLUSION_REQUEST = `${nameSpace}/GET_COMBINED_EXCLUSION_REQUEST`;
export const GET_COMBINED_EXCLUSION_SUCCESS = `${nameSpace}/GET_COMBINED_EXCLUSION_SUCCESS`;
export const GET_COMBINED_EXCLUSION_FAILURE = `${nameSpace}/GET_COMBINED_EXCLUSION_FAILURE`;

export const getCombinedExclusionRequest = makeActionCreator(
  GET_COMBINED_EXCLUSION_REQUEST,
  "pdrInvoiceId",
  "feeInvoiceId"
);
export const getCombinedExclusionSuccess = makeActionCreator(
  GET_COMBINED_EXCLUSION_SUCCESS,
  "exclusion"
);
export const getCombinedExclusionFailure = makeActionCreator(
  GET_COMBINED_EXCLUSION_FAILURE,
  "error"
);

export const RESTORE_REQUEST = `${nameSpace}/RESTORE_REQUEST`;
export const RESTORE_SUCCESS = `${nameSpace}/RESTORE_SUCCESS`;
export const RESTORE_FAILURE = `${nameSpace}/RESTORE_FAILURE`;

export const restoreRequest = makeActionCreator(
  RESTORE_REQUEST,
  "invoiceId",
  "itemId",
  "invoiceLineItemId"
);
export const restoreSuccess = makeActionCreator(
  RESTORE_SUCCESS,
  "invoiceId",
  "itemId"
);
export const restoreFailure = makeActionCreator(
  RESTORE_FAILURE,
  "invoiceItemId",
  "error"
);

export const DELETE_ITEM_REQUEST = `${nameSpace}/DELETE_ITEM_REQUEST`;
export const DELETE_ITEM_SUCCESS = `${nameSpace}/DELETE_ITEM_SUCCESS`;
export const DELETE_ITEM_FAILURE = `${nameSpace}/DELETE_ITEM_FAILURE`;

export const deleteItemRequest = makeActionCreator(
  DELETE_ITEM_REQUEST,
  "invoiceId",
  "invoiceItemId",
  "pullingForId"
);
export const deleteItemSuccess = makeActionCreator(
  DELETE_ITEM_SUCCESS,
  "invoiceId",
  "invoiceItemId",
  "pullingForId"
);
export const deleteItemFailure = makeActionCreator(
  DELETE_ITEM_FAILURE,
  "invoiceItemId",
  "error"
);
