import passwordSaga from "../login/passwordSaga";
import adminUserSaga from "../adminUsers/adminUserSaga";
import partnerSaga from "../partners/partnerSaga";
import partnerSagaExt from "../partners/sagas";
import employerSaga from "../employers/employerSaga";
import employerSagaExt from "../employers/sagas";
import groupSaga from "../groups/sagas";
import employeeSaga from "../employees/sagas";
import twoFactorSaga from "../twoFactor/twoFactorSaga";
import refiVendorSaga from "../refiVendors/refiVendorSaga";
import authSaga from "../auth/authSaga";
import groupsUltiproSaga from "../groups/sagas/ultipro";
import invoiceSaga from "../invoices/invoiceSaga";
import invoiceSagaExt from "../invoices/sagas/invoices";

export default [
  passwordSaga,
  adminUserSaga,
  ...partnerSagaExt,
  partnerSaga,
  ...employerSagaExt,
  employerSaga,
  groupSaga,
  employeeSaga,
  twoFactorSaga,
  refiVendorSaga,
  authSaga,
  groupsUltiproSaga,
  invoiceSagaExt,
  invoiceSaga
];
