import { compose } from "ramda";

import Button from "../button/Button";
import asText from "../button/asText";
import withWhiteLabel from "../button/withWhiteLabel";

export default compose(
  asText,
  withWhiteLabel
)(Button);
