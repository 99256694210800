import { get, last, replace, split, startCase } from "lodash";

import HttpError from "./HttpError";
import { TOKEN } from "../constants";

const _handleResponse = response =>
  response.headers.get("Content-Type") === "application/vnd.ms-excel"
    ? response.blob()
    : response.text();

const _handleError = error => {
  return error
    .map(item => {
      if (item && typeof item === "object") {
        // json errors return field name in the source.pointer
        // Get the last part of the pointer, e.g. "/data/attributes/email" => "Email"
        const errorPointer = last(split(get(item, "source.pointer"), "/"));
        return (
          startCase(replace(errorPointer, "_", " ")) + " " + get(item, "detail")
        );
      } else {
        return item;
      }
    })
    .join(" ");
};

// Add authentication here
export const fetchJson = (url, options = {}) => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: "application/vnd+slg.json"
    });
  if (
    !requestHeaders.has("Content-Type") &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/json");
  }

  if (localStorage.getItem(TOKEN)) {
    requestHeaders.set("Authorization", localStorage.getItem(TOKEN));
  }

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      _handleResponse(response).then(body => {
        return {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: body
        };
      })
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        let errorMessage;
        if (json && json.errors) {
          errorMessage = _handleError(json.errors);
        }
        return Promise.reject(
          new HttpError(errorMessage || statusText, status)
        );
      }
      return { status, headers, body, json };
    });
};

export const jsonApiHttpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/vnd.slg+json" });
  }
  options.headers.set("Content-Type", "application/vnd.api+json");
  return fetchJson(url, options);
};

export const queryParameters = data =>
  Object.keys(data)
    .map(key => [key, data[key]].map(encodeURIComponent).join("="))
    .join("&");
