import React from "react";
import * as R from "ramda";
import { toDisplayDateStringFromProp } from "services/formatters";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import PageAction from "./PageAction";
import ActionsCell from "./ActionsCell";

const renderAuthor = r =>
  `${r.admin_users.first_name} ${r.admin_users.last_name}`.trim();

export const columns = props => [
  {
    name: "note",
    label: "Note"
  },
  {
    name: "author.name",
    label: "Author",
    render: renderAuthor
  },
  {
    name: "created_at",
    label: "Created",
    format: toDisplayDateStringFromProp("created_at")
  },
  {
    name: "updated_at",
    label: "Updated",
    format: toDisplayDateStringFromProp("updated_at")
  },
  {
    name: "id",
    label: "Actions",
    disableSort: true,
    render: r => <ActionsCell {...props} {...r} />
  }
];

const createGetSearchFilterWith = props =>
  R.applySpec({
    "0": { customer_id_eq: R.always(props.employeeId) }
  });

const renderAction = props => ({ disabled }) => (
  <PageAction {...props} disabled={disabled} />
);

export const extraProps = props => {
  return {
    defaultSort: { order: "asc", orderBy: "updated_at" },
    title: "Notes",
    path: props.employeeId && "/notes",
    hideSearch: true,
    getSearchFilter: createGetSearchFilterWith({ ...props })
  };
};

const actionsProps = props => {
  return {
    columns: columns(props),
    actionSlot: renderAction({ ...props })
  };
};

export default R.compose(
  withProps(extraProps),
  withSort,
  withSearch,
  withPagination,
  withGetFetch,
  withProps(actionsProps)
)(PageComponent);
