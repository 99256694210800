import React from "react";
import PropTypes from "prop-types";

// core components
import CardFooter from "packages/mui-dashboard/components/Card/CardFooter.jsx";
import Card from "packages/mui-dashboard/components/Card/Card.jsx";
import { withStyles } from "@material-ui/core/styles";
import dashboardStyle from "packages/mui-dashboard/assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import { VaultCardHeaderIcon } from "components";

/*
 * Ex:
 * <StatCard
 *   title="Total Debt"
 *   icon={<AttachMoney />}
 *   data="$452"
 *   footer="Advisor"
 *   isLoading={isLoading}
 * />
 */

const StatCard = props => {
  const { classes, icon, title, data, footer, isLoading, color, toolTip } = props;

  return (
    <Card toolTip={toolTip} style={{cursor: toolTip ? 'pointer': 'auto'}}>
      <VaultCardHeaderIcon
        icon={icon}
        title={title}
        data={data}
        isLoading={isLoading}
        color={color}
        condensed
      />
      <CardFooter stats>
        <div className={classes.stats}>{footer}</div>
      </CardFooter>
    </Card>
  );
};

StatCard.propTypes = {
  classes: PropTypes.shape({ stats: PropTypes.string }).isRequired,
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.element.isRequired,
  color: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

StatCard.defaultProps = { title: "", data: "", footer: "", isLoading: false };

export default withStyles({
  ...dashboardStyle
})(StatCard);
