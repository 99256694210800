import React from "react";
import * as R from "ramda";

import {
  toYesNoStringFromProp,
  toDisplayDateStringFromProp
} from "services/formatters";
import withEmployerIdFromRouter from "components/containers/utils/withEmployerIdFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import PageAction from "./PageAction";
import ActionsCell from "./ActionsCell";

const isEmptyOrNil = R.either(R.isEmpty, R.isNil);
const renderName = v =>
  `${R.pathOr("", ["first_name"], v)} ${R.pathOr("", ["last_name"], v)}`.trim();
const formatRegistered = R.pipe(
  R.prop("invitation_accepted_at"),
  R.ifElse(
    isEmptyOrNil,
    R.always("No"),
    toDisplayDateStringFromProp("invitation_accepted_at")
  )
);
export const columns = props => [
  {
    name: "first_name",
    label: "Name",
    render: renderName
  },
  {
    name: "email",
    label: "Email"
  },
  {
    name: "active",
    label: "Active",
    format: toYesNoStringFromProp("active")
  },
  {
    name: "invitation_accepted_at",
    label: "Registered",
    format: formatRegistered
  },
  {
    name: "id",
    label: "Actions",
    render: r => <ActionsCell {...props} {...r} />
  }
];

const getSearchFilter = () => ({
  "0": {
    role_eq: "system_admin"
  }
});
const renderAction = props => ({ disabled }) => (
  <PageAction {...props} disabled={disabled} />
);

export function extraProps(props) {
  return {
    title: "VAULT ADMIN USERS",
    path: "/admin_users",
    getSearchFilter: getSearchFilter
  };
}

function actionsProps(props) {
  return {
    columns: columns(props),
    actionSlot: renderAction(props)
  };
}

function filterData(props) {
  return {
    rows: props.rows.filter(v => v.attributes.role === "system_admin")
  };
}

export default R.compose(
  withEmployerIdFromRouter,
  withProps(extraProps),
  withSort,
  withSearch,
  withPagination,
  withGetFetch,
  withProps(actionsProps),
  withProps(filterData)
)(PageComponent);
