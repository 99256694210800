import React from "react";
import { throttle } from "lodash";

import useFetch from "./utils/useFetch";

export default WC => ({ path, sort, filter, ...props }) => {
  const [data, setData] = React.useState([]);
  const getData = useFetch({ method: "get" });

  let latestFetch = React.useRef(getData.makeFetch);
  React.useEffect(() => {
    latestFetch.current = getData.makeFetch;
  });
  const fetch = React.useCallback(
    throttle(() => {
      if (path) {
        function handleSuccess({ data }) {
          setData(data);
        }

        latestFetch.current({ path, sort, filter, onSuccess: handleSuccess });
      }
    }, 500),
    [path, sort, filter]
  );
  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return <WC {...props} options={data} loading={getData.loading} />;
};
