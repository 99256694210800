import { put, all, call, takeEvery } from "redux-saga/effects";
import log from "services/logging/service";
import fetchApiService from "rest/fetchApiService";
import { showError, showSuccess } from "shared/sagas/notifications";
import {
  SEND_INVITES_REQUEST,
  sendInvitesSuccess,
  sendInvitesFailure
} from "groups/actions/ultiproActions";
import UltiproSendInvitesRequest from "groups/services/UltiproSendInvitesRequest";
import UltiproSendInvitesResponse from "groups/services/UltiproSendInvitesResponse";
import routeService from "groups/services/RouteService";

function* sendInvites({ employerId, groupId }) {
  try {
    const endpoint = routeService.sendInvites({ employerId, groupId });
    const request = new UltiproSendInvitesRequest({
      url: endpoint
    });

    const response = yield fetchApiService.fetch(
      request,
      UltiproSendInvitesResponse
    );
    if (response.ok) {
      yield call(showSuccess, "Invites sent!");
      yield put(sendInvitesSuccess());
    } else {
      yield call(showError, response.statusText);
      yield put(sendInvitesFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(sendInvitesFailure(e.message));
    log.error(e.message);
  }
}

export default function* sagas() {
  yield all([takeEvery(SEND_INVITES_REQUEST, sendInvites)]);
}
