import BaseRequest from "shared/BaseRequest";

export default class BatchCreateEligibleEmployeesRequest extends BaseRequest {
  method = "post";

  constructor({ url, file, employeesList }) {
    super({ url });

    const formData = new FormData();
    formData.append("data[file]", file);
    formData.append("data[file_data]", JSON.stringify(employeesList));
    this.data = formData;
  }
}
