import React from "react";
import PropTypes from "prop-types";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  overrides: {
    MuiFormHelperText: {
      // Name of the rule
      root: {
        "&$error": {
          color: "yellow"
        }
      }
    }
  }
});

const OverridesErrorHelperText = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

OverridesErrorHelperText.propTypes = {
  children: PropTypes.node.isRequired
};

export default OverridesErrorHelperText;
