import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Snackbar from "material-ui/Snackbar";
import { hideNotification as hideNotificationAction } from "../app/uiActions";

const styles = {
  confirmColor: "#5bc57f",
  warningColor: "#ff0033"
};
export class CustomNotification extends React.Component {
  handleRequestClose = () => {
    this.props.hideNotification();
  };

  render() {
    const style = { maxWidth: "100%" };
    const { type, message } = this.props;
    if (type === "warning") {
      style.backgroundColor = styles.warningColor;
    }
    if (type === "confirm" || type === "info") {
      style.backgroundColor = styles.confirmColor;
    }

    return (
      <Snackbar
        open={!!message}
        message={message}
        autoHideDuration={4000}
        onRequestClose={this.handleRequestClose}
        bodyStyle={style}
      />
    );
  }
}

CustomNotification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string.isRequired,
  hideNotification: PropTypes.func.isRequired
};

CustomNotification.defaultProps = {
  type: "info"
};

const mapStateToProps = state => ({
  message: state.ui.notification.text,
  type: state.ui.notification.type
});

export default connect(mapStateToProps, {
  hideNotification: hideNotificationAction
})(CustomNotification);
