import React from "react";
import PropTypes from "prop-types";
import IconButton from "material-ui/IconButton";
import FileDownload from "material-ui/svg-icons/file/file-download";

export const DownloadButton = props => {
  const { downloadAction, recordID } = props;

  return (
    <IconButton onClick={() => downloadAction(recordID)}>
      <FileDownload />
    </IconButton>
  );
};

DownloadButton.propTypes = {
  downloadAction: PropTypes.func.isRequired,
  recordID: PropTypes.string.isRequired
};

export default DownloadButton;
