import React from "react";

import Select from "./selects/EnhancedSelect";

const options = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 }
];

const rules = {
  required: "required"
};

export default function PasswordCountSelect(props) {
  return (
    <Select
      name="old_password_count"
      label="PWs Remembered"
      required
      rules={rules}
      options={options}
      {...props}
    />
  );
}
