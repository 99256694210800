import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { withRouter } from "react-router-dom";

import {
  SORT_DESC,
  BENEFITS_ADMIN,
  EMPLOYER_MANAGER,
  SUPPORT_REP
} from "../constants";
import { searchRequest } from "./actions/searchActions";
import { selectCurrentPartner } from "../partners/selectors";
import { fetchEmployers } from "../employers/employerActions";
import { fetchEmployees } from "../employees/actions";
import SearchForm from "../components/SearchForm";

const employeeFields = {
  customers: ["full_name", "email", "employer_id"]
};

const employerFields = {
  customers: ["name"]
};

class GlobalSearchForm extends React.Component {
  state = {
    resetForm: false
  };

  componentDidUpdate(previousProps) {
    const pLocation = previousProps.location;
    const { location } = this.props;

    if (pLocation && pLocation !== location) {
      if (location && location.pathname.indexOf("globalSearch") === -1) {
        this.resetForm();
      }
    }
  }

  fetchQuery = query => {
    const {
      role,
      partnerID,
      fetchEmployers,
      fetchEmployees,
      searchRequest,
      entityID
    } = this.props;

    const defaultSort = { field: "created_at", order: SORT_DESC };

    searchRequest(query);

    /**
     * single employer
     */
    const type =
      [BENEFITS_ADMIN, EMPLOYER_MANAGER, SUPPORT_REP].includes(role) && entityID
        ? "employer"
        : "partner";
    fetchEmployers(partnerID, true, defaultSort, 1, query, employerFields);
    fetchEmployees(
      type === "partner" ? partnerID : entityID,
      defaultSort,
      1,
      null,
      query,
      type,
      employeeFields
    );

    if (type === "employer") {
      return this.props.history.push(`/employers/${entityID}/globalSearch`);
    }
    return this.props.history.push(`/partners/${partnerID}/globalSearch`);
  };

  resetForm() {
    this.setState({ resetForm: true });
  }

  handleResetFormCompleted = () => {
    this.setState({ resetForm: false });
  };

  render() {
    return (
      <SearchForm
        onSubmit={this.fetchQuery}
        resetForm={this.state.resetForm}
        onResetFormCompleted={this.handleResetFormCompleted}
      />
    );
  }
}

GlobalSearchForm.propTypes = {
  partnerId: PropTypes.string,
  fetchEmployers: PropTypes.func.isRequired,
  fetchEmployees: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const partner = selectCurrentPartner(state);
  const partnerID = state.partners.selectedID;
  const entityID = state.userContext.entityID;
  const employerID = state.userContext.employerID;
  const role = state.userContext.userRole;
  return {
    role,
    partnerID,
    partner,
    entityID: entityID || employerID
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      searchRequest,
      fetchEmployers,
      fetchEmployees
    }
  )
);

export default withRouter(enhance(GlobalSearchForm));
