import React from "react";
import PropTypes from "prop-types";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

class BaseSwitch extends React.PureComponent {
  render() {
    const { row, label, ...props } = this.props;
    return (
      <FormGroup row={row}>
        <FormControlLabel
          control={<Switch color="primary" {...props} />}
          label={label}
        />
      </FormGroup>
    );
  }
}

BaseSwitch.propTypes = {
  row: PropTypes.bool,
  label: PropTypes.string
};

export default BaseSwitch;
