import makeActionCreator from "../../helpers/makeActionCreator";

const nameSpace = "employers/contacts";

export const GET_CONTACTS_REQUEST = `${nameSpace}/GET_CONTACTS_REQUEST`;
export const GET_CONTACTS_SUCCESS = `${nameSpace}/GET_CONTACTS_SUCCESS`;
export const GET_CONTACTS_FAILURE = `${nameSpace}/GET_CONTACTS_FAILURE`;
export const CREATE_REQUEST = `${nameSpace}/CREATE_REQUEST`;
export const CREATE_SUCCESS = `${nameSpace}/CREATE_SUCCESS`;
export const CREATE_FAILURE = `${nameSpace}/CREATE_FAILURE`;
export const UPDATE_REQUEST = `${nameSpace}/UPDATE_REQUEST`;
export const UPDATE_SUCCESS = `${nameSpace}/UPDATE_SUCCESS`;
export const UPDATE_FAILURE = `${nameSpace}/UPDATE_FAILURE`;
export const DELETE_REQUEST = `${nameSpace}/DELETE_REQUEST`;
export const DELETE_SUCCESS = `${nameSpace}/DELETE_SUCCESS`;
export const DELETE_FAILURE = `${nameSpace}/DELETE_FAILURE`;

export const getContactsRequest = makeActionCreator(
  GET_CONTACTS_REQUEST,
  "employerId"
);
export const getContactsSuccess = makeActionCreator(
  GET_CONTACTS_SUCCESS,
  "contacts"
);
export const getContactsFailure = makeActionCreator(
  GET_CONTACTS_FAILURE,
  "errors"
);

export const createRequest = makeActionCreator(
  CREATE_REQUEST,
  "formName",
  "contactType",
  "adminUserId",
  "employerId",
  "first_name",
  "last_name",
  "email",
  "job_title",
  "phone",
  "onSuccess"
);
export const createSuccess = makeActionCreator(CREATE_SUCCESS);
export const createFailure = makeActionCreator(CREATE_FAILURE, "errors");

export const updateRequest = makeActionCreator(
  UPDATE_REQUEST,
  "formName",
  "employerId",
  "contactId",
  "first_name",
  "last_name",
  "email",
  "job_title",
  "phone",
  "onSuccess"
);

export const updateSuccess = makeActionCreator(
  UPDATE_SUCCESS,
  "employerId",
  "contactId",
  "first_name",
  "last_name",
  "job_title",
  "phone"
);
export const updateFailure = makeActionCreator(UPDATE_FAILURE, "errors");

export const deleteRequest = makeActionCreator(
  DELETE_REQUEST,
  "contactType",
  "employerId",
  "contactId",
  "onSuccess"
);
export const deleteSuccess = makeActionCreator(
  DELETE_SUCCESS,
  "employerId",
  "contactId"
);
export const deleteFailure = makeActionCreator(DELETE_FAILURE, "errors");
