const apiHost = process.env.REACT_APP_API_HOST;

export default (type, params, method, auth = false) => {

  const payload = {
    data: { type: type, attributes: params }
  };

  var requestHeaders = new Headers({
    "Content-Type": "application/json",
    Accept: "application/vnd.slg+json",
  });
  var requestHeadersAuth = new Headers({
    Accept: "application/vnd.slg+json",
    Authorization: localStorage.getItem("token"),
    "Content-Type": "application/json"
  });

  return fetch(
    `${apiHost}/${type}`,
    {
      method: method ? method : "PUT",
      body: JSON.stringify(payload),
      headers: auth ? requestHeadersAuth : requestHeaders,
    }
  )
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return { data: {} };
      }
      return response.json();
    })
    .then((res) => {
      if (res && res.errors && (res.errors[0].status < 200 || res.errors[0].status >= 300)) {
        let err = res.errors[0].detail && 
          typeof res.errors[0].detail === "string" ? res.errors[0].detail :
            Array.isArray(res.errors[0].detail) === true ? res.errors[0].detail.join(', ') 
              : "";
        throw new Error(err);
      }
    });
}