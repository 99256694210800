import React, { Component } from "react";
import { propTypes, reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";

import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import Dialog from "material-ui/Dialog";
import CircularProgress from "material-ui/CircularProgress";

import { disableTwoFactor as disableTwoFactorAction } from "./twoFactorActions";

const styles = {
  flat: {
    overflow: "inherit"
  },
  flex: {
    display: "flex"
  },
  modal: {
    width: "30%",
    minWidth: "350px",
    maxWidth: "none"
  },
  modalBody: {
    overflowY: "scroll",
    justifyContent: "center",
    display: "flex"
  },
  actionsContainer: {
    boxSizing: "border-box",
    padding: 10,
    paddingTop: 20,
    width: "100%",
    textAlign: "right",
    marginTop: -1
  },
  title: {
    fontSize: "1.5em"
  },
  helpText: {
    marginTop: "10px"
  }
};

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => {
  return (
    <TextField
      inputStyle={styles.inputText}
      errorText={touched && error}
      floatingLabelStyle={styles.floatingLabel}
      {...inputProps}
      {...props}
      fullWidth
    />
  );
};

export class Disable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  disable = values => {
    const { disableTwoFactor } = this.props;
    disableTwoFactor(values.token);
  };

  render() {
    const { isLoading, handleSubmit } = this.props;
    return (
      <div>
        <Dialog
          open={this.state.open}
          bodyStyle={styles.modalBody}
          contentStyle={styles.modal}
          overlayClassName="overlay"
          contentClassName="content"
          bodyClassName="body"
        >
          <form onSubmit={handleSubmit(this.disable)}>
            <div>
              <div style={styles.title}>Disable 2FA</div>
              <div style={styles.flex}>
                <Field
                  name="token"
                  component={renderInput}
                  floatingLabelText="Authy Token"
                  disabled={isLoading}
                />
              </div>
              <div style={styles.helpText}>
                Enter your Authy code to disable 2FA.
              </div>
            </div>
            <div style={styles.actionsContainer}>
              <FlatButton
                primary
                label="cancel"
                onClick={this.props.handleClose}
              />
              <RaisedButton
                type="submit"
                primary
                disabled={isLoading}
                icon={isLoading && <CircularProgress size={25} thickness={2} />}
                label="Disable"
              />
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}

Disable.propTypes = {
  ...propTypes,
  disableTwoFactor: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.ui.loading > 0 });

const enhance = compose(
  reduxForm({
    form: "DisableTwoFactor",
    validate: (values, props) => {
      const errors = {};
      if (!values.token) errors.token = "required";
      return errors;
    },
    initialValues: {
      countryCode: "1"
    }
  }),
  connect(mapStateToProps, {
    disableTwoFactor: disableTwoFactorAction
  })
);
export default enhance(Disable);
