import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import SendButton from "components/buttons/icon/Send";
import { sendInvitesRequest } from "groups/actions/ultiproActions";

const SendInviteCell = ({ employerId, id, sendInvitesRequest }) => {
  const handleNewClick = () => sendInvitesRequest(employerId, id);

  return <SendButton onClick={handleNewClick} />;
};

export default compose(
  connect(
    null,
    {
      sendInvitesRequest
    }
  )
)(SendInviteCell);
