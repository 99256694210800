import axios from "axios";
import getUrl from "./getUrl";
import createHandleStream from "./createHandleStream";

export default ({ saveAs, mimeType }) => async response => {
  const url = getUrl(response);
  const dataResponse = await axios(url, {
    method: "get",
    responseType: "blob"
  });
  return createHandleStream({ saveAs, mimeType })(dataResponse);
};
