import { createSelector } from "reselect";
import { values } from "lodash";

const selectPartners = state => state.partners.byID;
const selectCurrentPartnerID = state => state.partners.selectedID;
export const selectEntityIdentifiers = state =>
  state.partners.entityIdentifiers || {};
const selectPartnerStats = state => state.partnerStats.byPartnerID;

export const selectPartnersList = createSelector(
  [selectPartners],
  partners => {
    return values(partners);
  }
);

export const selectCurrentPartner = createSelector(
  [selectPartners, selectCurrentPartnerID],
  (partners, currentPartnerID) => {
    return partners[currentPartnerID];
  }
);

export const selectStatsByCurrentPartner = createSelector(
  [selectPartnerStats, selectCurrentPartnerID],
  (partnerStats, currentPartnerID) => {
    return partnerStats[currentPartnerID];
  }
);

export const selectStatsByPartnerID = createSelector(
  [selectPartnerStats, (_, partnerID) => partnerID],
  (partnerStats, partnerID) => partnerStats[partnerID]
);
