import React from "react";

import Grid from "../../layouts/Grid";
import layVertical from "../../layouts/layVertical";
import withSmSpacing from "../../layouts/withSmSpacing";

const Group = layVertical(withSmSpacing(Grid));

export default WC => ({
  loading = false,
  disabled: disabledProp,
  onSubmit,
  actionSlot,
  ...props
}) => {
  const disabled = disabledProp || loading;
  return (
    <WC
      disabled={disabledProp}
      onSubmit={onSubmit}
      loading={loading}
      {...props}
    >
      <form onSubmit={onSubmit}>
        <Group>
          {React.Children.toArray(props.children).map((child, index) => (
            <child.type key={index} {...child.props} disabled={disabled} />
          ))}
          {actionSlot}
        </Group>
      </form>
    </WC>
  );
};
