import React from "react";
import { Field } from "redux-form";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

const renderRadioGroup = ({ input, ...rest }) => (
  <RadioButtonGroup
    {...input}
    {...rest}
    valueSelected={input.value}
    onChange={(event, value) => input.onChange(value)}
  />
);

const InlinedRadioButton = ({ name }) => {
  return (
    <Field name={name} component={renderRadioGroup}>
      <RadioButton
        style={{ display: "inline-block", width: "100px", marginTop: "7px" }}
        label="Partner"
        value={false}
      />
      <RadioButton
        style={{
          display: "inline-block",
          width: "100px",
          marginTop: "7px",
          marginLeft: "30px"
        }}
        label="Employer"
        value={true}
      />
    </Field>
  );
};

export default InlinedRadioButton;
