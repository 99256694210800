export default class BaseResponse {
  constructor({ result } = { result: {} }) {
    const status = result ? result.status : "";
    this.status = status;
    this.ok = status >= 200 && status < 300;
    this.statusText = result ? result.statusText : "";
    this.data = result ? result.data : {};
  }

  _indexedAndNormalizeById() {
    const { data } = this.data;
    const indexedData = {};
    const normalizedData = [];
    data.forEach(item => {
      const normalized = {
        id: item.id,
        ...item.attributes
      };
      normalizedData.push(normalized);
      return (indexedData[item.id] = normalized);
    });

    return { indexedData, normalizedData };
  }

  _normalizeRelationship(id) {
    const { included } = this.data;

    const itemData = included.filter(item => id === item.id);

    return {
      id,
      ...itemData[0].attributes
    };
  }

  _normalizeRelationships() {
    /** empty data */
    if (!this.data.data) return;

    const { id, relationships } = this.data.data;

    /** hash data */
    if (!id) return this.data.data;

    const normalized = {};

    if (relationships) {
      Object.keys(relationships).map(relationshipName => {
        const data = relationships[relationshipName].data;
        if (Array.isArray(data)) {
          normalized[relationshipName] = [];
          data.map(item => {
            const id = item.id;
            if (id) {
              normalized[relationshipName].push(
                this._normalizeRelationship(id)
              );
            }
            return true;
          });
        } else {
          const id = data ? data.id : null;
          if (id) {
            normalized[relationshipName] = this._normalizeRelationship(id);
          }
        }
        return true;
      });
    }

    const normalizedData = Object.assign({}, this.data.data.attributes, {
      ...normalized
    });

    return {
      id,
      ...normalizedData
    };
  }

  _transformData() {
    const { indexedData, normalizedData } = this._indexedAndNormalizeById();
    const total = this.data.meta ? this.data.meta.total : null;
    const data = {
      byId: indexedData,
      all: normalizedData,
      total
    };

    return data;
  }

  getData() {
    return this._normalizeRelationships();
  }

  getListData(id) {
    if (id) {
      return {
        [id]: this._transformData()
      };
    } else {
      return this._transformData();
    }
  }
}
