const numberFormat = value => {
  if (value !== '') {
    if (!isNaN(value)){
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(value / 100);
    } else {
      return '';
    }
  } else {
    return value
  }
}

const Amount = ({ ...trData }) => {
  return (
    numberFormat(trData.amount_cents)
  );
};

export default (Amount);
