import React, { Component } from "react";
import { Link } from "react-router-dom";
import Checkbox from "material-ui/Checkbox";
import Button from "@material-ui/core/Button";
import TextField from "material-ui/TextField";
import CircularProgress from "material-ui/CircularProgress";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import styled from "styled-components";

import { CustomNotification, Logo } from "../components";
import { verifyTwoFactor as verifyTwoFactorAction } from "./twoFactorActions";
import { clearAuthDetails } from "../helpers/authHelpers";
import BaseLayout from "../layouts/BaseLayout";

const styles = {
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "center"
  },
  resetPasswordBox: {
    minWidth: 400
  },
  logo: {
    maxHeight: "75%",
    maxWidth: "75%"
  },
  logoContainer: {
    margin: "1em",
    textAlign: "center"
  },
  form: {
    padding: "0 1em 1em 1em"
  },
  input: {
    display: "flex"
  },
  checkbox: {
    icon: {
      fill: "white"
    },
    input: {
      display: "flex",
      marginTop: "20px"
    },
    label: {
      color: "white"
    }
  },
  helpText: {
    textAlign: "center",
    color: "white",
    marginTop: "2em"
  },
  floatingLabel: {
    color: "white"
  },
  inputText: {
    color: "white"
  },
  button: {
    minWidth: "190px"
  }
};

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    inputStyle={styles.inputText}
    errorText={touched && error}
    floatingLabelStyle={styles.floatingLabel}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const renderCheckbox = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => {
  return (
    <Checkbox
      checked={!!inputProps.value}
      onCheck={inputProps.onChange}
      labelStyle={styles.checkbox.label}
      iconStyle={styles.checkbox.icon}
      {...inputProps}
      {...props}
    />
  );
};

const HalfWidthButton = styled(Button)`
  && {
    min-width: 190px;
  }
`;

export class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: "",
      remember_me: null
    };
  }

  verify = values => {
    const { verifyTwoFactor } = this.props;
    verifyTwoFactor(values);
  };

  cancelVerify() {
    clearAuthDetails();
  }

  render() {
    const { isLoading, theme, handleSubmit } = this.props;

    return (
      <BaseLayout theme={theme}>
        <div style={styles.logoContainer}>
          <Logo />
        </div>
        <div style={styles.resetPasswordBox}>
          <div style={styles.helpText}>
            <div>Verification requires the authy mobile app.</div>
            <div style={styles.text}>Enter your authy token to continue.</div>
          </div>
          <form onSubmit={handleSubmit(this.verify)}>
            <div style={styles.form}>
              <div style={styles.input}>
                <Field
                  name="token"
                  component={renderInput}
                  floatingLabelText="Authy Token"
                  disabled={isLoading}
                />
              </div>
              <div style={styles.checkbox.input}>
                <Field
                  name="remember_me"
                  component={renderCheckbox}
                  label="Remember this device for 30 days"
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <HalfWidthButton
                variant="contained"
                type="submit"
                component={props => (
                  <Link
                    {...props}
                    to={{
                      pathname: `/login`
                    }}
                  />
                )}
                onClick={this.cancelVerify}
              >
                {isLoading ? (
                  <CircularProgress size={25} thickness={2} />
                ) : (
                  "Back"
                )}
              </HalfWidthButton>
              <HalfWidthButton
                variant="contained"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <CircularProgress size={25} thickness={2} />
                ) : (
                  "Verify"
                )}
              </HalfWidthButton>
            </div>
          </form>
          <CustomNotification />
        </div>
      </BaseLayout>
    );
  }
}

Verify.propTypes = {
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ isLoading: state.ui.loading > 0 });

const enhance = compose(
  reduxForm({
    form: "verifyAuthy",
    validate: (values, props) => {
      const errors = {};
      if (!values.token) errors.token = "required";
      return errors;
    }
  }),
  connect(
    mapStateToProps,
    {
      verifyTwoFactor: verifyTwoFactorAction
    }
  )
);
export default enhance(Verify);
