export const FETCH_GROUPS = "FETCH_GROUPS_LIST";
export const FETCH_GROUPS_FAILURE = "FETCH_GROUPS_LIST_FAILURE";
export const FETCH_GROUPS_SUCCESS = "FETCH_GROUPS_LIST_SUCCESS";

export const FETCH_GROUP = "FETCH_GROUP";
export const FETCH_GROUP_FAILURE = "FETCH_GROUP_FAILURE";
export const FETCH_GROUP_SUCCESS = "FETCH_GROUP_SUCCESS";

export const CREATE_GROUP = "CREATE_GROUP";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE";

export const EDIT_GROUP = "EDIT_GROUP";
export const EDIT_GROUP_FAILURE = "EDIT_GROUP_FAILURE";
export const EDIT_GROUP_SUCCESS = "EDIT_GROUP_SUCCESS";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE";
