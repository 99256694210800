import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";

import { DialogTitleProps } from "./types";
import Grid from "../../layouts/Grid";
import GroupTitle from "../../layouts/list/Actions";
import justifyBetween from "../../layouts/justifyBetween";
import alignCenter from "../../layouts/alignCenter";
import CloseButton from "../../buttons/action/Close";

const Group = justifyBetween(alignCenter(Grid));

function Title({ Icon, children, onClose }) {
  return (
    <DialogTitle>
      <Group>
        <GroupTitle>
          {Icon && <Icon />}
          {children}
        </GroupTitle>
        <CloseButton onClick={onClose} />
      </Group>
    </DialogTitle>
  );
}

Title.propTypes = DialogTitleProps;

export default Title;
