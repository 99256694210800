import React from "react";
import PropTypes from "prop-types";
import IconButton from "material-ui/IconButton";
import InfoOutlineIcon from "material-ui/svg-icons/action/info-outline";

const styles = {
  root: { display: "inline-flex", verticalAlign: "middle", paddingLeft: "5px" },
  icon: { float: "left", width: "50%" },
  tooltip: { fontSize: "1.2em" }
};

export const InfoIcon = ({ tooltip, tooltipPosition, iconStyle }) => {
  return (
    <IconButton
      tooltip={<div style={styles.tooltip}>{tooltip}</div>}
      tooltipPosition={tooltipPosition || "top-right"}
      style={styles.root}
      iconStyle={{ ...styles.icon, ...iconStyle }}
    >
      <InfoOutlineIcon />
    </IconButton>
  );
};

InfoIcon.propTypes = {
  tooltip: PropTypes.element,
  tooltipPosition: PropTypes.string,
  iconStyle: PropTypes.object
};

export default InfoIcon;
