import React from "react";

export default LoadingComponent => WC => ({
  loading = false,
  disabled,
  ...props
}) => (
  <LoadingComponent loading={loading}>
    <WC {...props} disabled={disabled || loading} />
  </LoadingComponent>
);
