import React from "react";
import * as R from "ramda";

import { toDisplayDateStringFromProp } from "services/formatters";
import withEmployerIdFromRouter from "components/containers/utils/withEmployerIdFromRouter";
import withParamFromRouter from "components/containers/utils/withParamFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import PageAction from "./JobsPageAction";
import JobSummary from "./JobSummary";

export const columns = props => [
  {
    name: "csv_row",
    label: "Row",
    disableSort: true
  },
  {
    name: "old_values",
    label: "Old Value",
    disableSort: true
  },
  {
    name: "new_values",
    label: "New Value",
    disableSort: true
  },
  {
    name: "job_detail_status",
    label: "Status",
    disableSort: true
  },
  {
    name: "message",
    label: "Message",
    disableSort: true,
    ellipsis: true,
    maxWidth: 200
  },
  {
    name: "created_at",
    label: "Created On",
    format: toDisplayDateStringFromProp("created_at")
  }
];

const createGetSearchFilterWith = () => R.applySpec({});

export function extraProps(props) {
  const { employerID: employerId, batchId } = props;
  return {
    defaultSort: { order: "asc", orderBy: "created_at" },
    title: "Bulk Upload History",
    path:
      employerId &&
      `/employers/${employerId}/batch_upserts/${batchId}/batch_upsert_job_details`,
    hideSearch: true,
    getSearchFilter: createGetSearchFilterWith
  };
}

const renderAction = props => ({ disabled }) => (
  <PageAction {...props} disabled={disabled} />
);

const renderSubAction = props => () => <JobSummary {...props} />;

function actionsProps(props) {
  const employerId = props.employerID;

  return {
    columns: columns({ ...props, employerId }),
    actionSlot: renderAction({ ...props, employerId }),
    subActionSlot: renderSubAction(props)
  };
}

const withJobFromRouter = WC => props => {
  const state = props.location.state;
  const job = state ? state.job : {};
  return <WC {...job} {...props} />;
};

export default R.compose(
  withEmployerIdFromRouter,
  withParamFromRouter("batchId"),
  withJobFromRouter,
  withProps(extraProps),
  withSort,
  withSearch,
  withPagination,
  withGetFetch,
  withProps(actionsProps)
)(PageComponent);
