import * as R from "ramda";

const getError = o => R.pathOr("", ["errors", o.name, "message"], o);
const hasError = o => !!R.pathOr("", ["errors", o.name, "type"], o);

const getHelpText = R.ifElse(hasError, getError, R.prop("helperText"));
const isEmptyOrNull = R.either(R.isEmpty, R.isNil);
const checkValidateForRequired = R.pipe(
  R.prop("validate"),
  R.ifElse(isEmptyOrNull, R.F, R.has("required"))
);
export default R.applySpec({
  error: hasError,
  helpText: getHelpText,
  shouldDisabled: R.prop("disabled"),
  shouldRequired: R.either(
    R.prop("required"),
    R.ifElse(isEmptyOrNull, R.F, checkValidateForRequired)
  )
});
