import contacts from "./contacts";
import getReports from "./getReports";
import refiVendors from "./refiVendors";
import ultipro from "./ultipro";
import batchCreateEligibleEmployees from "./bulkUpload";
import fullFileUpload from "./fullFileUpload";
export default [
  getReports,
  refiVendors,
  ultipro,
  batchCreateEligibleEmployees,
  contacts,
  fullFileUpload
];
