import PropTypes from "prop-types";

export const ActionProps = {
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["submit", false]),
  children: PropTypes.node.isRequired
};

export const SingleColumnFormProps = {
  loading: PropTypes.bool,
  submitButtonFullWidth: PropTypes.bool,
  submitButtonLabel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node
};
