import React from "react";
import CardActions from "@material-ui/core/CardActions";

import { SubActionsProps } from "./types";
import Grid from "../../layouts/Grid";
import justifyEnd from "../../layouts/justifyEnd";

const PlacedAtEnd = justifyEnd(Grid);

function SubActions({ disabled, subActionSlot }) {
  return (
    <PlacedAtEnd>
      <CardActions>{subActionSlot({ disabled })}</CardActions>
    </PlacedAtEnd>
  );
}

SubActions.propTypes = SubActionsProps;

export default SubActions;
