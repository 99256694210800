import React from "react";

import Link from "../../components/links/Link";

function NameCell({ employerId, batch_id, filename, ...job }) {
  const pathname = `/employers/${employerId}/batch_upserts/${batch_id}`;
  const state = {
    job: { ...job, filename }
  };
  const to = { pathname, state };

  return <Link to={to}>{filename}</Link>;
}

export default NameCell;
