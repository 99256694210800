import { indexBy } from "../helpers/utils";
import { FETCH_REFI_VENDORS_SUCCESS } from "./refiVendorActions";

const defaultState = {
  data: [],
  byId: [],
  total: 0
};

export const refiVendors = (
  previousState = defaultState,
  { type, payload }
) => {
  switch (type) {
    case FETCH_REFI_VENDORS_SUCCESS:
      return { ...previousState, data: payload, byId: indexBy("id", payload) };
    default:
      return previousState;
  }
};
