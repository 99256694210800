import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import compose from "recompose/compose";

import FlatButton from "material-ui/FlatButton";
import EditContent from "material-ui/svg-icons/editor/mode-edit";
import CircularProgress from "material-ui/CircularProgress";

import { PartnerEditModalContact, PartnerEditModalBilling } from "./modals";
import { openModal as openModalAction } from "../app/uiActions";
import { SLGCard, InfoBlock, InfoField } from "../components";
import { formatCurrency } from "../helpers/formattingHelpers";
import { StyledGridContainer } from "components";
import { selectEntityIdentifiers } from "../partners/selectors";

import { WithPermission } from "../auth";
import { PARTNER_ADMIN, SYSTEM_ADMIN } from "../constants";
import ConfirmationDialog from "../components/modals/ConfirmationDialog";
import useFetch from "../components/containers/utils/useFetch";

const styles = {
  container: { display: "flex", flexDirection: "column" },
  columnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%"
  },
  buttonIcon: { height: "20px" },
  editLabel: { color: "#ffffff" }
};

export const GeneralSettings = ({ partner, openModal, entityIdentifiers }) => {
  const loadingIcon = <CircularProgress size={25} thickness={2} />;
  const partnerLeadTime = get(partner, "invoice_lead_days");
  const transactionFee = get(partner, "transaction_fee");
  const { makeFetch } = useFetch({ method: "post" });
  const generateApiCredentials = () => {
    makeFetch({
      path: `/partners/${partner.id}/api_credentials`,
      data: {},
      onSuccess: response => {
        const { api_key, api_secret } = response.data;
        partner.api_key = api_key;
        partner.api_secret = api_secret;
      },
      onError: response => console.log("ERRORS: ", response)
    });
  };

  return (
    <div style={styles.container}>
      {partner && partner.api_allowed ? (
        <WithPermission value={[SYSTEM_ADMIN, PARTNER_ADMIN]}>
          <div style={styles.columnContainer}>
            <SLGCard
              title="API SETTINGS"
              button={
                <FlatButton
                  label="GENERATE API KEYS"
                  labelStyle={styles.editLabel}
                  onClick={() =>
                    openModal(
                      <ConfirmationDialog
                        title="Are you sure?"
                        submitButtonProps={{
                          label: "Yes",
                          color: "primary",
                          onClick: _e => {
                            generateApiCredentials();
                          }
                        }}
                        content="Do you want generate new API credentials?"
                      />
                    )
                  }
                  style={{ overflow: "inherit" }}
                />
              }
              flexPercent={50}
            >
              {partner ? (
                <StyledGridContainer direction="column">
                  <InfoBlock header="API Access">
                    <InfoField
                      header="API Key"
                      value={get(partner, "api_key")}
                      xs={6}
                    />
                    <InfoField
                      header="API Secret token"
                      value={get(partner, "api_secret")}
                      xs={6}
                    />
                  </InfoBlock>
                </StyledGridContainer>
              ) : (
                loadingIcon
              )}
            </SLGCard>
          </div>
        </WithPermission>
      ) : null}

      <div style={styles.columnContainer}>
        <SLGCard
          title="GENERAL SETTINGS"
          button={
            <FlatButton
              label="Edit"
              labelStyle={styles.editLabel}
              icon={<EditContent color="white" style={styles.buttonIcon} />}
              onClick={() =>
                openModal(<PartnerEditModalContact partner={partner} />)
              }
              style={{ overflow: "inherit" }}
            />
          }
          flexPercent={50}
        >
          {partner ? (
            <StyledGridContainer direction="column">
              <InfoBlock header="Contact">
                <InfoField
                  header="First Name"
                  value={get(partner, "contacts.[0].first_name")}
                  xs={6}
                />
                <InfoField
                  header="Last Name"
                  value={get(partner, "contacts.[0].last_name")}
                  xs={6}
                />
                <InfoField
                  header="Title"
                  value={get(partner, "contacts.[0].job_title")}
                  xs={6}
                />
                <InfoField
                  header="Email"
                  value={get(partner, "contacts.[0].email")}
                  xs={6}
                />
                <InfoField
                  header="Phone"
                  value={get(partner, "contacts.[0].phone")}
                  xs={6}
                />
              </InfoBlock>
              <InfoBlock header="Support Email">
                <InfoField
                  header="Advisor"
                  value={get(partner, "advisor_support_email")}
                  xs={6}
                />
                <InfoField
                  header="Pay"
                  value={get(partner, "pay_support_email")}
                  xs={6}
                />
              </InfoBlock>
            </StyledGridContainer>
          ) : (
            loadingIcon
          )}
        </SLGCard>
      </div>
      <div style={styles.columnContainer}>
        <SLGCard
          title="INVOICE SETTINGS"
          button={
            <FlatButton
              label="Edit"
              labelStyle={styles.editLabel}
              icon={<EditContent color="white" style={styles.buttonIcon} />}
              onClick={() =>
                openModal(<PartnerEditModalBilling partner={partner} />)
              }
              style={{ overflow: "inherit" }}
            />
          }
          flexPercent={50}
        >
          {partner ? (
            <React.Fragment>
              <InfoBlock header="Remit To">
                <InfoField
                  header="Address 1"
                  value={get(partner, "billing_address.street_1")}
                  xs={12}
                />
                <InfoField
                  header="Address 2"
                  value={get(partner, "billing_address.street_2")}
                  xs={12}
                />
                <InfoField
                  header="City"
                  value={get(partner, "billing_address.city")}
                  xs={4}
                />
                <InfoField
                  header="State"
                  value={get(partner, "billing_address.state")}
                  xs={4}
                />
                <InfoField
                  header="Zip Code"
                  value={get(partner, "billing_address.zip_code")}
                  xs={4}
                />
              </InfoBlock>
              <InfoBlock header="Billing Options">
                <InfoField
                  header="Payment Lead Time"
                  value={
                    partnerLeadTime != null ? `${partnerLeadTime} days` : ""
                  }
                  xs={6}
                />
                <InfoField
                  header="Transaction Fees"
                  value={transactionFee ? formatCurrency(transactionFee) : ""}
                  xs={6}
                />
                <InfoField
                  header="Bill Fees to:"
                  value={
                    get(partner, "bill_fee_to_employer")
                      ? entityIdentifiers.employerIdentifier
                      : "Partner"
                  }
                  xs={6}
                />
                <InfoField
                  header="Bill Invoices to:"
                  value={
                    get(partner, "bill_pdr_to_employer")
                      ? entityIdentifiers.employerIdentifier
                      : "Partner"
                  }
                  xs={6}
                />
              </InfoBlock>
            </React.Fragment>
          ) : (
            loadingIcon
          )}
        </SLGCard>
      </div>
    </div>
  );
};

GeneralSettings.propTypes = {
  partner: PropTypes.object,
  openModal: PropTypes.func.isRequired
};

GeneralSettings.defaultProps = {
  partner: null
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return { entityIdentifiers };
}

const enhance = compose(
  connect(
    mapStateToProps,
    { openModal: openModalAction }
  )
);

export default enhance(GeneralSettings);
