import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    color: "white"
  }
});

export default WC => props => {
  const classes = useStyles();
  return <WC className={classes.root} {...props} />;
};
