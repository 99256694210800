import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import compose from "recompose/compose";

import FlatButton from "material-ui/FlatButton";
import AddIcon from "material-ui/svg-icons/content/add";

import { openModal as openModalAction } from "../app/uiActions";
import { AdminUserCreateModal } from "../adminUsers/modals";
import { SLGCard } from "../components";
import { AdminUsersList } from "./";
import { WithPermission } from "../auth";
import { SYSTEM_ADMIN, PARTNER_ADMIN } from "../constants";
import { togglePartnerRefiVendor as togglePartnerRefiVendorAction } from "./partnerActions";
import { selectEmployersListByPartner } from "../employers/selectors";

const styles = {
  container: { display: "flex", flexDirection: "column" },
  columnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%"
  },
  buttonIcon: { height: "20px" },
  editLabel: { color: "#ffffff" }
};

export const Users = ({ partner, openModal, employers, isLoading }) => {
  return (
    <div style={styles.container}>
      <div style={styles.columnContainer}>
        <SLGCard
          title="ADMIN USERS"
          button={
            <WithPermission value={[SYSTEM_ADMIN, PARTNER_ADMIN]}>
              <FlatButton
                label="New Admin User"
                labelStyle={styles.editLabel}
                icon={<AddIcon color="white" style={styles.buttonIcon} />}
                disabled={isLoading}
                onClick={() =>
                  openModal(
                    <AdminUserCreateModal
                      context={PARTNER_ADMIN}
                      partnerID={get(partner, "id")}
                      employers={employers}
                    />
                  )
                }
                style={{ overflow: "inherit" }}
              />
            </WithPermission>
          }
          flexPercent={60}
        >
          <AdminUsersList
            partnerID={get(partner, "id")}
            employers={employers}
          />
        </SLGCard>
      </div>
    </div>
  );
};

Users.propTypes = {
  partner: PropTypes.object,
  employers: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired
};

Users.defaultProps = {
  partner: null,
  employers: []
};

function mapStateToProps(state, props) {
  return {
    employers: selectEmployersListByPartner(
      state,
      get(props, "partner.id", "")
    ),
    isLoading: state.employers.isFetching
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      togglePartnerRefiVendor: togglePartnerRefiVendorAction,
      openModal: openModalAction
    }
  )
);

export default enhance(Users);
