import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Button from "packages/mui-dashboard/components/CustomButtons/Button.jsx";

// actions
import { showTwoFactor as showTwoFactorAction } from "twoFactor/twoFactorActions";
import { userLogout as userLogoutAction } from "auth";

import headerLinksStyle from "packages/mui-dashboard/assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

class HeaderLinks extends React.Component {
  state = {
    open: false
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  render() {
    const { classes, userLogout, showTwoFactor, userName } = this.props;
    const { open } = this.state;
    const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover);
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return (
      <div>
        <div className={managerClasses}>
          <Hidden mdDown implementation="css" className={managerClasses}>
            {userName}
          </Hidden>
          <Button
            color="transparent"
            justIcon
            aria-label="Person"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
            className={classes.buttonLink}
            buttonRef={node => {
              this.anchorEl = node;
            }}
          >
            <Person className={classes.headerLinksSvg + " " + classes.links} />
            <Hidden mdUp implementation="css">
              <span onClick={this.handleClick} className={classes.linkText}>
                {userName}
              </span>
            </Hidden>
          </Button>
          <Popper
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !open,
              [classes.pooperResponsive]: true,
              [classes.pooperNav]: true
            })}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={showTwoFactor}
                        className={dropdownItem}
                      >
                        2FA
                      </MenuItem>
                      <MenuItem
                        onClick={() => userLogout()}
                        className={dropdownItem}
                      >
                        Log out
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

const enhance = compose(
  withStyles(headerLinksStyle),
  connect(
    null,
    {
      userLogout: userLogoutAction,
      showTwoFactor: showTwoFactorAction
    }
  )
);
export default enhance(HeaderLinks);
