import { twoFactorReducer } from "../twoFactor";
import { authReducer } from "../auth";
import {
  employersReducer,
  fundsRequestsReducer,
  employerStatsReducer,
} from "../employers";
import { employerJourneyStats, employerUserStats, registeredClicksStats } from "../employers/employerReducer";
import { employersDetails } from "../employers/employerReducer";
import { partnerJourneyStats, partnerRegClicksStats, partnerUserStats } from "../partners/partnerReducer";
import { partnersDetails } from "../partners/partnerReducer";
import invoiceReducer from "../invoices/reducers";
import employersReducerExt from "../employers/reducers";
import { groupsReducer } from "../groups";
import { employeesReducer, employeeNotesReducer } from "../employees";
import { partnersReducer, partnerStatsReducer } from "../partners";
import partnersReducerExt from "../partners/reducers";
import { ui as uiReducer } from "../app/uiReducer";
import { adminUserReducer } from "../adminUsers";
import { refiVendorsReducer } from "../refiVendors";
import employerRefiVendors from "../employers/reducers/refiVendorsReducers";
import ultiproIntegrations from "../employers/reducers/ultiproReducers";
import groupsReducersExt from "../groups/reducers";
import globalSearch from "../global-search/reducers/searchReducers";
import { tutionsReducer } from "../employees/reducers/";

const reducers = {
  twoFactor: twoFactorReducer,
  userContext: authReducer,
  employers: employersReducer,
  employerStats: employerStatsReducer,
  partners: partnersReducer,
  partnerStats: partnerStatsReducer,
  groups: groupsReducer,
  employees: employeesReducer,
  employeeNotes: employeeNotesReducer,
  adminUsers: adminUserReducer,
  fundsRequests: fundsRequestsReducer,
  refiVendors: refiVendorsReducer,
  employerRefiVendors,
  ui: uiReducer,
  ...partnersReducerExt,
  ...employersReducerExt,
  ...ultiproIntegrations,
  ...groupsReducersExt,
  ...invoiceReducer,
  globalSearch,
  tutionsReducer: tutionsReducer,
  registeredClicksStats: registeredClicksStats,
  partnerRegClicksStats: partnerRegClicksStats,
  employersDetails: employersDetails,
  partnersDetails: partnersDetails,
  partnerUserStats: partnerUserStats,
  employerUserStats: employerUserStats,
  partnerJourneyStats: partnerJourneyStats,
  employerJourneyStats: employerJourneyStats,
};

export default reducers;
