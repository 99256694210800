import makeActionCreator from "helpers/makeActionCreator";

const nameSpace = "employers/bulkUpload";

export const BATCH_CREATE_ELIGIBLE_EMPLOYEES_REQUEST = `${nameSpace}/BATCH_CREATE_ELIGIBLE_EMPLOYEES_REQUEST`;
export const BATCH_CREATE_ELIGIBLE_EMPLOYEES_SUCCESS = `${nameSpace}/BATCH_CREATE_ELIGIBLE_EMPLOYEES_SUCCESS`;
export const BATCH_CREATE_ELIGIBLE_EMPLOYEES_FAILURE = `${nameSpace}/BATCH_CREATE_ELIGIBLE_EMPLOYEES_FAILURE`;
export const batchCreateEligibleEmployeesRequest = makeActionCreator(
  BATCH_CREATE_ELIGIBLE_EMPLOYEES_REQUEST,
  "employerId",
  "file",
  "employeesList"
);
export const batchCreateEligibleEmployeesSuccess = makeActionCreator(
  BATCH_CREATE_ELIGIBLE_EMPLOYEES_SUCCESS,
  "employerId"
);
export const batchCreateEligibleEmployeesFailure = makeActionCreator(
  BATCH_CREATE_ELIGIBLE_EMPLOYEES_FAILURE,
  "error"
);

export const HISTORY_REQUEST = `${nameSpace}/HISTORY_REQUEST`;
export const HISTORY_SUCCESS = `${nameSpace}/HISTORY_SUCCESS`;
export const HISTORY_FAILURE = `${nameSpace}/HISTORY_FAILURE`;
export const historyRequest = makeActionCreator(
  HISTORY_REQUEST,
  "employerId",
  "sort",
  "page",
  "query"
);
export const historySuccess = makeActionCreator(
  HISTORY_SUCCESS,
  "employerId",
  "batches"
);
export const historyFailure = makeActionCreator(HISTORY_FAILURE, "error");

export const JOBS_REQUEST = `${nameSpace}/JOBS_REQUEST`;
export const JOBS_SUCCESS = `${nameSpace}/JOBS_SUCCESS`;
export const JOBS_FAILURE = `${nameSpace}/JOBS_FAILURE`;
export const jobsRequest = makeActionCreator(
  JOBS_REQUEST,
  "employerId",
  "batchId"
);
export const jobsSuccess = makeActionCreator(JOBS_SUCCESS, "batchId", "job");
export const jobsFailure = makeActionCreator(JOBS_FAILURE, "error");

export const JOB_DETAILS_REQUEST = `${nameSpace}/JOB_DETAILS_REQUEST`;
export const JOB_DETAILS_SUCCESS = `${nameSpace}/JOB_DETAILS_SUCCESS`;
export const JOB_DETAILS_FAILURE = `${nameSpace}/JOB_DETAILS_FAILURE`;
export const jobDetailsRequest = makeActionCreator(
  JOB_DETAILS_REQUEST,
  "employerId",
  "sort",
  "page",
  "limit",
  "query",
  "batchId"
);
export const jobDetailsSuccess = makeActionCreator(
  JOB_DETAILS_SUCCESS,
  "batchId",
  "jobDetails"
);
export const jobDetailsFailure = makeActionCreator(
  JOB_DETAILS_FAILURE,
  "error"
);

export const CSV_DOWNLOAD_REQUEST = `${nameSpace}/CSV_DOWNLOAD_REQUEST`;
export const CSV_DOWNLOAD_SUCCESS = `${nameSpace}/CSV_DOWNLOAD_SUCCESS`;
export const CSV_DOWNLOAD_FAILURE = `${nameSpace}/CSV_DOWNLOAD_FAILURE`;
export const csvDownloadRequest = makeActionCreator(
  CSV_DOWNLOAD_REQUEST,
  "employerId",
  "batchId",
  "filename"
);
export const csvDownloadSuccess = makeActionCreator(CSV_DOWNLOAD_SUCCESS);
export const csvDownloadFailure = makeActionCreator(
  CSV_DOWNLOAD_FAILURE,
  "error"
);
