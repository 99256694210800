import React from "react";
import { eachYearOfInterval, getYear } from "date-fns";

import Select from "./selects/EnhancedSelect";

const thisYear = getYear(Date.now());

const options = interval =>
  eachYearOfInterval({
    start: new Date(thisYear, 1, 1),
    end: new Date(thisYear + interval - 1, 1, 1)
  }).map(v => {
    const m = getYear(new Date(v));
    return { value: m, label: m };
  });

const rules = {
  required: "required"
};

export default function YearSelect({ interval = 4, ...props }) {
  return (
    <Select
      name="year"
      label="Year"
      required
      rules={rules}
      options={options(interval)}
      {...props}
    />
  );
}
