import React from "react";
import ApproveIcon from "@material-ui/icons/PlaylistAddCheck";

import withStartIcon from "../../../components/buttons/action/withStartIcon";
import ButtonComponent from "../../../components/buttons/action/CardActionButton";
import ConfirmationDialog from "../../../components/dialogs/form-dialogs/ConfirmationDialog";

const ApproveButton = withStartIcon(ApproveIcon)(ButtonComponent);

function ApproveInvoice({ onSubmit }) {
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    if (onSubmit) onSubmit();
    setOpen(false);
  }

  return (
    <>
      <ApproveButton onClick={handleClick}>Approve</ApproveButton>
      <ConfirmationDialog
        open={open}
        type="Error"
        title="Approve Invoice"
        submitButtonLabel="Yes"
        onClose={handleClose}
        onSubmit={handleSubmit}
      >
        Once this invoice has been approved, additional changes to the line
        items cannot be made. Would you like to continue?
      </ConfirmationDialog>
    </>
  );
}

export default ApproveInvoice;
