import React from "react";
import * as R from "ramda";

import useSelections from "./utils/useSelections";
import useDownloadFile from "./utils/useDownloadFile";
import useFetch from "./utils/useFetch";

const getLineItemRowId = r => r.id;
const getExclusionsRowId = r => r.invoice_line_item_id;
const getLineItemsSelectedData = R.innerJoin((record, id) => record.id === id);
const getExclusionsSelectedData = R.innerJoin(
  (record, id) => record.invoice_line_item_id === id
);
const getInvoiceFromRouterState = R.pathOr(
  {
    name: "",
    pdr_id: undefined,
    fee_id: undefined
  },
  ["location", "state", "invoice"]
);

export default WC => props => {
  const initialInvoice = getInvoiceFromRouterState(props);

  const [lineItems, setLineItems] = React.useState([]);
  const [fees, setFees] = React.useState([]);
  const [exclusions, setExclusions] = React.useState([]);
  const [invoice, setInvoice] = React.useState(initialInvoice);

  const getLineItems = useFetch({ method: "get" });
  const getFees = useFetch({ method: "get" });
  const getExclusions = useFetch({ method: "get" });
  const getApproval = useFetch({ method: "patch" });
  const deleteUser = useFetch({ method: "delete" });
  const restoreUser = useFetch({ method: "put" });
  const downloadFile = useDownloadFile();
  const lineItemsSelections = useSelections({
    getRowId: getLineItemRowId,
    rows: lineItems
  });
  const exclusionsSelections = useSelections({
    getRowId: getExclusionsRowId,
    rows: exclusions
  });

  const handleApproveSubmit = () => {
    function handleSuccess() {
      setInvoice({
        ...invoice,
        status: "approved"
      });
    }

    const path = `/invoices/${invoice.id}`;
    const data = {
      type: "invoices",
      id: invoice.id,
      attributes: {
        status: "approved"
      }
    };
    getApproval.makeFetch({ path, onSuccess: handleSuccess, data });
  };

  /** line items */
  let latestLineItemsFetch = React.useRef(getLineItems.makeFetch);
  React.useEffect(() => {
    latestLineItemsFetch.current = getLineItems.makeFetch;
  });
  React.useEffect(() => {
    if (initialInvoice.id) {
      function handleSuccess({ data }) {
        setLineItems(data.invoice_line_items);
      }
      const path = `/invoices/${initialInvoice.id}`;
      latestLineItemsFetch.current({
        path,
        onSuccess: handleSuccess
      });
    }
  }, [initialInvoice.id]);

  /** fees */
  let latestFeesFetch = React.useRef(getFees.makeFetch);
  React.useEffect(() => {
    latestFeesFetch.current = getFees.makeFetch;
  });
  React.useEffect(() => {
    if (initialInvoice.fee_id) {
      function handleSuccess({ data }) {
        setFees(data.invoice_line_items);
      }
      const path = `/invoices/${initialInvoice.fee_id}`;
      latestFeesFetch.current({
        path,
        onSuccess: handleSuccess
      });
    }
  }, [initialInvoice.fee_id]);

  /** exclusions */
  let latestExclusionsFetch = React.useRef(getExclusions.makeFetch);
  React.useEffect(() => {
    latestExclusionsFetch.current = getExclusions.makeFetch;
  });
  React.useEffect(() => {
    if (initialInvoice.id) {
      function handleSuccess({ data }) {
        setExclusions(data);
      }
      const path = `/invoices/${initialInvoice.id}/excluded_users`;
      latestExclusionsFetch.current({
        path,
        onSuccess: handleSuccess
      });
    }
  }, [initialInvoice.id]);

  function handleDeleteLineItems(selected) {
    selected.map(itemId =>
      deleteUser.makeFetch({ path: `/invoice_line_items/${itemId}` })
    );

    const items = getLineItemsSelectedData(lineItems, selected);
    const newExclusions = items.map(item => ({
      id: item.pulling_for_id,
      invoice_line_item_id: item.id,
      amount_cents: item.amount_cents,
      full_name: item.pulling_for_name,
      personal_ids: item.personal_ids,
      reason: "Removed"
    }));

    const newLineItems = R.differenceWith(
      (r, i) => r.id === i.id,
      lineItems,
      items
    );

    setExclusions(exclusions.concat(newExclusions));
    setLineItems(newLineItems);
    lineItemsSelections.resetSelections();
  }

  function handleRestoreLineItems(selected) {
    selected.map(itemId =>
      restoreUser.makeFetch({ path: `/invoice_line_items/${itemId}/restore` })
    );

    const items = getExclusionsSelectedData(exclusions, selected);
    const newLineItems = items.map(item => ({
      id: item.invoice_line_item_id,
      amount_cents: item.amount_cents,
      pulling_for_id: item.id,
      pulling_for_name: item.full_name,
      personal_ids: item.personal_ids
    }));

    const newExclusions = R.differenceWith(
      (r, i) => r.id === i.id,
      exclusions,
      items
    );

    setLineItems(newLineItems.concat(lineItems));
    setExclusions(newExclusions);
    exclusionsSelections.resetSelections();
  }

  const useProps = {
    invoiceState: [invoice, setInvoice],
    getLineItems,
    lineItemsState: [lineItems, setLineItems],
    handleDeleteLineItems,
    handleRestoreLineItems,
    feesState: [fees, setFees],
    getFees,
    exclusionsState: [exclusions, setExclusions],
    getExclusions,
    downloadFile,
    lineItemsSelections,
    exclusionsSelections,
    getApproval,
    handleApproveSubmit
  };

  return <WC useProps={useProps} {...props} />;
};
