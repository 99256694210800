import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import Grid from "@material-ui/core/Grid";

import { selectCurrentPartner } from "../partners/selectors";
import EmployersList from "./EmployersList";
import EmployeesList from "./EmployeesList";

export class GlobalSearchPage extends Component {
  static propTypes = {
    partner: PropTypes.object
  };

  static defaultProps = {};

  render() {
    const { partnerID, entityID } = this.props;
    return (
      <Grid container direction="column" spacing={24}>
        <Grid item>
          <EmployersList partnerID={partnerID} entityID={entityID} />
        </Grid>
        <Grid item>
          <EmployeesList partnerID={partnerID} entityID={entityID} />
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  const partnerID = state.partners.selectedID;
  const entityID = state.userContext.entityID;
  const employerID = state.userContext.employerID;
  return {
    partner: selectCurrentPartner(state),
    partnerID,
    entityID: entityID || employerID
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {}
  )
);

export default enhance(GlobalSearchPage);
