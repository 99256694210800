import { compose } from "ramda";

import Group from "./Grid";
import justifyCenter from "./justifyCenter";
import alignCenter from "./alignCenter";

export default compose(
  justifyCenter,
  alignCenter
)(Group);
