import PropTypes from "prop-types";

const TitleProp = { title: PropTypes.string };
const OpenProp = { open: PropTypes.bool };
const CancelLabelProp = { cancelLabel: PropTypes.string };
const SubmitLabelProp = { cancelLabel: PropTypes.string };
const OnCloseProp = { onClose: PropTypes.func };
const OnSubmitProp = { onSubmit: PropTypes.func };
const OnCancelProp = { onCancel: PropTypes.func };

export const DialogTitleProps = {
  ...OnCloseProp
};

export const DialogContentWrapperProps = {};

export const DialogActionsProps = {
  ...CancelLabelProp,
  ...SubmitLabelProp,
  ...OnSubmitProp,
  ...OnCancelProp
};

export const DialogProps = {
  ...OpenProp,
  ...OnCloseProp,
  ...TitleProp,
  children: PropTypes.node.isRequired
};

export const ContainerProps = {
  ...OpenProp,
  ...OnCloseProp,
  titleSlot: PropTypes.node.isRequired,
  contentSlot: PropTypes.node.isRequired,
  actionsSlot: PropTypes.node.isRequired
};
