import React from "react";
import PropTypes from "prop-types";

import ThemedCardContainer from "./ThemedCardContainer";
import ThemedCardHeader from "./ThemedCardHeader";
import ThemedCardContent from "./ThemedCardContent";
import CloseButton from "../buttons/action/Close";

const defaultModalRenderActions = ({ handleClose }) => () => {
  return <CloseButton onClick={handleClose} />;
};

const ThemedCard = ({
  withHeader: { title, subtitle, titleAlign, icon, showIcon, renderActions },
  withModal: { asModal, handleClose },
  withContent: { contentStyle },
  withFooter: { renderFooter },
  children
}) => {
  const renderActionsCallback =
    renderActions || (asModal && defaultModalRenderActions);
  return (
    <ThemedCardContainer>
      <ThemedCardHeader
        title={title}
        subtitle={subtitle}
        titleAlign={titleAlign}
        avatar={icon}
        showAvatar={showIcon}
        renderActions={
          renderActionsCallback && renderActionsCallback({ handleClose })
        }
      />
      <ThemedCardContent style={contentStyle}>{children}</ThemedCardContent>
      {renderFooter && <ThemedCardContent>{renderFooter()}</ThemedCardContent>}
    </ThemedCardContainer>
  );
};

ThemedCard.propTypes = {
  withHeader: PropTypes.shape({
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    showIcon: PropTypes.bool,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    titleAlign: PropTypes.oneOf(["left", "right"]),
    renderActions: PropTypes.func
  }),
  withModal: PropTypes.shape({
    asModal: PropTypes.bool,
    handleClose: PropTypes.func
  }),
  withContent: PropTypes.shape({
    contentProps: PropTypes.object
  }),
  withFooter: PropTypes.shape({
    renderFooter: PropTypes.func
  }),
  children: PropTypes.node
};

ThemedCard.defaultProps = {
  withHeader: {
    titleAlign: "left",
    showIcon: false
  },
  withModal: {
    asModal: false
  },
  withContent: {},
  withFooter: {}
};

export default ThemedCard;
