import React from "react";
import * as R from "ramda";
import { withRouter } from "react-router";

/**
 * TODO
 * remove after thoroughly tested
 */
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { EmployerGeneralForm, EmployerPayForm } from "../forms";
// import { StepperForm, SLGModalForm } from "../../components";
// import { createEmployer as createEmployerAction } from "../employerActions";

import { formatLandingPageRoot } from "../../helpers/formattingHelpers";
import useFetch from "../../components/containers/utils/useFetch";
import useStepperState from "../../components/steppers/useStepperState";
import Stepper from "../../components/steppers/Stepper";
import DialogForm from "../../components/forms/dialog/Dialog";
import GeneralForm from "./GeneralForm";
import BillingForm from "./BillingForm";

const steps = [{ label: "General" }, { label: "Billing" }];
const defaultValues = {
  name: "",
  total_employees: "",
  whitelisted_domains: "",
  sso_bypass: true,
  federated_employers: [
    {
      foreign_id: "",
      foreign_name: ""
    }
  ],
  landing_page_slug: "",
  employer_security: "none",
  old_password_count: "",
  password_expiration_term: "",
  pay_enabled: false,
  billing_address: {
    street_1: "",
    street_2: "",
    city: "",
    state: "",
    zip_code: ""
  },
  contribution_date: {
    month: "",
    year: ""
  },
  payroll_cycle: ""
};

function EmployerCreateModal({ history, partnerID, partner, onSuccess }) {
  const [open, setOpen] = React.useState(true);
  const {
    activeStep,
    handleNext: onNext,
    handleBack: onBack
  } = useStepperState();
  const [generalFormValues, setGeneralFormValues] = React.useState(
    defaultValues
  );
  const [billingFormValues, setBillingFormValues] = React.useState(
    defaultValues
  );
  const createEmployer = useFetch({ method: "post" });

  const partnerSSOenabled = R.prop("sso_enabled", partner);
  const landingRoot = formatLandingPageRoot(R.prop("root_url", partner));
  const submitButtonLabel = activeStep === 0 ? "Next" : "Submit";
  const cancelButtonLabel = activeStep === 0 ? "Cancel" : "Back";

  function handleClose() {
    setOpen(false);
  }

  function handleNext(values) {
    setGeneralFormValues({
      ...values
    });

    onNext();
  }

  function handleBack(values) {
    setBillingFormValues({
      ...values
    });
    onBack();
  }

  function handleSubmit(values) {
    const combinedValues = { ...values, ...generalFormValues };

    function handleSuccess({ data }) {
      if (onSuccess) onSuccess();
      setOpen(false);
      console.log({ data });
      history.push(`/employers/${data.id}/dashboard`);
    }

    function handleError() {
      setOpen(false);
    }

    const path = `/employers`;
    const data = {
      type: "employers",
      attributes: {
        partner_id: partnerID,
        ...combinedValues,
        sso_bypass: !combinedValues.sso_bypass,
        federated_employers_attributes: combinedValues.federated_employers,
        billing_address_attributes: combinedValues.billing_address,
        contacts_attributes: combinedValues.contacts,
        selected_date: combinedValues.contribution_date
          ? new Date(
              combinedValues.contribution_date.year,
              combinedValues.contribution_date.month,
              1
            )
          : null
      }
    };
    createEmployer.makeFetch({
      path,
      data,
      onSuccess: handleSuccess,
      onError: handleError
    });
  }

  return (
    <DialogForm
      title="New Employer"
      open={open}
      onClose={activeStep === 0 ? handleClose : handleBack}
      onSubmit={activeStep === 0 ? handleNext : handleSubmit}
      disabled={createEmployer.loading}
      loading={createEmployer.loading}
      submitButtonProps={{
        label: submitButtonLabel
      }}
      cancelButtonProps={{
        label: cancelButtonLabel
      }}
    >
      {({ handleSubmit, ...formProps }) => {
        return (
          <Stepper steps={steps} activeStep={activeStep}>
            <GeneralForm
              landingRoot={landingRoot}
              defaultValues={{
                sso_bypass: partnerSSOenabled,
                ...generalFormValues
              }}
              partnerSSOenabled={partnerSSOenabled}
              {...formProps}
            />
            <BillingForm
              defaultValues={{ ...billingFormValues }}
              {...formProps}
            />
          </Stepper>
        );
      }}
    </DialogForm>
  );
}

export default withRouter(EmployerCreateModal);

/**
 * TODO remove after tested
 */
// const styles = {
//   modalStyle: { width: "700px" }
// };

// export const EmployerCreateModal = ({
//   partnerID,
//   createEmployer,
//   // partnerSSOenabled,
//   onClose,
//   entityIdentifiers,
//   ...partner
// }) => {
//   const partnerSSOenabled = partner.sso_enabled;
//   console.log({ partner, partnerSSOenabled });
//   return (
//     <SLGModalForm
//       title={`New Employer`}
//       formName="employerCreate"
//       submit={(...args) => {
//         console.log({ args });
//         // createEmployer.apply(null, [...args]);
//       }}
//       close={onClose}
//       contentStyle={styles.modalStyle}
//     >
//       <StepperForm>
//         <EmployerGeneralForm
//           formName="employerCreate"
//           label="General"
//           backButtonLabel="Cancel"
//           submitButtonLabel="Next"
//           initialValues={{
//             partner_id: partnerID,
//             sso_bypass: !partnerSSOenabled,
//             federated_employers: [{}]
//           }}
//           partnerSSOenabled={partnerSSOenabled}
//         />
//         <EmployerPayForm
//           formName="employerCreate"
//           label="Billing"
//           backButtonLabel="Back"
//           submitButtonLabel="Save"
//         />
//       </StepperForm>
//     </SLGModalForm>
//   );
// };

// EmployerCreateModal.propTypes = {
//   partnerID: PropTypes.string.isRequired,
//   createEmployer: PropTypes.func
// };

// function mapStateToProps(state) {
//   // const entityIdentifiers = selectEntityIdentifiers(state);

//   return {
//     employerCreateModalOpen: state.ui.employerCreateModalOpen
//     // entityIdentifiers
//   };
// }

// export default connect(
//   mapStateToProps,
//   {
//     createEmployer: createEmployerAction
//   }
// )(EmployerCreateModal);
