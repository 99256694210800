import {
  FETCH_GROUPS,
  FETCH_GROUP,
  CREATE_GROUP,
  EDIT_GROUP,
  DELETE_GROUP
} from "./types";

export const fetchGroups = (employerID, sort, page, limit) => {
  return {
    type: FETCH_GROUPS,
    payload: { employerID, sort, page, limit }
  };
};

export const fetchGroup = (groupID, employerID) => {
  return {
    type: FETCH_GROUP,
    payload: { groupID },
    employerID
  };
};

export const createGroup = (
  data,
  formName,
  employerID,
  employeeIDs,
  onSuccess
) => {
  return {
    type: CREATE_GROUP,
    payload: { data },
    formName: formName,
    employerID,
    employeeIDs,
    onSuccess
  };
};

export const editGroup = (
  data,
  formName,
  groupID,
  employerID,
  addedEmployeeIDs,
  removedEmployeeIDs,
  onSuccess
) => {
  return {
    type: EDIT_GROUP,
    payload: { data },
    formName: formName,
    groupID,
    employerID,
    addedEmployeeIDs,
    removedEmployeeIDs,
    onSuccess
  };
};

export const deleteGroup = (groupID, employerID, onSuccess) => {
  return {
    type: DELETE_GROUP,
    payload: { groupID, employerID, onSuccess }
  };
};
