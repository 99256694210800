import React from 'react';
import { pick } from 'lodash';
import SLGModalForm from 'components/modals/SLGModalForm';
import TRApproveForm  from '../forms/TRApproveForm';
//import { approveTR as approveTRAction } from '../../../employees/actions';

export const TRApproveModal = ({
  trData,
  approveTR,
  onClose,
  onSuccess
}) => {
 
  const tuition_id             = trData.attributes.id;
  const servicer_account_id    = trData.servicer_account.id;
  const employerId             = trData.employerId;
  const second_approval        = trData.second_approval;
  const second_level_approval  = trData.attributes.second_level_approval;
  const tr_approvals           = trData.tr_approvals;
  const can_be_approved_by_current_admin_user    = trData.attributes.can_be_approved_by_current_admin_user;
  const status                 = trData.attributes.status;
  //console.log(tr_approvals);
  const formName = 'approveTuitionReimbursement';
  const initialFields = pick(trData, ['amount_cents']);
  initialFields.amount = trData.amount_cents / 100;
  return (
    <SLGModalForm
      title="Approve Tuition Reimbursement"
      formName={ formName }
      close={ onClose }
    >
      <TRApproveForm
        formName={ formName }
        label="Approve Tuition Reimbursement"
        servicer_account_id={ servicer_account_id }
        employerId={ employerId }
        second_approval={second_approval}
        second_level_approval={second_level_approval}
        tr_approvals={tr_approvals}
        tuition_id={tuition_id}
        can_be_approved_by_current_admin_user={can_be_approved_by_current_admin_user}
        status={ status }
        enableReinitialize={ true }
        initialValues={ initialFields }
        onSuccess={ onSuccess }
      />
    </SLGModalForm>
  );
};

TRApproveModal.propTypes = {};

export default (TRApproveModal);
