import React from "react";

import { ActionProps } from "./types";
import FullWidthAsyncButton from "../../buttons/async/FullWidthAsyncButton";

function Action({ loading, onClick, type = "submit", children }) {
  return (
    <FullWidthAsyncButton loading={loading} onClick={onClick} type={type}>
      {children}
    </FullWidthAsyncButton>
  );
}

Action.propTypes = ActionProps;

export default Action;
