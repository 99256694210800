import React from "react";
import BaseTableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { TableLoadingBodyProps } from "./types";
import LoadingWrapper from "../feedback/component-loading/XLGLoadingWrapper";

function TableLoadingBody({ loading, colSpan, size = 48, height = 150 }) {
  return (
    <BaseTableBody>
      <TableRow style={{ height }}>
        <TableCell colSpan={colSpan}>
          <LoadingWrapper size={size} loading={loading} />
        </TableCell>
      </TableRow>
    </BaseTableBody>
  );
}

TableLoadingBody.propTypes = TableLoadingBodyProps;

export default TableLoadingBody;
