import { createSelector } from "reselect";
import { map } from "lodash";
import { selectEmployees, selectEmployeesIDList } from "./helpers";

export const selectAllEmployees = createSelector(
  [selectEmployees, selectEmployeesIDList],
  (employees, employeesIDList) => {
    return map(employeesIDList, val => employees[val]);
  }
);
