import React from "react";
import TextField from "@material-ui/core/TextField";

import withCreateRenderInput from "./withCreateRenderInput";
import Autocomplete from "./Autocomplete";

const createRenderInput = ({
  name,
  label,
  required,
  onChange,
  value
}) => params => {
  function handleChange(e) {
    params.inputProps.onChange(e);
    if (onChange) onChange(e);
  }
  const enhancedParams = {
    ...params,
    inputProps: {
      ...params.inputProps,
      onChange: handleChange,
      value
    }
  };
  return (
    <TextField
      {...enhancedParams}
      name={name}
      label={label}
      required={required}
      fullWidth
    />
  );
};

export default withCreateRenderInput(createRenderInput)(Autocomplete);
