import * as R from "ramda";
import DeleteIcon from "@material-ui/icons/Delete";

import ConfirmDialog, { withIcon, withProps } from "./ConfirmDialog";

const getSelectedData = R.innerJoin((record, id) => record.id === id);
const getNamesFromData = R.map(R.prop("pulling_for_name"));
const getSelectedNames = R.pipe(
  getSelectedData,
  getNamesFromData
);

export default withIcon(DeleteIcon)(
  withProps(props => ({
    title: "Remove Users",
    buttonLabel: `Remove ${props.selections.length} users`,
    submitButtonLabel: "Remove Users",
    names: getSelectedNames(props.data, props.selections),
    contentText: `Removing these users, see below, will remove them from this 
      funds request and they will not receive their contribution. 
      You can restore them from the exclusions list if removed by mistake.`
  }))(ConfirmDialog)
);
