import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import { TextField } from "redux-form-material-ui-next";

import OverridesErrorHelperText from "./inputs/OverridesErrorHelperText";

const classes = {
  root: {
    color: "#2e2e2e",
  },
  label: {
    color: "#2e2e2e",
    "&$focused": {
      color: "#2e2e2e",
    },
  },
  focused: {},
  underline: {
    borderBottomColor: "#2e2e2e",
    "&:after": {
      borderBottomColor: "#2e2e2e",
    },
    "&:before": {
      borderBottomColor: "#2e2e2e",
    },
  },
};

const LoginField = ({ classes, ...props }) => {
  return (
    <OverridesErrorHelperText>
      <TextField
        fullWidth
        {...props}
        InputProps={{
          classes: {
            underline: classes.underline,
            root: classes.root,
          },
        }}
        InputLabelProps={{
          FormLabelClasses: {
            root: classes.label,
            focused: classes.focused,
          },
        }}
      />
    </OverridesErrorHelperText>
  );
};

LoginField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(classes)(LoginField);
