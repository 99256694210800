import React from "react";
import { Field } from "redux-form";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

const styles = {
  wrapper: { marginTop: "14px" },
  label: { fontSize: "12.5px" },
  buttonGroup: { marginTop: "5px" }
};

const renderRadioGroup = ({ input, ...rest }) => {
  const { label, defaultOption, onOptionSelect, labelStyle } = rest;
  return (
    <div style={styles.wrapper}>
      <label style={{ ...styles.label, ...labelStyle }}>{label}</label>
      <RadioButtonGroup
        {...input}
        {...rest}
        valueSelected={input.value || defaultOption}
        style={styles.buttonGroup}
        onChange={(event, value) => {
          onOptionSelect(value);
          return input.onChange(value);
        }}
      />
    </div>
  );
};

const VerticalRadioButton = ({ name, options, ...props }) => {
  const radioButtons = options.map((option, index) => {
    return (
      <RadioButton key={index} label={option.label} value={option.value} />
    );
  });

  return (
    <Field name={name} component={renderRadioGroup} {...props}>
      {radioButtons}
    </Field>
  );
};

export default VerticalRadioButton;
