import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import compose from "recompose/compose";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { selectEntityIdentifiers } from "partners/selectors";
import { selectBatchCreateEligibleEmployeesFetching } from "employers/selectors-ext/bulkUploadSelectors";
import WrapWithLoading from "components/loading/WrapWithLoading";
import Dialog from "components/modals/ConfirmationDialog";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import DataGrid, {
  Column,
  Editing,
  Paging,
  Pager,
  SearchPanel,
  RequiredRule,
  PatternRule,
  Selection,
} from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";

import {
  fullFileUploadEmployees,
  createEmployees,
} from "employers/actions/fullFileUploadActions";

const styles = {
  fileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 20,
  },
  fileTextContainer: {
    marginRight: 20,
  },
  buttonContainer: {},
  input: {
    display: "none",
  },
};

class EligibilityFullFileUpload extends React.PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onUploadClick: PropTypes.func.isRequired,
    fullFileUploadEmployees: PropTypes.func.isRequired,
    loading: PropTypes.bool,
  };
  state = {
    prefix: "",
    columns: [
      { name: "personal_id", title: "Personal ID" },
      { name: "first_name", title: "First Name" },
      { name: "last_name", title: "Last Name" },
      { name: "email", title: "Email" },
    ],
    // file: null,
    employeesList: [],
    selectedEmployeesList: [],
    // selectedRowsData: [],
    selectedFile: null,
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.fullFileStatus !== this.props.fullFileStatus) {
      //let jsonData =JSON.stringify(nextProps.fullFileStatus);
      //let str = jsonData.replace(/\\/g, '');
      let jsonData = nextProps.fullFileStatus;
      // let str = jsonData.replace(new RegExp("\\\\", "g"), "");

      // this.setState({
      //   employeesList: JSON.parse(str),
      //   selectedEmployeesList: JSON.parse(str),
      // });
    }
    if (nextProps.createEmployeeStatus !== this.props.createEmployeeStatus) {
      window.location.reload();
    }
  };
  handleUploadClick = () => {
    // const { selectedRowsData } = this.state;
    const { fullFileUploadEmployees, employerID } = this.props;
    const { selectedFile } = this.state;

    fullFileUploadEmployees(employerID, selectedFile);

    // let jsonData = {
    //   emps: selectedRowsData,
    // };
    // createEmployees(jsonData, employerID);
  };

  handleFileChange = (e) => {
    const ext = e.target.files[0].type;
    const extension = ext.replace(/(.*)\//g, "");

    //this.props.handleFileChange(e);
    if (extension === "csv") {
      this.setState({
        selectedFile: e.target.files[0],
      });
    } else {
      alert("Invalid file type. Please upload CSV file.");
    }
    /*this.setState({
      employeesList:data
    })*/
  };
  onSelectionFilterChanged({ value }) {
    let prefix = value;
    if (prefix) {
      let filteredEmployees =
        prefix === "all"
          ? this.state.employeesList
          : this.state.employeesList.filter(
              (employee) => employee.prefix === prefix
            );
      this.setState({
        prefix,
        selectedEmployeesList: filteredEmployees,
      });
    }
  }
  onClearButtonClicked() {
    this.setState({
      prefix: "",
      selectedEmployeesList: this.state.employeesList,
    });
  }
  // onSelectionChanged({ selectedRowsData }) {
  //   this.setState({
  //     selectedRowsData,
  //   });
  // }
  showFileData() {
    const { loading } = this.props;
    const { prefix, selectedEmployeesList } = this.state;

    return (
      <WrapWithLoading loading={loading}>
        <div>
          <SelectBox
            style={{ width: "20%", display: "inline-block" }}
            id="select-prefix"
            dataSource={["all", "new", "update", "terminate"]}
            onValueChanged={this.onSelectionFilterChanged.bind(this)}
            placeholder="Select title"
            value={prefix}
          />{" "}
          <Button
            className="MuiButton-containedPrimary"
            onClick={this.onClearButtonClicked.bind(this)}
          >
            <Typography style={{ color: "white" }}>Clear Selection</Typography>
          </Button>
        </div>
        <DataGrid
          id="gridContainer"
          dataSource={selectedEmployeesList}
          keyExpr="id"
          allowColumnReordering={true}
          showBorders={true}
          showSelectionControls={true}
          onSelectionChanged={this.onSelectionChanged.bind(this)}
        >
          <SearchPanel visible={true} />
          <Paging enabled={true} defaultPageSize={10} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[10, 20, 50]}
            showInfo={true}
          />
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={true}
            allowAdding={true}
          />
          <Selection mode="multiple" showCheckBoxesMode="always" />
          <Column dataField="personal_id" caption="Personal ID" />
          {/*<Column dataField="employee_status" caption="Status">
            <Lookup dataSource={["active", "inactive"]} />
          </Column>
          <Column
            dataField="employment_started_on"
            caption="Start Date"
            dataType="date"
          />*/}
          <Column dataField="first_name" caption="First Name">
            <RequiredRule />
          </Column>
          <Column dataField="last_name" caption="Last Name">
            <RequiredRule />
          </Column>
          {/*<Column dataField="invite_now" caption="Invite Now">
            <Lookup dataSource={["Y", "N"]} />
          </Column>*/}
          <Column dataField="email" width={130}>
            <RequiredRule />
            <PatternRule
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}$"
              message="Email is invalid"
            />
          </Column>
          {/*<Column dataField="group" caption="Group" />*/}
        </DataGrid>
      </WrapWithLoading>
    );
  }

  renderUploadRules() {
    const { classes, loading, entityIdentifiers } = this.props;
    const { selectedFile } = this.state;

    return (
      <WrapWithLoading loading={loading}>
        <div>
          <Typography variant="subtitle1" gutterBottom>
            {`File Requirements: (download ${entityIdentifiers.employeeIdentifier.toLowerCase()} list to see example)`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">- Must be CSV file type</Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- Personal_ID: Unique Identifier for ${entityIdentifiers.employeeIdentifier} (cannot be changed)`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- Email: Email address for ${entityIdentifiers.employeeIdentifier.toLowerCase()}`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- First_name: First name of ${entityIdentifiers.employeeIdentifier.toLowerCase()}`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- Last_name: Last name of ${entityIdentifiers.employeeIdentifier.toLowerCase()}`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- ${entityIdentifiers.employeeIdentifier}_status: "Active" or "Inactive"`}
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            - Group: Must match name exactly or user will be added to default
            group
          </Typography>
        </div>
        <div>
          <Typography variant="body2">
            {`- employment_started_on: ${entityIdentifiers.employeeIdentifier} start date that is used as a start
            date for Vested Pay -- in the format of YYYY-MM-DD, ex: 2017-10-21`}
          </Typography>
        </div>
        <div style={{ marginTop: 10 }}></div>
        <div className={classes.fileContainer}>
          <div className={classes.fileTextContainer}>
            {selectedFile && `File selected: ${selectedFile.name}`}
          </div>
          <div className={classes.buttonContainer}>
            <input
              accept=".csv"
              className={classes.input}
              id="contained-button-file"
              type="file"
              onChange={(e) => this.handleFileChange(e)}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                component="span"
                color="primary"
                className={classes.button}
                disabled={loading}
              >
                <Typography style={{ color: "white" }} noWrap>
                  Select File
                </Typography>
              </Button>
            </label>
          </div>
        </div>
      </WrapWithLoading>
    );
  }

  render() {
    const { entityIdentifiers } = this.props;
    const { selectedFile } = this.state;
    // const disableUpload = this.state.selectedRowsData.length === 0;
    const disableUpload =
      this.state.selectedFile && this.state.selectedFile.length === 0;
    //active only when file is selected

    return (
      <Dialog
        title="Users Full File Upload"
        subTitle={`Create, update and Terminate ${entityIdentifiers.employeeIdentifier.toLowerCase()}s using personal ID as unique id`}
        disabled={disableUpload}
        maxWidth={"md"}
        submitButtonProps={{
          label: "Submit",
          onClick: this.handleUploadClick,
          closeOnSubmit: false,
        }}
        content={
          this.state.employeesList.length === 0
            ? this.renderUploadRules()
            : this.showFileData()
        }
      />
    );
  }
}

const mapStateToProps = (state, _ownProps) => {
  const loading = selectBatchCreateEligibleEmployeesFetching(state);
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    loading,
    entityIdentifiers,
    fullFileStatus: state.fullFileUploadReducers.fullFileStatus,
    createEmployeeStatus: state.creareEmployeesReducers.createEmployeeStatus,
  };
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return {
    fullFileUploadEmployees,
    createEmployees,
  };
};
/*const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default withStyles(styles)(enhance(EligibilityFullFileUpload));
*/
export default withStyles(styles)(
  compose(connect(mapStateToProps, mapDispatchToProps()))(
    EligibilityFullFileUpload
  )
);
