import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const GridItem = props => {
  const { children, ...rest } = props;
  return (
    <Grid item {...rest} style={{ padding: "0 15px" }}>
      {children}
    </Grid>
  );
};

GridItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

const StyledGridItem = styled(GridItem)`
  padding: 0 15px !important;
`;

export default StyledGridItem;
