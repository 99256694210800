import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mapKeys, pick, replace } from "lodash";

import { PartnerBillingForm } from "../forms";
import { SLGModalForm } from "../../components/modals";
import { editPartner as editPartnerAction } from "../partnerActions";

const styles = {
  modalStyle: { width: "700px" }
};

export const PartnerEditModalBilling = ({ partner, editPartner, onClose }) => {
  const formName = "partnerEditContact";

  const billingFields = pick(partner, [
    "id",
    "billing_address",
    "transaction_fee",
    "bill_fee_to_employer",
    "bill_pdr_to_employer",
    "invoice_lead_days"
  ]);

  const initialFields = mapKeys(billingFields, function(value, key) {
    return replace(key, "billing_address", "billing_address_attributes");
  });

  return (
    <SLGModalForm
      title="Edit Partner"
      formName={formName}
      submit={editPartner}
      close={onClose}
      contentStyle={styles.modalStyle}
    >
      <PartnerBillingForm
        formName={formName}
        label="Contact"
        initialValues={initialFields}
      />
    </SLGModalForm>
  );
};

PartnerEditModalBilling.propTypes = {
  partner: PropTypes.object.isRequired,
  editPartner: PropTypes.func
};

export default connect(
  null,
  {
    editPartner: editPartnerAction
  }
)(PartnerEditModalBilling);
