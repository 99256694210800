import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";
import { Link } from "react-router-dom";
import { InfoBlock, InfoField } from "components";
import DialogForm from "components/forms/dialog/Dialog";
import { StyledGridContainer } from "components";
import ActionsCell from "../../tuitionReimbursement/ActionsCell";
//import { approveTR } from "../../../employees/actions";
//import { TRApproveModal } from "../../components/modals/TRApproveModal";
import { openModal } from "../../../app/uiActions";

import '../../tuitionReimbursement/TuitionReimbursementList.css'



const TRViewModal = ({
  openModal,
  trData,
  approveTR,
  onClose,
  onSuccess,
}) => {
  const [open, setOpen] = React.useState(true);
  let status = 'N/A';
  //let showApprovedDiv = false;
  if (trData.attributes.status === 'pending') {
    status = 'Pending';
  }
  if (trData.attributes.status === 'approved') {
    status = 'Approved';
    //showApprovedDiv = true;
  }
  if (trData.attributes.status === 'rejected') {
    status = 'Rejected';
  }
  if (trData.attributes.status === 'need_more_info') {
    status = 'Need More Info';
  }

  const formName = 'tuitionReimbursement';
  //console.log("deepak TRData: ", trData);
  function handleClose() {
    setOpen(false);
  };
  function downloadImage (e,url, name){
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('An error sorry'));
  }
  /*function handleApproveClick() {
    handleClose()
   openModal(<TRApproveModal trData={ trData } />);
  };*/
  
  function prettyAmountCents(amount) {
    return new Intl.NumberFormat(
      'en-US',
      { style: 'currency', currency: 'USD' }
    ).format(amount / 100)
  }
  return (
    <DialogForm
      title="Tuition Reimbursement"
      formName={ formName }
      open={ open }
      onClose={ handleClose }
      contentStyle={{ width: "40%" }}
      submitButtonProps={{ hide: "true" }}
    >
      <StyledGridContainer direction="column">
        <InfoBlock>
          <InfoField
            header="Name"
            value={ trData.customer_name }
            xs={ 6 }
          />
          <InfoField
            header="School/Institution Name"
            value={ trData.attributes.school_name }
            xs={ 6 }
          />
        </InfoBlock>
        <InfoBlock>
          <InfoField
            header="Course Name"
            value={ trData.attributes.course_name }
            xs={ 6 }
          />
          <InfoField
            header="Reimbursement Amount"
            value={ prettyAmountCents(trData.attributes.amount_cents) }
            xs={ 6 }
          />
        </InfoBlock>
        {
          /*showApprovedDiv ?
            <InfoBlock>
              <InfoField
                header="Approved Amount"
                value={ prettyAmountCents(trData.servicer_account.match_payment_cents) }
                xs={ 6 }
              />
            </InfoBlock>
          : null*/
        }
        <InfoBlock>
          <InfoField
            header="Submission Date"
            value={ moment(trData.attributes.created_at).format('MM/DD/YYYY') }
            xs={ 6 }
          />
          <InfoField
            header="Status"
            value={ status }
            xs={ 6 }
          />
        </InfoBlock>
        <InfoBlock>
          <InfoField
            header="Title"
            value={ trData.attributes.title }
            xs={6}
          />
          <InfoField
            header="Department"
            value={ trData.attributes.department }
            xs={6}
          />
        </InfoBlock>
        <InfoBlock>
          <InfoField
            header="Education Expense"
            value={ trData.attributes.education_expense_name }
            xs={6}
          />
        </InfoBlock>  
        <InfoBlock>
          <InfoField
            header="Notes for Manager"
            value={ trData.attributes.notes_manager }
            xs={12}
          />
        </InfoBlock>
        <InfoBlock>
          <InfoField
            header="Description"
            value={ trData.attributes.description }
            xs={ 12 }
          />
        </InfoBlock>
        <InfoBlock>
          <InfoField
            header="Course Start Date"
            value={ moment(trData.attributes.start_date).format('MM/DD/YYYY') }
            xs={ 6 }
          />
          <InfoField
            header="Course Completion Date"
            value={ moment(trData.attributes.completion_date).format('MM/DD/YYYY') }
            xs={ 6 }
          />
        </InfoBlock>

        <InfoBlock>
          <InfoField
            header="Reason"
            value={ trData.attributes.tuition_rejection_note ? trData.attributes.tuition_rejection_note : 'N/A' }
            xs={ 12 }
          />
        </InfoBlock> 
        <InfoBlock header="Receipts">
          {
            trData.receipts.length > 0 ?
              trData.receipts.map(function(item, i){
                const fileName = item.attributes.file_name;
                const ext = fileName && fileName.substr(fileName.lastIndexOf('.') + 1);
                
                return (
                  <>
                    {fileName ?
                      <div key={i} style={{ "margin-right": "5px" }}>
                        <Link to="#" key={i} onClick={(e) => downloadImage(e, item.attributes.url, item.attributes.file_name)} style={{ cursor: 'pointer' }}>
                          {
                            ext === 'pdf' ?
                              item.attributes.file_name :
                              <img key={i} style={{ margin: "0 5px 0 0", float: "left", display: "inline-block" }} src={item.attributes.url} alt={item.attributes.file_name} width="150" height="150" />
                          }
                        </Link>
                      </div>
                      : <p style={{ "margin-left": "5px" }}> No record found </p>
                    }
                  </>
                )
              })
            : <p style={{ "margin-left": "5px" }}> No record found </p>
          }
        </InfoBlock>
        <br />
        <br />
        <InfoBlock header="Proof of Completion">
          {
            trData.proof_of_completions.length > 0 ?
              trData.proof_of_completions.map(function(item, i) {
                const fileName = item.attributes.file_name;
                const ext = fileName && fileName.substr(fileName.lastIndexOf('.') + 1);

                return (
                  <>
                    {fileName ?
                      <div key={i} style={{ "margin-right": "5px" }}>
                        <Link to="#" key={i} onClick={(e) => downloadImage(e, item.attributes.url, item.attributes.file_name)} style={{ cursor: 'pointer' }} >
                          {
                            ext === 'pdf' ?
                              item.attributes.file_name :
                              <img key={i} style={{ margin: "0 5px 0 0", float: "left", display: "inline-block" }} src={item.attributes.url} alt={item.attributes.file_name} width="150" height="150" />
                          }
                        </Link>
                      </div>
                      : <p style={{ "margin-left": "5px" }}> No record found </p>
                    }
                  </>
                )
              })
            : <p style={{ "margin-left": "5px" }}> No record found </p>
          }
        </InfoBlock>
        {/* <div className='receipt_re'>
          <h3>Receipts</h3>
          <div className='receipt_record'>
            {
              trData.receipts.length >  0 ?
                trData.receipts.map(function(item, i){
                  return(
                    <div key={ i }>
                      <img key={ i }  src={ item.attributes.url } alt={ item.attributes.file_name} />
                      <Link to="#" onClick={ (e)=> downloadImage(e,item.attributes.url, item.attributes.file_name) } style={{ cursor:'pointer' }}>
                        Download
                      </Link>
                    </div>
                  )
                })
              : 'No record found'
            }
          </div>
        </div> */}
        {/* <div className='receipt_re'>
          <h3>Proof of Completion</h3>
          <div className='receipt_record'>
            {
              trData.proof_of_completions.length > 0 ?
                trData.proof_of_completions.map(function(item, i) {
                  return(
                    <div key={ i }>
                      <img src={ item.attributes.url } alt={ item.attributes.file_name} />
                      <Link to="#" onClick={ (e) => downloadImage(e,item.attributes.url, item.attributes.file_name) } style={{ cursor:'pointer' }} >{ item.attributes.file_name }</Link>
                    </div>
                  )
                })
              : 'No record found'
            }
          </div>
        </div>   */}
        

        <br />
        <br />
        <InfoBlock>
          <>
            <ActionsCell { ...trData } handleClose={ handleClose }/>
          </>
        </InfoBlock>
      </StyledGridContainer>
    </DialogForm>
  );
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(TRViewModal);
