import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { closeModal as closeModalAction } from "../../app/uiActions";
import SLGPortal from "../SLGPortal";
import Modal from "./Modal";

export const ModalContainer = ({ closeModal, modals }) => {
  return modals.map((item, i) => (
    <SLGPortal key={i}>
      <Modal item={item} onClose={item => closeModal(item.id)} />
    </SLGPortal>
  ));
};

function mapStateToProps(state) {
  return {
    modals: state.ui.modals
  };
}

const enhance = compose(
  connect(mapStateToProps, {
    closeModal: closeModalAction
  })
);

export default enhance(ModalContainer);
