import React from "react";

function Container({ Card, Header, SubActions, Search, Content }) {
  function CardComponent({
    hideTitleBar,
    title,
    disabled,
    loading,
    actionSlot,
    subActionSlot,
    subAction1Slot,
    contentSlot,
    hideSearch,
    searchFormProps
  }) {
    return (
      <Card>
        {!hideTitleBar && (
          <Header
            title={title}
            loading={loading}
            disabled={disabled}
            actionSlot={actionSlot}
          />
        )}
        {subActionSlot && (
          <SubActions
            disabled={disabled}
            loading={loading}
            subActionSlot={subActionSlot}
          />
        )}
        {!hideSearch && <Search {...searchFormProps} />}
        {subAction1Slot && (
          <SubActions
            disabled={disabled}
            loading={loading}
            subActionSlot={subAction1Slot}
          />
        )}
        <Content
          disabled={disabled}
          loading={loading}
          contentSlot={contentSlot}
        />
      </Card>
    );
  }

  return CardComponent;
}

export default Container;
