/*import { put, all, call, takeEvery } from "redux-saga/effects";
import { destroy } from "redux-form";
import * as R from "ramda";
import { GET_LIST, CREATE, UPDATE, DELETE, GET_ONE, FILE_UPLOAD } from "rest/types";
import fetchService from "rest/fetchApiService";
import slgRestClient from "rest/slgRestClient";
import log from "services/logging/service";
import {
  FULL_FILE_UPLOAD,
  FULL_FILE_UPLOAD_SUCCESS
} from "../actions/types";

import { showNotification, closeAllModals } from "app/uiActions";
function _submissionErrorNotification(errorMessage) {
  return showNotification(
    errorMessage + ", please update and resubmit.",
    "warning"
  );
}

function* fullFileUpload(action) {
  //yield put({
    //type: FULL_FILE_UPLOAD_SUCCESS,
    //payload: 1
  //});
  try {
    const fileUpload = yield call(
      requestFullFileUpload,
      action.payload.data,
      action.employerId,
    );

    yield put({
      type: FULL_FILE_UPLOAD_SUCCESS,
      payload: fileUpload
    });
    yield put(closeAllModals());
    yield put(showNotification("CSV upload successfully"));
  } catch (e) {
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}
function requestFullFileUpload(data, employerId) {
  return slgRestClient(FILE_UPLOAD, "employers/"+employerId+'/get_csv_file', {
    ...{ data }
  })
    .then((resp) => {
      return resp.data
    })
    .catch((e) => {
      throw new Error(e.message);
      log.error(e.message)
    });
}
export default function* sagas() {
  yield all([
    takeEvery(FULL_FILE_UPLOAD, fullFileUpload)
  ]);
}*/
import { put, all, call, takeEvery } from "redux-saga/effects";

import routeService from "../services/RouteService";
import fetchApiService from "../../rest/fetchApiService";
import { showError, showSuccess } from "../../shared/sagas/notifications";

import {
  FULL_FILE_UPLOAD_EMPLOYEES_REQUEST,
  FULL_FILE_UPLOAD_SUCCESS,
} from "../actions/fullFileUploadActions";

import FullFileUploadEmployeesRequest from "../services/FullFileUploadEmployeesRequest";
import FullFileUploadEmployeesResponse from "../services/FullFileUploadEmployeesResponse";

function* fullFileUploadEmployees({ employerId, file }) {
  try {
    const endpoint = routeService.fullFileUpload(employerId);
    const request = new FullFileUploadEmployeesRequest({
      url: endpoint,
      file,
    });
    const response = yield fetchApiService.uploadFile(
      request,
      FullFileUploadEmployeesResponse
    );
    if (response.ok) {
      // const data = response.data.emps;
      yield call(showSuccess, response.data.message);
      // yield put({
      //   type: FULL_FILE_UPLOAD_SUCCESS,
      //   payload: data,
      // });
    } else {
      yield call(showError, response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
  }
}

export default function* sagas() {
  yield all([
    takeEvery(FULL_FILE_UPLOAD_EMPLOYEES_REQUEST, fullFileUploadEmployees),
  ]);
}
