import BaseRequest from "../../shared/BaseRequest";

export default class CreateContactRequest extends BaseRequest {
  method = "post";

  constructor({
    url,
    contactType,
    adminUserId,
    employerId,
    first_name,
    last_name,
    email,
    job_title,
    phone
  }) {
    super({ url });

    let attributes;
    if (contactType === "admin") {
      attributes = {
        type: "admin_user",
        contact_owners_contact_sources_attributes: [
          {
            contact_owner_id: employerId,
            contact_owner_type: "Employer",
            contact_source_id: adminUserId,
            contact_source_type: "AdminUser"
          }
        ]
      };
    } else {
      attributes = {
        type: "light_contact",
        first_name,
        last_name,
        email,
        job_title,
        phone,
        contact_owners_contact_source_attributes: {
          contact_owner_id: employerId,
          contact_owner_type: "Employer"
        }
      };
    }

    this.data = {
      data: {
        type: "derived_contacts",
        attributes
      }
    };
  }
}
