import React from "react";
import { useFormContext } from "react-hook-form";

import useRegister from "./useRegister";

export default WC => ({
  loading,
  name,
  helperText,
  disabled,
  required,
  validate,
  onChange,
  Component,
  ...props
}) => {
  const methods = useFormContext();
  const {
    handleChange: handleFormChange,
    shouldDisabled,
    shouldRequired,
    error,
    helpText,
    value
  } = useRegister({
    ...methods,
    loading,
    name,
    helperText,
    disabled,
    validate,
    required
  });
  function handleChange(e) {
    if (onChange) onChange(e);
    handleFormChange(e);
  }
  return (
    <WC
      {...props}
      name={name}
      disabled={shouldDisabled}
      required={shouldRequired}
      error={error}
      onChange={handleChange}
      helpText={helpText}
      value={value}
      loading={loading}
    />
  );
};
