import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import StyledGridContainer from "components/StyledGridContainer";
import StyledGridItem from "components/StyledGridItem";

const ChildrenContainer = styled(StyledGridContainer)`
  margin-left: 10px;
  margin-right: 10px;
`;

const InfoBlock = props => {
  const { header, children, parentContainerProps, childContainerProps } = props;

  return (
    <StyledGridContainer {...parentContainerProps}>
      <StyledGridItem xs={12}>
        <strong> {header} </strong>
      </StyledGridItem>
      <ChildrenContainer {...childContainerProps}>{children}</ChildrenContainer>
    </StyledGridContainer>
  );
};

InfoBlock.propTypes = {
  flexPercent: PropTypes.number,
  header: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

InfoBlock.defaultProps = {
  flexPercent: 50,
  header: "",
  children: null
};

export default InfoBlock;
