import createReducer from "../../helpers/createReducer";
import {
  TEST_REQUEST,
  TEST_SUCCESS,
  TEST_FAILURE,
  ENABLE_SYNC_REQUEST,
  ENABLE_SYNC_SUCCESS,
  SYNC_NOW_FAILURE,
  SYNC_NOW_REQUEST,
  SYNC_NOW_SUCCESS,
  ENABLE_SYNC_FAILURE,
  EDIT_REQUEST,
  EDIT_SUCCESS,
  EDIT_FAILURE,
  GET_LOGS_REQUEST,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAILURE
} from "../actions/ultiproActions";

const defaultState = {
  logs: {
    byEmployerId: {}
  },
  testFetching: false,
  testErrors: null,
  editFetching: false,
  editErrors: null,
  enableSyncFetching: false,
  enableSyncErrors: null,
  syncNowFetching: false,
  syncNowErrors: null,
  getLogsFetching: false,
  getLogsErrors: null
};

/** test */
function testRequest(state) {
  return Object.assign({}, state, {
    testFetching: true,
    testErrors: null
  });
}

function testSuccess(state) {
  return Object.assign({}, state, {
    testFetching: false,
    testErrors: null
  });
}
function testFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    testFetching: false,
    testErrors: error
  });
}

/** enable sync */
function enableSyncRequest(state) {
  return Object.assign({}, state, {
    enableSyncFetching: true,
    enableSyncErrors: null
  });
}

function enableSyncSuccess(state) {
  return Object.assign({}, state, {
    enableSyncFetching: false,
    enableSyncErrors: null
  });
}
function enableSyncFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    enableSyncFetching: false,
    enableSyncErrors: error
  });
}

/** edit */
function editRequest(state) {
  return Object.assign({}, state, {
    editFetching: true,
    editErrors: null
  });
}

function editSuccess(state) {
  return Object.assign({}, state, {
    editFetching: false,
    editErrors: null
  });
}
function editFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    editFetching: false,
    editErrors: error
  });
}

/** sync now */
function syncNowRequest(state) {
  return Object.assign({}, state, {
    syncNowFetching: true,
    syncNowErrors: null
  });
}

function syncNowSuccess(state) {
  return Object.assign({}, state, {
    syncNowFetching: false,
    syncNowErrors: null
  });
}
function syncNowFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    syncNowFetching: false,
    syncNowErrors: error
  });
}

/** get logs */
function getLogsRequest(state) {
  return Object.assign({}, state, {
    getLogsFetching: true,
    getLogsErrors: null
  });
}

function getLogsSuccess(state, action) {
  const data = action.payload;
  return Object.assign({}, state, {
    getLogsFetching: false,
    getLogsErrors: null,
    logs: Object.assign({}, state.logs.byEmployerId, { byEmployerId: data })
  });
}
function getLogsFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    getLogsFetching: false,
    getLogsErrors: error
  });
}

export default createReducer(defaultState, {
  [TEST_REQUEST]: testRequest,
  [TEST_SUCCESS]: testSuccess,
  [TEST_FAILURE]: testFailure,
  [ENABLE_SYNC_REQUEST]: enableSyncRequest,
  [ENABLE_SYNC_SUCCESS]: enableSyncSuccess,
  [ENABLE_SYNC_FAILURE]: enableSyncFailure,
  [EDIT_REQUEST]: editRequest,
  [EDIT_SUCCESS]: editSuccess,
  [EDIT_FAILURE]: editFailure,
  [SYNC_NOW_REQUEST]: syncNowRequest,
  [SYNC_NOW_SUCCESS]: syncNowSuccess,
  [SYNC_NOW_FAILURE]: syncNowFailure,
  [GET_LOGS_REQUEST]: getLogsRequest,
  [GET_LOGS_SUCCESS]: getLogsSuccess,
  [GET_LOGS_FAILURE]: getLogsFailure
});
