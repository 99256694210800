import React from "react";
import BaseTextField from "@material-ui/core/TextField";

import withHookFormContext from "../containers/withHookFormContext";

export function TextFieldComponent({
  name,
  helpText,
  register,
  hookFormMethods,
  validate = {},
  formProps,
  ...props
}) {
  return (
    <BaseTextField
      margin="normal"
      fullWidth
      id={name}
      name={name}
      helperText={helpText}
      inputRef={register}
      {...props}
    />
  );
}

const TextField = withHookFormContext(TextFieldComponent);

export default TextField;
