import useMakeFetch from "../../../services/api/useMakeFetch";

function useFetch({ method }) {
  const { makeFetch, loading, data, total } = useMakeFetch({ method });

  return {
    makeFetch,
    loading,
    data,
    total
  };
}

export default useFetch;
