import * as R from "ramda";

const removeTypeFromName = R.evolve({
  invoice_name: R.pipe(
    R.replace(/(PDR|FEES)$/, ""),
    R.replace(/\//g, "-"),
    R.replace(/ /g, "")
  )
});
const removeTotal = R.ifElse(
  i => i.invoice_type === "pdr",
  R.pipe(
    i => ({ ...i, pdr_id: i.id }),
    R.omit(["total_fees_cents"])
  ),
  R.pipe(
    i => ({ ...i, fee_id: i.id }),
    R.omit(["total_pdr_cents"])
  )
);
const groupByName = R.groupBy(R.prop("invoice_name"));
const mergeInvoices = R.map(R.mergeAll);

export default R.pipe(
  R.map(
    R.pipe(
      removeTypeFromName,
      removeTotal
    )
  ),
  groupByName,
  mergeInvoices,
  R.values
);
