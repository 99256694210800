export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_LOADING = "USER_LOGIN_LOADING";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const userLogin = (payload, pathName) => ({
  type: USER_LOGIN,
  payload,
  meta: { auth: true, pathName }
});

export const USER_CHECK = "USER_CHECK";
export const USER_CHECK_SUCCESS = "USER_CHECK_SUCCESS";

export const userCheck = pathName => ({
  type: USER_CHECK,
  meta: { auth: true, pathName }
});

export const USER_LOGOUT = "USER_LOGOUT";

export const userLogout = msg => ({
  type: USER_LOGOUT,
  meta: { auth: true },
  payload: msg
});

export const FETCH_ERROR = "FETCH_ERROR";
