import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pick } from "lodash";

import { EmployerGeneralForm } from "../forms";
import { SLGModalForm } from "../../components/modals";
import { editEmployer as editEmployerAction } from "../employerActions";
import { selectEntityIdentifiers } from "../../partners/selectors";

const styles = {
  modalStyle: { width: "700px" }
};

export const EmployerEditModalGeneral = ({
  employer,
  editEmployer,
  partnerSSOenabled,
  onClose,
  entityIdentifiers
}) => {
  const initialFields = pick(employer, [
    "id",
    "name",
    "sso_bypass",
    "sso_id",
    "landing_page_slug",
    "whitelisted_domains",
    "two_factor_required",
    "contacts",
    "password_expiration_term",
    "old_password_count",
    "employer_security",
    "federated_employers",
    "total_employees"
  ]);
  return (
    <SLGModalForm
      title={`Edit ${entityIdentifiers.employerIdentifier}`}
      formName="employerEditGeneral"
      submit={(...args) => editEmployer.apply(null, [...args, initialFields])}
      close={onClose}
      contentStyle={styles.modalStyle}
    >
      <EmployerGeneralForm
        formName="employerEditGeneral"
        label="General"
        backButtonLabel="Cancel"
        submitButtonLabel="Save"
        initialValues={initialFields}
        partnerSSOenabled={partnerSSOenabled}
      />
    </SLGModalForm>
  );
};

EmployerEditModalGeneral.propTypes = {
  employer: PropTypes.object.isRequired,
  editEmployer: PropTypes.func
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return {
    entityIdentifiers
  };
}

export default connect(
  mapStateToProps,
  {
    editEmployer: editEmployerAction
  }
)(EmployerEditModalGeneral);
