import React from "react";

import TextField from "./text-field/Field";

const rules = {
  required: "required",
  pattern: {
    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    message: "Invalid email"
  }
};

const attributes = {
  name: "email",
  label: "Email",
  required: true,
  rules
};

export default function EmailField(props) {
  return <TextField {...attributes} {...props} />;
}
