import React from "react";

import RadioGroup from "./RadioGroup";

const options = [
  { label: "None", value: "none" },
  {
    label: "Require 2-Factor Authentication",
    value: "two_factor"
  },
  { label: "Password Expiration", value: "password_expiration" }
];

export default function SecurityPicker(props) {
  return (
    <RadioGroup
      name="employer_security"
      label="Security"
      options={options}
      {...props}
    />
  );
}
