import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
/** legacy code, will remove once new form is done */
import NewButton from "components/buttons/action/New";
import { openModal } from "app/uiActions";
import { NoteCreateModal } from "../modals";

const PageAction = ({
  openModal,
  loading,
  disabled,
  stateProps: { requestData },
  ...employee
}) => {
  const handleNewClick = () => {
    const handleSuccess = () => requestData();

    openModal(
      <NoteCreateModal
        selectedEmployee={{ ...employee, id: employee.employeeId }}
        onSuccess={handleSuccess}
      />
    );
  };

  return (
    <>
      <NewButton disabled={disabled || loading} onClick={handleNewClick}>
        New Note
      </NewButton>
    </>
  );
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(PageAction);
