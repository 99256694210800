import { createSelector } from "reselect";
import { map } from "lodash";
import { selectEmployees, selectEmployeesIDList } from "./helpers";

export const selectEmployeesByEmployer = createSelector(
  [selectEmployees, selectEmployeesIDList, (_, employerID) => employerID],
  (employees, employeesIDList, employerID) => {
    return map(employeesIDList, val => employees[val]).filter(
      val => employerID === val.employer_id
    );
  }
);
