import React from "react";
import { compose } from "ramda";

import spacing from "../../../theme/spacing";
import Centered from "../Centered";
import withSpacing from "../withSpacing";

function Actions(props) {
  return <Centered {...props} />;
}

export default compose(withSpacing(spacing.xs))(Actions);
