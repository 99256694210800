import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import LoadingWrapper from "../../feedback/component-loading/SMLoadingWrapper";
import withIconComponent from "./withIconComponent";
import Select from "./EnhancedSelect";

const AsyncIcon = ({ loading }) => (
  <LoadingWrapper loading={loading}>
    <ArrowDropDownIcon />
  </LoadingWrapper>
);
const AsyncSelect = withIconComponent(AsyncIcon)(Select);

export default AsyncSelect;
