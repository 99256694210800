import React from 'react';
import * as R from 'ramda';
import ListPage, { withProps } from 'components/containers/ListPage';
import withPagination from 'components/containers/withPagination';
import Status from './Status';
import ViewCell from './ViewCell';
import Amount from './Amount';
import SubmissionDate from './SubmissionDate'
import './TuitionReimbursementList.css'

export const columns = props => [
  {
    name: 'customer_name',
    label: 'Employee Name'
  },
  {
    name: 'school_name',
    label: 'School/Institution Name'
  },
  {
    name: 'course_name',
    label: 'Course Name',
  },
  {
    name: 'amount_cents',
    label: 'Reimbursement Amount',
    render: r => <Amount { ...props } { ...r } />
  },
  {
    name: 'created_at',
    label: 'Submission Date',
    render: r => <SubmissionDate { ...props } { ...r } />
  },
  {
    name: 'status',
    label: 'Status',
    render: r => <Status { ...props } { ...r } />
  },
  {
    name: 'id',
    label: 'Actions',
    disableSort: true,
    render: r => <ViewCell { ...props } { ...r } />
  }
];

const extraProps = props => {
  const employerId = props.employerId;
  return {
    title: 'Tuition Reimbursement',
    hideSearch: true,
    path: employerId && '/employers/' + employerId + '/tuitions'
  };
};
const actionsProps = props => {
  return {
    columns: columns(props)
  };
};
export default R.compose(
  withProps(extraProps),
  withProps(actionsProps),
  withPagination
)(ListPage);
