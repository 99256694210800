import { compose } from "ramda";

import Button from "../button/Button";
import withWhiteLabel from "../button/withWhiteLabel";
import withPrimaryBackground from "../button/withPrimaryBackground";

export default compose(
  withWhiteLabel,
  withPrimaryBackground
)(Button);
