import React from "react";
import * as R from "ramda";

import { adminTypeTooltipMsg } from "../../helpers";
import Grid from "../../components/layouts/Grid";
import justifyBetween from "../../components/layouts/justifyBetween";
import alignCenter from "../../components/layouts/alignCenter";
import InfoTooltip from "../../components/tooltips/Info";

const CellGroup = justifyBetween(alignCenter(Grid));
const capitalize = R.compose(
  R.join(""),
  R.juxt([
    R.compose(
      R.toUpper,
      R.head
    ),
    R.tail
  ])
);

function RoleCell({ ...row }) {
  const tooltipTitle = adminTypeTooltipMsg(row.attributes.role);
  return (
    <CellGroup>
      {capitalize(row.attributes.role)}
      {tooltipTitle && <InfoTooltip title={tooltipTitle} />}
    </CellGroup>
  );
}

export default RoleCell;
