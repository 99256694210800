import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  TextField as TextInput,
  SelectField as SelectInput,
} from 'redux-form-material-ui';
import MenuItem from "material-ui/MenuItem";
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import NumberFormat from 'react-number-format';
import { required, maxLength50 } from 'helpers';
import { approveTR,fetchSecondApproval  } from '../../../employees/actions';

const styles = {
  inputUnderline: { borderColor: '#258ffa' },
  inputLabel: { color: '#00acc1' },
  raisedButton: { borderRadius: '3px' },
  width70: { width: '70%' },
  formButtonsBar: { marginTop: '20px', float: 'right' },
  inline70: { display: 'inline-block', width: '70%' },
  spaceRight: { marginRight: '5%' },

};
function MyNumberFormatOld(props) {
    const { inputRef, onChange, input, ...other } = props;
    const amount = input.value
    return (
      <NumberFormat
      { ...other }
      getInputRef={ inputRef }
      prefix="$"
      thousandsgroupstyle="thousand"
      decimalSeparator="."
      displayType="input"
      thousandSeparator={ true }
      decimalScale={ 2 }
      fixedDecimalScale={ true }
      value={ amount }
      />
    );
  }
export class TRApproveForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //second_approval: [],
      amount:this.props.initialValues ? this.props.initialValues.amount : 0
    }
  }
  static propTypes = {
    formName: PropTypes.string.isRequired
  };
  componentDidMount() {
    //const { employerId fetchSecondApproval }= this.props;
    //fetchSecondApproval(employerId)
  }
  componentDidUpdate(prevProps) {
    /*if(prevProps.second_approval !== this.props.second_approval){
      console.log(this.props.second_approval);
      this.setState({
        second_approval:this.props.second_approval
      })
    }*/
  }
  menuItems(second_approval) {
    return second_approval.map(option => (
      <MenuItem key={option.id} value={option.id} primaryText={option.attributes.email} />
    ));
  }
  handleChange = (value)=>{
    console.log(value)
  }
  
  MyNumberFormat =(props)=>{
    const { inputRef, onChange, input, ...other } = props;
    
    return (
      <NumberFormat
      { ...other }
      getInputRef={ inputRef }
      prefix="$"
      thousandsgroupstyle="thousand"
      decimalSeparator="."
      displayType="input"
      thousandSeparator={ true }
      decimalScale={ 2 }
      fixedDecimalScale={ true }
      value={this.state.amount}
      onValueChange={(values) => {
        const { value } = values;
        this.setState({
          amount: value
        })
      }}
      />
    );
  }
  _submit = values => {
    const {
      approveTR,
      formName,
      servicer_account_id,
      employerId,
      onSuccess,
      second_level_approval,
      tuition_id,
      tr_approvals
    } = this.props;
    console.log(this.state)
    let data = {
      //'servicer_account_id': servicer_account_id,
      'tuition_id':tuition_id,
      'match_payment_cents': this.state.amount * 100
    }
    var trApprovals = tr_approvals.find((item) => item.status === 'pending');
    if(second_level_approval===true && tr_approvals.length === 1  && trApprovals!==undefined ){
      data.second_approver_id =values.second_approver_id;
    }
    return approveTR(data, formName, servicer_account_id,employerId, onSuccess);
  }
  render() {
    const { 
      handleSubmit, 
      handlePrev, 
      second_approval, 
      second_level_approval, 
      tr_approvals, 
      can_be_approved_by_current_admin_user
    } = this.props;  
    var trApprovals = tr_approvals.find((item) => item.status === 'pending');
     
    return (
      <div>
        <form onSubmit={ handleSubmit(this._submit) }>
          <div>
            <Field
              name="amount"
              component={ this.MyNumberFormat }
              customInput={ TextInput }
              floatingLabelText="Amount *"
              floatingLabelShrinkStyle={ styles.inputLabel }
              underlineStyle={ styles.inputUnderline }
              style={ styles.width70 }
              validate={ [required, maxLength50] }
              floatingLabelFixed={ true }
            />
          </div>
          {(second_level_approval===true && tr_approvals.length ===1 && trApprovals!==undefined && can_be_approved_by_current_admin_user ===true) ?
          <div>
            <Field
              name="second_approver_id"
              component={SelectInput}
              floatingLabelText="Select second approval*"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={Object.assign({}, styles.inline70, styles.spaceRight)}
              validate={required}
              onChange={(event, index, value) => { this.handleChange(index) }}
            >
               {this.menuItems(second_approval)}
            </Field> 
          </div>
          :null}
          <div style={ styles.formButtonsBar }>
            <FlatButton
              label="Cancel"
              type="button"
              onClick={ handlePrev }
              style={{ marginRight: "12px" }}
            />
            {second_level_approval===true && can_be_approved_by_current_admin_user ===true ?
              <RaisedButton
                label="Save"
                type="submit"
                buttonStyle={ styles.raisedButton }
                style={ styles.raisedButton }
                primary={ true }
              />
            :null}
            {second_level_approval===false && can_be_approved_by_current_admin_user ===false ?
              <RaisedButton
                label="Save"
                type="submit"
                buttonStyle={ styles.raisedButton }
                style={ styles.raisedButton }
                primary={ true }
              />
            :null}
          </div>
        </form>
      </div>
    );
  }
}

TRApproveForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true
})(TRApproveForm);

const mapStateToProps = (state, ownProps) => {
  return {
    form: ownProps.formName,
    second_approval: state.tutionsReducer.getSecondApproval
  };
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { approveTR,fetchSecondApproval};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(TRApproveForm);
