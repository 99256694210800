import { concat, keyBy, includes } from "lodash";
import moment from "moment";

import {
  FETCH_EMPLOYERS_SUCCESS,
  FETCH_EMPLOYERS,
  FETCH_EMPLOYERS_FAILURE,
  FETCH_EMPLOYER_STATS,
  FETCH_EMPLOYER_STATS_FAILURE,
  FETCH_EMPLOYER_STATS_SUCCESS,
  FETCH_EMPLOYER,
  FETCH_EMPLOYER_SUCCESS,
  FETCH_EMPLOYER_FAILURE,
  CREATE_EMPLOYER_SUCCESS,
  EDIT_EMPLOYER_SUCCESS,
  FETCH_FUNDS_REQUESTS,
  FETCH_FUNDS_REQUESTS_SUCCESS,
  FETCH_FUNDS_REQUESTS_FAILURE,
  FETCH_REGISTERED_CLICK,
  FETCH_REGISTERED_CLICK_SUCCESS,
  FETCH_REGISTERED_CLICK_FAILURE,
  FETCH_EMPLOYERS_DETAILS,
  FETCH_EMPLOYERS_DETAILS_SUCCESS,
  FETCH_EMPLOYERS_DETAILS_FAILURE,
  FETCH_EMPLOYERS_USER_STATS,
  FETCH_EMPLOYERS_USER_STATS_FAILURE,
  FETCH_EMPLOYERS_USER_STATS_SUCCESS,
  FETCH_EMPLOYER_JOURNEY_STATS,
  FETCH_EMPLOYER_JOURNEY_STATS_SUCCESS,
  FETCH_EMPLOYER_JOURNEY_STATS_FAILURE,
} from "./employerActions";
import {
  TEST_SUCCESS as ULTIPRO_TEST_SUCCESS,
  ENABLE_SYNC_SUCCESS as ULTIPRO_ENABLE_SYNC_SUCCESS,
} from "./actions/ultiproActions";

const defaultState = {
  employers: {
    byID: {},
    items: [],
    selectedID: null,
    isFetching: false,
    receivedAt: null,
    isFetched: false,
    total: 0,
  },
  // Break these into their own reducers
  employerStats: {
    byEmployerID: {},
    isFetching: false,
    receivedAt: null,
  },
  fundsRequests: {
    byID: {},
    items: [],
    isFetching: false,
    receivedAt: null,
    total: 0,
  },
  registeredClicksStats: {
    data: null,
    isFetching: false,
  },
   employersDetails: {
    data: null,
    isFetching: false,
  },
  employersUserStats: {
    data: null,
    isFetching: false,
  },
  employerJourneyStats: {
    data: null,
    isFetching: false,
  }
};

export const fundsRequests = (
  previousState = defaultState.fundsRequests,
  { type, payload }
) => {
  switch (type) {
    case FETCH_FUNDS_REQUESTS:
      return {
        ...previousState,
        isFetching: true,
      };
    case FETCH_FUNDS_REQUESTS_SUCCESS:
      return {
        ...previousState,
        byID: {
          ...previousState.byID,
          ...keyBy(payload.data, "id"),
        },
        items: payload.data.map((item) => item.id),
        total: payload.total,
        receivedAt: Date.now(),
        isFetching: false,
      };
    case FETCH_FUNDS_REQUESTS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

export const employerStats = (
  previousState = defaultState.employerStats,
  { type, payload }
) => {
  switch (type) {
    case FETCH_EMPLOYER_STATS:
      return {
        ...previousState,
        isFetching: true,
      };
    case FETCH_EMPLOYER_STATS_SUCCESS:
      return {
        ...previousState,
        byEmployerID: {
          ...previousState.byEmployerID,
          [payload.employerID]: payload,
        },
        isFetching: false,
        receivedAt: Date.now(),
      };
    case FETCH_EMPLOYER_STATS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

const mergeEmployerUltipro = (state, employerId, ultipro) => {
  const employer = state.byID[employerId];
  const byID = Object.assign({}, state.byID, {
    [employerId]: Object.assign({}, employer, {
      ultipro_integration: Object.assign(
        {},
        employer.ultipro_integration,
        ultipro
      ),
    }),
  });
  return {
    ...state,
    byID,
  };
};

export const employers = (
  previousState = defaultState.employers,
  { type, payload, employerId }
) => {
  switch (type) {
    case FETCH_EMPLOYERS:
      return {
        ...previousState,
        isFetching: true,
      };
    case FETCH_EMPLOYERS_SUCCESS:
      const employersById = keyBy(payload.data, "id");
      const allEmployers = Object.keys(employersById).map((key) => {
        return key;
      });
      return {
        ...previousState,
        byID: employersById,
        items: allEmployers,
        isFetching: false,
        total: payload.total,
        receivedAt: Date.now(),
        isFetched: true,
      };
    case FETCH_EMPLOYERS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    case CREATE_EMPLOYER_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: concat(previousState.items, payload.id),
        total: previousState.total + 1,
      };
    case EDIT_EMPLOYER_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
      };
    case FETCH_EMPLOYER:
      return {
        ...previousState,
        isFetching: true,
      };
    case FETCH_EMPLOYER_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: includes(previousState.items, payload.id)
          ? previousState.items
          : concat(previousState.items, payload.id),
        isFetching: false,
        receivedAt: Date.now(),
      };
    case FETCH_EMPLOYER_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    case ULTIPRO_TEST_SUCCESS:
      return mergeEmployerUltipro(previousState, employerId, {
        credentials_successfully_tested_at: moment().format(),
      });
    case ULTIPRO_ENABLE_SYNC_SUCCESS:
      return mergeEmployerUltipro(previousState, employerId, {
        sync_enabled: true,
      });

    default:
      return previousState;
  }
};

export const registeredClicksStats = (
  previousState = defaultState.registeredClicksStats,
  { type, payload }
) => {
  switch (type) {
    case FETCH_REGISTERED_CLICK:
      return {
        ...previousState,
        isFetching: true,
      };

    case FETCH_REGISTERED_CLICK_SUCCESS:
      return {
        ...previousState,
        data: payload,
        isFetching: false,
        receivedAt: Date.now(),
      };
    case FETCH_REGISTERED_CLICK_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

export const employersDetails= (
  previousState = defaultState.employersDetails,
  { type, payload }
) => {
  switch (type) {
    case FETCH_EMPLOYERS_DETAILS:
      return {
        ...previousState,
        isFetching: true,
      };

    case FETCH_EMPLOYERS_DETAILS_SUCCESS:
      return {
        ...previousState,
        data: payload,
        isFetching: false,
        receivedAt: Date.now(),
      };
    case FETCH_EMPLOYERS_DETAILS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

export const employerUserStats = (
  previousState = defaultState.employersUserStats,
  { type, payload }
) => {
  switch (type) {
    case FETCH_EMPLOYERS_USER_STATS:
      return {
        ...previousState,
        isFetching: true,
      };

    case FETCH_EMPLOYERS_USER_STATS_SUCCESS:
      return {
        ...previousState,
        data: payload,
        isFetching: false,
        receivedAt: Date.now(),
      };
    case FETCH_EMPLOYERS_USER_STATS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

export const employerJourneyStats = (
  previousState = defaultState.employerJourneyStats,
  { type, payload }
) => {
  switch (type) {
    case FETCH_EMPLOYER_JOURNEY_STATS:
      return {
        ...previousState,
        isFetching: true,
      };

    case FETCH_EMPLOYER_JOURNEY_STATS_SUCCESS:

      return {
        ...previousState,
        data: payload,
        isFetching: false,
        // receivedAt: Date.now(),
      };
    case FETCH_EMPLOYER_JOURNEY_STATS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

