import React from "react";
import PropTypes from "prop-types";

const styles = {
  header: { display: "flex", flex: "45%", fontWeight: "bold" },
  value: { display: "flex", flex: "40%", alignSelf: "center" },
  container: {
    display: "flex",
    marginBottom: "5%"
  }
};

const SettingsField = ({ header, value }) => {
  return (
    <div style={styles.container}>
      <span style={styles.header}>{header}</span>
      <span style={styles.value}>{value}</span>
    </div>
  );
};

SettingsField.propTypes = {
  header: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ])
};

SettingsField.defaultProps = {
  header: "",
  value: ""
};

export default SettingsField;
