const Status = ({ ...trData }) => {
  return trData.status === "pending"
    ? "Pending"
    : trData.status === "approved"
    ? "Approved"
    : trData.status === "rejected"
    ? "Rejected"
    : trData.status === "need_more_info"
    ? "Need more info"
    : "";
};

export default Status;
