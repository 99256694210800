import BaseCard from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";

import { CardProps } from "./types";
import Container from "./Container";
import Header from "./Header";
import Search from "./Search";
import SubActions from "./SubActions";
import Content from "./Content";

const styles = {
  root: {
    flex: 1
  }
};
const Card = Container({
  SubActions,
  Content,
  Header,
  Search,
  Card: withStyles(styles)(BaseCard)
});

Card.propTypes = CardProps;

export default Card;
