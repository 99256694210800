import * as R from "ramda";
import {
  centsToMoneyStringFromProp,
  toDisplayDateStringFromProp
} from "services/formatters";
import ListPage, { withProps } from "components/containers/ListPage";

const columns = [
  {
    name: "short_code",
    label: "Name"
  },
  {
    name: "amount_cents",
    label: "Amount",
    format: centsToMoneyStringFromProp("amount_cents")
  },
  { name: "status", label: "Status", disableSort: true },
  {
    name: "created_at",
    label: "Created",
    format: toDisplayDateStringFromProp("created_at")
  },
  {
    name: "updated_at",
    label: "Updated",
    format: toDisplayDateStringFromProp("updated_at")
  }
];

const extraProps = props => {
  const employeeId = props.employeeId;
  return {
    title: "Contributions",
    hideSearch: true,
    path: employeeId && `/employees/${employeeId}/disbursements`,
    columns
  };
};
export default R.compose(withProps(extraProps))(ListPage);
