import axios from "axios";
import Qs from "qs";

import log from "../logging/service";
import appConfig from "../../configs/app";
import apiConfig from "./config";
import addAuthorizationInterceptor from "./interceptors/addAuthorization";
import transformParamsInterceptor from "./interceptors/transformParams";
import parseResponseData from "./responseHandlers/parseData";
import createHandleDownload from "./responseHandlers/createHandleDownload";
import getFileUrl from "./responseHandlers/getUrl";

const createAPI = () => {
  const instance = axios.create({
    baseURL: appConfig.baseUrl,
    paramsSerializer: params =>
      Qs.stringify(params, { arrayFormat: "brackets" }),
    ...apiConfig
  });
  instance.interceptors.request.use(addAuthorizationInterceptor);
  instance.interceptors.request.use(transformParamsInterceptor);
  return instance;
};

const API = createAPI();

/** set interceptors */
API.interceptors.response.use(parseResponseData);

function get(url, params, options) {
  return API.get(url, { params, ...options });
}

function post(
  url,
  data,
  options = { headers: { "content-type": "application/json; charset=utf-8" } }
) {
  return API.post(url, { data }, options);
}

function del(url) {
  return API.delete(url);
}

function put(url) {
  return API.put(url);
}

function patch(
  url,
  data,
  options = { headers: { "Content-type": "application/vnd.api+json" } }
) {
  return API.patch(url, { data }, options);
}

function fetchFile(url, { mimeType, saveAs, params = {}, ...options }) {
  const fileApi = createAPI();
  return fileApi
    .get(url, { params, options })
    .then(createHandleDownload({ saveAs, mimeType }));
}

async function fetchFileData(url) {
  const fileApi = createAPI();

  try {
    const response = await fileApi.get(url);
    const fileResponse = await axios(getFileUrl(response), {
      method: "get",
      responseType: "blob"
    });

    return fileResponse.data;
  } catch (e) {
    log.error(e.message);
    throw new Error(e);
  }
}

export default {
  get,
  post,
  del,
  put,
  patch,
  fetchFile,
  fetchFileData
};
