import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { PartnerInfoForm, PartnerBillingForm } from "../forms";
import { StepperForm } from "../../components";
import { SLGModalForm } from "../../components/modals";
import { createPartner as createPartnerAction } from "../partnerActions";

const styles = {
  modalStyle: { width: "700px" }
};

export const PartnerCreateModal = ({ createPartner, onClose }) => {
  const defaultSupportEmail = "genius@vault.co";

  return (
    <SLGModalForm
      title="New Partner"
      formName="partnerCreate"
      submit={createPartner}
      close={onClose}
      contentStyle={styles.modalStyle}
    >
      <StepperForm>
        <PartnerInfoForm
          formName="partnerCreate"
          label="Info"
          backButtonLabel="Cancel"
          submitButtonLabel="Next"
          initialValues={{
            advisor_support_email: defaultSupportEmail,
            pay_support_email: defaultSupportEmail
          }}
        />
        <PartnerBillingForm
          formName="partnerCreate"
          label="Billing"
          backButtonLabel="Back"
          submitButtonLabel="Save"
        />
      </StepperForm>
    </SLGModalForm>
  );
};

PartnerCreateModal.propTypes = {
  createPartner: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default connect(
  null,
  { createPartner: createPartnerAction }
)(PartnerCreateModal);
