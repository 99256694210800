import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SLGModalForm from "components/modals/SLGModalForm";
import TRNeedMoreInfoForm  from "../forms/TRNeedMoreInfoForm";
import { needMoreInfoTR as needMoreInfoTRAction } from "../../../employees/actions";

export const TRNeedMoreInfoModal = ({
  trData,
  needMoreInfoTR,
  onClose,
  onSuccess
}) => {
  
  const tuition_id          = trData.attributes.id;
  const servicer_account_id = trData.servicer_account.id;
  const employerId = trData.employerId;
  const formName = "needMoreInfoTuitionReimbursement";

  return (
    <SLGModalForm
      title="Need More Info Tuition Reimbursement"
      formName={formName}
      submit={(...args) =>
        needMoreInfoTR.apply(null, [...args, servicer_account_id, employerId, onSuccess])
      }
      close={onClose}
    >
      <TRNeedMoreInfoForm
        formName={ formName }
        label="Need More Info Tuition Reimbursement"
        onSuccess={ onSuccess }
        servicer_account_id={ servicer_account_id }
        employerId={ employerId }
        tuition_id={ tuition_id }
      />
    </SLGModalForm>
  );
};

TRNeedMoreInfoModal.propTypes = {
  needMoreInfoTR: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    needMoreInfoTR: needMoreInfoTRAction
  }
)(TRNeedMoreInfoModal);
