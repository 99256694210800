import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

/** legacy code, will remove once new form is done */
import { openModal as openModalAction } from "../../app/uiActions";
import { EmployerCreateModal } from "../modals";
import {
  selectCurrentPartner,
  selectEntityIdentifiers
} from "../../partners/selectors";

import { allowToPartnerManager } from "../../services/permission/service";
import NewButton from "../../components/buttons/action/New";

function PageAction({
  partnerId,
  partner,
  disabled,
  openModal,
  entityIdentifiers
}) {
  function handleClick() {
    openModal(<EmployerCreateModal partner={partner} partnerID={partnerId} />);
  }

  return (
    <>
      {allowToPartnerManager() && (
        <NewButton disabled={disabled} onClick={handleClick}>
          {`New ${entityIdentifiers.employerIdentifier}`}
        </NewButton>
      )}
    </>
  );
}

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    partner: selectCurrentPartner(state),
    entityIdentifiers
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      openModal: openModalAction
    }
  )
)(PageAction);
