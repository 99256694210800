import BaseResponse from "../../shared/BaseResponse";

export default class GetInvoicesResponse extends BaseResponse {
  getListData() {
    /** combine PDR and FEES invoices based on names */
    let invoices = super.getListData();
    invoices = invoices ? invoices.all : [];

    const combinedInvoices = {};

    invoices.map(invoice => {
      const invoice_name = invoice.invoice_name.replace(/(PDR|FEES)$/, "");
      const current = {
        invoice_name,
        type: "pdr_fee"
      };

      if (invoice.invoice_type === "pdr") {
        current.id = invoice.id;
        current.pdr_id = invoice.id;
        current.total_pdrs_cents = invoice.total_pdrs_cents;
        current.status = invoice.status;
      } else if (invoice.invoice_type === "fee") {
        current.id = invoice.id;
        current.fee_id = invoice.id;
        current.status = invoice.status;
        current.total_fees_cents = invoice.total_fees_cents;
      } else {
        current.id = invoice.id;
        current.combined_id = invoice.id;
        current.total_pdrs_cents = invoice.total_pdrs_cents;
        current.total_fees_cents = invoice.total_fees_cents;
        current.status = invoice.status;
        current.type = invoice.invoice_type;
      }

      combinedInvoices[invoice_name] = {
        ...combinedInvoices[invoice_name],
        ...current
      };
      return true;
    });

    return {
      byId: combinedInvoices,
      all: Object.values(combinedInvoices),
      total: 0
    };
  }
}
