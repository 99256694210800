import { put } from "redux-saga/effects";

import { showNotification } from "../../app/uiActions";

export function* showSuccess(message) {
  yield put(showNotification(message));
}

export function* showError(message) {
  yield put(showNotification(message, "warning"));
}
