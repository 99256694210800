import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import {
  SelectField as SelectInput,
  TextField 
} from "redux-form-material-ui";
import MenuItem from "material-ui/MenuItem";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

import { required, includeNoSpecialChar } from "helpers";
import { needMoreInfoTR  } from "../../../employees/actions";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  width70: { width: "70%" },
  formButtonsBar: { marginTop: "20px", float: "right" },
  inline70: { display: "inline-block", width: "70%" },
  spaceRight: { marginRight: "5%" },

};
const options = [
  'Need additional receipts',
  'Need proof of completion',
  'Other'
];

export class TRNeedMoreInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDiv:false
    }
  }
  static propTypes = {
    formName: PropTypes.string.isRequired
  };
  handleSelect = (event, index, value) => this.setState({ value });

  menuItems(options) {
    return options.map(option => (
      <MenuItem key={option} value={option} primaryText={option} />
    ));
  }
  handleChange = (value)=>{
    if(value === 'Other') {
      this.setState({
        showDiv : true
      })
    } else{
      this.setState({
        showDiv : false
      })
    }
  }
  _submit = values => {
    const {
      needMoreInfoTR,
      formName,
      servicer_account_id,
      employerId,
      onSuccess,
      tuition_id,
    } = this.props;

    let data = {
      "servicer_account_id" : servicer_account_id,
      "status": 3,
      "tuition_id": tuition_id,
    }
    if(this.state.showDiv && values.tuition_rejection_note==='Other' && values.reason!==""){
      data.tuition_rejection_note = values.reason; 
    } else{
       data.tuition_rejection_note = values.tuition_rejection_note; 
    }
    return needMoreInfoTR(data, formName, servicer_account_id,employerId, onSuccess);
  }
  render() {
    const { handleSubmit,handlePrev } = this.props;
    return (
      <div>
        <form onSubmit={handleSubmit(this._submit)}>
          <div>
            <Field
              name="tuition_rejection_note"
              component={SelectInput}
              floatingLabelText="Reason Type *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={Object.assign({}, styles.inline70, styles.spaceRight)}
              validate={required}
              onChange={(event, index, value) => { this.handleChange(index) }}
            >
              {this.menuItems(options)}
            </Field>
          </div>
          { this.state.showDiv ? 
            
              <div>
                <Field
                  name="reason"
                  component={TextField}
                  floatingLabelText="Reason *"
                  floatingLabelShrinkStyle={styles.inputLabel}
                  underlineStyle={styles.inputUnderline}
                  style={styles.width70}
                  validate={includeNoSpecialChar}
                />
              </div>
            : null
          }
          <div style={styles.formButtonsBar}>
            <FlatButton
              label="Cancel"
              type="button"
              style={{ marginRight: "12px" }}
              onClick={handlePrev}
            />
            <RaisedButton
              label="Save"
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}


TRNeedMoreInfoForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(TRNeedMoreInfoForm);

const mapStateToProps = (state, ownProps) => {
  return {
    form: ownProps.formName,
  };
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { needMoreInfoTR};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(TRNeedMoreInfoForm);