import "react-app-polyfill/ie11"; // For IE 11 support
import "core-js/es6/map";
import "core-js/es6/set";
import "core-js/fn/array/find";
import "core-js/fn/array/includes";
import "core-js/fn/string/starts-with";
import "core-js/fn/object";

import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./index.css";

ReactDOM.render(<App />, document.getElementById("root"));
