import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import pick from "lodash/pick";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";

import SLGModalForm from "../../components/modals/SLGModalForm";

import AdminUserForm from "../forms/AdminUserForm";
import { editAdminUser as editAdminUserAction } from "../adminUserActions";
import { selectEmployersListByPartner } from "../../employers/selectors";

export const AdminUserEditModal = ({
  adminUser,
  editAdminUser,
  onClose,
  onSuccess,
  context,
  employers
}) => {
  const initialFields = pick(adminUser, [
    "id",
    "first_name",
    "last_name",
    "active",
    "email",
    "type",
    "role"
  ]);
  const whitelist = get(adminUser, "filters.[0].whitelist");
  initialFields["whitelist"] = whitelist;

  const selectedEmployers = map(adminUser.filters, emp => {
    const employerName = get(
      find(employers, val => val.id === emp.employer_id),
      "name"
    );
    return { ...emp, name: employerName };
  });

  return (
    <SLGModalForm
      title="Edit Admin User"
      formName="employerEditGeneral"
      submit={(...args) =>
        editAdminUser.apply(null, [
          ...args,
          adminUser.id,
          null,
          null,
          null,
          null,
          onSuccess
        ])
      }
      close={onClose}
      contentStyle={{ width: "40%" }}
    >
      <AdminUserForm
        formName="adminUserEdit"
        label="Edit Admin User"
        backButtonLabel="Cancel"
        submitButtonLabel="Save"
        enableReinitialize={true}
        emailDisabled={true}
        initialValues={initialFields}
        context={context}
        employers={map(employers, emp => {
          return { employer_id: emp.id, name: emp.name };
        })}
        adminUserID={adminUser.id}
        selectedEmployers={selectedEmployers}
      />
    </SLGModalForm>
  );
};

AdminUserEditModal.propTypes = {
  adminUser: PropTypes.object.isRequired,
  editAdminUser: PropTypes.func.isRequired,
  partnerID: PropTypes.string,
  employers: PropTypes.array,
  onClose: PropTypes.func
};

AdminUserEditModal.defaultProps = {
  employers: []
};

function mapStateToProps(state, props) {
  const employers = props.partnerID
    ? selectEmployersListByPartner(state, props.partnerID)
    : [];
  return {
    employers
  };
}
export default connect(
  mapStateToProps,
  {
    editAdminUser: editAdminUserAction
  }
)(AdminUserEditModal);
