import { put, all, call, takeEvery } from "redux-saga/effects";
import { destroy } from "redux-form";
import * as R from "ramda";

import log from "../../services/logging/service";
import routeService from "../services/RouteService";
import fetchApiService from "../../rest/fetchApiService";
import { closeAllModals } from "../../app/uiActions";
import { showError, showSuccess } from "../../shared/sagas/notifications";
import {
  GET_CONTACTS_REQUEST,
  getContactsRequest,
  getContactsSuccess,
  getContactsFailure,
  CREATE_REQUEST,
  createSuccess,
  createFailure,
  UPDATE_REQUEST,
  updateSuccess,
  updateFailure,
  DELETE_REQUEST,
  deleteSuccess,
  deleteFailure
} from "../actions/contactActions";
import GetContactsRequest from "../services/GetContactsRequest";
import GetContactsResponse from "../services/GetContactsResponse";
import CreateContactRequest from "../services/CreateContactRequest";
import CreateContactResponse from "../services/CreateContactResponse";
import UpdateContactRequest from "../services/UpdateContactRequest";
import UpdateContactResponse from "../services/UpdateContactResponse";
import DeleteContactRequest from "../services/DeleteContactRequest";
import DeleteContactResponse from "../services/DeleteContactResponse";

const isNilOrEmpty = R.or(R.isNil, R.isEmpty);
const rejectNilOrEmpty = R.reject(isNilOrEmpty);

function* getContacts({ employerId }) {
  try {
    const endpoint = routeService.getContacts(employerId);
    const request = new GetContactsRequest({ url: endpoint });
    const response = yield fetchApiService.fetch(request, GetContactsResponse);
    if (response.ok) {
      const data = response.getListData(employerId);

      yield put(getContactsSuccess(data));
    } else {
      yield call(showError, response.statusText);
      yield put(getContactsFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getContactsFailure(e.message));
    log.error(e.message);
  }
}

function* createContact({
  formName,
  contactType,
  adminUserId,
  employerId,
  first_name,
  last_name,
  email,
  job_title,
  phone,
  onSuccess
}) {
  try {
    const endpoint = routeService.createContact();
    const request = new CreateContactRequest({
      url: endpoint,
      contactType,
      adminUserId,
      employerId,
      first_name,
      last_name,
      email,
      job_title,
      phone
    });
    const response = yield fetchApiService.fetch(
      request,
      CreateContactResponse
    );
    if (response.ok) {
      yield put(createSuccess());

      yield put(getContactsRequest(employerId));
      yield put(closeAllModals());
      yield put(destroy(formName));
      yield call(showSuccess, "Contact created successfully.");

      if (onSuccess) {
        onSuccess();
      }
    } else {
      yield call(showError, response.statusText);
      yield put(createFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(createFailure(e.message));
    log.error(e.message);
  }
}

function* updateContact({
  formName,
  employerId,
  contactId,
  first_name,
  last_name,
  email,
  job_title,
  phone,
  onSuccess
}) {
  try {
    const endpoint = routeService.updateContact(contactId);
    const request = new UpdateContactRequest(
      rejectNilOrEmpty({
        url: endpoint,
        contactId,
        first_name,
        last_name,
        email,
        job_title,
        phone
      })
    );
    const response = yield fetchApiService.fetch(
      request,
      UpdateContactResponse
    );
    if (response.ok) {
      yield put(
        updateSuccess(
          employerId,
          contactId,
          first_name,
          last_name,
          job_title,
          phone
        )
      );

      yield put(getContactsRequest(employerId));
      yield put(closeAllModals());
      yield put(destroy(formName));
      yield call(showSuccess, "Contact updated successfully.");

      if (onSuccess) {
        onSuccess();
      }
    } else {
      yield call(showError, response.statusText);
      yield put(updateFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(updateFailure(e.message));
    log.error(e.message);
  }
}

function* deleteContact({ contactType, employerId, contactId, onSuccess }) {
  try {
    const endpoint = routeService.deleteContact(contactId);
    const request = new DeleteContactRequest({
      url: endpoint,
      contactType,
      employerId,
      contactId
    });
    const response = yield fetchApiService.fetch(
      request,
      DeleteContactResponse
    );
    if (response.ok) {
      yield put(deleteSuccess(employerId, contactId));

      yield put(getContactsRequest(employerId));
      yield put(closeAllModals());
      yield call(showSuccess, "Contact deleted successfully.");

      if (onSuccess) {
        onSuccess();
      }
    } else {
      yield call(showError, response.statusText);
      yield put(deleteFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(deleteFailure(e.message));
    log.error(e.message);
  }
}

export default function* getReportsSagas() {
  yield all([
    takeEvery(GET_CONTACTS_REQUEST, getContacts),
    takeEvery(CREATE_REQUEST, createContact),
    takeEvery(UPDATE_REQUEST, updateContact),
    takeEvery(DELETE_REQUEST, deleteContact)
  ]);
}
