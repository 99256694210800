import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: props => ({ fontSize: props.fontSize })
});

export default fontSize => WC => props => {
  const classes = useStyles({ fontSize });
  return <WC className={classes.root} {...props} />;
};
