import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pick } from "lodash";

import SLGModalForm from "components/modals/SLGModalForm";
import NoteForm from "../forms/NoteForm";
import { editEmployeeNote as editEmployeeNoteAction } from "employees/actions";

export const NoteEditModal = ({
  selectedNote,
  editEmployeeNote,
  onClose,
  onSuccess
}) => {
  const formName = "employeeNoteEdit";

  const initialFields = pick(selectedNote, ["id", "note"]);
  const noEditValues = pick(selectedNote, [
    "created_at",
    "updated_at",
    "author"
  ]);

  return (
    <SLGModalForm
      title="Edit Note"
      formName={formName}
      submit={(...args) =>
        editEmployeeNote.apply(null, [...args, selectedNote.id, onSuccess])
      }
      close={onClose}
    >
      <NoteForm
        formName={formName}
        label="Note"
        initialValues={initialFields}
        noEditValues={noEditValues}
      />
    </SLGModalForm>
  );
};

NoteEditModal.propTypes = {
  selectedNote: PropTypes.object.isRequired,
  editEmployeeNote: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    editEmployeeNote: editEmployeeNoteAction
  }
)(NoteEditModal);
