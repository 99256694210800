import * as R from "ramda";

export const log = msg => R.tap(x => console.log(msg, x));
const createFilterObj = filter => {
  let newFilter = {};
  Object.keys(filter).forEach(i => {
    Object.keys(filter[`${i}`]).forEach(j => {
      const field = `filter[${i}][${j}]`;
      newFilter[field] = filter[i][j];
    });
  });
  return newFilter;
};

const stringifyFilter = R.ifElse(
  R.has("filter"),
  R.pipe(
    R.prop("filter"),
    createFilterObj
  ),
  R.identity
);

export default R.evolve({
  params: R.converge(R.merge, [R.dissoc("filter"), stringifyFilter])
});
