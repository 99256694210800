import React, { Component } from "react";
import { propTypes, reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import compose from "recompose/compose";
import PropTypes from "prop-types";

import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import TextField from "material-ui/TextField";
import Dialog from "material-ui/Dialog";
import CircularProgress from "material-ui/CircularProgress";

import { enableTwoFactor as enableTwoFactorAction } from "./twoFactorActions";

const styles = {
  flat: {
    overflow: "inherit"
  },
  flex: {
    display: "flex"
  },
  modal: {
    width: "50%",
    minWidth: "350px",
    maxWidth: "none"
  },
  modalBody: {
    overflowY: "scroll",
    justifyContent: "center",
    display: "flex"
  },
  actionsContainer: {
    boxSizing: "border-box",
    padding: 10,
    paddingTop: 20,
    width: "100%",
    textAlign: "right",
    marginTop: -1
  },
  title: {
    fontSize: "1.5em"
  },
  helpText: {
    marginTop: "12px"
  }
};

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = {},
  input: { ...inputProps },
  ...props
}) => {
  return (
    <TextField
      inputStyle={styles.inputText}
      errorText={touched && error}
      floatingLabelStyle={styles.floatingLabel}
      {...inputProps}
      {...props}
    />
  );
};

export class Enable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  enable = values => {
    const { enableTwoFactor } = this.props;
    enableTwoFactor(values.countryCode, values.cellphone);
  };

  render() {
    const { isLoading, handleSubmit } = this.props;

    return (
      <div>
        <Dialog
          open={this.state.open}
          bodyStyle={styles.modalBody}
          contentStyle={styles.modal}
          overlayClassName="overlay"
          contentClassName="content"
          bodyClassName="body"
        >
          <form onSubmit={handleSubmit(this.enable)}>
            <div style={styles.title}>Enable 2FA</div>
            <div style={styles.flex}>
              <Field
                name="countryCode"
                component={renderInput}
                floatingLabelText="Country Code"
                disabled={isLoading}
                style={{ flex: 1, marginRight: 10 }}
              />
              <Field
                name="cellphone"
                component={renderInput}
                floatingLabelText="Cellphone"
                disabled={isLoading}
                hintText="(xxx)xxx-xxxx"
                style={{ flex: 4 }}
              />
            </div>
            <div style={styles.helpText}>
              Input your mobile number, we'll text you.
            </div>
            <div style={styles.actionsContainer}>
              <FlatButton
                primary
                label="cancel"
                onClick={this.props.handleClose}
              />
              <RaisedButton
                type="submit"
                primary
                disabled={isLoading}
                icon={isLoading && <CircularProgress size={25} thickness={2} />}
                label="Enable"
              />
            </div>
          </form>
        </Dialog>
      </div>
    );
  }
}
Enable.propTypes = {
  ...propTypes,
  enableTwoFactor: PropTypes.func.isRequired
};
const mapStateToProps = state => ({ isLoading: state.ui.loading > 0 });
const enhance = compose(
  reduxForm({
    form: "enableTwoFactor",
    validate: (values, props) => {
      const errors = {};
      if (!values.countryCode) errors.countryCode = "required";
      if (!values.cellphone) errors.cellphone = "required";
      return errors;
    },
    initialValues: {
      countryCode: "1"
    }
  }),
  connect(mapStateToProps, {
    enableTwoFactor: enableTwoFactorAction
  })
);
export default enhance(Enable);
