import React from "react";
import PropTypes from "prop-types";

import getFileTypeFromName from "../../services/utils/getFileTypeFromName";
import Link from "../navigation/links/Link";
import useDownloadFile from "../containers/utils/useDownloadFile";

function FileNameColumn({ row, createDownloadPathFromRow }) {
  const reportFile = useDownloadFile();

  function handleClick() {
    const path = createDownloadPathFromRow(row);
    const fileType = getFileTypeFromName(row.file_name);
    reportFile.download({ path, saveAs: row.file_name, fileType });
  }

  return <Link onClick={handleClick}>{row.file_name}</Link>;
}

FileNameColumn.propTypes = {
  row: PropTypes.object.isRequired,
  createDownloadPathFromRow: PropTypes.func.isRequired
};

export default FileNameColumn;
