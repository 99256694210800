import * as R from "ramda";

export const log = msg => R.tap(x => console.log(msg, x));
const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
const getDirectionStr = R.ifElse(
  R.pipe(
    R.prop("order"),
    R.toUpper,
    R.equals("ASC")
  ),
  R.always(""),
  R.always("-")
);

export default R.evolve({
  params: {
    sort: R.ifElse(
      isNilOrEmpty,
      R.identity,
      R.converge(R.concat, [getDirectionStr, R.prop("field")])
    )
  }
});
