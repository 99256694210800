import React from "react";
import CardContent from "@material-ui/core/CardContent";

import { ContentProps } from "./types";

function Content({ contentSlot }) {
  return <CardContent>{contentSlot({})}</CardContent>;
}

Content.propTypes = ContentProps;

export default Content;
