import PropTypes from "prop-types";
import * as R from "ramda";

import { CardProps } from "../cards/basic-card/types";

export const LabelProp = { label: PropTypes.string };
export const NameProp = { name: PropTypes.string };
export const OrderProp = { order: PropTypes.oneOf(["asc", "desc"]) };
export const ColSpanProp = { colSpan: PropTypes.number };
export const ColumnsProp = { columns: PropTypes.array.isRequired };
export const RowsProp = { rows: PropTypes.array.isRequired };
export const TotalProp = { total: PropTypes.number };
export const LoadingProp = { loading: PropTypes.bool };
export const DisabledProp = { disabled: PropTypes.bool };
export const OnSubmitProp = { onSubmit: PropTypes.func };
export const OnChangeProp = { onChange: PropTypes.func };
export const OnSortChangeProp = { onSortChange: PropTypes.func };
export const HeaderActionHandlersProp = {
  headerActionHandler: PropTypes.shape({
    onSortChange: PropTypes.func
  })
};
export const SearchFormProp = {
  searchFormProps: PropTypes.shape({
    ...LabelProp,
    ...NameProp,
    ...LoadingProp,
    ...DisabledProp,
    ...OnSubmitProp,
    ...OnChangeProp
  })
};

export const TableHeadProps = {
  ...ColumnsProp,
  ...DisabledProp,
  ...OrderProp,
  ...HeaderActionHandlersProp,
  defaultSort: PropTypes.shape({
    field: PropTypes.string
  }).isRequired,
  showSelection: PropTypes.bool,
  selected: PropTypes.bool,
  onSelectChange: PropTypes.func
};
export const withTableHeadProps = R.merge(TableHeadProps);

export const TableLoadingBodyProps = {
  height: PropTypes.number,
  size: PropTypes.number,
  ...ColSpanProp
};
export const withTableLoadingBodyProps = R.merge(TableLoadingBodyProps);

export const TableBodyProps = {
  ...ColumnsProp,
  ...RowsProp
};
export const withTableBodyProps = R.merge(TableBodyProps);

export const PaginationProps = {
  ...TotalProp,
  defaultRowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number)
};
export const withPaginationProps = R.merge(PaginationProps);

export const TableProps = R.compose(
  {},
  withTableHeadProps,
  withTableBodyProps,
  withPaginationProps
);
export const withTableProps = R.merge(TableProps);

export const TableHocProps = {
  Table: PropTypes.node,
  TableHead: PropTypes.node,
  TableLoadingBody: PropTypes.node,
  TableBody: PropTypes.node,
  TablePagination: PropTypes.node
};

export const ListTableProps = {
  ...CardProps,
  ...TableProps
};
