import React from "react";

import Link from "../../../components/links/Link";

function NameCell({ value, employerId }) {
  const to = {
    pathname: `/employers/${employerId}/invoices/${value.invoice_name}`,
    state: { invoice: value, employerId }
  };
  return <Link to={to}>{value.invoice_name}</Link>;
}

export default NameCell;
