import React from "react";
import * as R from "ramda";
import BaseTableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";

import { TableBodyProps } from "../types";
import OverflowTip from "../../tooltips/OverflowTip";

const dotPath = R.useWith(R.path, [R.split(".")]);
const withOverflow = (ellipsis, maxWidth, value, row, renderer) => {
  return ellipsis ? (
    <OverflowTip value={value} maxWidth={maxWidth}>
      {renderer ? renderer(row) : value}
    </OverflowTip>
  ) : renderer ? (
    renderer(row)
  ) : (
    value
  );
};
const handleWithRender = ({ column, value, row }) =>
  withOverflow(column.ellipsis, column.maxWidth, value, row, column.render);
const handleWithFormat = ({ column, value, row }) =>
  withOverflow(column.ellipsis, column.maxWidth, value, row, column.format);
const handleWithValue = ({ column, value, row }) =>
  withOverflow(column.ellipsis, column.maxWidth, value, row);
const renderCellWith = R.cond([
  [R.hasPath(["column", "render"]), handleWithRender],
  [R.hasPath(["column", "format"]), handleWithFormat],
  [R.T, handleWithValue]
]);
const createRenderRowCell = ({ row, index }) => (column, i) => {
  const value = dotPath(column.name, row);
  return (
    <TableCell key={`${index}${i}`}>
      {renderCellWith({ column, value, row })}
    </TableCell>
  );
};

const createRenderTableBodyRow = ({
  disabled,
  columns,
  showSelection,
  selectionDisabled,
  selections,
  getRowId,
  onClick
}) => (row, i) => {
  const clickDisabled = selectionDisabled || disabled;
  const id = showSelection && getRowId(row);
  const selected = showSelection && R.contains(id, selections);

  function handleClick(e) {
    if (onClick) onClick(e, id);
  }

  const filteredColumns = R.filter(c => c.hideColumn !== true, columns);

  return (
    <TableRow
      hover
      key={i}
      onClick={showSelection && !clickDisabled ? handleClick : undefined}
      selected={showSelection && selected}
    >
      {showSelection && (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            disabled={clickDisabled}
            checked={selected}
          />
        </TableCell>
      )}
      {filteredColumns.map(createRenderRowCell({ row, index: i }))}
    </TableRow>
  );
};

function TableBody({
  disabled,
  columns,
  rows,
  showSelection,
  selectionDisabled,
  selections,
  getRowId,
  onRowClick
}) {
  return (
    <BaseTableBody>
      {rows &&
        rows.map(
          createRenderTableBodyRow({
            disabled,
            columns,
            showSelection,
            selectionDisabled,
            getRowId,
            selections,
            onClick: onRowClick
          })
        )}
    </BaseTableBody>
  );
}

TableBody.propTypes = TableBodyProps;

export default TableBody;
