import isFinite from "lodash/isFinite";
export const required = value =>
  value || value === 0 ? undefined : "Required";

export const boolRequired = value =>
  typeof value === "boolean" ? undefined : "Required";

export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength10 = maxLength(10);
export const maxLength15 = maxLength(15);
export const maxLength20 = maxLength(20);
export const maxLength25 = maxLength(25);
export const maxLength50 = maxLength(50);
export const maxLength128 = maxLength(128);
export const maxLength280 = maxLength(280);

export const minLength = min => value =>
  value && value.length < min
    ? `Must be ${min} characters or greater`
    : undefined;

export const minLength8 = minLength(8);

export const noSpaces = value =>
  value && /\s/.test(value) ? "Spaces not allowed" : undefined;

// Lifted from Redux docs. There might be a better universal email regex test.
// https://redux-form.com/7.2.3/examples/fieldlevelvalidation/
export const email = value =>
  value && !/^[A-Z0-9._%+-/']+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(value)
    ? "Invalid email address"
    : undefined;

export const positive = value =>
  isFinite(value) && value <= 0 ? "Must be greater than zero" : undefined;

export const isNumeric = value =>
  value && (Number.isNaN(parseFloat(value)) || !isFinite(parseFloat(value)))
    ? "Must be a number"
    : undefined;

// password requirements
export const hasUpper = value =>
  value && /[A-Z]/.test(value)
    ? undefined
    : "At least one uppercase letter required";

export const hasLower = value =>
  value && /[a-z]/.test(value)
    ? undefined
    : "At least one lowercase letter required";

export const hasSpecialChar = value =>
  value && /[[-_\\.\][?/<~#`!@$%^&*()+=}|:";',>{]/.test(value)
    ? undefined
    : "At least one special character required";

export const includeNoSpecialChar = value =>
  value && /[[\\\][<~%^*+=}|;>{]/.test(value)
    ? "No special characters allowed"
    : undefined;

export const hasNumber = value =>
  value && /[0-9]/.test(value) ? undefined : "At least one number required";

export const greaterThan25 = value =>
  value && value >=25 ? undefined : "Contribution amount greater than or equal $25";