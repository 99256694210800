import makeActionCreator from "../../helpers/makeActionCreator";

const nameSpace = "groups/ultipro";

export const SEND_INVITES_REQUEST = `${nameSpace}/SEND_INVITES_REQUEST`;
export const SEND_INVITES_SUCCESS = `${nameSpace}/SEND_INVITES_SUCCESS`;
export const SEND_INVITES_FAILURE = `${nameSpace}/SEND_INVITES_FAILURE`;

export const sendInvitesRequest = makeActionCreator(
  SEND_INVITES_REQUEST,
  "employerId",
  "groupId"
);
export const sendInvitesSuccess = makeActionCreator(SEND_INVITES_SUCCESS);
export const sendInvitesFailure = makeActionCreator(
  SEND_INVITES_FAILURE,
  "error"
);
