import React from "react";
import * as R from "ramda";
import {
  toYesNoStringFromProp,
  toDisplayDateStringFromProp
} from "services/formatters";
import withPartnerIdFromRouter from "components/containers/utils/withPartnerIdFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import PageAction from "./PageAction";
import ActionsCell from "./ActionsCell";
import RoleCell from "./RoleCell";

const isEmptyOrNil = R.either(R.isEmpty, R.isNil);
const renderName = v =>
  `${R.pathOr("", ["first_name"], v)} ${R.pathOr("", ["last_name"], v)}`.trim();
const formatRegistered = R.pipe(
  R.prop("invitation_accepted_at"),
  R.ifElse(
    isEmptyOrNil,
    R.always("No"),
    toDisplayDateStringFromProp("invitation_accepted_at")
  )
);
export const columns = props => [
  {
    name: "first_name",
    label: "Name",
    render: renderName
  },
  {
    name: "type_id",
    label: "Role",
    disableSort: true,
    format: r => <RoleCell {...r} />
  },
  {
    name: "email",
    label: "Email"
  },
  {
    name: "active",
    label: "Active",
    disableSort: true,
    format: toYesNoStringFromProp("active")
  },
  {
    name: "invitation_accepted_at",
    label: "Registered",
    disableSort: true,
    format: formatRegistered
  },
  {
    name: "id",
    label: "Actions",
    disableSort: true,
    render: r => <ActionsCell {...props} {...r} />
  }
];

const createGetSearchFilterWith = partnerId =>
  R.applySpec({
    "0": { admin_users_partner_partner_id_eq: R.always(partnerId) },
    "1": {
      name_cont: R.identity,
      email_cont: R.identity,
      m: R.always("or")
    }
  });
const renderAction = props => ({ disabled }) => (
  <PageAction {...props} disabled={disabled} />
);

export function extraProps(props) {
  const partnerID = props.partnerId;
  return {
    partnerID,
    title: "ADMIN USERS",
    path: "/admin_users",
    getSearchFilter: createGetSearchFilterWith(props.partnerId)
  };
}

function actionsProps(props) {
  return {
    columns: columns(props),
    actionSlot: renderAction(props)
  };
}

export default R.compose(
  withPartnerIdFromRouter,
  withProps(extraProps),
  withSort,
  withSearch,
  withPagination,
  withGetFetch,
  withProps(actionsProps)
)(PageComponent);
