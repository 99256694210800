import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const GridContainer = props => {
  const { children, ...rest } = props;
  return (
    <Grid container {...rest}>
      {children}
    </Grid>
  );
};

GridContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

GridContainer.defaultProps = {
  children: null
};

const StyledGridContainer = styled(GridContainer)``;

export default StyledGridContainer;
