import React from "react";

import { ConfirmFieldProps } from "./ConfirmField";
import ConfirmField from "./ConfirmField";

function ConfirmPasswordField({
  name = "passwordConfirmation",
  label = "Confirm Password",
  required = true,
  matchFieldName = "password",
  errorMessage = "Passwords must match",
  ...props
}) {
  return (
    <ConfirmField
      name={name}
      matchFieldName={matchFieldName}
      errorMessage={errorMessage}
      required={required}
      label={label}
      type="password"
      {...props}
    />
  );
}

ConfirmPasswordField.propTypes = {
  ...ConfirmFieldProps
};

export default ConfirmPasswordField;
