import { createSelector } from "reselect";
import { map, some, get } from "lodash";
import { BENEFITS_ADMIN, SYSTEM_ADMIN } from "../constants";

export const selectAdminUsers = state => state.adminUsers.byID;

const selectAdminUsersIDList = state => state.adminUsers.items;

// Benefits Admin
export const makeSelectAdminUsersByEmployer = () => {
  return createSelector(
    [selectAdminUsers, selectAdminUsersIDList, (_, employerID) => employerID],
    (adminUsers, adminUsersIDList, employerID) => {
      return map(adminUsersIDList, val => adminUsers[val]).filter(val =>
        some(val.filters, filter => employerID === filter.employer_id)
      );
    }
  );
};

// Not Benefits / System Admin -- These can probably be composed selectors with two basic ones
// Select by partner and select by non benefits admin
export const makeSelectAdminUsersByPartner = () => {
  return createSelector(
    [selectAdminUsers, selectAdminUsersIDList, (_, partnerID) => partnerID],
    (adminUsers, adminUsersIDList, partnerID) => {
      return map(adminUsersIDList, val => adminUsers[val]).filter(
        val =>
          get(val, "partner.id") === partnerID && val.type !== BENEFITS_ADMIN
      );
    }
  );
};

export const selectSystemAdmins = createSelector(
  [selectAdminUsers, selectAdminUsersIDList],
  (adminUsers, adminUsersIDList) => {
    return map(adminUsersIDList, val => adminUsers[val]).filter(
      val => val.type === SYSTEM_ADMIN
    );
  }
);

export const selectAdminUserByID = createSelector(
  [selectAdminUsers, (_, employeeID) => employeeID],
  (adminUsers, employeeID) => {
    return adminUsers[employeeID];
  }
);
