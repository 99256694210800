import * as React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const styles = {
  container: {
    minWidth: 400
  },
  warningIcon: {
    color: "red"
  },
  contentContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  footerContainer: {
    margin: 20
  },
  deleteButton: {
    color: "red"
  }
};

class ConfirmationDialog extends React.PureComponent {
  state = {
    open: true
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = () => {
    const {
      submitButtonProps: { onClick, openOnSubmit }
    } = this.props;

    if (!openOnSubmit) this.handleClose();
    if (onClick) onClick();
  };

  render() {
    const {
      classes,
      disabled,
      isWarning,
      submitButtonProps,
      title,
      subTitle,
      content,
      maxWidth = "sm"
    } = this.props;

    const disableButtons = disabled;

    return (
      <Dialog
        className={classes.container}
        fullWidth
        maxWidth={maxWidth}
        open={this.state.open}
        onClose={this.handleClose}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <DialogTitle id="dialog-title" disableTypography>
              <Grid container spacing={2}>
                {isWarning && (
                  <Grid item>
                    <WarningIcon className={classes.warningIcon} />
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="h5">{title}</Typography>
                  <Typography variant="caption">{subTitle}</Typography>
                </Grid>
              </Grid>
            </DialogTitle>
          </Grid>
          <Grid item>
            <DialogActions>
              <IconButton onClick={this.handleClose}>
                <CloseIcon />
              </IconButton>
            </DialogActions>
          </Grid>
        </Grid>
        <Divider />
        <DialogContent classes={{ root: classes.contentContainer }}>
          {typeof content === "string" ? (
            <DialogContentText>{content}</DialogContentText>
          ) : (
            content
          )}
        </DialogContent>
        <Divider />
        <DialogActions className={classes.footerContainer}>
          <Button onClick={this.handleClose} color="inherit">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={this.handleSubmit}
            color="primary"
            style={{
              color: submitButtonProps.color === "warning" ? "red" : null
            }}
            disabled={disableButtons}
          >
            <Typography style={{ color: "white" }}>
              {submitButtonProps.label}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ConfirmationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  content: PropTypes.any,
  disabled: PropTypes.bool,
  isWarning: PropTypes.bool,
  cancelButtonProps: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func
  }),
  submitButtonProps: PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    openOnSubmit: PropTypes.bool
  }),
  maxWidth: PropTypes.string
};

ConfirmationDialog.defaultProps = {
  cancelButtonProps: {
    label: "Cancel",
    color: "default"
  },
  submitButtonProps: {
    label: "Submit",
    color: "primary",
    openOnSubmit: false
  }
};

export default withStyles(styles)(ConfirmationDialog);
