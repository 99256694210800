import { compose } from "ramda";

import Button from "../button/Button";
import asText from "../button/asText";
import withPrimaryColor from "../withPrimaryColor";
import withSizeSmall from "../withSizeSmall";

export default compose(
  asText,
  withPrimaryColor,
  withSizeSmall
)(Button);
