import { call, put, all, takeLatest } from "redux-saga/effects";
import { showNotification } from "../app/uiActions";

import log from "../services/logging/service";
import {
  DOWNLOAD_INVOICE,
  DOWNLOAD_INVOICE_SUCCESS,
  DOWNLOAD_INVOICE_FAILURE
} from "./invoiceActions";
import { TOKEN } from "../constants";

// API Call to fetch the invoice download url for invoice
function fetchInvoiceDownloadUrl(invoiceID, invoiceType) {
  var requestHeaders = new Headers({
    Accept: "application/vnd.slg+json",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem(TOKEN)
  });

  const downloadSuffix = invoiceType === "xls" ? "download_xls" : "download";

  return fetch(
    `${process.env.REACT_APP_API_HOST}/invoices/${invoiceID}/${downloadSuffix}`,
    {
      method: "GET",
      headers: requestHeaders
    }
  )
    .then(response => response.text())
    .then(text => JSON.parse(text)["data"]["url"])
    .catch(e => log.error(e.message));
}

function downloadInvoiceFrom(url) {
  return (window.location = url);
}

function* downloadInvoice(action) {
  try {
    const url = yield call(
      fetchInvoiceDownloadUrl,
      action.payload.invoiceID,
      action.payload.invoiceType
    );

    if (url.includes("missing")) {
      throw new Error("The invoice download url is invalid.");
    }

    downloadInvoiceFrom(url);
    yield put({ type: DOWNLOAD_INVOICE_SUCCESS });
  } catch (e) {
    yield put({ type: DOWNLOAD_INVOICE_FAILURE, message: e.message });
    const errorText =
      e.message || "An error occurred while downloading the invoice";
    yield put(showNotification(errorText, "warning"));
    log.error(e.message);
  }
}

export default function* invoiceSaga() {
  yield all([takeLatest(DOWNLOAD_INVOICE, downloadInvoice)]);
}
