import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import PageLayout from "./PageLayout";
import { LoginSubTitle } from "../components";
import Link from "../components/links/Link";
import Typography from "../components/typography/Typography";
import asCaption from "../components/typography/asCaption";
import withWhiteText from "../components/typography/withWhiteText";
import SingleColumnForm from "../components/forms/single-column/SingleColumnForm";
import EmailField from "../components/inputs/EmailField";
import PasswordField from "../components/inputs/PasswordField";
import { userLogin as userLoginAction } from "../auth";

function Header() {
  return <LoginSubTitle>Admin Portal</LoginSubTitle>;
}

const Caption = asCaption(withWhiteText(Typography));
function Footer() {
  return (
    <Link to="/passwords">
      <Caption>Forgot your password?</Caption>
    </Link>
  );
}

export class Login extends Component {
  login = auth => {
    const { userLogin, location } = this.props;
    return userLogin(auth, location.state ? location.state.nextPathname : "/");
  };

  render() {
    const { isLoading, theme } = this.props;
    return (
      <PageLayout
        theme={theme}
        headerSlot={<Header />}
        contentSlot={
          <SingleColumnForm
            submitButtonLabel="Sign In"
            onSubmit={this.login}
            loading={isLoading}
          >
            <EmailField />
            <PasswordField shouldValidate={false} />
          </SingleColumnForm>
        }
        footerSlot={<Footer />}
      />
    );
  }
}

Login.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
  userLogin: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.ui.loading > 0 });

const enhance = compose(
  connect(
    mapStateToProps,
    { userLogin: userLoginAction }
  )
);

export default enhance(Login);
