import React from "react";
import Link from "components/links/Link";

const NameCell = ({ employer_id, id, first_name, last_name, attr }) => {
  const to = `/employers/${employer_id}/employees/${id}`;
  const display = attr === "first_name" ? first_name : last_name;

  return <Link to={to}>{display}</Link>;
};

export default NameCell;
