import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import map from "lodash/map";

import SLGModalForm from "../../components/modals/SLGModalForm";
import AdminUserForm from "../forms/AdminUserForm";
import { createAdminUser as createAdminUserAction } from "../adminUserActions";

const styles = {
  modalContent: { width: "40%" }
};
export const AdminUserCreateModal = ({
  createAdminUser,
  context,
  onClose,
  onSuccess,
  partnerID,
  employerFilterIDs,
  employers
}) => {
  const defaultValues = {
    active: true
  };
  return (
    <SLGModalForm
      title="New Admin User"
      formName="adminUserCreate"
      submit={(...args) => {
        return createAdminUser.apply(null, [
          ...args,
          context,
          partnerID,
          employerFilterIDs,
          onSuccess
        ]);
      }}
    
      close={onClose}
      contentStyle={styles.modalContent}
    >
      <AdminUserForm
        formName="adminUserCreate"
        label="Create Admin User"
        initialValues={defaultValues}
        employers={map(employers, emp => {
          return { employer_id: emp.id, name: emp.name };
        })}
        context={context}
        partnerID={partnerID}
      />
    </SLGModalForm>
  );
};

AdminUserCreateModal.propTypes = {
  createAdminUser: PropTypes.func.isRequired,
  context: PropTypes.string.isRequired,
  partnerID: PropTypes.string,
  employerFilterIDs: PropTypes.array,
  employers: PropTypes.array,
  onClose: PropTypes.func
};

AdminUserCreateModal.defaultProps = {
  employers: []
};

export default connect(
  null,
  {
    createAdminUser: createAdminUserAction
  }
)(AdminUserCreateModal);
