import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { emailResetPassword as emailResetPasswordAction } from "./passwordActions";
import PageLayout from "./PageLayout";
import HVCentered from "../components/layouts/HVCentered";
import Typography from "../components/typography/Typography";
import asBody1 from "../components/typography/asBody1";
import asCaption from "../components/typography/asCaption";
import withWhiteText from "../components/typography/withWhiteText";
import SingleColumnForm from "../components/forms/single-column/SingleColumnForm";
import EmailField from "../components/inputs/EmailField";

const Line1 = asBody1(withWhiteText(Typography));
const Line2 = asCaption(withWhiteText(Typography));
function Header() {
  return (
    <HVCentered>
      <Line1>Forgot Your Password? No Problem.</Line1>
      <Line2>
        Enter your email and we’ll send you a link to securely create a new one.
      </Line2>
    </HVCentered>
  );
}

export class RequestPassword extends Component {
  requestPassword = values => {
    const { emailResetPassword } = this.props;
    emailResetPassword(values.email);
  };

  render() {
    const { isLoading, theme } = this.props;
    return (
      <PageLayout
        theme={theme}
        headerSlot={<Header />}
        contentSlot={
          <SingleColumnForm
            submitButtonLabel="Send Email"
            onSubmit={this.requestPassword}
            loading={isLoading}
          >
            <EmailField />
          </SingleColumnForm>
        }
      />
    );
  }
}

RequestPassword.propTypes = {
  theme: PropTypes.object,
  emailResetPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.ui.loading > 0 });

const enhance = compose(
  connect(
    mapStateToProps,
    {
      emailResetPassword: emailResetPasswordAction
    }
  )
);

export default enhance(RequestPassword);
