import React from "react";
import { compose } from "ramda";

import { SingleColumnFormProps } from "./types";
import withFormContainer from "../containers/withFormContainer";
import withHookForm from "../containers/withHookForm";
import withAction from "./withAction";
import Action from "./Action";

function SingleColumnFormComponent({ loading, ...props }) {
  return <div {...props} />;
}

const SingleColumnForm = compose(
  withHookForm,
  withAction(Action),
  withFormContainer
)(SingleColumnFormComponent);

SingleColumnForm.propTypes = SingleColumnFormProps;
export default SingleColumnForm;
