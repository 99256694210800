import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import { TextField as TextInput } from "redux-form-material-ui";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

import { required } from "../../helpers/validation";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  halfWidth: { width: "50%" },
  formButtonsBar: { marginTop: "20px", float: "right" }
};

export class UltiproSettingsForm extends Component {
  render() {
    const {
      handleSubmit,
      handlePrev,
      backButtonLabel,
      submitButtonLabel
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="api_url"
              component={TextInput}
              floatingLabelText="Web Based URL *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={[required]}
              autoFocus={true}
            />
          </div>
          <div>
            <Field
              name="api_username"
              component={TextInput}
              floatingLabelText="Admin Username *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={[required]}
            />
          </div>
          <div>
            <Field
              name="api_password"
              component={TextInput}
              floatingLabelText="Admin Password *"
              type="password"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={[required]}
            />
          </div>
          <div>
            <Field
              name="api_key"
              component={TextInput}
              floatingLabelText="API Key *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={[required]}
            />
          </div>
          <div>
            <Field
              name="prestart_interval"
              component={TextInput}
              floatingLabelText="Pre-start Interval"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
            />
          </div>
          <div style={styles.formButtonsBar}>
            {handlePrev ? (
              <FlatButton
                label={backButtonLabel}
                type="button"
                onClick={handlePrev}
                style={{ marginRight: "12px" }}
              />
            ) : null}
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

UltiproSettingsForm.propTypes = {
  formName: PropTypes.string.isRequired,
  handlePrev: PropTypes.func,
  backButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string
};

UltiproSettingsForm.defaultProps = {
  backButtonLabel: "Back",
  submitButtonLabel: "Save"
};

function mapStateToProps(state, props) {
  return {
    form: props.formName
  };
}

UltiproSettingsForm = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(UltiproSettingsForm);

export default connect(mapStateToProps)(UltiproSettingsForm);
