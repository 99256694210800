import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import PageLayout from "../login/PageLayout";
import SingleColumnForm from "../components/forms/single-column/SingleColumnForm";
import LoginSubTitle from "../components/LoginSubTitle";
import PasswordField from "../components/inputs/PasswordField";
import ConfirmPasswordField from "../components/inputs/confirms/ConfirmPasswordField";
import { registerAdminUser as registerAdminUserAction } from "adminUsers/adminUserActions";
import { getParams } from "helpers/utils";

function Header() {
  return <LoginSubTitle>Admin Portal</LoginSubTitle>;
}

export class AdminUserRegistration extends Component {
  registerAdminUser = values => {
    const { registerAdminUser } = this.props;
    const params = getParams(window.location.hash);
    const rawInvitationToken = params["raw_invitation_token"];
    const resetParams = {
      raw_invitation_token: rawInvitationToken,
      password: values.password,
      password_confirmation: values.passwordConfirmation
    };

    registerAdminUser(resetParams);
  };

  render() {
    const { isLoading, theme } = this.props;
    return (
      <PageLayout
        theme={theme}
        headerSlot={<Header />}
        contentSlot={
          <SingleColumnForm
            submitButtonLabel="Register Admin User"
            onSubmit={this.registerAdminUser}
            loading={isLoading}
          >
            <PasswordField />
            <ConfirmPasswordField />
          </SingleColumnForm>
        }
      />
    );
  }
}

AdminUserRegistration.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
  registerAdminUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ isLoading: state.ui.loading > 0 });

const enhance = compose(
  connect(
    mapStateToProps,
    {
      registerAdminUser: registerAdminUserAction
    }
  )
);

export default enhance(AdminUserRegistration);
