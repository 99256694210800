import makeActionCreator from "../../helpers/makeActionCreator";

const nameSpace = "partners/reports";

export const GET_PARTNER_REPORTS_REQUEST = `${nameSpace}/GET_REPORTS_REQUEST`;
export const GET_PARTNER_REPORTS_SUCCESS = `${nameSpace}/GET_REPORTS_SUCCESS`;
export const GET_PARTNER_REPORTS_FAILURE = `${nameSpace}/GET_REPORTS_FAILURE`;
export const DOWNLOAD_REPORT_REQUEST = `${nameSpace}/DOWNLOAD_REPORT_REQUEST`;
export const DOWNLOAD_REPORT_SUCCESS = `${nameSpace}/DOWNLOAD_REPORT_SUCCESS`;
export const DOWNLOAD_REPORT_FAILURE = `${nameSpace}/DOWNLOAD_REPORT_FAILURE`;

export const requestGetReports = makeActionCreator(
  GET_PARTNER_REPORTS_REQUEST,
  "partnerId",
);
export const getReportsSuccess = makeActionCreator(
  GET_PARTNER_REPORTS_SUCCESS,
  "data",
);
export const getReportsFailure = makeActionCreator(
  GET_PARTNER_REPORTS_FAILURE,
  "errors",
);

export const requestDownloadReport = makeActionCreator(
  DOWNLOAD_REPORT_REQUEST,
  "partnerId",
  "reportId",
  "fileName",
);
export const downloadReportSuccess = makeActionCreator(DOWNLOAD_REPORT_SUCCESS);
export const downloadReportFailure = makeActionCreator(
  DOWNLOAD_REPORT_FAILURE,
  "errors",
);
