import {
  put,
  takeLatest,
  all,
  call,
  fork,
  takeEvery
} from "redux-saga/effects";
import * as Sentry from "@sentry/browser";

import log from "../../services/logging/service";
import routeService from "../services/RouteService";
import fetchApiService from "../../rest/fetchApiService";
import { showError, showSuccess } from "../../shared/sagas/notifications";
import {
  GET_PARTNER_REPORTS_REQUEST,
  requestGetReports,
  getReportsSuccess,
  getReportsFailure,
  DOWNLOAD_REPORT_REQUEST,
  downloadReportSuccess,
  downloadReportFailure
} from "../actions";
import GetReportRequest from "../services/GetReportsRequest";
import GetReportResponse from "../services/GetReportsResponse";

function* getPartnerReports({ partnerId }) {
  try {
    const endpoint = routeService.getPartnerReports(partnerId);
    const request = new GetReportRequest({ url: endpoint });
    const response = yield fetchApiService.fetch(request, GetReportResponse);
    if (response.ok) {
      const data = response.getListData(partnerId);
      yield put(getReportsSuccess(data));
    } else {
      log.error(response.statusText);
      yield call(showError, "Error! Getting partner reports");
      Sentry.captureException(response.statusText);
      yield put(getReportsFailure(response.statusText));
    }
  } catch (e) {
    log.error(e.message);
    yield call(showError, "Error! Getting partner reports");
    Sentry.captureException(e);
    yield put(getReportsFailure(e.message));
  }
}

export function* getAllPartnersReports({ payload }) {
  const data = payload.partners || {};
  const total = data.total;
  const partnersData = data.data;

  for (let i = 0; i < total; i = i + 1) {
    const partnerId = partnersData[i].id;
    yield put(requestGetReports(partnerId));
    yield fork(getPartnerReports, { partnerId });
  }
}

/** download report */

function* downloadReport({ partnerId, reportId, fileName }) {
  try {
    const url = routeService.getPartnerReportDownload(partnerId, reportId);
    const response = yield fetchApiService.fetchFile(url, fileName);
    if (response.ok) {
      yield call(showSuccess, "Report successfully downloaded!");
      yield put(downloadReportSuccess());
    } else {
      log.error(response.statusText);
      yield call(showError, "Error! Downloading report");
      Sentry.captureException(response.statusText);
      yield put(downloadReportFailure(response.statusText));
    }
  } catch (e) {
    log.error(e.message);
    yield call(showError, "Error! Downloading report");
    Sentry.captureException(e);
    yield put(downloadReportFailure(e.message));
  }
}

export default function* getReportsSagas() {
  yield all([
    takeEvery(GET_PARTNER_REPORTS_REQUEST, getPartnerReports),
    takeLatest(DOWNLOAD_REPORT_REQUEST, downloadReport)
  ]);
}
