import React, { Component } from "react";
import PropTypes from "prop-types";

const hasUpper = value => /[A-Z]/.test(value) || false;

const hasLower = value => /[a-z]/.test(value) || false;

const hasSpecialChar = value =>
  /[[-_\\.\][?/<~#`!@$%^&*()+=}|:";',>{]/.test(value) || false;

const hasNumber = value => /[0-9]/.test(value) || false;

class PasswordPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputPasword: "",
      hasMinCharacter: false,
      hasNumber: false,
      hasLowercas: false,
      hasUppercase: false,
      hasSpecialChracter: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.passInput !== this.props.passInput) {
      //console.log("_______Change: ", nextProps.passInput);
      let passInput = nextProps.passInput;

      this.setState({
        inputPassword: passInput,
        hasMinCharacter: passInput.length > 10 ? true : false,
        hasUppercase: hasUpper(passInput),
        hasNumber: hasNumber(passInput),
        hasLowercas: hasLower(passInput),
        hasUppercase: hasUpper(passInput),
        hasSpecialChracter: hasSpecialChar(passInput)
      });
    } else {
      //console.log("_______No Change");
    }
  }

  // #f44336
  render() {
    const { passInput, showPassPolicy } = this.props;

    const {
      hasMinCharacter,
      hasNumber,
      hasLowercas,
      hasUppercase,
      hasSpecialChracter
    } = this.state;

    return (
      <div>
        <div>
          We are serious about protecting your data. Use these requirements to
          help keep your account safe.
        </div>
        <ul>
          <li
            style={{
              color: `${hasMinCharacter === true ? "green" : "#f44336"}`
            }}
          >
            10 Characters
          </li>
          <li style={{ color: `${hasNumber === true ? "green" : "#f44336"}` }}>
            1 Number
          </li>
          <li
            style={{ color: `${hasUppercase === true ? "green" : "#f44336"}` }}
          >
            1 Uppercase Letter (A-Z)
          </li>
          <li
            style={{ color: `${hasLowercas === true ? "green" : "#f44336"}` }}
          >
            1 Lowercase Letter (a-z)
          </li>
          <li
            style={{
              color: `${hasSpecialChracter === true ? "green" : "#f44336"}`
            }}
          >
            1 Special Character (!, @, #, $, %, &#38;, etc.)
          </li>
        </ul>
      </div>
    );
  }
}

PasswordPolicy.propTypes = {
  passInput: PropTypes.string
};

export default PasswordPolicy;
