import BaseRequest from "../../shared/BaseRequest";

export default class ToggleRefiVendorRequest extends BaseRequest {
  method = "put";
  type = "employers";

  constructor({ url, vendorId, vendorableRecordId, toggle }) {
    super({ url });

    this.data = {
      type: "employers",
      vendorableRecordId,
      vendorId,
      toggle
    };
  }
}
