import React from "react";
import PropTypes from "prop-types";

import usePropsTransformer from "./usePropsTransformer";

function useRegister({
  setValue,
  watch,
  register,
  errors,
  loading,
  name,
  helperText,
  disabled,
  validate,
  required
}) {
  const {
    shouldDisabled,
    error,
    helpText,
    shouldRequired
  } = usePropsTransformer({
    loading,
    errors,
    name,
    helperText,
    disabled,
    validate,
    required
  });

  const value = watch(name, "");

  React.useEffect(() => {
    register({ name, type: "custom" }, validate);
  }, [register, name, validate]);

  function handleChange(e) {
    const value = e && e.target ? e.target.value : e;
    setValue(name, value);
  }

  return {
    handleChange,
    shouldDisabled,
    shouldRequired,
    error,
    helpText,
    value
  };
}

useRegister.propTypes = {
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  register: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  validate: PropTypes.object,
  required: PropTypes.bool
};

export default useRegister;
