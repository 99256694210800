import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

/** legacy code, will remove once new form is done */
import { openModal as openModalAction } from "../../app/uiActions";

import EditButton from "../../components/buttons/action/Edit";
import { AdminUserEditModal } from "../../adminUsers/modals";

function ActionsCell({ openModal, stateProps: { requestData }, ...adminUser }) {
  function handleEditClick() {
    function handleSuccess() {
      requestData();
    }

    openModal(
      <AdminUserEditModal adminUser={adminUser} onSuccess={handleSuccess} />
    );
  }

  return (
    <>
      <EditButton onClick={handleEditClick}>Edit</EditButton>
    </>
  );
}

export default compose(
  connect(
    null,
    {
      openModal: openModalAction
    }
  )
)(ActionsCell);
