import React from "react";
import { compose } from "ramda";

import Dialog from "../../dialogs/form-dialogs/Dialog";
import withHookForm from "../containers/withHookForm";
import withFormContainer from "../containers/withFormContainer";

/**
 * to be consumed as dialog body
 */
function DialogFormComponent({
  title,
  open,
  onSubmit,
  onClose,
  maxWidth = "xs",
  fullWidth = true,
  loading,
  children
}) {
  return (
    <Dialog
      title={title}
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      loading={loading}
    >
      {children}
    </Dialog>
  );
}

export default compose(
  withHookForm,
  withFormContainer
)(DialogFormComponent);
