import React from "react";
import * as R from "ramda";

const handleFilter = row => row.type_id !== 4;
const excludeLoanContribution = R.ifElse(
  R.path(["employer", "pay_enabled?"]),
  R.prop("rows"),
  R.pipe(
    R.prop("rows"),
    R.filter(handleFilter)
  )
);

export default WC => props => {
  return <WC {...props} reportsFilter={excludeLoanContribution} />;
};
