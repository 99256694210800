import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import reduce from "lodash/reduce";
import round from "lodash/round";
import {
  FlexibleXYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint
} from "react-vis";
import Payment from "@material-ui/icons/Payment";
import DateRange from "@material-ui/icons/DateRange";
import Language from "@material-ui/icons/Language";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import { Typography } from "@material-ui/core";
import {
  fetchPartnerRegClick as fetchPartnerRegClickAction,
  fetchPartnersDetails as fetchPartnersDetailsAction,
  fetchPartnerStats as fetchPartnerStatsAction,
  fetchPartnerUserStats as fetchPartnerUserStatsAction,
  fetchPartnerJourneyStats as fetchPartnerJourneyStatsAction
} from "../partners/partnerActions";

import { convertCurrency, formatCurrency } from "helpers/formattingHelpers";
import {
  StatCard,
  TableGraph,
  StyledGridContainer,
  StyledGridItem,
  VaultCardHeaderIcon,
  DownloadButton
} from "components";
import { selectEntityIdentifiers } from "../partners/selectors";
import stateName from "../helpers/stateName";
import Table from "packages/mui-dashboard/components/Table/Table.jsx";
import Card from "packages/mui-dashboard/components/Card/Card.jsx";
import CardBody from "packages/mui-dashboard/components/Card/CardBody.jsx";
import moment from "moment";
import {
  PersonAdd,
  HourglassFull,
  AccountBalance,
  AttachMoney,
  AccountBox,
  AccessibilityNew,
  Assessment,
  Terrain,
  Subject,
  Traffic,
  FlightTakeoff
} from "@material-ui/icons";
import { DatePicker } from "@material-ui/pickers";
import { partnerJourneyStats } from "./partnerReducer";

export class Stats extends Component {
  state = {
    hoveredCell: false,
    averagePaymentCell: false,
    activeCustomerCell: false,
    enrolledCustomerCell: false,
    employerCustomerCell: false,
    topEmployerCustomerCell: false,
    payoffTimeCell: false,
    averageBalanceCell: false,
    totalBalanceCell: false,
    selectedDate: null
  };

  componentDidMount() {
    const {
      partnerID,
      fetchPartnerRegClick,
      fetchPartnersDetails,
      fetchPartnerUserStats,
      fetchPartnerJourneyStats
    } = this.props;
    fetchPartnerRegClick(partnerID);
    fetchPartnersDetails(partnerID);
    fetchPartnerUserStats(partnerID);
    fetchPartnerJourneyStats(partnerID);
  }

  filterEnrollmentData = enrollmentData => {
    const { entityIdentifiers } = this.props;

    const barGraphShortNames = {
      [`Total ${entityIdentifiers.employeeIdentifier}s`]: "Total",
      [`Invited ${entityIdentifiers.employeeIdentifier}s`]: "Invited",
      [`Loan-registered ${entityIdentifiers.employeeIdentifier}s`]: "Loan-registered",
      [`${entityIdentifiers.employeeIdentifier}s included in previous invoices`]: "Previously Invoiced"
    };

    return reduce(
      enrollmentData,
      (result, obj) => {
        if (!obj.barGraph || !obj.y) {
          return result;
        }

        result.push({ x: barGraphShortNames[obj.x], y: obj.y });
        return result;
      },
      []
    );
  };

  getTopEmployers = e => {
    const {
      partnerID,
      fetchPartnerStats,
      fetchPartnerRegClick,
      fetchPartnerUserStats,
      fetchPartnerJourneyStats
    } = this.props;

    let date = moment(e).format("YYYY-MM-DD");
    this.setState({
      selectedDate: e
    });
    fetchPartnerStats(partnerID, date);
    fetchPartnerRegClick(partnerID, date);
    fetchPartnerUserStats(partnerID, date);
  };

  filterMonthlyAvgData = avgMonthlyData => {
    return reduce(
      avgMonthlyData,
      (result, obj) => {
        result.push({ x: obj.xFormat, y: obj.y });
        return result;
      },
      []
    );
  };

  filterRankEmployersData = avgEmployerRank => {
    return reduce(
      avgEmployerRank,
      (result, obj) => {
        const splitText = obj.x.split(" ");
        result.push({ x: `${splitText[0]}...`, y: obj.y });
        return result;
      },
      []
    );
  };

  render() {
    const {
      isLoading,
      partnerStats,
      entityIdentifiers,
      registeredClicksData,
      partnersDetails,
      partnerName,
      partnerUserStatsDetails,
      partnerJourneyStatsDetails
    } = this.props;
    const {
      hoveredCell,
      averagePaymentCell,
      averageBalanceCell,
      activeCustomerCell,
      employerCustomerCell,
      topEmployerCustomerCell,
      enrolledCustomerCell,
      payoffTimeCell,
      totalBalanceCell
    } = this.state;
    const {
      pay_benefit: benefitStats,
      advisor: advisorStats,
      employers
    } = partnerStats;

    const showFiveTwoNineReport =
      partnersDetails &&
      partnersDetails.partnersData &&
      partnersDetails.partnersData.data &&
      partnersDetails.partnersData.data.attributes.has_five_two_nine;

    const totalEmployees =
      employers && employers.data
        ? employers.data.attributes.all_employees_count
        : 0;
    const allFamilyCount =
      employers && employers.data
        ? employers.data.attributes.all_family_count
        : 0;
    const employersCount =
      employers && employers.data
        ? employers.data.attributes.employers_count
        : 0;
    const eligibleEmployeesCount =
      employers && employers.data
        ? employers.data.attributes.eligible_employees_count
        : 0;
    const enrolledEmployeesCount =
      employers && employers.data
        ? employers.data.attributes.enrolled_employees_count
        : 0;
    const activeEmployeesCount =
      employers && employers.data
        ? employers.data.attributes.active_employees_count
        : 0;
    const employeesOnLastPdr =
      employers && employers.data
        ? employers.data.attributes.employees_on_last_pdr
        : 0;
    const totalLoanCount =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.total_loan_count
        : 0;
    const payEmployersCount =
      employers && employers.data
        ? employers.data.attributes.pay_employers_count
        : 0;
    const avgSavingsWithBenefit =
      benefitStats && benefitStats.data
        ? benefitStats.data.attributes.avg_savings_with_benefit
        : 0;
    const avgTimeSavedWithBenefit =
      benefitStats && benefitStats.data
        ? benefitStats.data.attributes.avg_time_saved_with_benefit
        : 0;
    const distributedFunds =
      employers && employers.data
        ? employers.data.attributes.distributed_funds
        : 0;
    const totalBalance =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.total_balance
        : 0;
    const avgBalance =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.avg_balance
        : 0;
    const avgMonthlyPayment =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.avg_monthly_payment
        : 0;
    const avgPayoffTime =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.avg_payoff_time
        : 0;
    const lastCronDate =
      employers && employers.data
        ? moment(employers.data.attributes.date)
        : this.state.selectedDate;

    const monthlyAvgBalance =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.monthly_avg_balance
        : 0;

    const monthlyAvgPayment =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.monthly_avg_monthly_payment
        : 0;

    const monthlyAvgPayoffTime =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.monthly_avg_payoff_time
        : 0;

    const monthlyTotalBalance =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.monthly_total_balance
        : 0;

    const monthlyGrowthActiveCustomers =
      employers && employers.data
        ? employers.data.attributes.monthly_growth_active_customers
        : 0;

    const monthlyGrowthEmployersWithCustomers =
      employers && employers.data
        ? employers.data.attributes.monthly_growth_employers_with_customers
        : 0;

    const monthlyGrowthEnrolledCustomers =
      employers && employers.data
        ? employers.data.attributes.monthly_growth_enrolled_customers
        : 0;

    const rankEmployersByCustomerPresence =
      employers && employers.data
        ? employers.data.attributes.rank_employers_by_customers_presence
        : 0;

    const eligibleForMatch =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.eligible_for_match
        : 0;
    const eligibleForPay =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.eligible_for_pay
        : 0;
    const eligibleForTeacherLoanForgiveness =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.eligible_for_tlf
        : 0;
    const eligibleForPublicLoanForgiveness =
      advisorStats && advisorStats.data
        ? advisorStats.data.attributes.eligible_for_pslf
        : 0;

    const topUserContentStats =
      partnerUserStatsDetails && partnerUserStatsDetails.stats
        ? partnerUserStatsDetails.stats.top_content
        : [];
    const topUserCalculatorStats =
      partnerUserStatsDetails && partnerUserStatsDetails.stats
        ? partnerUserStatsDetails.stats.top_used_calculators
        : [];
    const topTrafficSource =
      partnerUserStatsDetails && partnerUserStatsDetails.stats
        ? partnerUserStatsDetails.stats.traffic_source
        : [];

    const journeyTableStats =
      partnerJourneyStatsDetails && partnerJourneyStatsDetails.stats
        ? partnerJourneyStatsDetails.stats
        : {};

    const hasTableData = topUserData => {
      if (!!topUserData && topUserData.length > 0) {
        if (!!topUserData[0]) {
          return (
            !!topUserData[0].activeUsers &&
            !!topUserData[0].screenPageViews &&
            !!topUserData[0].pageTitle
          );
        }
      }
    };

    const hasTrafficdata = trafficData => {
      if (!!trafficData && trafficData.length > 0) {
        if (!!trafficData[0]) {
          return (
            !!trafficData[0].session_source &&
            !!trafficData[0].session_medium &&
            !!trafficData[0].total
          );
        }
      }
    };

    const pushDataIntoMonthlyGraph = (monthlyDataArr, monthlyDataSet) => {
      for (const x in monthlyDataSet) {
        monthlyDataArr.push({
          x: x,
          y: convertCurrency(monthlyDataSet[x], true) || 0,
          barGraph: true,
          xFormat: `${moment(x).format("MMM YY")}`,
          yFormat: formatCurrency(monthlyDataSet[x], true) || 0
        });
      }
    };

    const pushDataIntoMonthlyAvgGraph = (monthlyDataArr, monthlyDataSet) => {
      for (const x in monthlyDataSet) {
        monthlyDataArr.push({
          x: x,
          y: monthlyDataSet[x] || 0,
          barGraph: true,
          xFormat: `${moment(x).format("MMM YY")}`,
          yFormat: `${monthlyDataSet[x]} months` || 0
        });
      }
    };

    const pushDataIntoMonthlyGraphCustomers = (
      monthlyDataArr,
      monthlyDataSet
    ) => {
      for (const x in monthlyDataSet) {
        monthlyDataArr.push({
          x: x,
          y: monthlyDataSet[x]["absolute"],
          barGraph: true,
          xFormat: `${moment(x).format("MMM YY")}`,
          yFormat: `${monthlyDataSet[x]["absolute"]} (${
            monthlyDataSet[x]["percent"]
          }%)`
        });
      }
    };

    const exportToCsv = () => {
      let headers = ["Date, Employer, # of employees, % growth"];

      let createdAt = lastCronDate
        ? moment(lastCronDate).format("MM-DD-YYYY")
        : this.state.selectedDate
        ? moment(this.state.selectedDate).format("MM-DD-YYYY")
        : moment().format("MM-DD-YYYY");

      let topEmployersCsv = rankEmployersByCustomerPresenceData.reduce(
        (exportCsv, item) => {
          let percentageArr = item.yFormat.split(" ");
          let percentage = `${percentageArr[1].substr(
            1,
            percentageArr[1].length - 2
          )}`;

          exportCsv.push([createdAt, item.x, item.y, percentage]);

          return exportCsv;
        },
        []
      );

      const topEmployersData = [...headers, ...topEmployersCsv].join("\n");
      const fileName = `TopEmployers-${createdAt}.csv`;
      const fileType = "text/csv";
      downloadCsv(topEmployersData, fileName, fileType);
    };

    const downloadCsv = (data, fileName, fileType) => {
      const blob = new Blob([data], { type: fileType });

      const a = document.createElement("a");
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    };

    const monthlyAvgBalanceData = [],
      monthlyAvgPaymentData = [],
      monthlyAvgPayoffTimeData = [],
      monthlyTotalBalanceData = [],
      monthlyGrowthActiveCustomersData = [],
      monthlyGrowthEmployersWithCustomersData = [],
      monthlyGrowthEnrolledCustomersData = [],
      rankEmployersByCustomerPresenceData = [];

    pushDataIntoMonthlyGraph(monthlyAvgBalanceData, monthlyAvgBalance);
    pushDataIntoMonthlyGraph(monthlyAvgPaymentData, monthlyAvgPayment);
    pushDataIntoMonthlyAvgGraph(monthlyAvgPayoffTimeData, monthlyAvgPayoffTime);
    pushDataIntoMonthlyGraph(monthlyTotalBalanceData, monthlyTotalBalance);

    pushDataIntoMonthlyGraphCustomers(
      monthlyGrowthActiveCustomersData,
      monthlyGrowthActiveCustomers
    );
    pushDataIntoMonthlyGraphCustomers(
      monthlyGrowthEmployersWithCustomersData,
      monthlyGrowthEmployersWithCustomers
    );
    pushDataIntoMonthlyGraphCustomers(
      monthlyGrowthEnrolledCustomersData,
      monthlyGrowthEnrolledCustomers
    );
    pushDataIntoMonthlyGraphCustomers(
      rankEmployersByCustomerPresenceData,
      rankEmployersByCustomerPresence
    );

    const enrollmentData = [
      {
        x: `Total ${entityIdentifiers.employerIdentifier}s`,
        y: employersCount || 0,
        barGraph: false
      },
      {
        x: `Total ${entityIdentifiers.employeeIdentifier}s`,
        y: totalEmployees || 0,
        barGraph: true,
        title: "tTip-All Employees"
      },
      {
        x: `Invited ${entityIdentifiers.employeeIdentifier}s`,
        y: eligibleEmployeesCount || 0,
        barGraph: true,
        title:
          "tTip-Employees with an active and effective account on the present date"
      },
      {
        x: `Enrolled ${entityIdentifiers.employeeIdentifier}s`,
        y: enrolledEmployeesCount || 0,
        barGraph: false,
        title: "tTip-Active employees who accepted Vault invitation"
      },
      {
        x: `Loan-registered ${entityIdentifiers.employeeIdentifier}s`,
        y: activeEmployeesCount || 0,
        barGraph: true,
        title: "tTip-Enrolled Employees who registered their loan info"
      },
      {
        x: `${entityIdentifiers.employeeIdentifier}s included in previous invoices`,
        y: employeesOnLastPdr || 0,
        barGraph: true,
        title:
          "tTip-Employees included to the latest invoices across all employers"
      },
      {
        x: "Eligible for Pay",
        y: eligibleForPay || 0,
        title: "tTip-Employees with Flat, Vested or CustomBenefit services"
      },
      {
        x: "Eligible for Match",
        y: eligibleForMatch || 0,
        title: "tTip-Employees with Genius Match services"
      },
      {
        x: "Eligible for Teacher Loan Forgiveness",
        y: eligibleForTeacherLoanForgiveness || 0,
        title: "tTip-Employees eligible for Teacher Loan Forgiveness program"
      },
      {
        x: "Eligible for Public Loan Forgiveness",
        y: eligibleForPublicLoanForgiveness || 0,
        title: "tTip-Employees eligible for Public Loan Forgiveness program"
      },
      {
        x: "Total # of Loans",
        y: totalLoanCount || 0,
        title: "tTip-Active Loans count"
      }
    ];

    if (allFamilyCount > 0) {
      enrollmentData.splice(5, 0, {
        x: "Invited Family Members",
        y: allFamilyCount
      });
    }

    const vaultPayData = [
      {
        x: `Active ${entityIdentifiers.employerIdentifier}s With Pay`,
        y: payEmployersCount || 0,
        title:
          "tTip-Employers with available Flat, Vested, CustomBenefit services"
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Savings`,
        y: formatCurrency(avgSavingsWithBenefit, true),
        title:
          "tTip-Average employee money savings with paying extra amount for all employees"
      },
      {
        x: `Average ${entityIdentifiers.employeeIdentifier} Pay Time Saved (mos.)`,
        y: `${round(avgTimeSavedWithBenefit)} months`,
        title:
          "tTip-Average employee time savings with paying extra amount for all employees"
      },
      {
        x: "Total Contributions Distributed",
        y: formatCurrency(distributedFunds, true),
        title: "tTip-Total disbursement sum"
      }
    ];

    const visitorStats = [
      {
        x: `Active visitors`,
        y:
          partnerUserStatsDetails && partnerUserStatsDetails.stats
            ? partnerUserStatsDetails.stats.active_visitors
            : "0",
        title:
          "tTip-Number of distinct employees who's session lasted longer than 10 seconds"
      },
      {
        x: `Average time spent in Vault tool`,
        y:
          partnerUserStatsDetails && partnerUserStatsDetails.stats
            ? partnerUserStatsDetails.stats.avg_engagement + " mins"
            : "0",
        title:
          "tTip-Average amount of time Vault website was in foreground of employee's device"
      },
      {
        x: `#/% of participants viewing articles`,
        y:
          partnerUserStatsDetails && partnerUserStatsDetails.stats
            ? `${partnerUserStatsDetails.stats.article_visitors.absolute} (${partnerUserStatsDetails.stats.article_visitors.percent} %)`
            : "0 %",
        title:
          "tTip-% employees who viewed Match FAQ, FAQ, Payroll Deduction, Benefits of 529 Plan, How Do I Choose The 529 Plan That Is Best For Me?, PSLF, 529 Tax Benefits By State."
      },
      {
        x: `#/% of participants viewing calculators`,
        y:
          partnerUserStatsDetails && partnerUserStatsDetails.stats
            ? `${partnerUserStatsDetails.stats.calculator_visitors.absolute} (${partnerUserStatsDetails.stats.calculator_visitors.percent} %)`
            : "0 %",
        title: "tTip-% employees who viewed calculators"
      },
      {
        x: `Average time spent in Vault session`,
        y:
          partnerUserStatsDetails && partnerUserStatsDetails.stats
            ? partnerUserStatsDetails.stats.avg_session_duration + " mins"
            : "0",
        title: "tTip-Average duration of employee's session"
      }
    ];
    const fiveTwoNinePlaceholder = [
      {
        x: "Contributees",
        y: "0"
      },
      {
        x: "Contributors",
        y: "0"
      },
      {
        x: "Average Contribution",
        y: "$0"
      },
      {
        x: "529 users",
        y: "0"
      },
      {
        x: "Number of user clicks on the state map",
        y: "0"
      }
    ];

    const regClickDataToArray = Object.entries(
      registeredClicksData !== null && registeredClicksData.clickStats
    );

    const registeredClickData = [
      regClickDataToArray
        .map(e => {
          if (e[0] === "average_contribution") {
            return {
              x: stateName[e[0]],
              y: "$" + e[1]
            };
          } else if (e[1] === null) {
            return {
              x: stateName[e[0]],
              y: "0"
            };
          } else {
            return {
              x: stateName[e[0]],
              y: e[1]
            };
          }
        })
        .filter(e => {
          return e.x !== "Date";
        })
    ];

    const stateData = registeredClickData[0].filter(e => {
      return e.x && e.x.includes(":");
    });
    const clicksData = registeredClickData[0].filter(e => {
      return e.x && !e.x.includes(":");
    });

    // divide array into four parts to map it seperately
    const quarterIndex = Math.ceil(stateData.length / 4);
    const stateColOne = stateData.splice(0, quarterIndex);
    const stateColTwo = stateData.splice(0, quarterIndex);
    const stateColThree = stateData.splice(0, quarterIndex);
    const stateColFour = stateData.splice(0, quarterIndex, -quarterIndex);
    const regClicksData = [
      [...stateColOne],
      [...stateColTwo],
      [...stateColThree],
      [...stateColFour]
    ];

    return (
      <React.Fragment>
        <StyledGridContainer>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Total ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={formatCurrency(totalBalance, true)}
              icon={<AttachMoney />}
              footer="Advisor"
              isLoading={isLoading}
              color="info"
              toolTip="Total debt upon all employees"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Debt Balance`}
              data={formatCurrency(avgBalance, true)}
              icon={<Person />}
              footer="Advisor"
              isLoading={isLoading}
              color="rose"
              toolTip="Average debt upon all employees"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Monthly Payment`}
              data={formatCurrency(avgMonthlyPayment, true)}
              icon={<Payment />}
              footer="Advisor"
              isLoading={isLoading}
              color="success"
              toolTip="Average monthly payment upon all employees"
            />
          </StyledGridItem>
          <StyledGridItem xs={12} sm={6} md={6} lg={3}>
            <StatCard
              title={`Average ${entityIdentifiers.employeeIdentifier} Payoff`}
              data={`${round(avgPayoffTime)} months`}
              icon={<DateRange />}
              footer="Advisor"
              isLoading={isLoading}
              color="warning"
              toolTip="Average time of debt payoff"
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridItem xs={12} sm={12} md={12}>
          <div
            style={{
              marginBottom: "-15px",
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <DatePicker
              minDate={moment().subtract(6, "months")}
              disableFuture
              onChange={e => this.getTopEmployers(e)}
              value={lastCronDate || this.state.selectedDate}
            />
          </div>
        </StyledGridItem>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Enrollment"
              icon={<People />}
              isLoading={isLoading}
              tableData={enrollmentData.map(val => [val.x, val.y, val.title])}
              hover={true}
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#5372FC"
                  stroke="#5372FC"
                  stackBy="y"
                  yDomain={[0, totalEmployees]}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis
                    title={`${entityIdentifiers.employeeIdentifier}s stats`}
                  />
                  <VerticalBarSeries
                    data={this.filterEnrollmentData(enrollmentData)}
                    barWidth={0.25}
                    onValueMouseOver={v => {
                      this.setState({ hoveredCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ hoveredCell: false })
                    }
                  />
                  {hoveredCell && (
                    <Hint
                      value={hoveredCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${hoveredCell.y} Employees`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Employers Adoption"
              icon={<AccountBox />}
              iconColor="danger"
              isLoading={isLoading}
              tableData={monthlyGrowthEmployersWithCustomersData.map(val => [
                val.xFormat,
                val.yFormat
              ])}
              headerTooltip="#/% of employers with enrolled employees"
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#E8403C"
                  stroke="#E8403C"
                  stackBy="y"
                  // yDomain={[0, monthlyGrowthEmployersWithCustomersMax]}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis title={`Employers Adoption`} />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(
                      monthlyGrowthEmployersWithCustomersData
                    )}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ employerCustomerCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ employerCustomerCell: false })
                    }
                  />
                  {employerCustomerCell && (
                    <Hint
                      value={employerCustomerCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${employerCustomerCell.y}`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        {partnerName && partnerName.toUpperCase().includes("SCHWAB") && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <TableGraph
                title="Top Employers by adoption"
                icon={<AccessibilityNew />}
                iconColor="danger"
                isLoading={isLoading}
                className="mt0"
                tableData={rankEmployersByCustomerPresenceData.map(val => [
                  val.x,
                  val.yFormat
                ])}
                headerTooltip="#/% of top 5 employers by enrolled employees"
                downloadCsv={exportToCsv}
                hasDownloadCsv={true}
                graph={
                  <FlexibleXYPlot
                    xType="ordinal"
                    fill="#E8403C"
                    stroke="#E8403C"
                    stackBy="y"
                    // yDomain={[0, monthlyGrowthEmployersWithCustomersMax]}
                  >
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis />
                    <YAxis title={`Top Employers by adoption`} />
                    <VerticalBarSeries
                      data={this.filterRankEmployersData(
                        rankEmployersByCustomerPresenceData
                      )}
                      barWidth={0.35}
                      onValueMouseOver={v => {
                        this.setState({ topEmployerCustomerCell: v });
                      }}
                      onValueMouseOut={() =>
                        this.setState({ topEmployerCustomerCell: false })
                      }
                    />
                    {topEmployerCustomerCell && (
                      <Hint
                        value={topEmployerCustomerCell}
                        align={{
                          horizontal: Hint.ALIGN.AUTO,
                          vertical: Hint.ALIGN.BOTTOM
                        }}
                      >
                        <div className="rv-hint__content">{`${topEmployerCustomerCell.y}`}</div>
                      </Hint>
                    )}
                  </FlexibleXYPlot>
                }
              />
            </StyledGridItem>
          </StyledGridContainer>
        )}

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Enrolled Employees"
              icon={<PersonAdd />}
              iconColor="danger"
              isLoading={isLoading}
              tableData={monthlyGrowthEnrolledCustomersData.map(val => [
                val.xFormat,
                val.yFormat
              ])}
              headerTooltip="Active employees who accepted Vault invitation"
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#E8403C"
                  stroke="#E8403C"
                  stackBy="y"
                  // yDomain={[0, monthlyGrowthEnrolledCustomersMax]}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis title={`Enrolled Employees`} />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(
                      monthlyGrowthEnrolledCustomersData
                    )}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ enrolledCustomerCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ enrolledCustomerCell: false })
                    }
                  />
                  {enrolledCustomerCell && (
                    <Hint
                      value={enrolledCustomerCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${enrolledCustomerCell.y}`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Loan-registered Employees"
              icon={<People />}
              iconColor="danger"
              isLoading={isLoading}
              tableData={monthlyGrowthActiveCustomersData.map(val => [
                val.xFormat,
                val.yFormat
              ])}
              headerTooltip="Monthly growth of employees who registered their loan info"
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#E8403C"
                  stroke="#E8403C"
                  stackBy="y"
                  // yDomain={[0, monthlyGrowthActiveCustomersMax]}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis title={`Loan-registered Employees`} />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(
                      monthlyGrowthActiveCustomersData
                    )}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ activeCustomerCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ activeCustomerCell: false })
                    }
                  />
                  {activeCustomerCell && (
                    <Hint
                      value={activeCustomerCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${activeCustomerCell.y}`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Average Balance"
              icon={<AccountBalance />}
              iconColor="rose"
              isLoading={isLoading}
              tableData={monthlyAvgBalanceData.map(val => [
                val.xFormat,
                val.yFormat
              ])}
              headerTooltip="Monthly average debt upon all employees"
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#DC2365"
                  stroke="#DC2365"
                  stackBy="y"
                  // yDomain={[0, 40]}
                  margin={{ left: 50 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis title={`Average Balance`} />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyAvgBalanceData)}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ averageBalanceCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ averageBalanceCell: false })
                    }
                  />
                  {averageBalanceCell && (
                    <Hint
                      value={averageBalanceCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">
                        {formatCurrency(averageBalanceCell.y)}
                      </div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Total Balance"
              icon={<AccountBalance />}
              isLoading={isLoading}
              tableData={monthlyTotalBalanceData.map(val => [
                val.xFormat,
                val.yFormat
              ])}
              headerTooltip="Monthly total debt upon all employees"
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#0AB3C8"
                  stroke="#0AB3C8"
                  stackBy="y"
                  // yDomain={[0, monthlyTotalBalanceMax]}
                  margin={{ left: 70 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis title={`Total Balance`} />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyTotalBalanceData)}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ totalBalanceCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ totalBalanceCell: false })
                    }
                  />
                  {totalBalanceCell && (
                    <Hint
                      value={totalBalanceCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">
                        {formatCurrency(totalBalanceCell.y)}
                      </div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Average Payment"
              icon={<AttachMoney />}
              isLoading={isLoading}
              iconColor="success"
              tableData={monthlyAvgPaymentData.map(val => [
                val.xFormat,
                val.yFormat
              ])}
              headerTooltip="Monthly average payment upon all employees"
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#48A34C"
                  stroke="#48A34C"
                  stackBy="y"
                  // yDomain={[0, monthlyAvgPaymentMax]}
                  margin={{ left: 50 }}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis title={`Average Payment`} />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyAvgPaymentData)}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ averagePaymentCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ averagePaymentCell: false })
                    }
                  />
                  {averagePaymentCell && (
                    <Hint
                      value={averagePaymentCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">
                        {formatCurrency(averagePaymentCell.y)}
                      </div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Average Payoff Time"
              icon={<HourglassFull />}
              iconColor="warning"
              isLoading={isLoading}
              tableData={monthlyAvgPayoffTimeData.map(val => [
                val.xFormat,
                val.yFormat
              ])}
              headerTooltip="Monthly time of debt payoff upon all employees"
              graph={
                <FlexibleXYPlot
                  xType="ordinal"
                  fill="#ff7843"
                  stroke="#ff7843"
                  stackBy="y"
                  // yDomain={[0, monthlyAvgPayoffTimeMax]}
                >
                  <VerticalGridLines />
                  <HorizontalGridLines />
                  <XAxis />
                  <YAxis title={`Average Payoff Time`} />
                  <VerticalBarSeries
                    data={this.filterMonthlyAvgData(monthlyAvgPayoffTimeData)}
                    barWidth={0.35}
                    onValueMouseOver={v => {
                      this.setState({ payoffTimeCell: v });
                    }}
                    onValueMouseOut={() =>
                      this.setState({ payoffTimeCell: false })
                    }
                  />
                  {payoffTimeCell && (
                    <Hint
                      value={payoffTimeCell}
                      align={{
                        horizontal: Hint.ALIGN.AUTO,
                        vertical: Hint.ALIGN.BOTTOM
                      }}
                    >
                      <div className="rv-hint__content">{`${payoffTimeCell.y} months`}</div>
                    </Hint>
                  )}
                </FlexibleXYPlot>
              }
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Pay"
              icon={<Language />}
              tableData={vaultPayData.map(val => [val.x, val.y, val.title])}
              isLoading={isLoading}
              hover={true}
            />
          </StyledGridItem>
        </StyledGridContainer>

        <StyledGridContainer>
          <StyledGridItem xs={12}>
            <TableGraph
              title="Visitor Stats"
              icon={<Assessment />}
              tableData={visitorStats.map(val => [val.x, val.y, val.title])}
              isLoading={isLoading}
              hover={true}
            />
          </StyledGridItem>
        </StyledGridContainer>

        {hasTableData(topUserContentStats) && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Terrain />}
                  title="Top Content"
                  color="info"
                  headerTooltip="Top 3 page ranked by where employees spent the most time."
                />

                <CardBody>
                  <StyledGridItem xs>
                    <Table
                      tableData={topUserContentStats.map(val => [
                        val.pageTitle ? val.pageTitle : "-",
                        val.pagePath ? val.pagePath : "-",
                        val["calcMetric:avg_engagement"]
                          ? val["calcMetric:avg_engagement"] + " mins"
                          : "0 min"
                      ])}
                      tableHead={[
                        "Page Title",
                        "Page Path",
                        "Avg. time of usage per customer"
                      ]}
                    />
                  </StyledGridItem>
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}

        {hasTableData(topUserCalculatorStats) && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Subject />}
                  title="Top Calculators"
                  color="info"
                  headerTooltip="Calculators ranked by where customer spent the most time."
                />

                <CardBody>
                  <StyledGridItem xs>
                    <Table
                      tableData={topUserCalculatorStats.map(val => [
                        val.pageTitle ? val.pageTitle : "-",
                        val["calcMetric:avg_engagement"]
                          ? val["calcMetric:avg_engagement"] + " mins"
                          : "0 min"
                      ])}
                      tableHead={["Type", "Avg. time of usage per customer"]}
                    />
                  </StyledGridItem>
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}
        {hasTrafficdata(topTrafficSource) && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Traffic />}
                  title="Traffic Source"
                  color="info"
                  headerTooltip="# of visits coming from the particular traffic source (% of the total visits)."
                />

                <CardBody>
                  <StyledGridItem xs>
                    <Table
                      tableData={topTrafficSource.map(val => [
                        val.session_source ? val.session_source : "-",
                        val.session_medium ? val.session_medium : "-",
                        val.absolute
                          ? val.absolute + " visits" + " (" + val.percent + "%)"
                          : "0"
                      ])}
                      tableHead={["Source", "Medium", "Visits"]}
                    />
                  </StyledGridItem>
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}

        {journeyTableStats && (
          <StyledGridContainer>
            {Object.keys(journeyTableStats).map(key => {
              const section = journeyTableStats[key];
              return (
                <StyledGridItem xs={12} sm={6} key={key}>
                  <Card>
                    <VaultCardHeaderIcon
                      icon={<FlightTakeoff />}
                      title={section.title}
                      color="info"
                      headerTooltip=""
                    />
                    <CardBody>
                      <StyledGridItem xs>
                        <Table
                          tableData={section.journeys.map(val => [
                            val.descriptor,
                            val.first_step || "-",
                            val.first_step_customers !== null
                              ? val.first_step_customers
                              : "-",
                            val.last_step || "-",
                            val.last_step_customers !== null
                              ? val.last_step_customers
                              : "-",
                            val.percent !== undefined ? val.percent + " %" : "-"
                          ])}
                          tableHead={[
                            "Journey",
                            "Initial Step",
                            "#",
                            "Final Step",
                            "#",
                            "Success Rate"
                          ]}
                        />
                      </StyledGridItem>
                    </CardBody>
                  </Card>
                </StyledGridItem>
              );
            })}
          </StyledGridContainer>
        )}

        {showFiveTwoNineReport === true && (
          <StyledGridContainer>
            <StyledGridItem xs={12}>
              <Card>
                <VaultCardHeaderIcon
                  icon={<Language />}
                  title="529"
                  color="info"
                />

                <CardBody>
                  <StyledGridItem xs>
                    {clicksData.length <= 1 ? (
                      <Table
                        tableData={fiveTwoNinePlaceholder.map(val => [
                          val.x,
                          val.y
                        ])}
                      />
                    ) : (
                      <>
                        <Table
                          tableData={clicksData
                            .filter(val => !val.x.startsWith("Number of"))
                            .map(val => [val.x, val.y])}
                        />
                        <div style={{ cursor: "pointer" }}>
                          <Table
                            clickHandler={() =>
                              this.setState(prevState => ({
                                showClicks: !prevState.showClicks
                              }))
                            }
                            tableData={clicksData
                              .filter(val => val.x.startsWith("Number of"))
                              .map(val => [val.x, val.y])}
                          />
                        </div>
                      </>
                    )}
                  </StyledGridItem>
                  <br></br>
                  {this.state.showClicks ? (
                    <StyledGridContainer justify="space-between">
                      {regClicksData.map((e, i) => {
                        return (
                          <StyledGridItem key={i} xs>
                            <Table tableData={e.map(val => [val.x, val.y])} />
                          </StyledGridItem>
                        );
                      })}
                    </StyledGridContainer>
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </StyledGridItem>
          </StyledGridContainer>
        )}
      </React.Fragment>
    );
  }
}

Stats.propTypes = {
  partnerStats: PropTypes.shape({
    pay_benefit: PropTypes.object,
    advisor: PropTypes.object,
    employers: PropTypes.object
  }),
  isLoading: PropTypes.bool.isRequired
};

Stats.defaultProps = {
  partnerStats: {
    pay_benefit: {},
    advisor: {},
    employers: {}
  }
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return {
    entityIdentifiers,
    registeredClicksDataLoading: state.partnerRegClicksStats.isFetching,
    registeredClicksData: state.partnerRegClicksStats.data,
    partnersDetails: state.partnersDetails.data,
    partnerUserStatsDetails: state.partnerUserStats.data,
    partnerJourneyStatsDetails: state.partnerJourneyStats.data
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchPartnerRegClick: fetchPartnerRegClickAction,
      fetchPartnersDetails: fetchPartnersDetailsAction,
      fetchPartnerStats: fetchPartnerStatsAction,
      fetchPartnerUserStats: fetchPartnerUserStatsAction,
      fetchPartnerJourneyStats: fetchPartnerJourneyStatsAction
    }
  )
);
export default enhance(Stats);
