import { USER_CHECK_SUCCESS, USER_LOGIN_SUCCESS } from "./authActions";

const defaultState = {
  userName: null,
  userRole: null,
  entity: null,
  entityID: null,
  userID: null,
  isAuthenticated: false
};

export const auth = (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case USER_LOGIN_SUCCESS:
    case USER_CHECK_SUCCESS:
      return {
        ...previousState,
        ...payload,
        isAuthenticated: true
      };
    default:
      return previousState;
  }
};

export default auth;
