import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEmpty, isEqual, get } from "lodash";

import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableFooter
} from "material-ui/Table";
import CircularProgress from "material-ui/CircularProgress";

import { PrevNextPagination } from "./";
import { SORT_ASC, SORT_DESC, PER_PAGE } from "../constants";
import { filterNullChildren } from "../helpers";

/**
 * SLGList
 *
 * Handles the render / fetching / pagination / sorting of tables.
 * Must be used with the accompanying SLGTableField component
 * Props:
 *  - fetchData (Will be used to fetch data from the api, with sort / pagination passed in as a param)
 *  - params to apply to your fetch data function (Don't pass in params to fetch without params)
 *  - total
 *  - data (Actual data being rendered in the list)
 *
 * @example
 * // Function for fetching data
 * const fetchData = params => {
 *   return fetchAdminUsersList.apply(null, [...params]);
 * };
 *
 *   <SLGList fetchData={fetchData} data={adminUsers}>
 *     <SLGTableColumn
 *       field="name"
 *       title="Name"
 *       sortable={true}
 *       render={val => `${val.first_name} ${val.last_name}`}
 *     />
 *     <SLGTableColumn field="email" title="Email" sortable={true} />
 *     <SLGTableColumn
 *       field="active"
 *       title="Active"
 *       sortable={true}
 *       rowStyle={styles.rowField}
 *       headerStyle={styles.columnHeader}
 *       render={val =>
 *         !val.active ? "Yes" : <span style={styles.inactive}>No</span>
 *       }
 *   </SLGList>
 */

export class SLGList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: props.sort,
      page: 1
    };
  }

  componentDidMount() {
    if (this.shouldFetch(this.props.params)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(prevState.sort, this.state.sort) ||
      prevState.page !== this.state.page
    ) {
      this.fetchData();
    }

    if (!isEqual(prevProps.sort, this.props.sort)) {
      this.setState({ sort: this.props.sort });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { params } = this.props;
    if (
      this.shouldFetch(nextProps.params) &&
      !isEqual(nextProps.params, params)
    ) {
      this.fetchData(nextProps.params);
    }
  }

  fetchData(params = this.props.params) {
    return this.props.fetchData(...params, this.state.sort, this.state.page);
  }

  shouldFetch(params) {
    return params && params.length > 0;
  }

  setSort = event => {
    event.stopPropagation();
    const sortField = event.currentTarget.dataset.sort;
    // Default to descending
    let sortOrder = SORT_DESC;
    if (this.state.sort.field === sortField) {
      // If we're sorting the same field, flip sort
      sortOrder = this.state.sort.order === SORT_DESC ? SORT_ASC : SORT_DESC;
    }
    this.setState({ sort: { field: sortField, order: sortOrder } });
  };

  setPage = pageNumber => {
    this.setState({ page: pageNumber });
  };

  generateTableRows(data, children) {
    if (isEmpty(data)) {
      return (
        <TableFooter>
          <TableRow>
            <TableRowColumn>{this.props.noDataText}</TableRowColumn>
          </TableRow>
        </TableFooter>
      );
    }
    return (
      <TableBody displayRowCheckbox={false} stripedRows={true}>
        {data.map((item, index) => {
          return (
            <TableRow key={item.id || index}>
              {React.Children.map(children, (val, index) => {
                return (
                  <TableRowColumn key={index} style={val.props.rowStyle}>
                    {val.props.render
                      ? val.props.render(item, index)
                      : get(item, `${val.props.field}`, "")}
                  </TableRowColumn>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    );
  }

  render() {
    const {
      data,
      children,
      total,
      tableStyle,
      isLoading,
      paginate
    } = this.props;
    const { page, sort } = this.state;

    const setSort = this.setSort;
    const cleanChildren = filterNullChildren(children);
    const tableRows = this.generateTableRows(data, cleanChildren);

    // TODO: Should be a presentational component
    return isLoading ? (
      <div>
        <Table style={tableStyle} fixedHeader={false} selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              {React.Children.map(cleanChildren, element => {
                return React.cloneElement(element, {
                  updateSort: setSort,
                  currentSort: sort,
                  isLoading
                });
              })}
            </TableRow>
          </TableHeader>
        </Table>
        <CircularProgress size={40} />
      </div>
    ) : (
      <div>
        <Table style={tableStyle} fixedHeader={false} selectable={false}>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              {React.Children.map(cleanChildren, element => {
                return React.cloneElement(element, {
                  updateSort: setSort,
                  currentSort: sort,
                  isLoading
                });
              })}
            </TableRow>
          </TableHeader>
          {tableRows}
        </Table>
        {paginate && (
          <PrevNextPagination
            page={page}
            perPage={PER_PAGE}
            total={total}
            setPage={this.setPage}
          />
        )}
      </div>
    );
  }
}

SLGList.propTypes = {
  fetchData: PropTypes.func,
  params: PropTypes.array,
  total: PropTypes.number,
  data: PropTypes.array.isRequired,
  tableStyle: PropTypes.object,
  noDataText: PropTypes.string,
  isLoading: PropTypes.bool,
  paginate: PropTypes.bool,
  sort: PropTypes.shape({ field: PropTypes.string, order: PropTypes.string })
};

SLGList.defaultProps = {
  data: [],
  tabelStyle: {},
  fetchData: () => {},
  isLoading: false,
  paginate: true,
  sort: { field: "created_at", order: SORT_DESC }
};

export default SLGList;
