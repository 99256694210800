import * as R from "ramda";

export const log = msg => R.tap(x => console.log(msg, x));
const parsePage = R.ifElse(
  R.has("pagination"),
  R.pipe(
    R.prop("pagination"),
    R.applySpec({
      page: {
        limit: R.prop("limit"),
        number: R.prop("page")
      }
    })
  ),
  R.identity
);
export default R.converge(R.merge, [R.dissoc("pagination"), parsePage]);
