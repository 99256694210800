import * as R from "ramda";

export const log = msg => R.tap(x => console.log(msg, x));
const isArray = arr => R.type(arr) === "Array";
const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
const getData = R.path(["data", "data"]);
const getMeta = R.path(["data", "meta"]);
const noData = R.pipe(
  getData,
  isNilOrEmpty
);
const isDataAnArray = R.pipe(
  getData,
  isArray
);

export const flattenAttributes = data => ({
  ...data,
  ...data.attributes,
  ...data.relationships
});
export const mergeRelationshipItem = includedIndexById =>
  R.pipe(
    R.prop("id"),
    id => R.prop(id, includedIndexById),
    R.ifElse(R.prop("id"), flattenAttributes, R.always({}))
  );
export const handleRelationshipObjectItem = includedIndexById =>
  mergeRelationshipItem(includedIndexById);
export const handleRelationshipArrayItem = includedIndexById =>
  R.map(mergeRelationshipItem(includedIndexById));
export const mergeRelationshipItemData = includedIndexById =>
  R.pipe(
    R.prop("data"),
    R.ifElse(
      isArray,
      handleRelationshipArrayItem(includedIndexById),
      handleRelationshipObjectItem(includedIndexById)
    )
  );
export const handleRelationshipItem = includedIndexById =>
  R.ifElse(
    isNilOrEmpty,
    R.identity,
    mergeRelationshipItemData(includedIndexById)
  );
export function mergeRelationships(included) {
  const includedIndexById = R.indexBy(R.prop("id"), included);
  return R.evolve({
    relationships: R.pipe(
      R.map(handleRelationshipItem(includedIndexById)),
      R.ifElse(isArray, R.always([]), R.identity)
    )
  });
}
export const handleObjectData = response => ({
  data: {
    data: flattenAttributes(
      mergeRelationships(response.data.included)(response.data.data)
    )
  }
});

export const createHandleListDataItem = response => item =>
  response.data.included
    ? flattenAttributes(mergeRelationships(response.data.included)(item))
    : { ...item, ...item.attributes };
export const handleListData = response => {
  const handleListDataItem = createHandleListDataItem(response);
  return R.applySpec({
    data: {
      data: R.pipe(
        getData,
        R.map(handleListDataItem)
      ),
      meta: getMeta
    }
  })(response);
};

const handleData = R.ifElse(isDataAnArray, handleListData, handleObjectData);

export default R.pipe(R.cond([[noData, R.identity], [R.T, handleData]]));
