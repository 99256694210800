import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

import { openModal } from "../../app/uiActions";
import { allowToSupportRep } from "services/permission/service";
import GroupActions from "../../components/layouts/list/Actions";
import RejectedButton from "../../components/buttons/action/Rejected";
import ApproveButton from "../../components/buttons/action/Approve";
import InfoButton from "../../components/buttons/action/Info";
import { TRApproveModal } from "../components/modals/TRApproveModal";
import { TRRejectModal } from "../components/modals/TRRejectModal";
import { TRNeedMoreInfoModal } from "../components/modals/TRNeedMoreInfoModal";

const ActionsCell = ({ handleClose, openModal, ...trData }) => {
  const handleApproveClick = () => {
    handleClose();
    openModal(<TRApproveModal trData={trData} />);
  };
  const handleNeedMoreInfoClick = () => {
    handleClose();
    openModal(<TRNeedMoreInfoModal trData={trData} />);
  };
  const handleRejectClick = () => {
    handleClose();
    openModal(<TRRejectModal trData={trData} />);
  };
  return (
    <GroupActions>
      {allowToSupportRep() &&
        (trData.status === "pending" ? (
          <>
            <ApproveButton onClick={handleApproveClick}>Approve</ApproveButton>
            <InfoButton onClick={handleNeedMoreInfoClick}>
              Need More Info
            </InfoButton>
            <RejectedButton onClick={handleRejectClick}>
              Rejected
            </RejectedButton>
          </>
        ) : (
          ""
        ))}
    </GroupActions>
  );
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(ActionsCell);
