import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BaseCardHeader from "@material-ui/core/CardHeader";

import { HeaderProps } from "./types";
import CardTitle from "../../typography/CardTitle";

const useStyles = makeStyles(theme => ({
  root: {
    height: "20px",
    backgroundColor: theme.palette.primary.main
  }
}));

function Header({ title, disabled, actionSlot }) {
  const classes = useStyles();

  return (
    <BaseCardHeader
      className={classes.root}
      disableTypography
      title={<CardTitle>{title}</CardTitle>}
      action={actionSlot && actionSlot({ disabled })}
    />
  );
}

Header.propTypes = HeaderProps;

export default Header;
