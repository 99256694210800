import React from "react";
import styled from "styled-components";

const StyledText = styled.p`
  font-size: 0.875rem;
  text-align: ${props => props.align || "left"};
`;

const CardSubTitleText = props => {
  return <StyledText {...props} />;
};

export default CardSubTitleText;
