import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const styles = {
  menuItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
    cursor: "pointer"
  },
  selectedMenuItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
    cursor: "pointer",
    backgroundColor: "white"
  },
  link: {
    textDecoration: "none",
    marginTop: ".2em"
  },
  icon: {
    height: 40,
    width: 40,
    color: "white"
  },
  selectedIcon: {
    height: 40,
    width: 40,
    color: "#00acc1"
  },
  dividerStyle: {
    marginTop: "12px",
    width: "75%",
    marginLeft: "10px",
    backgroundColor: "white"
  },
  label: { textAlign: "center", color: "white", width: "100%" },
  selectedLabel: { textAlign: "center", color: "#00acc1", width: "100%" }
};

export const MenuItem = props => {
  const { linkTo, onClick, icon, label, isSelected } = props;

  const menuItemStyle = isSelected()
    ? styles.selectedMenuItem
    : styles.menuItem;
  const iconStyle = isSelected() ? styles.selectedIcon : styles.icon;
  const labelStyle = isSelected() ? styles.selectedLabel : styles.label;

  return (
    <Link to={linkTo} style={styles.link} onClick={onClick}>
      <div style={menuItemStyle}>
        {icon && React.createElement(icon, { style: iconStyle })}
        <span style={labelStyle}>{label}</span>
      </div>
    </Link>
  );
};

MenuItem.propTypes = {
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.func,
  label: PropTypes.string,
  isSelected: PropTypes.func
};

MenuItem.defaultProps = {
  linkTo: "",
  onClick: () => {},
  isSelected: () => false,
  label: ""
};

export default MenuItem;
