import React from "react";
import * as R from "ramda";
import Grid from "@material-ui/core/Grid";

import CheckboxField from "../../components/inputs/checkbox/Field";
import NameField from "../../components/inputs/Name";
import StateSelectField from "../../components/inputs/StateSelect";
import MonthSelectField from "../../components/inputs/MonthSelect";
import YearSelectField from "../../components/inputs/YearSelect";
import IntervalSelectField from "../../components/inputs/IntervalSelect";

const noRule = {};
const payDisabledText = `Contributions are currently being processed. Cannot update Pay
Settings at this time.`;
const payContributionText = "Contributions sent on the 9th of the month";

function renderPayFields({ defaultValues, hasLockedGroups, ...fieldProps }) {
  const disabled = hasLockedGroups;

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <MonthSelectField
          data-test-id="contribution_date.month"
          name="contribution_date.month"
          label="Start Month"
          defaultValue={R.pathOr(
            "",
            ["contribution_date.month"],
            defaultValues
          )}
          {...fieldProps}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <YearSelectField
          data-test-id="contribution_date.year"
          name="contribution_date.year"
          label="Start Year"
          defaultValue={R.pathOr("", ["contribution_date.year"], defaultValues)}
          {...fieldProps}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={4}>
        <IntervalSelectField
          data-test-id="payroll_cycle"
          name="payroll_cycle"
          label="Interval"
          defaultValue={defaultValues.payroll_cycle}
          {...fieldProps}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

export default function BillingForm({
  defaultValues,
  hasLockedGroups,
  loading,
  ...fieldProps
}) {
  const [enablePay, setEnablePay] = React.useState(defaultValues.pay_enabled);

  function handlePayChange([_, v]) {
    setEnablePay(v);
    return v;
  }

  return (
    <Grid container spacing={0} direction="column">
      <Grid item>
        <NameField
          name="billing_address.street_1"
          label="Address 1"
          defaultValue={R.pathOr(
            "",
            ["billing_address.street_1"],
            defaultValues
          )}
          {...fieldProps}
        />
      </Grid>
      <Grid item>
        <NameField
          name="billing_address.street_2"
          label="Address 2"
          required={false}
          defaultValue={R.pathOr(
            "",
            ["billing_address.street_2"],
            defaultValues
          )}
          {...fieldProps}
          rules={noRule}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={2} direction="row">
          <Grid item xs={5}>
            <NameField
              name="billing_address.city"
              label="City"
              defaultValue={R.pathOr(
                "",
                ["billing_address.city"],
                defaultValues
              )}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={4}>
            <StateSelectField
              data-test-id="billing_address.state"
              name="billing_address.state"
              defaultValue={R.pathOr(
                "",
                ["billing_address.state"],
                defaultValues
              )}
              {...fieldProps}
            />
          </Grid>
          <Grid item xs={3}>
            <NameField
              name="billing_address.zip_code"
              label="Zip"
              defaultValue={R.pathOr(
                "",
                ["billing_address.zip_code"],
                defaultValues
              )}
              {...fieldProps}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CheckboxField
          name="pay_enabled"
          label="Pay"
          onChange={handlePayChange}
          defaultValue={defaultValues.pay_enabled}
          {...fieldProps}
          disabled={hasLockedGroups}
          helperText={
            hasLockedGroups
              ? payDisabledText
              : enablePay
              ? payContributionText
              : null
          }
        />
      </Grid>
      {enablePay
        ? renderPayFields({ defaultValues, hasLockedGroups, ...fieldProps })
        : null}
    </Grid>
  );
}
