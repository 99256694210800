import { keyBy, map, concat, includes } from "lodash";

import {
  SET_SELECTED_PARTNER,
  FETCH_PARTNER,
  FETCH_PARTNER_FAILURE,
  FETCH_PARTNER_SUCCESS,
  FETCH_PARTNERS,
  FETCH_PARTNERS_SUCCESS,
  FETCH_PARTNERS_FAILURE,
  EDIT_PARTNER_SUCCESS,
  CREATE_PARTNER_SUCCESS,
  TOGGLE_PARTNER_REFI_VENDOR_SUCCESS,
  FETCH_PARTNER_STATS,
  FETCH_PARTNER_STATS_SUCCESS,
  FETCH_PARTNER_STATS_FAILURE,
  FETCH_PARTNER_REG_CLICK,
  FETCH_PARTNER_REG_CLICK_SUCCESS,
  FETCH_PARTNER_REG_CLICK_FAILURE,
  FETCH_PARTNERS_DETAILS,
  FETCH_PARTNERS_DETAILS_SUCCESS,
  FETCH_PARTNERS_DETAILS_FAILURE,
  FETCH_PARTNER_USER_STATS,
  FETCH_PARTNER_USER_STATS_FAILURE,
  FETCH_PARTNER_USER_STATS_SUCCESS,
  FETCH_PARTNER_JOURNEY_STATS,
  FETCH_PARTNER_JOURNEY_STATS_FAILURE,
  FETCH_PARTNER_JOURNEY_STATS_SUCCESS,

} from "./partnerActions";
import { PARTNER_ID } from "../constants";
import { entityIdentifiers } from "services/formatters";

export const defaultState = {
  partners: {
    byID: {},
    items: [],
    selectedID: localStorage.getItem(PARTNER_ID),
    entityIdentifiers: {
      employerIdentifier: "Employer",
      employeeIdentifier: "Employee"
    },
    isFetching: false,
    receivedAt: null,
    total: 0
  },
  partnerStats: {
    byPartnerID: {},
    isFetching: false,
    receivedAt: null
  },
  partnerRegClicksStats: {
    data: null,
    isFetching: false,
  },
  partnerUserStats: {
    data: null,
    isFetching: false,
  },
  partnerJourneyStats: {
    data: null,
    isFetching: false,
  },
  partnersDetails:{
    data: null,
    isFetching: false,
  }
};

export const partners = (
  previousState = defaultState.partners,
  { type, payload }
) => {
  let partner;

  switch (type) {
    case FETCH_PARTNERS:
      return {
        ...previousState,
        isFetching: true
      };
    case FETCH_PARTNERS_SUCCESS:
      const partnersById = keyBy(payload.partners.data, "id");
      partner = partnersById[payload.defaultPartnerID];
      return {
        ...previousState,
        byID: keyBy(payload.partners.data, "id"),
        items: map(payload.partners.data),
        isFetching: false,
        total: payload.partners.total,
        receivedAt: Date.now(),
        selectedID: payload.defaultPartnerID,
        entityIdentifiers: entityIdentifiers({ partnerName: partner.name })
      };
    case FETCH_PARTNERS_FAILURE:
      return {
        ...previousState,
        isFetching: false
      };
    case FETCH_PARTNER:
      return {
        ...previousState,
        isFetching: true
      };
    case FETCH_PARTNER_SUCCESS:
      let updatedIdentifiers = previousState.entityIdentifiers;
      if (previousState.selectedID === payload.id) {
        updatedIdentifiers = entityIdentifiers({ partnerName: payload.name });
      }
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: includes(previousState.items, payload.id)
          ? previousState.items
          : concat(previousState.items, payload.id),
        isFetching: false,
        entityIdentifiers: updatedIdentifiers
      };
    case FETCH_PARTNER_FAILURE:
      return {
        ...previousState,
        isFetching: false
      };
    case EDIT_PARTNER_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload }
      };
    case CREATE_PARTNER_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload }
      };
    case SET_SELECTED_PARTNER:
      const partnerId = payload.partnerID;
      partner = previousState.byID[partnerId];
      const partnerName = partner ? partner.name : "";
      return {
        ...previousState,
        selectedID: payload.partnerID,
        entityIdentifiers: entityIdentifiers({ partnerName })
      };
    case TOGGLE_PARTNER_REFI_VENDOR_SUCCESS:
      return {
        ...previousState,
        byID: {
          ...previousState.byID,
          [payload.id]: payload
        }
      };
    default:
      return previousState;
  }
};

export const partnerStats = (
  previousState = defaultState.partnerStats,
  { type, payload }
) => {
  switch (type) {
    case FETCH_PARTNER_STATS:
      return {
        ...previousState,
        isFetching: true
      };
    case FETCH_PARTNER_STATS_SUCCESS:
      return {
        ...previousState,
        byPartnerID: {
          ...previousState.byPartnerID,
          [payload.partnerID]: payload.stats
        },
        isFetching: false,
        receivedAt: Date.now()
      };
    case FETCH_PARTNER_STATS_FAILURE:
      return {
        ...previousState,
        isFetching: false
      };
    default:
      return previousState;
  }
};

export const partnerRegClicksStats = (
  previousState = defaultState.partnerRegClicksStats,
  { type, payload }
) => {
  switch (type) {
    case FETCH_PARTNER_REG_CLICK:
      return {
        ...previousState,
        isFetching: true,
      };

    case FETCH_PARTNER_REG_CLICK_SUCCESS:
      return {
        ...previousState,
        data: payload,
        isFetching: false,
        receivedAt: Date.now(),
      };
    case FETCH_PARTNER_REG_CLICK_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

export const partnersDetails= (
  previousState = defaultState.partnersDetails,
  { type, payload }
) => {
  switch (type) {
    case FETCH_PARTNERS_DETAILS:
      return {
        ...previousState,
        isFetching: true,
      };

    case FETCH_PARTNERS_DETAILS_SUCCESS:
      return {
        ...previousState,
        data: payload,
        isFetching: false,
        receivedAt: Date.now(),
      };
    case FETCH_PARTNERS_DETAILS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

export const partnerUserStats = (
  previousState = defaultState.partnerUserStats,
  { type, payload }
) => {
  switch (type) {
    case FETCH_PARTNER_USER_STATS:
      return {
        ...previousState,
        isFetching: true,
      };

    case FETCH_PARTNER_USER_STATS_SUCCESS:

      return {
        ...previousState,
        data: payload,
        isFetching: false,
        // receivedAt: Date.now(),
      };
    case FETCH_PARTNER_USER_STATS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};

export const partnerJourneyStats = (
  previousState = defaultState.partnerJourneyStats,
  { type, payload }
) => {
  switch (type) {
    case FETCH_PARTNER_JOURNEY_STATS:
      return {
        ...previousState,
        isFetching: true,
      };

    case FETCH_PARTNER_JOURNEY_STATS_SUCCESS:

      return {
        ...previousState,
        data: payload,
        isFetching: false,
        // receivedAt: Date.now(),
      };
    case FETCH_PARTNER_JOURNEY_STATS_FAILURE:
      return {
        ...previousState,
        isFetching: false,
      };
    default:
      return previousState;
  }
};
