import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import DialogContentText from "@material-ui/core/DialogContentText";

/** legacy code, will remove once new form is done */
import { openModal as openModalAction } from "../../app/uiActions";
import ConfirmationDialog from "../../components/modals/ConfirmationDialog";

import { allowToEmployerManager } from "../../services/permission/service";
import GroupActions from "../../components/layouts/list/ActionsCell";
import EditButton from "../../components/buttons/action/Edit";
import DeleteButton from "../../components/buttons/action/Delete";
import { deleteRequest } from "../actions/contactActions";
import EditModal from "./EditModal";

function ActionsCell({
  openModal,
  employerId,
  deleteRequest,
  stateProps: { requestData },
  ...contact
}) {
  function handleEditClick() {
    function handleSuccess() {
      requestData();
    }

    openModal(
      <EditModal
        name="editEmployerContact"
        employerId={employerId}
        contact={contact}
        onSuccess={handleSuccess}
      />
    );
  }

  function handleDeleteConfirmation() {
    function handleDelete() {
      function handleSuccess() {
        requestData();
      }

      deleteRequest(contact.type, employerId, contact.id, handleSuccess);
    }

    openModal(
      <ConfirmationDialog
        title="Delete Contact"
        isWarning={true}
        submitButtonProps={{
          label: "Delete",
          color: "warning",
          onClick: handleDelete
        }}
        content={<DialogContentText>Are you sure...</DialogContentText>}
      />
    );
  }

  const disabled = contact.type === "admin_user";
  return (
    <GroupActions>
      {allowToEmployerManager() && (
        <>
          <EditButton disabled={disabled} onClick={handleEditClick}>
            Edit
          </EditButton>
          <DeleteButton disabled={disabled} onClick={handleDeleteConfirmation}>
            Delete
          </DeleteButton>
        </>
      )}
    </GroupActions>
  );
}

export default compose(
  connect(
    null,
    {
      openModal: openModalAction,
      deleteRequest
    }
  )
)(ActionsCell);
