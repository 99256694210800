import React from "react";
import { pathOr, propOr } from "ramda";
import Paper from "@material-ui/core/Paper";
import { centsToMoneyString } from "services/formatters";
import justifyAround from "components/layouts/justifyAround";
import alignCenter from "components/layouts/alignCenter";
import layHorizontal from "components/layouts/layHorizontal";
import Grid from "components/layouts/Grid";
import GroupActions from "components/layouts/list/Actions";
import Typography from "components/typography/Typography";
import withFontSize from "components/typography/withFontSize";
import ActionsCell from "./ActionsCell";

const Group = layHorizontal(justifyAround(alignCenter(Grid)));
const Text = withFontSize(18)(Typography);
const formatTotal = v =>
  centsToMoneyString(
    propOr(0, "total_pdrs_cents", v) + propOr(0, "total_fees_cents", v)
  );

const styles = { padding: "20px" };

function Summary({ invoice, lineItems, exclusions }) {
  return (
    <Paper style={styles}>
      <Group>
        <Text>Status: {pathOr("", ["status"], invoice)}</Text>
        <Text>Total Due: {formatTotal(invoice)}</Text>
        <Text>Total Line Items: {lineItems.length}</Text>
        <Text>Total Exclusions: {exclusions.length}</Text>
        <GroupActions>
          <Text>Download:</Text>
          <ActionsCell value={invoice} />
        </GroupActions>
      </Group>
    </Paper>
  );
}

export default Summary;
