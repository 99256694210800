import BaseRequest from "../../shared/BaseRequest";

export default class UpdateContactRequest extends BaseRequest {
  method = "put";

  constructor({
    url,
    contactId,
    first_name,
    last_name,
    email,
    job_title,
    phone
  }) {
    super({ url });

    this.data = {
      data: {
        type: "derived_contacts",
        id: contactId,
        attributes: {
          first_name,
          last_name,
          job_title,
          phone,
          email
        }
      }
    };
  }
}
