export default class BaseRequest {
  constructor({ url, method, params, options }) {
    this.url = url;
    this.method = method || "GET";
    this.options = options;
    this.data = {};
    this.params = {};

    this._init({ params });
  }

  _init({ params }) {
    this._createPaginationQuery(params);
    this._createSortQuery(params);
    this._createFilterQuery(params);
    this._createFieldsQuery(params);
  }

  _createPaginationQuery(params) {
    if (!params || !params.pagination) return params;

    const { pagination } = params;
    const page = {};
    if (pagination.limit) page.limit = pagination.limit;
    if (pagination.page) page.number = pagination.page;
    this.params = Object.assign({}, this.params, { page });
  }

  _createSortQuery(params) {
    if (!params || !params.sort) return params;

    const { field, order } = params.sort;
    this.params.sort = `${order.toUpperCase() === "ASC" ? "" : "-"}${field}`;
  }

  _createFilterQuery(params) {
    if (!params || !params.filter) return;

    const { filter } = params;
    filter.forEach((filterObj, i) => {
      Object.keys(filterObj).forEach(key => {
        let filterField = `filter[${i}][${key}]`;
        this.params[filterField] = filterObj[key];
      });
    });
  }

  _createFieldsQuery(params) {
    if (!params || !params.fields) return params;

    const { fields } = params;
    this.params.fields = fields;
  }

  setData(data) {
    this.data = data;
  }

  setMethod(method) {
    this.method = method;
  }
}
