import React from "react";
import AppBar from "@material-ui/core/AppBar";
import BaseTabs from "@material-ui/core/Tabs";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";

import { TabsProps } from "./types";
import Grid from "../layouts/Grid";
import layHorizontal from "../layouts/layHorizontal";
import justifyBetween from "../layouts/justifyBetween";
import alignCenter from "../layouts/alignCenter";
import Tab from "./Tab";
import withPrimary from "./withPrimary";

const TabsBar = withPrimary(BaseTabs);
const GroupTabs = alignCenter(justifyBetween(layHorizontal(Grid)));

const createRenderTab = () => (props, i) => <Tab key={i} {...props} />;
const createRenderTabContent = ({ value }) => children => {
  return React.Children.toArray(children)[value];
};

function Tabs({
  initialTab = 0,
  tabs = [],
  appBarProps = {},
  actionSlot,
  children
}) {
  const [value, setValue] = React.useState(initialTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper>
      <AppBar position="static" {...appBarProps}>
        <GroupTabs>
          <TabsBar value={value} onChange={handleChange}>
            {tabs.map(createRenderTab())}
          </TabsBar>
          {actionSlot && <Toolbar>{actionSlot({ current: value })}</Toolbar>}
        </GroupTabs>
      </AppBar>
      {createRenderTabContent({ value })(children)}
    </Paper>
  );
}

Tabs.propTypes = TabsProps;

export default Tabs;
