import React from "react";
import { compose } from "ramda";

import ListTable from "../tables/list-table/ListTable";
import withSort from "./withSort";
import withSearch from "./withSearch";
import withGetFetch from "./withGetFetch";

export function PageComponent({
  rows,
  total,
  loading,
  defaultSort,
  onSortChange,
  onSearchChange,
  onSearchSubmit,
  ...props
}) {
  return (
    <ListTable
      rows={rows}
      total={total}
      loading={loading}
      defaultSort={defaultSort}
      headerActionHandlers={{
        onSortChange
      }}
      searchFormProps={{
        onChange: onSearchChange,
        onSubmit: onSearchSubmit
      }}
      {...props}
    />
  );
}

export const withProps = extraProps => WC => props => (
  <WC {...props} {...extraProps(props)} />
);

export default compose(
  withSort,
  withSearch,
  withGetFetch
)(PageComponent);
