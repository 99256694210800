import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { get } from "lodash";
import { formValueSelector } from "redux-form";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import EditContent from "material-ui/svg-icons/editor/mode-edit";
import CardActions from "material-ui/Card/CardActions";
import moment from "moment";

import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  PARTNER_MANAGER
} from "../../constants";
import { openModal as openModalAction } from "../../app/uiActions";
import {
  testRequest,
  enableSyncRequest,
  syncNowRequest
} from "../actions/ultiproActions";
import { selectUltiproIntegrations } from "employers/selectors";
import {
  selectTestFetching,
  selectEnableSyncFetching,
  selectSyncNowFetching
} from "../selectors-ext/ultiproSelectors";
import permisionService from "../../rest/permissionService";

import WrapWithLoading from "../../components/loading/WrapWithLoading";
import { SLGCard, InfoField } from "../../components";
import EditModal from "./EditModal";

const styles = {
  container: { display: "flex", flexDirection: "column" },
  columnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%"
  },
  cardTitle: {
    marginBottom: "10px",
    fontSize: "1.5em",
    fontWeight: "bold"
  },
  infoBlockContainer: { display: "flex", flexWrap: "wrap" },
  settingsContainer: { display: "flex", flexDirection: "column" },
  buttonIcon: { height: "20px" },
  editLabel: { color: "#ffffff" },
  buttonColor: "#00acc1"
};

class Page extends React.PureComponent {
  handleTestCredentials = () => {
    const { testRequest, employer, ultiproIntegrations } = this.props;
    const { api_url } = ultiproIntegrations;

    testRequest(employer, api_url);
  };

  handleEnableSync = () => {
    const { enableSyncRequest, employerId, ultiproIntegrations } = this.props;
    const { id } = ultiproIntegrations;

    enableSyncRequest(employerId, id, true);
  };

  handleSyncNow = () => {
    const { syncNowRequest, employerId } = this.props;

    syncNowRequest(employerId);
  };

  enableButtons() {
    const { ultiproIntegrations } = this.props;

    if (!ultiproIntegrations) {
      return false;
    }

    const {
      api_url,
      api_username,
      api_password,
      api_key
    } = ultiproIntegrations;

    return api_url && api_username && api_password && api_key ? true : false;
  }

  render() {
    const {
      employerId,
      ultiproIntegrations,
      openModal,
      testFetching,
      enableSyncFetching,
      syncNowFetching
    } = this.props;

    const allowEdit = permisionService.allowWith([
      SYSTEM_ADMIN,
      PARTNER_ADMIN,
      PARTNER_MANAGER,
      EMPLOYERS_ADMIN,
      EMPLOYER_MANAGER
    ]);

    const credentialsTested =
      ultiproIntegrations &&
      ultiproIntegrations.credentials_successfully_tested_at;
    const enableSyncNow =
      ultiproIntegrations && ultiproIntegrations.sync_enabled;
    const enableButtons = this.enableButtons();
    const disableButtons =
      enableSyncFetching || testFetching || syncNowFetching;

    let syncDate;
    if (get(ultiproIntegrations, "last_successful_sync_at") === null) {
      syncDate = "";
    } else {
      syncDate = moment(
        get(ultiproIntegrations, "last_successful_sync_at")
      ).format("MMM D, YYYY h:mm:ss A");
    }

    return (
      <>
        <SLGCard
          title="ULTIPRO SETTINGS"
          button={
            allowEdit && (
              <>
                <FlatButton
                  label="Edit"
                  labelStyle={{ color: "#ffffff" }}
                  icon={<EditContent color="white" style={styles.buttonIcon} />}
                  onClick={() =>
                    openModal(
                      <EditModal
                        employerId={employerId}
                        ultiproIntegrations={ultiproIntegrations}
                      />
                    )
                  }
                  style={{ overflow: "inherit" }}
                  disabled={disableButtons}
                />
              </>
            )
          }
          flexPercent={50}
          style={{ marginRight: "10px" }}
        >
          <InfoField
            header="Web Base URL"
            value={get(ultiproIntegrations, "api_url")}
          />
          <InfoField
            header="Service Account Username"
            value={get(ultiproIntegrations, "api_username")}
          />
          <InfoField
            header="Service Account Password"
            type="password"
            value={get(ultiproIntegrations, "api_password")}
          />
          <InfoField
            header="Customer API Key"
            value={get(ultiproIntegrations, "api_key")}
          />
          <InfoField
            header="Pre-start Interval"
            value={get(ultiproIntegrations, "prestart_interval")}
          />
          <InfoField header="Last Successful Sync At" value={syncDate} />
          {allowEdit && (
            <CardActions
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div style={styles.columnContainer}>
                <WrapWithLoading loading={syncNowFetching}>
                  <RaisedButton
                    label="Sync Now"
                    labelColor="white"
                    onClick={this.handleSyncNow}
                    backgroundColor={styles.buttonColor}
                    disabled={
                      testFetching ||
                      !credentialsTested ||
                      !enableButtons ||
                      !enableSyncNow
                    }
                  />
                </WrapWithLoading>
              </div>
              <div style={styles.columnContainer}>
                <WrapWithLoading loading={enableSyncFetching}>
                  <RaisedButton
                    label="Enable Sync"
                    labelColor="white"
                    onClick={this.handleEnableSync}
                    backgroundColor={styles.buttonColor}
                    disabled={
                      disableButtons || !credentialsTested || !enableButtons
                    }
                  />
                </WrapWithLoading>
              </div>
              <div style={styles.columnContainer}>
                <WrapWithLoading loading={testFetching}>
                  <RaisedButton
                    label="Test Credentials"
                    labelColor="white"
                    onClick={this.handleTestCredentials}
                    backgroundColor={styles.buttonColor}
                    disabled={disableButtons || !enableButtons}
                  />
                </WrapWithLoading>
              </div>
            </CardActions>
          )}
        </SLGCard>
      </>
    );
  }
}

Page.defaultProps = {
  employer: {}
};

function mapStateToProps(state, props) {
  const employer = props.employer;
  const employerId = props.employerID;
  const ultiproIntegrations = selectUltiproIntegrations(state, employerId);
  const formValues = formValueSelector("ultiproIntegrations")(
    state,
    "api_url",
    "api_username",
    "api_password",
    "api_key"
  );

  const testFetching = selectTestFetching(state);
  const enableSyncFetching = selectEnableSyncFetching(state);
  const syncNowFetching = selectSyncNowFetching(state);

  return {
    employer,
    employerId,
    ultiproIntegrations,
    formValues,
    testFetching,
    enableSyncFetching,
    syncNowFetching
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      openModal: openModalAction,
      testRequest,
      enableSyncRequest,
      syncNowRequest
    }
  )
)(Page);
