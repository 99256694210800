import React from "react";
import * as R from "ramda";

import withPartnerIdFromRouter from "../../components/containers/utils/withPartnerIdFromRouter";
import BaseReportPage from "../../components/reports/ReportPage";
import withProps from "../../components/reports/withProps";
import Form from "../../components/reports/GenerateReportForm";

const createGetReportPath = partnerId =>
  partnerId && `/partners/${partnerId}/reports`;

const createFileDownloadPath = partnerId => row => {
  const reportId = row.id;
  return partnerId && `/partners/${partnerId}/reports/${reportId}/download`;
};

function extraProps(pageProps) {
  const partnerId = pageProps.partnerId;
  function renderForm(formProps) {
    const ReportForm = R.compose(
      withProps(() => ({
        partnerId,
        resource: "partners",
        ...formProps
      }))
    )(Form);

    return <ReportForm />;
  }

  return {
    path: createGetReportPath(partnerId),
    partnerId,
    renderGenerateForm: renderForm,
    createDownloadPathFromRow: createFileDownloadPath(partnerId),
    page: 0,
  };
}
export default R.compose(
  withPartnerIdFromRouter,
  withProps(extraProps)
)(BaseReportPage);
