import { centsToMoneyString } from "services/formatters";
import Panel, { withProps } from "./Panel";

const getRowId = r => r.invoice_line_item_id;
const formatAmount = v => centsToMoneyString(v.amount_cents);
const columns = [
  {
    name: "full_name",
    label: "Name"
  },
  {
    name: "reason",
    label: "Status"
  },
  {
    name: "amount_cents",
    label: "Amount",
    render: formatAmount
  }
];

export default withProps({ getRowId, columns })(Panel);
