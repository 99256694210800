import React from "react";
import PropTypes from "prop-types";
import SLGModalForm from "components/modals/SLGModalForm";
import { GroupForm } from "../forms";

export const GroupCreateModal = ({
  onClose,
  onSuccess,
  employerID,
  employer,
  employerPayEnabled,
  subscriptionEnabled,
  employerFtnEnabled,
  employerTrEnabled
}) => {
  const formName = "groupCreate";
  
  let initialFields = {
    'services_attributes':[
      {'type':'EmployerService::FlatMatch'},
      {'type':'EmployerService::Savings'},
      {'type':'EmployerService::Tuition'}
    ]
  }
  return (
    <SLGModalForm
      title="New Group"
      formName={formName}
      close={onClose}
      contentStyle={{ width: "700px" }}
    >
      <GroupForm
        formName={formName}
        label="Group"
        employerID={employerID}
        employer={employer}
        employerPayEnabled={employerPayEnabled}
        onSuccess={onSuccess}
        subscriptionEnabled={subscriptionEnabled}
        employerFtnEnabled={employerFtnEnabled}
        employerTrEnabled={employerTrEnabled}
        initialValues={initialFields}
      />
    </SLGModalForm>
  );
};

GroupCreateModal.propTypes = {
  employerID: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  employerPayEnabled: PropTypes.bool
};

GroupCreateModal.defaultProps = {
  employerPayEnabled: false
};

export default GroupCreateModal;
