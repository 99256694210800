import { createSelector } from "reselect";
import { selectEmployees, selectEmployeesIDList } from "./helpers";
import { map } from "lodash";

export const selectEmployeesByGroup = createSelector(
  [selectEmployees, selectEmployeesIDList, (_, groupID) => groupID],
  (employees, employeesIDList, groupID) => {
    return map(employeesIDList, val => employees[val]).filter(
      val => groupID === val.group_id
    );
  }
);
