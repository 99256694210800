import createReducer from "../../helpers/createReducer";
import {
  SEND_INVITES_REQUEST,
  SEND_INVITES_SUCCESS,
  SEND_INVITES_FAILURE
} from "../actions/ultiproActions";

const defaultState = {
  sendInvitesFetching: false,
  sendInvitesErrors: null
};

/** sendInvites */
function sendInvitesRequest(state) {
  return Object.assign({}, state, {
    sendInvitesFetching: true,
    sendInvitesErrors: null
  });
}

function sendInvitesSuccess(state) {
  return Object.assign({}, state, {
    sendInvitesFetching: false,
    sendInvitesErrors: null
  });
}
function sendInvitesFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    sendInvitesFetching: false,
    sendInvitesErrors: error
  });
}

export default createReducer(defaultState, {
  [SEND_INVITES_REQUEST]: sendInvitesRequest,
  [SEND_INVITES_SUCCESS]: sendInvitesSuccess,
  [SEND_INVITES_FAILURE]: sendInvitesFailure
});
