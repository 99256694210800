import React from "react";
import PropTypes from "prop-types";

import { TableHeaderColumn } from "material-ui/Table";
import FlatButton from "material-ui/FlatButton";
import ContentSort from "material-ui/svg-icons/content/sort";

import { SORT_ASC } from "../constants";

/**
 * SLGTableColumn
 *
 * SLGTableColumn will deteremine the the structure of the table. Add a SLGTableColumn
 * component for every desired column in the table.
 *
 * Props:
 *  - sortable
 *  - field (key for the data)
 *  - title
 *  - render (Function for handling how the columns data is displayed. Will render the value as a string
 *            if not supplied)
 *  - rowStyle
 *  - headerStyle
 *
 * @example
 *   <SLGList fetchData={fetchData} data={adminUsers}>
 *     <SLGTableColumn
 *       field="active"
 *       title="Active"
 *       sortable={true}
 *       rowStyle={styles.rowField}
 *       headerStyle={styles.columnHeader}
 *       render={val =>
 *         !val.active ? "Yes" : <span style={styles.inactive}>No</span>
 *       }
 *   </SLGList>
 */

const styles = {
  label: { float: "left", paddingLeft: "0px" }
};

export const SLGTableColumn = ({
  title,
  currentSort,
  updateSort,
  sortable,
  field,
  headerStyle,
  headerLabelStyle,
  isLoading
}) => {
  return (
    <TableHeaderColumn style={headerStyle}>
      <FlatButton
        labelPosition="before"
        onClick={updateSort}
        data-sort={field}
        disabled={!sortable || isLoading}
        label={title || field}
        icon={
          field === currentSort.field && sortable ? (
            <ContentSort
              style={
                currentSort.order === SORT_ASC
                  ? { transform: "rotate(180deg)" }
                  : {}
              }
            />
          ) : (
            false
          )
        }
        labelStyle={{ ...styles.label, ...headerLabelStyle }}
      />
    </TableHeaderColumn>
  );
};

SLGTableColumn.propTypes = {
  sortable: PropTypes.bool,
  field: PropTypes.string,
  title: PropTypes.string,
  rowStyle: PropTypes.object,
  headerStyle: PropTypes.object,
  headerLabelStyle: PropTypes.object,
  currentSort: PropTypes.object,
  updateSort: PropTypes.func,
  isLoading: PropTypes.bool
};

SLGTableColumn.defaultProps = {
  sortable: false,
  field: " ",
  rowStyle: {},
  headerStyle: {},
  headerLabelStyle: {},
  isLoading: false
};

export default SLGTableColumn;
