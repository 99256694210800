import BaseRequest from "shared/BaseRequest";

export default class FullFileUploadEmployeesRequest extends BaseRequest {
  method = "post";

  constructor({ url, file }) {
    super({ url });

    const formData = new FormData();
    formData.append("file", file);
    this.data = formData;
  }
}
