import { put, all, call, takeEvery } from "redux-saga/effects";

import log from "../../services/logging/service";
import routeService from "../services/RouteService";
import fetchApiService from "../../rest/fetchApiService";
import { showError } from "../../shared/sagas/notifications";
import {
  GET_REFI_VENDORS_REQUEST,
  TOGGLE_VISIBILITY_REQUEST,
  TOGGLE_REQUEST,
  requestGetRefiVendors,
  getRefiVendorsSuccess,
  getRefiVendorsFailure,
  toggleFailure,
  toggleSuccess,
  toggleVisibilitySuccess,
  toggleVisibilityFailure
} from "../actions/refiVendorActions";
import { fetchEmployer } from "../employerActions";
import GetRefiVendorsRequest from "../services/GetRefiVendorsRequest";
import GetRefiVendorsResponse from "../services/GetRefiVendorsResponse";
import ToggleRefiVendorRequest from "../services/ToggleRefiVendorRequest";
import ToggleRefiVendorResponse from "../services/GetRefiVendorsResponse";
import ToggleVisibilityRequest from "../services/ToggleVisibilityRequest";
import ToggleVisibilityResponse from "../services/ToggleVisibilityResponse";

function* getRefiVendors({ employerId }) {
  try {
    const endpoint = routeService.getRefiVendors(employerId);
    const request = new GetRefiVendorsRequest({ url: endpoint });
    const response = yield fetchApiService.fetch(
      request,
      GetRefiVendorsResponse
    );
    if (response.ok) {
      const data = response.getListData(employerId);

      yield put(getRefiVendorsSuccess(data));
    } else {
      yield call(showError, response.statusText);
      yield put(getRefiVendorsFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getRefiVendorsFailure(e.message));
    log.error(e.message);
  }
}

function* toggleVisibility({ employerId, toggle }) {
  try {
    const endpoint = routeService.update(employerId);
    const request = new ToggleVisibilityRequest({
      url: endpoint,
      employerId,
      toggle
    });
    const response = yield fetchApiService.update(
      request,
      ToggleVisibilityResponse
    );
    if (response.ok) {
      /** update employer to reflect enable_own_refi_vendors */
      yield put(fetchEmployer(employerId));
      /** update employerRefiVendors  */
      yield put(requestGetRefiVendors(employerId));
      yield put(toggleVisibilitySuccess(employerId));
    } else {
      yield put(toggleFailure(employerId, response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(toggleVisibilityFailure(employerId, e.message));
    log.error(e.message);
  }
}

function* toggleVendor({ employerId, vendorableRecordId, vendorId, toggle }) {
  try {
    const endpoint = routeService.toggleVendor(employerId);
    const request = new ToggleRefiVendorRequest({
      url: endpoint,
      vendorableRecordId,
      vendorId,
      toggle
    });

    const response = yield fetchApiService.toggleRefiVendor(
      request,
      ToggleRefiVendorResponse
    );
    if (response.ok) {
      /** refresh employer */
      yield put(fetchEmployer(employerId));
      yield put(requestGetRefiVendors(employerId));
      yield put(toggleSuccess(vendorId));
    } else {
      yield put(toggleFailure(vendorId, response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(toggleFailure(vendorId, e.message));
    log.error(e.message);
  }
}

export default function* sagas() {
  yield all([
    takeEvery(GET_REFI_VENDORS_REQUEST, getRefiVendors),
    takeEvery(TOGGLE_VISIBILITY_REQUEST, toggleVisibility),
    takeEvery(TOGGLE_REQUEST, toggleVendor)
  ]);
}
