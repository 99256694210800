import * as R from "ramda";
import createHandleBlob from "./createHandleBlob";
import createHandleUrl from "./createHandleUrl";
import createHandleStream from "./createHandleStream";

const isBlob = response => response.data instanceof Blob;
export default ({ saveAs, mimeType = "application/xls" }) =>
  R.pipe(
    R.cond([
      [isBlob, createHandleBlob({ saveAs, mimeType })],
      [
        R.pipe(
          R.pathOr("", ["headers", "content-disposition"]),
          R.test(/^attachment;/)
        ),
        createHandleStream({ saveAs, mimeType: "text/csv" })
      ],
      [R.T, createHandleUrl({ saveAs, mimeType })]
    ])
  );
