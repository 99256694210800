import React from "react";

import HookFormContextContainer from "./HookFormContextContainer";

export default WC => props => {
  return (
    <HookFormContextContainer {...props}>
      {renderProps => <WC {...renderProps} />}
    </HookFormContextContainer>
  );
};
