import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import EditForm from "./EditForm";
import { SLGModalForm } from "../../components/modals";
import { makeSelectAdminUsersByEmployer } from "../../adminUsers/selectors";
import { createRequest, updateRequest } from "../actions/contactActions";
import { selectEntityIdentifiers } from "../../partners/selectors";

const styles = {
  modalStyle: { width: "700px" }
};

const findByEmail = (users, email) => {
  const found = users.filter(user => user.email === email);
  return found && found.length ? found[0] : found;
};

class EditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null
    };
  }

  handleEmailChanged = email => {
    this.setState({ email });
  };

  getAdminUser() {
    const { adminUsers } = this.props;
    const { email } = this.state;
    return findByEmail(adminUsers, email);
  }

  isAdminType() {
    const { contact } = this.props;
    const { email } = this.state;

    if (contact && contact.type !== "admin_user") {
      return false;
    } else {
      const adminUser = this.getAdminUser();
      return !email ? true : adminUser && adminUser.id ? true : false;
    }
  }

  isCreate() {
    const { contact } = this.props;
    return !contact || !contact.id;
  }

  handleSubmit = values => {
    const {
      employerId,
      name,
      createRequest,
      updateRequest,
      contact,
      onSuccess,
      formName
    } = this.props;
    const { email } = this.state;

    const isCreate = this.isCreate();
    const adminUser = this.getAdminUser();
    const isAdminType = this.isAdminType();
    const contactType = isAdminType ? "admin" : "light";
    const adminUserId = adminUser && adminUser.id;

    if (!isCreate) {
      updateRequest(
        formName,
        employerId,
        contact.id,
        values.first_name,
        values.last_name,
        email,
        values.job_title,
        values.phone,
        onSuccess
      );
    } else {
      createRequest(
        name,
        contactType,
        adminUserId || "",
        employerId,
        values.first_name || "",
        values.last_name || "",
        email,
        values.job_title || "",
        values.phone || "",
        onSuccess
      );
    }
  };

  render() {
    const {
      employerId,
      name,
      contact,
      onClose,
      entityIdentifiers
    } = this.props;

    const initialFields = contact;
    const isCreate = this.isCreate();
    const isAdminType = this.isAdminType();

    return (
      <SLGModalForm
        title={`${isCreate ? "Create" : "Edit"} Contact`}
        formName={name}
        submit={this.handleSubmit}
        close={onClose}
        contentStyle={styles.modalStyle}
      >
        <EditForm
          formName={name}
          label={`${isCreate ? "Create" : "Edit"} ${
            entityIdentifiers.employerIdentifier
          } Contact`}
          backButtonLabel="Cancel"
          submitButtonLabel="Save"
          enableReinitialize={true}
          isCreate={isCreate}
          initialValues={initialFields}
          wrapperStyle={{ marginTop: "1em" }}
          employerId={employerId}
          onEmailChange={this.handleEmailChanged}
          isAdminType={isAdminType}
        />
      </SLGModalForm>
    );
  }
}

EditModal.propTypes = {
  employerId: PropTypes.string
};

function mapStateToProps(state, props) {
  const employerId = props.employerId;
  const adminUsers = makeSelectAdminUsersByEmployer()(state, employerId);
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    adminUsers,
    entityIdentifiers
  };
}

export default connect(
  mapStateToProps,
  {
    createRequest,
    updateRequest
  }
)(EditModal);
