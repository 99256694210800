import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loading = styled(CircularProgress)`
  && {
    animation: none;
  }
`;

export default Loading;
