import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import getMuiTheme from "material-ui/styles/getMuiTheme";
import Grid from "@material-ui/core/Grid";

import { CustomNotification } from "../components";
import { isCitizens } from "../helpers/utils";
import { CITIZENS_PRIMARY } from "../constants";

const BaseContainer = styled(Grid)`
  flex-grow: 1;
  background: ${props => props.background};
`;

// Base layout for non authed pages, extend as needed
class BaseLayout extends Component {
  render() {
    const { theme, children } = this.props;
    const muiTheme = getMuiTheme(theme);
    const vaultGradient = "linear-gradient(233deg, #ff7843 0%, #a00067 100%)";
    const background = isCitizens() ? CITIZENS_PRIMARY : vaultGradient;

    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <React.Fragment>
          <BaseContainer
            container
            direction="column"
            alignItems="center"
            alignContent="center"
            justify="center"
            spacing={2}
            background={background}
          >
            {children}
          </BaseContainer>
          <CustomNotification />
        </React.Fragment>
      </MuiThemeProvider>
    );
  }
}

BaseLayout.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

export default BaseLayout;
