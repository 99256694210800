import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

/** legacy code, will remove once new form is done */
import { openModal as openModalAction } from "../../app/uiActions";
import EditModal from "./EditModal";

import { allowToEmployerManager } from "../../services/permission/service";
import NewButton from "../../components/buttons/action/New";

function PageAction({
  employerId,
  disabled,
  openModal,
  stateProps: { requestData }
}) {
  function handleClick() {
    function handleSuccess() {
      requestData();
    }
    openModal(
      <EditModal
        name="createEmployerContact"
        employerId={employerId}
        onSuccess={handleSuccess}
      />
    );
  }

  return (
    <>
      {allowToEmployerManager() && (
        <NewButton disabled={disabled} onClick={handleClick}>
          New Contact
        </NewButton>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

export default compose(
  connect(
    mapStateToProps,
    {
      openModal: openModalAction
    }
  )
)(PageAction);
