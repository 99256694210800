import PropTypes from "prop-types";

import { InputProps } from "./text-field/types";
import withProps from "./text-field/withProps";
import TextField from "./text-field/TextField";

const min = {
  value: 10,
  message: "Must be 10 characters or greater"
};

const hasUpper = value =>
  /[A-Z]/.test(value) || "At least one uppercase letter required";

const hasLower = value =>
  /[a-z]/.test(value) || "At least one lowercase letter required";

const hasSpecialChar = value =>
  /[[-_\\.\][?/<~#`!@$%^&*()+=}|:";',>{]/.test(value) ||
  "At least one special character required";

const hasNumber = value =>
  /[0-9]/.test(value) || "At least one number required";

const hasMinCharacter = value => {
  if (value.length >= 10) {
    return true;
  } else {
    return "Must be 10 characters or greater";
  }
};

const validate = ({ shouldValidate }) => ({
  required: "required",
  min: shouldValidate && min,
  validate: shouldValidate && {
    hasUpper,
    hasLower,
    hasSpecialChar,
    hasNumber,
    hasMinCharacter
  }
});

const props = {
  name: "password",
  label: "Password",
  type: "password",
  shouldValidate: true,
  validate
};

const PasswordField = withProps(props)(TextField);
PasswordField.propTypes = {
  ...InputProps,
  name: PropTypes.string
};

export default PasswordField;
