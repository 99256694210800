function urlService() {
  const getBase = () => {
    return process.env.REACT_APP_API_HOST;
  };

  return {
    getBase,
  };
}

export default urlService();
