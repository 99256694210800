import React, { Component } from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import CloseIcon from "material-ui/svg-icons/navigation/close";

const styles = {
  modal: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20%"
  },
  modalContent: {
    width: "100%",
    maxWidth: "700px"
  },
  modalTitleBar: { position: "relative", backgroundColor: "#9d1607" },
  nonDeleteModalTitleBar: { position: "relative", backgroundColor: "#00acc1" },
  modalTitle: {
    fontWeight: "bold",
    justifyContent: "center",
    display: "flex",
    color: "#FFFFFF"
  },
  modalBody: {
    width: "100%",
    maxWidth: "600px",
    justifyContent: "center"
  },
  closeIcon: {
    cursor: "pointer",
    color: "#FFFFFF",
    position: "absolute",
    right: "40px",
    top: "30px"
  },
  raisedButton: { borderRadius: "10px", backgroundColor: "#9d1607" },
  nonDeleteRaisedButton: { borderRadius: "10px", backgroundColor: "#00acc1" },
  formButtonsBar: { marginTop: "20px", float: "right" },
  messageContent: {
    textAlign: "center",
    fontSize: "20px",
    color: "#000000",
    marginTop: "20px"
  }
};

export class DeleteConfirmationModal extends Component {
  render() {
    const {
      title,
      resourceName,
      name,
      deleteAction,
      contentStyle,
      message,
      isDeleteAction,
      buttonLabel,
      onClose
    } = this.props;

    return (
      <div>
        <Dialog
          title={
            <div
              style={
                isDeleteAction
                  ? styles.modalTitleBar
                  : styles.nonDeleteModalTitleBar
              }
            >
              <div style={styles.modalTitle}>{title}</div>
              <CloseIcon onClick={onClose} style={styles.closeIcon} />
            </div>
          }
          modal={true}
          open={true}
          contentStyle={{ ...styles.modalContent, ...contentStyle }}
          overlayClassName="overlay"
          contentClassName="content"
          bodyClassName="body"
          bodyStyle={styles.modalBody}
          style={styles.modal}
        >
          <div style={styles.messageContent}>
            {isDeleteAction && (
              <div>
                Are you sure you want to delete
                {resourceName && ` ${resourceName}`}
                {name && ` ${name}`}?
              </div>
            )}
            <div>{message && ` ${message}`}</div>
          </div>
          <div style={styles.formButtonsBar}>
            <FlatButton
              label="Cancel"
              type="button"
              onClick={onClose}
              style={{ marginRight: "12px" }}
            />
            <RaisedButton
              label={buttonLabel}
              type="button"
              onClick={deleteAction}
              buttonStyle={
                isDeleteAction
                  ? styles.raisedButton
                  : styles.nonDeleteRaisedButton
              }
              style={
                isDeleteAction
                  ? styles.raisedButton
                  : styles.nonDeleteRaisedButton
              }
              primary={true}
            />
          </div>
        </Dialog>
      </div>
    );
  }
}

DeleteConfirmationModal.propTypes = {
  title: PropTypes.string,
  deleteAction: PropTypes.func.isRequired,
  resourceName: PropTypes.string,
  contentStyle: PropTypes.object,
  isDeleteAction: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func
};

DeleteConfirmationModal.defaultProps = {
  contentStyle: {},
  message: "",
  isDeleteAction: true,
  buttonLabel: "Delete"
};

export default DeleteConfirmationModal;
