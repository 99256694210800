import PropTypes from "prop-types";

export const InputProps = {
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  onChange: PropTypes.func
};
