import createReducer from "helpers/createReducer";
import {
  BATCH_CREATE_ELIGIBLE_EMPLOYEES_REQUEST,
  BATCH_CREATE_ELIGIBLE_EMPLOYEES_SUCCESS,
  BATCH_CREATE_ELIGIBLE_EMPLOYEES_FAILURE,
  HISTORY_REQUEST,
  HISTORY_SUCCESS,
  HISTORY_FAILURE,
  JOBS_REQUEST,
  JOBS_SUCCESS,
  JOBS_FAILURE,
  JOB_DETAILS_REQUEST,
  JOB_DETAILS_SUCCESS,
  JOB_DETAILS_FAILURE,
  CSV_DOWNLOAD_REQUEST,
  CSV_DOWNLOAD_SUCCESS,
  CSV_DOWNLOAD_FAILURE
} from "../actions/bulkUploadActions";

const defaultState = {
  historyBatches: {
    byEmployerId: {
      byId: {},
      all: [],
      total: 0
    }
  },
  jobs: {
    byBatchId: {}
  },
  jobDetails: {
    byBatchId: {}
  },
  batchCreateEligibleEmployeesFetching: false,
  batchCreateEligibleEmployeesErrors: null,
  historyFetching: false,
  historyErrors: null,
  jobsFetching: false,
  jobsErrors: null,
  jobDetailsFetching: false,
  jobDetailsErrors: null,
  csvDownloadFetching: false,
  csvDownloadErrors: null
};

function batchCreateEligibleEmployeesRequest(state) {
  return Object.assign({}, state, {
    batchCreateEligibleEmployeesFetching: true,
    batchCreateEligibleEmployeesErrors: null
  });
}
function batchCreateEligibleEmployeesSuccess(state) {
  return Object.assign({}, state, {
    batchCreateEligibleEmployeesFetching: false,
    batchCreateEligibleEmployeesErrors: null
  });
}
function batchCreateEligibleEmployeesFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    batchCreateEligibleEmployeesFetching: false,
    batchCreateEligibleEmployeesErrors: error
  });
}

function historyRequest(state) {
  return Object.assign({}, state, {
    historyFetching: true,
    historyErrors: null
  });
}
function historySuccess(state, { employerId, batches }) {
  const byEmployerId = {
    [employerId]: batches
  };
  return Object.assign({}, state, {
    historyBatches: Object.assign({}, state.historyBatches, { byEmployerId }),
    historyFetching: false,
    historyErrors: null
  });
}
function historyFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    historyFetching: false,
    historyErrors: error
  });
}

function jobsRequest(state) {
  return Object.assign({}, state, {
    jobsFetching: true,
    jobsErrors: null
  });
}
function jobsSuccess(state, { batchId, job }) {
  const byBatchId = {
    [batchId]: job
  };
  return Object.assign({}, state, {
    jobs: Object.assign({}, state.jobs, { byBatchId }),
    jobsFetching: false,
    jobsErrors: null
  });
}
function jobsFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    jobsFetching: false,
    jobsErrors: error
  });
}

function jobDetailsRequest(state) {
  return Object.assign({}, state, {
    jobDetailsFetching: true,
    jobDetailsErrors: null
  });
}
function jobDetailsSuccess(state, { batchId, jobDetails }) {
  const byBatchId = {
    [batchId]: jobDetails
  };
  return Object.assign({}, state, {
    jobDetails: Object.assign({}, state.jobDetails, { byBatchId }),
    jobDetailsFetching: false,
    jobDetailsErrors: null
  });
}
function jobDetailsFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    jobDetailsFetching: false,
    jobDetailsErrors: error
  });
}

function csvDownloadRequest(state) {
  return Object.assign({}, state, {
    csvDownloadFetching: true,
    csvDownloadErrors: null
  });
}
function csvDownloadSuccess(state) {
  return Object.assign({}, state, {
    csvDownloadFetching: false,
    csvDownloadErrors: null
  });
}
function csvDownloadFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    csvDownloadFetching: false,
    csvDownloadErrors: error
  });
}

export default createReducer(defaultState, {
  [BATCH_CREATE_ELIGIBLE_EMPLOYEES_REQUEST]: batchCreateEligibleEmployeesRequest,
  [BATCH_CREATE_ELIGIBLE_EMPLOYEES_SUCCESS]: batchCreateEligibleEmployeesSuccess,
  [BATCH_CREATE_ELIGIBLE_EMPLOYEES_FAILURE]: batchCreateEligibleEmployeesFailure,
  [HISTORY_REQUEST]: historyRequest,
  [HISTORY_SUCCESS]: historySuccess,
  [HISTORY_FAILURE]: historyFailure,
  [JOBS_REQUEST]: jobsRequest,
  [JOBS_SUCCESS]: jobsSuccess,
  [JOBS_FAILURE]: jobsFailure,
  [JOB_DETAILS_REQUEST]: jobDetailsRequest,
  [JOB_DETAILS_SUCCESS]: jobDetailsSuccess,
  [JOB_DETAILS_FAILURE]: jobDetailsFailure,
  [CSV_DOWNLOAD_REQUEST]: csvDownloadRequest,
  [CSV_DOWNLOAD_SUCCESS]: csvDownloadSuccess,
  [CSV_DOWNLOAD_FAILURE]: csvDownloadFailure
});
