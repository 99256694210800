import React from "react";
import { filter, mapKeys, reduce, keys, isEqual } from "lodash";
import {
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SUPPORT_REP,
  PARTNER_MANAGER,
  BENEFITS_ADMIN
} from "../constants";

export const getParams = query => {
  if (!query) {
    return {};
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split("?")
    .slice(1)
    .reduce((params, param) => {
      let [key, value] = param.split("=");
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, " ")) : "";
      return params;
    }, {});
};

export const renameKey = (obj, prevName, newName) => {
  return mapKeys(obj, (value, key) => (key === prevName ? newName : key));
};

export const filterNullChildren = children => {
  // Convert children to an array
  const childrenArray = React.Children.toArray(children);
  // Filter null children
  return filter(childrenArray, val => val);
};

export const adminTypeTooltipMsg = type => {
  switch (type) {
    case PARTNER_ADMIN:
      return "Full administration rights for Partner and all Employers.";
    case EMPLOYERS_ADMIN:
      return "Full administration rights for accessible Employers.";
    case EMPLOYER_MANAGER:
      return "Update rights for accessible Employers. Cannot create new Employers.";
    case SUPPORT_REP:
      return "Read only rights to accessible Employers. Can impersonate Employees.";
    case PARTNER_MANAGER:
      return "Full administration rights for all accessible employers.";
    case BENEFITS_ADMIN:
      return "Read only rights to accessible Employer. Can only be assigned to a single employer.";
    default:
      return null;
  }
};

// Compares two different objects and returns difference.
// e.g. getObjectDiff({a: 1, b: 2, c: 4}, {a: 0, b: 2, d: 3})
// => {a: 0, d: 3}
export const getObjectDiff = (oldObj, newObj) => {
  return reduce(
    keys(newObj),
    (result, key) => {
      if (!isEqual(oldObj[key], newObj[key])) {
        result[key] = newObj[key];
      }
      return result;
    },
    {}
  );
};

export const isCitizens = () =>
  window.location.hostname.indexOf("citizens") > -1;

export const renameAttributes = (obj, fields) => {
  const res = { ...obj };
  fields.forEach(field => {
    if (res[field] != null) {
      res[`${field}_attributes`] = res[field];
      delete res[field];
    }
  });
  return res;
};

export const indexBy = (key, data) => {
  const indexed = {};
  data.map(item => {
    const id = item[key];
    indexed[id] = item;
    return true;
  });

  return indexed;
};
