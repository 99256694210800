import { UPDATE } from "../rest/types";

export const REGISTER_ADMIN_USER = "REGISTER_ADMIN_USER";
export const REGISTER_ADMIN_USER_FAILURE = "REGISTER_ADMIN_USER_FAILURE";
export const REGISTER_ADMIN_USER_SUCCESS = "REGISTER_ADMIN_USER_SUCCESS";

export const registerAdminUser = (data, basePath) => ({
  type: REGISTER_ADMIN_USER,
  payload: { data: { ...data } },
  meta: { resource: "registrations", fetch: UPDATE, cancelPrevious: false }
});

export const FETCH_ADMIN_USERS = "FETCH_ADMIN_USERS";
export const FETCH_ADMIN_USERS_BY_EMAIL = "FETCH_ADMIN_USERS_BY_EMAIL";
export const FETCH_ADMIN_USERS_FAILURE = "FETCH_ADMIN_USERS_FAILURE";
export const FETCH_ADMIN_USERS_SUCCESS = "FETCH_ADMIN_USERS_SUCCESS";

export const fetchAdminUsers = (
  { adminUserType = null, employerID, partnerID },
  sort,
  page,
  query
) => ({
  type: FETCH_ADMIN_USERS,
  payload: { adminUserType, employerID, partnerID, sort, page, query }
});

export const fetchAdminUsersByEmail = (
  { adminUserType = null, employerID, partnerID },
  query
) => ({
  type: FETCH_ADMIN_USERS_BY_EMAIL,
  payload: { adminUserType, employerID, partnerID, query }
});

export const CREATE_ADMIN_USER = "CREATE_ADMIN_USER";
export const CREATE_ADMIN_USER_FAILURE = "CREATE_ADMIN_USER_FAILURE";
export const CREATE_ADMIN_USER_SUCCESS = "CREATE_ADMIN_USER_SUCCESS";

export const createAdminUser = (
  data,
  formName,
  context,
  partnerID,
  employerFilterIDs,
  onSuccess
) => {
  return {
    type: CREATE_ADMIN_USER,
    payload: { data },
    formName: formName,
    context,
    partnerID,
    employerFilterIDs,
    onSuccess
  };
};

export const EDIT_ADMIN_USER = "EDIT_ADMIN_USER";
export const EDIT_ADMIN_USER_FAILURE = "EDIT_ADMIN_USER_FAILURE";
export const EDIT_ADMIN_USER_SUCCESS = "EDIT_ADMIN_USER_SUCCESS";

export const editAdminUser = (
  data,
  formName,
  adminUserID,
  context,
  employersToAdd,
  employersToRemove,
  currentEmployers,
  onSuccess
) => {
  return {
    type: EDIT_ADMIN_USER,
    payload: { data },
    formName: formName,
    adminUserID,
    context,
    employersToAdd,
    employersToRemove,
    currentEmployers,
    onSuccess
  };
};
