import createReducer from "../../helpers/createReducer";
import {
  GET_EMPLOYER_REPORTS_REQUEST,
  GET_EMPLOYER_REPORTS_SUCCESS,
  GET_EMPLOYER_REPORTS_FAILURE
} from "../actions";

const defaultState = {
  byEmployerId: {},
  isFetching: false,
  fetchingErrors: null,
  isDownloadingReport: false,
  downloadReportErrors: null
};

function requestReports(state) {
  return Object.assign({}, state, { isFetching: true, fetchingErrors: null });
}

function requestReportsSuccess(state, action) {
  return Object.assign({}, state, {
    isFetching: false,
    fetchingErrors: null,
    byEmployerId: Object.assign({}, state.byEmployerId, action.data)
  });
}

function requestReportsError(state, action) {
  return Object.assign({}, state, {
    isFetching: false,
    fetchingErrors: action.errors
  });
}

export default createReducer(defaultState, {
  [GET_EMPLOYER_REPORTS_REQUEST]: requestReports,
  [GET_EMPLOYER_REPORTS_SUCCESS]: requestReportsSuccess,
  [GET_EMPLOYER_REPORTS_FAILURE]: requestReportsError
});
