import React from "react";

function useSelections({ rows, getRowId }) {
  const [selected, setSelected] = React.useState([]);

  function handleSelectAllChange(e) {
    let newSelecteds = [];
    if (e.target.checked) {
      newSelecteds = rows.map(getRowId);
    }

    setSelected(newSelecteds);
  }

  const handleChange = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  function resetSelections() {
    setSelected([]);
  }

  return {
    selected,
    handleChange,
    handleSelectAllChange,
    resetSelections
  };
}

export default useSelections;
