import * as R from "ramda";

const isArray = arr => R.type(arr) === "Array";
const ofData = R.ifElse(isArray, R.identity, R.of);

const handleData = async ({ data, saveAs, mimeType }) => {
  const downloadUrl = window.URL.createObjectURL(
    new Blob(ofData(data), { type: mimeType })
  );
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.setAttribute("download", saveAs); //any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default ({ saveAs, mimeType = "xls" }) =>
  R.pipe(
    R.merge({
      saveAs,
      mimeType
    }),
    handleData
  );
