import { createSelector } from "reselect";
import { selectEmployeeNotes, selectEmployeeNotesIDList } from "./helpers";
import { get, map } from "lodash";

export const selectEmployeeNotesByEmployee = createSelector(
  [
    selectEmployeeNotes,
    selectEmployeeNotesIDList,
    (_, employeeID) => employeeID
  ],
  (employeeNotes, employeeNotesIDList, employeeID) => {
    return map(employeeNotesIDList, val => employeeNotes[val]).filter(
      val => employeeID === get(val, "customers.id")
    );
  }
);
