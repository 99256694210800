import BaseRequest from "../../shared/BaseRequest";

export default class BulkUploadBatchRequest extends BaseRequest {
  constructor({ url, sort, query, page }) {
    const params = { sort, pagination: { page } };
    const filter = [];
    if (query) {
      filter.push({ old_values_cont: query });
      filter.push({ new_values_cont: query });
      filter.push({ message_cont: query });
    }

    super({ url, params: { ...params, filter } });
  }
}
