import React from "react";

/**
 *
 * @name SendSvg
 *
 * Send Svg Icon from `https://material.io/tools/icons/?icon=send&style=baseline`. Not available in current version
 * of material UI.
 * TODO: Replace once material-ui is upgraded.
 *
 */
const SendSvg = props => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export default SendSvg;
