import React from "react";
import { Controller } from "react-hook-form";
import MuiTextField from "@material-ui/core/TextField";

import withControls from "../enhancements/withControls";

const EnhancedField = withControls(MuiTextField);

export default function TextField({ loading, control, formProps, ...props }) {
  return (
    <Controller
      as={<EnhancedField />}
      margin="normal"
      fullWidth
      control={control}
      formProps={{ ...formProps, control }}
      {...props}
    />
  );
}
