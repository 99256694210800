import * as R from "ramda";
import {
  centsToMoneyStringFromProp,
  toDisplayDateStringFromProp
} from "services/formatters";
import withGetFetch from "components/containers/withGetFetch";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import { PageComponent, withProps } from "components/containers/ListPage";

const columns = [
  { name: "invoice_name", label: "Name" },
  {
    name: "employee_name",
    label: "Employee Name"
  },
  {
    name: "amount_cents",
    label: "Amount",
    format: centsToMoneyStringFromProp("amount_cents")
  },
  { name: "status", label: "Status", disableSort: true },
  {
    name: "created_at",
    label: "Created",
    format: toDisplayDateStringFromProp("created_at")
  },
  {
    name: "updated_at",
    label: "Updated",
    format: toDisplayDateStringFromProp("updated_at")
  }
];

const createGetSearchFilterWith = employerId =>
  R.applySpec({
    "0": {
      employer_id_eq: R.always(employerId)
    }
  });

function extraProps(props) {
  const employerId = props.employerId;
  return {
    defaultSort: { order: "desc", orderBy: "created_at" },
    title: "Contributions",
    hideSearch: true,
    path: employerId && `/disbursements`,
    columns,
    getSearchFilter: createGetSearchFilterWith(employerId)
  };
}
export default R.compose(
  withProps(extraProps),
  withSort,
  withSearch,
  withPagination,
  withGetFetch
)(PageComponent);
