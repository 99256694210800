import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

export default IC => WC => ({ loading, ...props }) => {
  const WIC = () => <IC loading={loading} />;

  return (
    <WC
      loading={loading}
      IconComponent={loading ? WIC : ArrowDropDownIcon}
      {...props}
    />
  );
};
