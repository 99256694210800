export const GET_LIST = "GET_LIST";
export const GET_ONE = "GET_ONE";
export const GET_MANY = "GET_MANY";
export const GET_MANY_REFERENCE = "GET_MANY_REFERENCE";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const DOWNLOAD = "DOWNLOAD";
export const UPDATE_TR = "UPDATE_TR";
export const CREATE_EMPLOYEES = "CREATE_EMPLOYEES";
