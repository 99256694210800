import React from "react";

export default function useStepperState({ onStepChange } = {}) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => {
      const cur = prevActiveStep + 1;
      if (onStepChange) onStepChange(cur);
      return cur;
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => {
      const cur = prevActiveStep - 1;
      if (onStepChange) onStepChange(cur);
      return cur;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return {
    handleNext,
    handleBack,
    handleReset,
    activeStep
  };
}
