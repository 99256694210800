import React from "react";
import PropTypes from "prop-types";

import GenerateReportButton from "./GenerateReportButton";

function PageAction({
  reports,
  disabled,
  onClose,
  onGenerateReportCompleted,
  renderGenerateForm,
  entityIdentifiers
}) {
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(true);
    if (onClose) onClose();
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <GenerateReportButton disabled={disabled} onClick={handleClick} />
      {renderGenerateForm({
        reports,
        open,
        onClose: handleClose,
        onGenerateReportCompleted,
        entityIdentifiers
      })}
    </>
  );
}

PageAction.propTypes = {
  reports: PropTypes.array,
  disabled: PropTypes.bool,
  renderGenerateForm: PropTypes.func.isRequired
};

export default PageAction;
