import React from "react";

export default WC => ({
  onSearchChange,
  onSearchSubmit,
  getSearchFilter,
  ...props
}) => {
  const transformFilter = v => (getSearchFilter ? getSearchFilter(v) : v);
  const [filterText, setFilterText] = React.useState("");
  const [filter, setFilter] = React.useState(transformFilter(""));

  function handleSearchChange(v) {
    if (onSearchChange) onSearchChange(v);
    setFilterText(v);
  }

  function handleSearchSubmit() {
    if (onSearchSubmit) onSearchSubmit(filterText);
    setFilter(transformFilter(filterText));
  }

  const stateProps = {
    ...props.stateProps,
    searchState: [filter, setFilter]
  };
  return (
    <WC
      {...props}
      stateProps={stateProps}
      filter={filter}
      onSearchChange={handleSearchChange}
      onSearchSubmit={handleSearchSubmit}
    />
  );
};
