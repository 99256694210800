import React from "react";

import Select from "./selects/EnhancedSelect";

const options = [
  { value: "1 month", label: "1 month" },
  { value: "2 months", label: "2 months" },
  { value: "3 months", label: "3 months" },
  { value: "6 months", label: "6 months" },
  { value: "9 months", label: "9 months" },
  { value: "1 year", label: "1 year" }
];

const rules = {
  required: "required"
};

export default function PasswordExpirationSelect(props) {
  return (
    <Select
      name="password_expiration_term"
      label="Password Age"
      required
      rules={rules}
      options={options}
      {...props}
    />
  );
}
