import React from "react";

import useDownloadFile from "../../components/containers/utils/useDownloadFile";
import Link from "../../components/links/Link";
import BackButton from "../../components/buttons/action/Back";
import DownloadButton from "../../components/buttons/action/Download";

function PageAction({ disabled, employerId, batchId, ...job }) {
  const { download } = useDownloadFile();

  function handleDownloadClick() {
    const path = `/employers/${employerId}/batch_upserts/${batchId}/batch_upsert_job_details.csv`;
    const saveAs = job.filename;
    download({ path, saveAs, fileType: "csv" });
  }

  return (
    <>
      <Link to={`/employers/${employerId}/employees/bulkHistory`}>
        <BackButton>Back to Bulk History</BackButton>
      </Link>
      <DownloadButton disabled={disabled} onClick={handleDownloadClick}>
        Download CSV
      </DownloadButton>
    </>
  );
}

export default PageAction;
