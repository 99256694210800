import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { Card, CardText, CardHeader } from "material-ui/Card";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import FlatButton from "material-ui/FlatButton";
import RaisedButton from "material-ui/RaisedButton";
import AddIcon from "material-ui/svg-icons/content/add";
import EditContent from "material-ui/svg-icons/editor/mode-edit";
import { theme } from "app";
import { openModal } from "app/uiActions";
import { SLGTableColumn, SLGList } from "components";
import SendSvg from "components/svg/sendSvg";
import SearchForm from "components/SearchForm";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SELF_SERVICE_ADMIN,
  SORT_ASC
} from "constants/index";
import { WithPermission } from "auth";
import {
  fetchEmployees,
  sendEmployeeInvite,
  downloadEmployeesToCsv
} from "employees/actions";
import { EmployeeCreateModal, EmployeeEditModal } from "./modals";
import {
  selectEmployeesByEmployer,
  selectEmployeesByGroup
} from "employees/selectors";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  rowField: { textAlign: "center" },
  textLink: { cursor: "pointer", textDecoration: "none" },
  editButton: { color: "#00acc1" },
  sendSvgIcon: { fill: "#00acc1", cursor: "pointer" },
  raisedButton: { margin: 10 },
  uploadInput: {
    cursor: "pointer",
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: "100%",
    opacity: 0
  }
};

const defaultSort = { field: "last_name", order: SORT_ASC };

export class EmployeesList extends React.Component {
  /**
   * Fetches employees based on query parameter provided by the `Search` component.
   *
   * @param {string} query
   * @return {Promise}
   */

  static propTypes = {
    employer: PropTypes.object,
    employees: PropTypes.array,
    employerID: PropTypes.string,
    groupID: PropTypes.string,
    employerName: PropTypes.string,
    groupName: PropTypes.string,
    total: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    sendEmployeeInvite: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired
  };

  static defaultProps = {
    employer: {},
    employees: [],
    employerName: ""
  };

  fetchSearchEmployees = query => {
    const { partnerID, employerID, groupID } = this.props;
    const defaultPage = 1;
    const type = partnerID ? "partner" : "employer";
    const id = partnerID ? partnerID : employerID;
    const filter = groupID ? { group_id_eq: groupID } : null;

    return this.props.fetchEmployees(
      id,
      defaultSort,
      defaultPage,
      null,
      query,
      type,
      filter
    );
  };

  renderAddButton() {
    const { openModal, employer, employerID } = this.props;
    const ultiproEnabled = employer.ultipro_ready;

    return (
      <RaisedButton
        primary
        style={styles.raisedButton}
        icon={<AddIcon />}
        onClick={() =>
          openModal(<EmployeeCreateModal employerID={employerID} />)
        }
        label="New Employee"
        disabled={ultiproEnabled}
      />
    );
  }

  renderActions() {
    const { employer } = this.props;
    const ultiproEnabled = employer.ultipro_ready;

    return (
      <WithPermission
        value={[SYSTEM_ADMIN, PARTNER_ADMIN, EMPLOYERS_ADMIN, EMPLOYER_MANAGER, SELF_SERVICE_ADMIN]}
      >
        {ultiproEnabled ? (
          <Tooltip title="Employee creation is disabled due to Ultimate Integration">
            {this.renderAddButton()}
          </Tooltip>
        ) : (
          this.renderAddButton()
        )}
      </WithPermission>
    );
  }

  downloadEmployees(e) {
    e.preventDefault();
    const { partnerID, employerID } = this.props;
    const type = partnerID ? "partner" : "employer";
    const id = partnerID ? partnerID : employerID;

    return this.props.downloadEmployeesToCsv(id, type);
  }

  renderDownloadEmployeesLink() {
    const primaryColor = getMuiTheme(theme).palette["primary1Color"];
    const DownloadComponent = (
      <Link
        to="#"
        style={{
          ...styles.textLink,
          color: primaryColor,
          float: "right",
          marginTop: "-20px"
        }}
        onClick={e => this.downloadEmployees(e)}
      >
        Download Employee CSV
      </Link>
    );

    return (
      <WithPermission
        value={[SYSTEM_ADMIN, PARTNER_ADMIN, EMPLOYERS_ADMIN, EMPLOYER_MANAGER, SELF_SERVICE_ADMIN]}
      >
        {DownloadComponent}
      </WithPermission>
    );
  }

  render() {
    const {
      employees,
      employerName,
      group,
      groupName,
      total,
      isLoading,
      employerID,
      groupID,
      fetchEmployees,
      openModal,
      sendEmployeeInvite
    } = this.props;

    const primaryColor = getMuiTheme(theme).palette["primary1Color"];
    let params = [];
    if (employerID) {
      if (groupID) {
        params = [
          employerID,
          null,
          null,
          null,
          null,
          "employer",
          null,
          { group_id_eq: groupID }
        ];
      } else {
        params = [employerID];
      }
    }

    return (
      <Card>
        <CardHeader
          title={
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
              {groupName
                ? `Employees in ${groupName}`
                : `${employerName} Employees`}
            </span>
          }
          style={styles.header}
        ></CardHeader>
        <CardText>{!groupName && this.renderDownloadEmployeesLink()}</CardText>
        <CardText>
          <SearchForm onSubmit={this.fetchSearchEmployees} />
          <SLGList
            fetchData={fetchEmployees}
            params={params}
            data={employees}
            total={total}
            isLoading={isLoading}
            noDataText="No Employees"
            sort={defaultSort}
          >
            <SLGTableColumn
              sortable
              field="personal_ids"
              title="Personal ID"
              render={val => {
                const personalIds = val.personal_ids;
                return personalIds && personalIds.length > 0
                  ? personalIds[0].pid
                  : "";
              }}
            />
            <SLGTableColumn
              sortable
              field="last_name"
              title="Name"
              render={val => {
                return (
                  <Link
                    to={`/employers/${val.employer_id}/employees/${val.id}`}
                    style={{ ...styles.textLink, color: primaryColor }}
                  >
                    {val.full_name || val.email}
                  </Link>
                );
              }}
            />
            <SLGTableColumn sortable field="email" title="Email" />
            <SLGTableColumn
              field="group_id"
              title="Group"
              render={val => {
                return (
                  <Link
                    to={`/employers/${val.employer_id}/groups/${val.group_id}`}
                    style={{ ...styles.textLink, color: primaryColor }}
                  >
                    {val.group_name}
                  </Link>
                );
              }}
            />
            <SLGTableColumn
              field="employee_status"
              title="Active"
              render={val => (val.employee_status === "active" ? "Yes" : "No")}
            />
            {group && group["pay_enabled?"] && (
              <SLGTableColumn
                field="pay_enrolled?"
                title="Pay Enrolled?"
                render={val => (val["pay_enrolled?"] ? "Yes" : "No")}
              />
            )}
            <SLGTableColumn field="invitation_status" title="Invite" />
            <SLGTableColumn
              rowStyle={styles.rowField}
              render={val => (
                <WithPermission
                  value={[
                    SYSTEM_ADMIN,
                    PARTNER_ADMIN,
                    EMPLOYERS_ADMIN,
                    EMPLOYER_MANAGER,
                    SELF_SERVICE_ADMIN
                  ]}
                >
                  <FlatButton
                    label="Edit"
                    labelStyle={styles.editButton}
                    icon={
                      <EditContent
                        color={styles.editButton.color}
                        style={styles.buttonIcon}
                      />
                    }
                    onClick={() =>
                      openModal(<EmployeeEditModal selectedEmployee={val} />)
                    }
                    style={{ overflow: "inherit" }}
                  />
                </WithPermission>
              )}
            />
            <SLGTableColumn
              render={val => {
                const allowSendInvite =
                  !val.invitation_accepted_at &&
                  val.employee_status !== "inactive";
                return (
                  allowSendInvite && (
                    <WithPermission
                      value={[
                        SYSTEM_ADMIN,
                        PARTNER_ADMIN,
                        EMPLOYERS_ADMIN,
                        EMPLOYER_MANAGER,
                        SELF_SERVICE_ADMIN
                      ]}
                    >
                      <span
                        title="Send Invitation"
                        onClick={() => sendEmployeeInvite(val)}
                      >
                        <SendSvg
                          title="Re-send invitation"
                          style={styles.sendSvgIcon}
                        />
                      </span>
                    </WithPermission>
                  )
                );
              }}
            />
          </SLGList>
        </CardText>
      </Card>
    );
  }
}

const mapStateToProps = (state, props) => {
  const employerID = props.employerID;
  const employees = props.groupID
    ? selectEmployeesByGroup(state, props.groupID)
    : selectEmployeesByEmployer(state, props.employerID);

  return {
    employerID,
    employees,
    total: state.employees.total,
    isLoading: state.employees.isFetching
  };
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return {
    fetchEmployees,
    openModal,
    sendEmployeeInvite,
    downloadEmployeesToCsv
  };
};
const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(EmployeesList);
