import React from "react";
import PropTypes from "prop-types";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import Logo from "../components/logo/Logo";
import LoginBox from "../components/LoginBox";
import BaseLayout from "../layouts/BaseLayout";

/** style submit button to brand color just for this layout */
function createTheme(theme) {
  return createMuiTheme({
    palette: {
      primary: { main: theme.palette.brand }
    }
  });
}

function PageLayout({ theme, headerSlot, contentSlot, footerSlot }) {
  return (
    <BaseLayout theme={theme} alignItems="center">
      <Grid item>
        <Logo />
      </Grid>
      <Grid item>{headerSlot}</Grid>
      <Grid item>
        <LoginBox item>
          <Card raised>
            <CardContent>
              <ThemeProvider theme={theme => createTheme(theme)}>
                {contentSlot}
              </ThemeProvider>
            </CardContent>
          </Card>
        </LoginBox>
      </Grid>
      <Grid item>{footerSlot}</Grid>
    </BaseLayout>
  );
}

PageLayout.propTypes = {
  theme: PropTypes.objectOf(PropTypes.object).isRequired,
  headerSlot: PropTypes.node,
  contentSlot: PropTypes.node,
  footerSlot: PropTypes.node
};

export default PageLayout;
