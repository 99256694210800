import { createSelector } from "reselect";

const reducerName = "employerRefiVendors";
export const selectVendorsByEmployerId = state => {
  return state[reducerName].byEmployerId;
};
export const selectIsFetching = state => {
  return state[reducerName].isFetching;
};

export const selectToggleFetchingByEmployerId = (state, employerId) => {
  return state[reducerName].toggleFetching[employerId];
};

export const selectToggleVisibilityFetching = state => {
  return state[reducerName].toggleVisibilityFetching;
};

export const makeSelectAllVendorsByEmployerId = createSelector(
  [selectVendorsByEmployerId, (_, employerId) => employerId],
  (vendors, employerId) => {
    return vendors[employerId] ? vendors[employerId].all : [];
  }
);

export const makeSelectVendorsByIdByEmployerId = createSelector(
  [selectVendorsByEmployerId, (_, employerId) => employerId],
  (vendors, employerId) => {
    return vendors[employerId] ? vendors[employerId].byId : [];
  }
);
