export default ({ saveAs, mimeType }) => response => {
  const blob = new Blob([response.data], { type: mimeType });
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = downloadUrl;
  link.setAttribute("download", saveAs); //any other extension
  link.setAttribute("target", "_blank");
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(downloadUrl);
  link.remove();
};
