import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { resetPassword as resetPasswordAction } from "./passwordActions";
import { getParams } from "../helpers/utils";
import PageLayout from "./PageLayout";
import SingleColumnForm from "../components/forms/single-column/SingleColumnForm";
import PasswordField from "../components/inputs/PasswordField";
import ConfirmPasswordField from "../components/inputs/confirms/ConfirmPasswordField";
import PasswordPolicy from "./PasswordPolicy";

export class ResetPassword extends Component {
  
  state = {
    passInput: "",
  }

  resetPassword = values => {
    const { resetPassword } = this.props;
    const params = getParams(window.location.hash);
    const resetPasswordToken = params["reset_password_token"];
    const resetParams = {
      reset_password_token: resetPasswordToken,
      password: values.password,
      password_confirmation: values.passwordConfirmation
    };

    resetPassword(resetParams);
  };

  onChangePassword = (event) => {
    console.log("_______Input Password: ", event.target.value);
    let inputPassword = event.target.value;
    this.setState({
      passInput: inputPassword,
    });
  }

  render() {
    const { isLoading, theme } = this.props;
    const { passInput } = this.state;

    return (
      <PageLayout
        theme={theme}
        contentSlot={
          <SingleColumnForm
            submitButtonLabel="Reset Password"
            onSubmit={this.resetPassword}
            loading={isLoading}
          >
            <PasswordField shouldValidate={true} onChange={this.onChangePassword}/>
            <PasswordPolicy passInput={passInput} />
            <ConfirmPasswordField />
          </SingleColumnForm>
        }
      />
    );
  }
}

ResetPassword.propTypes = {
  theme: PropTypes.objectOf(PropTypes.objects).isRequired,
  resetPassword: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
   isLoading: state.ui.loading > 0 });

const enhance = compose(
  connect(
    mapStateToProps,
    {
      resetPassword: resetPasswordAction
    }
  )
);

export default enhance(ResetPassword);
