import React from "react";
import * as R from "ramda";
import {
  toYesNoStringFromProp,
  centsToMoneyStringFromProp,
} from "services/formatters";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import { PageComponent, withProps } from "components/containers/ListPage";
import withGetFetch from "components/containers/withGetFetch";
import PageAction from "./PageAction";
import ActionsCell from "./ActionsCell";
import NameCell from "./NameCell";
import SendInviteCell from "./SendInviteCell";

const ultiproEnabled = R.pathOr(false, ["ultipro_ready"]);

const columns = props => [
  {
    name: "name",
    label: "Name",
    render: r => <NameCell {...props} {...r} />
  },
  {
    name: "pay_enabled?",
    label: "Pay",
    disableSort: true,
    format: toYesNoStringFromProp("pay_enabled?"),
    hideColumn: props.isSelfServiceUser
      ? !props.employerPayEnabled
      : false,
  },
  {
    name: "ftn_enabled?",
    label: "529",
    disableSort: true,
    render: r => (r["ftn_enabled?"] ? 'Yes' : 'No'),
    hideColumn: props.isSelfServiceUser
      ? !props.employerFtnEnabled
      : true,
  },
  {
    name: "tr_enabled?",
    label: "TR",
    disableSort: true,
    render: r => (r["tr_enabled?"] ? 'Yes' : 'No'),
    hideColumn: props.isSelfServiceUser
      ? !props.employerTrEnabled
      : true,
  },
  {
    name: "employees_count",
    label: "EE's",
    disableSort: true
  },
  {
    name: "active_employees_count",
    label: "Active EE's",
    disableSort: true
  },
  {
    name: "pay_employees_count",
    label: "Pay EE's",
    disableSort: true,
    render: r => (r["pay_enabled?"] ? r.pay_employees_count : 0),
    hideColumn: props.isSelfServiceUser
  },
  {
    name: "services_attributes.0.match_amount_cents",
    label: "Pay Amount",
    disableSort: true,
    format: centsToMoneyStringFromProp(
      "services_attributes.0.match_amount_cents"
    ),
    hideColumn: props.isSelfServiceUser
      ? !props.employerPayEnabled
      : false,
  },
  {
    name: "services_attributes.0.lifetime_cap_cents",
    label: "Pay Lifetime Cap",
    disableSort: true,
    format: centsToMoneyStringFromProp(
      "services_attributes.0.lifetime_cap_cents"
    ),
    hideColumn: props.isSelfServiceUser
      ? !props.employerPayEnabled
      : false,
  },
  {
    name: "ftn_enabled?.match_amount_cents",
    label: "529 Amount",
    disableSort: true,
    format: centsToMoneyStringFromProp(
      "ftn_enabled?.match_amount_cents"
    ),
    hideColumn: props.isSelfServiceUser
      ? !props.employerFtnEnabled
      : true,
  },
  {
    name: "ftn_enabled?.lifetime_cap_cents",
    label: "529 Lifetime Cap",
    disableSort: true,
    format: centsToMoneyStringFromProp(
      "ftn_enabled?.lifetime_cap_cents"
    ),
    hideColumn: props.isSelfServiceUser
      ? !props.employerFtnEnabled
      : true,
  },
  {
    name: "tr_enabled?.lifetime_cap_cents",
    label: "Tr Lifetime Cap",
    disableSort: true,
    format: centsToMoneyStringFromProp(
      "tr_enabled?.lifetime_cap_cents"
    ),
    hideColumn: props.isSelfServiceUser
      ? !props.employerTrEnabled
      : true,
  },
  {
    name: "ultipro_ready",
    label: "Send Invite",
    hideColumn: !ultiproEnabled(props.employer),
    render: r => <SendInviteCell {...props} {...r} />
  },
  {
    name: "id",
    label: "Actions",
    disableSort: true,
    render: r => <ActionsCell {...props} {...r} />
  }
];

const createGetSearchFilterWith = employerId =>
  R.applySpec({
    "0": { employer_id_eq: R.always(employerId) }
  });

const renderActions = passThruProps => props => (
  <PageAction {...passThruProps} {...props} />
);

function extraProps(props) {
  const employerId = props.employerID;

  return {
    title: "Groups",
    employer: props.employer,
    defaultSort: { order: "asc", orderBy: "created_at" },
    path: employerId && `/employers/${employerId}/groups`,
    columns: columns({ ...props, employerId }),
    getSearchFilter: createGetSearchFilterWith(employerId)
  };
}

function actionsProps(props) {
  const employerId = props.employerID;

  return {
    columns: columns({ ...props, employerId }),
    actionSlot: renderActions({ ...props, employerId })
  };
}

export default R.compose(
  withProps(extraProps),
  withSort,
  withSearch,
  withPagination,
  withGetFetch,
  withProps(actionsProps)
)(PageComponent);
