import PropTypes from "prop-types";

import { InputProps as InputPropTypes } from "../text-field/types";

export const SelectProps = {
  ...InputPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string
    })
  ).isRequired
};
