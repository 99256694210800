import * as R from "ramda";

import filterTransformer from "../requestTransformers/filterTransformer";
import sortTransformer from "../requestTransformers/sortTransformer";
import paginationTransformer from "../requestTransformers/paginationTransformer";

export default R.pipe(
  filterTransformer,
  sortTransformer,
  paginationTransformer
);
