import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { destroy } from "redux-form";

import Dialog from "material-ui/Dialog";
import CloseIcon from "material-ui/svg-icons/navigation/close";

const styles = {
  modal: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20%",
    borderRadius: "6px"
  },
  modalContent: {
    width: "100%",
    minWidth: "500px",
    maxWidth: "700px"
  },
  modalTitleBar: {
    position: "relative",
    backgroundColor: "#ffffff",
    borderRadius: "6px"
  },
  modalTitle: {
    justifyContent: "center",
    display: "flex",
    color: "#3C4858"
  },
  modalBody: {
    width: "100%",
    overflowY: "scroll",
    justifyContent: "center",
    borderRadius: "6px"
  },
  closeIcon: {
    cursor: "pointer",
    color: "#3C4858",
    position: "absolute",
    right: "40px",
    top: "30px"
  }
};

export class SLGModalForm extends Component {
  save = values => {
    const { submit, formName } = this.props;
    submit(values, formName);
  };

  cancel = () => {
    const { formName, close, destroy } = this.props;
    destroy(formName);
    close();
  };

  render() {
    const { title, children, contentStyle } = this.props;

    return (
      <div>
        <Dialog
          title={
            <div style={styles.modalTitleBar}>
              <div style={styles.modalTitle}>{title}</div>
              <CloseIcon onClick={this.cancel} style={styles.closeIcon} />
            </div>
          }
          modal={true}
          open={true}
          contentStyle={{ ...styles.modalContent, ...contentStyle }}
          overlayClassName="overlay"
          contentClassName="content"
          bodyClassName="body"
          bodyStyle={styles.modalBody}
          style={styles.modal}
        >
          {React.cloneElement(children, {
            onSubmit: this.save,
            handlePrev: this.cancel
          })}
        </Dialog>
      </div>
    );
  }
}

SLGModalForm.propTypes = {
  title: PropTypes.string,
  formName: PropTypes.string.isRequired,
  submit: PropTypes.func,
  contentStyle: PropTypes.object
};

SLGModalForm.defaultProps = {
  contentStyle: {}
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ destroy }, dispatch);
}

export default connect(
  null,
  mapDispatchToProps
)(SLGModalForm);
