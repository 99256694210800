import * as R from "ramda";

export const toNameString = R.pipe(
  R.converge(R.concat, [
    R.propOr("", "first_name"),
    R.always(" "),
    R.propOr("", "last_name")
  ]),
  R.trim
);

export default toNameString;
