import React from "react";

import stateData from "../../helpers/stateData";
import SelectField from "./selects/EnhancedSelect";

const rules = {
  required: "required"
};

const attributes = {
  name: "state",
  label: "State",
  rules,
  required: true,
  options: stateData.map(v => ({ value: v.id, label: v.name }))
};

export default function StateSelect(props) {
  return <SelectField {...attributes} {...props} />;
}
