const stateName = {
  contributees_count: "Contributees",
  contributors_count: "Contributors",
  average_contribution: "Average Contribution",
  users_count: "Users Count",
  five_two_nine_users_count: "529 users",
  click_count: "Number of user clicks on the state map ▾",
  date: "Date",
  clicks_by_state_alabama: "Alabama:",
  clicks_by_state_alazka: "Alazka:",
  clicks_by_state_arizona: "Arizona:",
  clicks_by_state_arkansas: "Arkansas:",
  clicks_by_state_california: "California:",
  clicks_by_state_colorado: "Colorado:",
  clicks_by_state_connecticut: "Connecticut:",
  clicks_by_state_delaware: "Delaware:",
  clicks_by_state_florida: "Florida:",
  clicks_by_state_georgia: "Georgia:",
  clicks_by_state_hawaii: "Hawaii:",
  clicks_by_state_idaho: "Idaho:",
  clicks_by_state_illinois: "Illinois:",
  clicks_by_state_indiana: "Indiana:",
  clicks_by_state_iowa: "Iowa:",
  clicks_by_state_kansas: "Kansas:",
  clicks_by_state_kentucky: "Kentucky:",
  clicks_by_state_louisiana: "Louisiana:",
  clicks_by_state_maine: "Maine:",
  clicks_by_state_maryland: "Maryland:",
  clicks_by_state_massachusetts: "Massachusetts:",
  clicks_by_state_michigan: "Michigan:",
  clicks_by_state_minnesota: "Minnesota:",
  clicks_by_state_mississippi: "Mississippi:",
  clicks_by_state_missouri: "Missouri:",
  clicks_by_state_montana: "Montana:",
  clicks_by_state_nebraska: "Nebraska:",
  clicks_by_state_nevada: "Nevada:",
  clicks_by_state_new_hampshire: "New Hampshire:",
  clicks_by_state_new_jersey: "New Jerse:",
  clicks_by_state_new_mexico: "New Mexico:",
  clicks_by_state_new_york: "New York:",
  clicks_by_state_north_carolina: "North Carolina:",
  clicks_by_state_north_dakota: "North Dakota:",
  clicks_by_state_ohio: "Ohio:",
  clicks_by_state_oklahoma: "Oklahoma:",
  clicks_by_state_oregon: "Oregon:",
  clicks_by_state_pennsylvania: "Pennsylvania:",
  clicks_by_state_rhode_island: "Rhode Island:",
  clicks_by_state_south_carolina: "South Carolina:",
  clicks_by_state_south_dakota: "South Dakota:",
  clicks_by_state_tennessee: "Tennessee:",
  clicks_by_state_texas: "Texas:",
  clicks_by_state_utah: "Utah:",
  clicks_by_state_vermont: "Vermont:",
  clicks_by_state_virginia: "Virginia:",
  clicks_by_state_washington: "Washington:",
  clicks_by_state_west_virginia: "West Virginia:",
  clicks_by_state_wisconsin: "Wisconsin:",
  clicks_by_state_wyoming: "Wyoming:"
};
export default stateName;
