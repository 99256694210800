import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import SLGModalForm from "components/modals/SLGModalForm";
import NoteForm from "../forms/NoteForm";
import { createEmployeeNote as createEmployeeNoteAction } from "employees/actions";

export const NoteCreateModal = ({
  selectedEmployee,
  createEmployeeNote,
  onSuccess,
  onClose
}) => {
  const formName = "employeeNoteCreate";

  return (
    <SLGModalForm
      title="New Note"
      formName={formName}
      submit={(...args) =>
        createEmployeeNote.apply(null, [
          ...args,
          selectedEmployee.id,
          onSuccess
        ])
      }
      close={onClose}
    >
      <NoteForm formName={formName} label="Note" />
    </SLGModalForm>
  );
};

NoteCreateModal.propTypes = {
  selectedEmployee: PropTypes.object.isRequired,
  createEmployeeNote: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    createEmployeeNote: createEmployeeNoteAction
  }
)(NoteCreateModal);
