import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  formValueSelector,
  clearFields as clearFieldsAction,
  FieldArray
} from "redux-form";
import includes from "lodash/includes";
import get from "lodash/get";

import {
  TextField as TextInput,
  Toggle as ToggleInput,
  SelectField as SelectInput
} from "redux-form-material-ui";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import MenuItem from "material-ui/MenuItem";
import IconButton from "@material-ui/core/IconButton";

import {
  required,
  maxLength50,
  noSpaces,
  positive,
  isNumeric
} from "../../helpers/validation";
import { formatLandingPageRoot } from "../../helpers/formattingHelpers";
import { VerticalRadioButton } from "../../components";

import {
  selectCurrentPartner,
  selectEntityIdentifiers
} from "../../partners/selectors";
import AddCircle from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  ssoTwoFactorInfo: {
    position: "absolute",
    top: "12px",
    right: "0px",
    width: "30%",
    fontSize: "11px",
    textAlign: "center",
    fontWeight: "bold"
  },
  ssoToggle: { width: "20%", marginTop: "12px" },
  twoFactorToggle: {
    display: "flex",
    position: "relative",
    width: "95%"
  },
  formButtonsBar: { marginTop: "20px", float: "right" }
};

const renderSubFields = (member, index, fields) => {
  return (
    <div key={index}>
      <Field
        name={`${member}.foreign_id`}
        floatingLabelText="SSO ID"
        floatingLabelShrinkStyle={styles.inputLabel}
        underlineStyle={styles.inputUnderline}
        style={{ width: "30%", marginRight: "20px" }}
        component={TextInput}
        validate={required}
      />
      <Field
        name={`${member}.foreign_name`}
        component={TextInput}
        floatingLabelText="SSO Name"
        floatingLabelShrinkStyle={styles.inputLabel}
        underlineStyle={styles.inputUnderline}
        style={{ width: "40%", marginRight: "20px" }}
      />
      <IconButton onClick={() => fields.remove(index)} aria-label="Delete">
        <DeleteIcon />
      </IconButton>
    </div>
  );
};
const renderMembers = ({ fields }) => {
  return (
    <div>
      {fields.map(renderSubFields)}
      <IconButton onClick={() => fields.push({})} aria-label="Delete">
        <AddCircle />
      </IconButton>
    </div>
  );
};

export class EmployerGeneralForm extends Component {
  _clearPasswordExpirationFields = () => {
    const { formName, clearFields } = this.props;
    clearFields(
      formName,
      false,
      false,
      "password_expiration_term",
      "old_password_count"
    );
  };

  render() {
    const {
      handleSubmit,
      handlePrev,
      backButtonLabel,
      submitButtonLabel,
      ssoEnabled,
      employerSecurity,
      currentPartner,
      partnerSSOenabled,
      entityIdentifiers
    } = this.props;

    const landingRoot = formatLandingPageRoot(
      get(currentPartner, "root_url", null)
    );

    const buttons = [
      { label: "None", value: "none" },
      {
        label: "Require 2-Factor Authentication",
        value: "two_factor"
      },
      { label: "Password Expiration", value: "password_expiration" }
    ];

    const passwordExpirationFields =
      employerSecurity === "password_expiration" ? (
        <div>
          <Field
            name="password_expiration_term"
            component={SelectInput}
            floatingLabelText="Password Age *"
            floatingLabelShrinkStyle={styles.inputLabel}
            underlineStyle={styles.inputUnderline}
            validate={required}
            style={{ display: "inline-block", marginRight: "5%", width: "30%" }}
          >
            {[
              "1 month",
              "2 months",
              "3 months",
              "6 months",
              "9 months",
              "1 year"
            ].map(val => (
              <MenuItem key={val} value={val} primaryText={val} />
            ))}
          </Field>
          <Field
            name="old_password_count"
            component={SelectInput}
            floatingLabelText="PWs Remembered *"
            floatingLabelShrinkStyle={styles.inputLabel}
            underlineStyle={styles.inputUnderline}
            style={{ display: "inline-block", width: "30%" }}
            validate={required}
          >
            {[1, 2, 3, 4, 5].map(val => (
              <MenuItem key={val} value={val} primaryText={val} />
            ))}
          </Field>
        </div>
      ) : null;

    const ssoToggle = includes(
      ["two_factor", "password_expiration"],
      employerSecurity
    ) ? (
      <div style={{ display: "flex", position: "relative" }}>
        <Field
          name="sso_bypass"
          component={ToggleInput}
          label="SSO"
          disabled={true}
          style={styles.ssoToggle}
          format={val => !val}
          parse={val => !val}
        />
        <div style={styles.ssoTwoFactorInfo}>
          SSO cannot be paired with Two-Factor or PW Expiration
        </div>
      </div>
    ) : (
      <Field
        name="sso_bypass"
        component={ToggleInput}
        label="SSO"
        style={styles.ssoToggle}
        format={val => !val}
        parse={val => !val}
      />
    );

    const ssoDependentFields =
      partnerSSOenabled && ssoEnabled ? (
        <React.Fragment>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start"
            }}
          >
            <FieldArray name="federated_employers" component={renderMembers} />
          </div>
        </React.Fragment>
      ) : (
        <div>
          <div>
            <TextField
              floatingLabelText="Custom Landing Page URL"
              floatingLabelShrinkStyle={styles.inputLabel}
              value={landingRoot}
              disabled={true}
              underlineShow={false}
              fullWidth={true}
            />
          </div>
          <div>
            <Field
              name="landing_page_slug"
              component={TextInput}
              floatingLabelText=""
              floatingLabelShrinkStyle={styles.inputLabel}
              floatingLabelFixed={true}
              underlineStyle={styles.inputUnderline}
              fullWidth={true}
              validate={noSpaces}
            />
          </div>
          <div>
            <Field
              name="whitelisted_domains"
              component={TextInput}
              floatingLabelText="Email Whitelist"
              floatingLabelShrinkStyle={styles.inputLabel}
              floatingLabelFixed={true}
              hintText="Comma separated with no @ (e.g. vault.co, gmail.com)"
              underlineStyle={styles.inputUnderline}
              fullWidth={true}
              validate={noSpaces}
            />
          </div>
          <div>
            <VerticalRadioButton
              label="Security"
              name="employer_security"
              options={buttons}
              defaultOption="none"
              onOptionSelect={value => {
                return value !== "password_expiration"
                  ? this._clearPasswordExpirationFields()
                  : null;
              }}
              labelStyle={styles.inputLabel}
            />
          </div>
          {passwordExpirationFields}
        </div>
      );

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="name"
              component={TextInput}
              floatingLabelText={`${entityIdentifiers.employerIdentifier} Name *`}
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              validate={[required, maxLength50]}
              autoFocus
            />
          </div>
          <div>
            <Field
              name="total_employees"
              component={TextInput}
              floatingLabelText={`Total Number of ${entityIdentifiers.employeeIdentifier}s`}
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              validate={[isNumeric, positive]}
            />
          </div>
          {partnerSSOenabled ? ssoToggle : null}
          {ssoDependentFields}
          <div style={styles.formButtonsBar}>
            {handlePrev ? (
              <FlatButton
                label={backButtonLabel}
                type="button"
                onClick={handlePrev}
                style={{ marginRight: "12px" }}
              />
            ) : null}
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

EmployerGeneralForm.propTypes = {
  formName: PropTypes.string.isRequired,
  handlePrev: PropTypes.func,
  backButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  currentPartner: PropTypes.object
};

EmployerGeneralForm.defaultProps = {
  backButtonLabel: "Back",
  submitButtonLabel: "Save"
};

const selector = (formName, ...other) => formValueSelector(formName)(...other);

function mapStateToProps(state, props) {
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    currentPartner: selectCurrentPartner(state),
    form: props.formName,
    ssoEnabled: !selector(props.formName, state, "sso_bypass"),
    employerSecurity: selector(props.formName, state, "employer_security"),
    entityIdentifiers
  };
}

EmployerGeneralForm = reduxForm({
  // destroyOnMount=false and forceUnregisterOnUnmount=true required for stepper
  // wizard so that form data is not lost across steps.
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(EmployerGeneralForm);

export default connect(
  mapStateToProps,
  { clearFields: clearFieldsAction }
)(EmployerGeneralForm);
