import React from "react";

import Typography from "../../components/typography/Typography";
import asSummaryText from "../../components/typography/asSummaryText";

const Text = asSummaryText(Typography);

function JobSummary(job) {
  return (
    <Text>
      {job.filename &&
        `${job.filename} - 
      (${job.total} Total, 
      ${job.created} Created, 
      ${job.updated} Updated, 
      ${job.failed} Failed, 
      ${job.skipped} Skipped)`}
    </Text>
  );
}

export default JobSummary;
