import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from "react-router-dom";

import { Card, CardText, CardHeader } from "material-ui/Card";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { fetchEmployees as fetchEmployeesAction } from "../employees/actions";
import { SLGTableColumn, SLGList } from "../components";
import Grid from "../components/grids/Grid";
import SearchStatusComponent from "./SearchStatusComponent";
import { theme } from "../app";
import { selectAllEmployees } from "../employees/selectors/";
import { createSelectEmployerByID } from "../employers/selectors";
import { selectSearchQuery } from "../global-search/selectors/searchSelectors";
import { selectEntityIdentifiers } from "../partners/selectors";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  rowField: { textAlign: "center" },
  textLink: { cursor: "pointer", textDecoration: "none" },
  editButton: { color: "#00acc1" },
  sendSvgIcon: { fill: "#00acc1", cursor: "pointer" }
};

const fields = {
  customers: ["full_name", "email", "employer_id"]
};

const defaultSort = { field: "last_name", order: "ASC" };

export class EmployeesList extends Component {
  /**
   * Fetches employees based on query parameter provided by the `Search` component.
   *
   * @param {string} query
   * @return {Promise}
   */

  static propTypes = {
    employees: PropTypes.array,
    employerID: PropTypes.string,
    total: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  static defaultProps = {
    employees: []
  };

  state = {
    currentPage: 1
  };

  fetchEmployees = (sort, page, limit) => {
    const { partnerID, globalSearchQuery } = this.props;
    this.props.fetchEmployees(
      partnerID,
      sort,
      page,
      limit,
      globalSearchQuery,
      "partner",
      fields
    );

    this.setState({ currentPage: page });
  };

  renderShown() {
    const { currentPage } = this.state;
    const { total, entityIdentifiers } = this.props;

    if (!total) {
      return `no ${entityIdentifiers.employeeIdentifier.toLowerCase()} found`;
    } else {
      const maxShown = 25 * currentPage;
      const top = 25 * (currentPage - 1) + 1;
      const bottom = maxShown > total ? total : maxShown;
      return `showing ${top}-${bottom} of ${total}`;
    }
  }

  render() {
    const {
      employees,
      total,
      isLoading,
      employerID,
      selectEmployerById,
      entityIdentifiers
    } = this.props;
    const params = employerID ? [employerID] : [];
    const primaryColor = getMuiTheme(theme).palette["primary1Color"];

    return (
      <Card>
        <CardHeader
          title={
            <div>
              <span
                style={{ fontSize: "1.5em" }}
              >{`${entityIdentifiers.employeeIdentifier}s`}</span>
              <SearchStatusComponent text={this.renderShown()} />
            </div>
          }
          style={styles.header}
        />
        <CardText>
          <Grid container direction="column" spacing={16}>
            <Grid item xs={12} />
            <SLGList
              fetchData={this.fetchEmployees}
              params={params}
              sort={defaultSort}
              data={employees}
              total={total}
              isLoading={isLoading}
              noDataText={`No ${entityIdentifiers.employeeIdentifier}`}
            >
              <SLGTableColumn
                sortable
                field="last_name"
                title="Name"
                render={val => {
                  return (
                    <Link
                      to={`/employers/${val.employer_id}/employees/${val.id}`}
                      style={{ ...styles.textLink, color: primaryColor }}
                    >
                      {val.full_name || val.email}
                    </Link>
                  );
                }}
              />
              <SLGTableColumn sortable field="email" title="Email" />
              <SLGTableColumn
                title={`${entityIdentifiers.employerIdentifier}`}
                render={val => {
                  const employer = selectEmployerById(val.employer_id);
                  return employer && employer.name;
                }}
              />
            </SLGList>
            <Grid item xs={12} />
          </Grid>
        </CardText>
      </Card>
    );
  }
}

function mapStateToProps(state, props) {
  const employees = selectAllEmployees(state);
  const globalSearchQuery = selectSearchQuery(state);
  const selectEmployerById = createSelectEmployerByID(state);
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    employees,
    total: state.employees.total,
    isLoading: state.employees.isFetching,
    globalSearchQuery,
    selectEmployerById,
    entityIdentifiers
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchEmployees: fetchEmployeesAction
    }
  )
);

export default enhance(EmployeesList);
