import React from "react";
import PropTypes from "prop-types";
import CustomInput from "packages/mui-dashboard/components/CustomInput/CustomInput.jsx";
import { StyledGridItem } from "components";
import { Link } from "react-router-dom";

const InfoField = props => {
  const { value, header, hide, fullWidth, xs, link, type } = props;
  const parsedValue = value == null ? "" : value;
  const customInput = !link ? (
    <CustomInput
      labelText={header}
      id="float"
      formControlProps={{
        disabled: true,
        fullWidth
      }}
      inputProps={{ value: parsedValue, type }}
    />
  ) : (
    <Link to={link}>
      <CustomInput
        labelText={header}
        id="float"
        formControlProps={{
          disabled: true,
          fullWidth
        }}
        inputProps={{
          value: parsedValue,
          inputProps: { style: { cursor: "pointer", color: "blue" } }
        }}
      />
    </Link>
  );
  return !hide && <StyledGridItem xs={xs}>{customInput}</StyledGridItem>;
};

InfoField.propTypes = {
  header: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  xs: PropTypes.number,
  hide: PropTypes.bool,
  fullWidth: PropTypes.bool,
  link: PropTypes.string
};

InfoField.defaultProps = {
  value: "",
  header: "",
  hide: false,
  fullWidth: true,
  xs: null,
  link: null
};

export default InfoField;
