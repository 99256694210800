import React from "react";
import FormHelperText from "@material-ui/core/FormHelperText";
import BaseAutocomplete from "@material-ui/lab/Autocomplete";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import LoadingWrapper from "../../feedback/component-loading/SMLoadingWrapper";
import withHookForm from "../containers/withHookForm";
import BaseFormControl from "../form-control/FormControl";

const FormControl = BaseFormControl;
const AsyncIcon = ({ loading }) => (
  <LoadingWrapper loading={loading}>
    <ArrowDropDownIcon />
  </LoadingWrapper>
);

function AutocompleteComponent({
  createRenderInput,
  width,
  name,
  label,
  helpText,
  disabled,
  required,
  error,
  options,
  value,
  loading,
  onChange,
  onInputChange,
  ...props
}) {
  const [inputValue, setInputValue] = React.useState("");

  function handleInputChange(e) {
    if (onInputChange) onInputChange(e.target.value);
    setInputValue(e.target.value);
  }

  function handleChange(e, v) {
    if (onChange) onChange(v);

    const value = props.getOptionLabel(v);
    if (onInputChange) onInputChange(value);
    setInputValue(value);
  }

  return (
    <FormControl
      disabled={disabled}
      required={required}
      error={error}
      style={{ width }}
    >
      <BaseAutocomplete
        disabled={disabled}
        loading={loading}
        value={value}
        options={options}
        popupIcon={<AsyncIcon loading={loading} />}
        onChange={handleChange}
        renderInput={createRenderInput({
          name,
          label,
          required,
          value: inputValue,
          onChange: handleInputChange
        })}
        {...props}
      />
      <FormHelperText>{helpText}</FormHelperText>
    </FormControl>
  );
}

export default withHookForm(AutocompleteComponent);
