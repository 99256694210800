import * as R from "ramda";

let toYesNoString = R.cond([
  [R.equals(true), R.always("Yes")],
  [R.equals("active"), R.always("Yes")],
  [R.T, R.always("No")] //Default case
]);

export const toYesNoStringFromProp = R.curry(
  R.compose(
    toYesNoString,
    R.prop
  )
);
export default toYesNoString;
