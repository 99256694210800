import createReducer from "../../helpers/createReducer";
import { SEARCH_REQUEST, RESET_SEARCH } from "../actions/searchActions";

const defaultState = {
  query: null
};

function searchRequest(state, action) {
  const query = action.query;
  return Object.assign({}, state, { query });
}

function resetSearch(state) {
  return Object.assign({}, state, { query: null });
}

export default createReducer(defaultState, {
  [SEARCH_REQUEST]: searchRequest,
  [RESET_SEARCH]: resetSearch
});
