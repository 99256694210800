import React from "react";
import PropTypes from "prop-types";

import WrapWithLoading from "../loading/WrapWithLoading";
import ListCard from "./ListCard";
import RefiCard from "./RefiCard";

const config = {
  cols: 3
};

class RefiListCard extends React.PureComponent {
  renderCell = ({ row }) => {
    const {
      withDelegates: { handleInAdvisorChanged, getCellProps }
    } = this.props;
    const { disableInAdvisorToggle, inAdvisor } = getCellProps({ row });

    const name = row.name;
    const withMedia = {
      src: row.image_url,
      title: name
    };

    const withContent = {
      title: name,
      content: row.description
    };

    return (
      <RefiCard
        data={row}
        disableInAdvisorToggle={disableInAdvisorToggle}
        inAdvisor={inAdvisor}
        withMedia={withMedia}
        withContent={withContent}
        withDelegates={{
          handleInAdvisorChanged,
          urlButtonHref: row.url
        }}
      />
    );
  };

  render() {
    const { data, loading, withDelegates, withHeader } = this.props;
    const getCellId = withDelegates.getCellId;

    return (
      <WrapWithLoading loading={loading}>
        <ListCard
          withGrid={{
            data,
            cols: config.cols,
            getCellId,
            renderCell: this.renderCell
          }}
          withDelegates={withDelegates}
          withHeader={withHeader}
        />
      </WrapWithLoading>
    );
  }
}

RefiListCard.propTypes = {
  loading: PropTypes.bool,
  disableVisibilityToggle: PropTypes.bool,
  withHeader: PropTypes.shape({
    title: PropTypes.string.isRequired,
    renderActions: PropTypes.func
  }),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      image_url: PropTypes.string,
      description: PropTypes.string
    })
  ),
  withDelegates: PropTypes.shape({
    handleInAdvisorChanged: PropTypes.func.isRequired,
    handleVisibilityChanged: PropTypes.func.isRequired,
    getCellProps: PropTypes.func.isRequired,
    getCellId: PropTypes.func.isRequired
  }).isRequired
};

RefiListCard.defaultProps = {
  withGrid: {
    data: []
  }
};

export default RefiListCard;
