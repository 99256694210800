import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import SendIcon from "@material-ui/icons/Send";

/** legacy code, will remove once new form is done */
import { openModal } from "app/uiActions";
import { allowEmployerManager } from "services/permission/service";
import GroupActions from "components/layouts/list/ActionsCell";
import ActionButton from "components/buttons/action/TableActionButton";
import withStartIcon from "components/buttons/action/withStartIcon";
import EditButton from "components/buttons/action/Edit";
import { EmployeeEditModal } from "../modals";
import { sendEmployeeInvite } from "employees/actions";

const SendButton = withStartIcon(SendIcon)(ActionButton);

const ActionsCell = ({
  openModal,
  sendEmployeeInvite,
  stateProps: { requestData },
  ...employee
}) => {
  const allowSendInvite =
    !employee.invitation_accepted_at && employee.employee_status !== "inactive";

  const handleEditClick = () => {
    const handleSuccess = () => requestData();

    openModal(
      <EmployeeEditModal
        selectedEmployee={employee}
        onSuccess={handleSuccess}
      />
    );
  };

  const handleSendClick = () => sendEmployeeInvite(employee);

  return (
    <GroupActions>
      {allowEmployerManager() && (
        <>
          <EditButton onClick={handleEditClick}>Edit</EditButton>
          <SendButton disabled={!allowSendInvite} onClick={handleSendClick}>
            Send Invitation
          </SendButton>
        </>
      )}
    </GroupActions>
  );
};

const mapDispatchToProps = () => {
  return { openModal, sendEmployeeInvite };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(ActionsCell);
