import React from "react";
import compose from "recompose/compose";

// import {
//   toDisplayDateStringFromProp
// } from "services/formatters";

import withEmployerIdFromRouter from "components/containers/utils/withEmployerIdFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import PageAction from "./PageAction";
// const formatCreatedAt = toDisplayDateStringFromProp("date_of_generation");

export const columns = props => [
  {
    name: "kind",
    label: "Type"
  },
  {
    name: "date_of_generation",
    label: "Date of generation"
    //disableSort: true
    //format: formatCreatedAt
  },
  {
    name: "name",
    label: "Report Name"
  },
  {
    name: "reporting_period",
    label: "Reporting Period"
  },
  {
    name: "total_transaction_value",
    label: "Total transaction value"
  },
  {
    name: "employees_on_report",
    label: "Total transaction number"
  },
  {
    name: "id",
    label: "Actions",
    disableSort: true,
    render: r => <PageAction {...r} {...props} />
  }
];

function extraProps(props) {
  return {
    title: "Match Reports",
    columns: columns(props)
  };
}

function searchProps(props) {
  return {
    path: "employers/" + props.employerId + "/match_reports"
    //getSearchFilter: createGetSearchFilterWith(partnerId)
  };
}

export default compose(
  withEmployerIdFromRouter,
  withProps(searchProps),
  withSearch,
  withSort,
  withPagination,
  withGetFetch,
  withProps(extraProps)
)(PageComponent);
