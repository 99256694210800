import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from "react-router-dom";

import { Card, CardText, CardHeader } from "material-ui/Card";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import { theme } from "../app";
import { SLGTableColumn, SLGList } from "../components";
import SearchStatusComponent from "./SearchStatusComponent";
import Grid from "../components/grids/Grid";
import { fetchEmployers as fetchEmployersAction } from "../employers/employerActions";
import { selectEmployersListByPartner } from "../employers/selectors";
import {
  selectCurrentPartner,
  selectEntityIdentifiers
} from "../partners/selectors";
import { selectSearchQuery } from "../global-search/selectors/searchSelectors";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  rowFieldCenter: { textAlign: "center" },
  raisedButton: { borderRadius: "3px" },
  table: { minWidth: "100%", width: "auto" }
};

const fields = {
  customers: ["name"]
};
const defaultSort = { field: "created_at", order: "DESC" };

export class EmployersList extends Component {
  static propTypes = {
    partner: PropTypes.object,
    employers: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    fetchEmployers: PropTypes.func.isRequired
  };

  static defaultProps = {
    employers: [],
    partner: {}
  };

  state = {
    currentPage: 1
  };

  fetchEmployers = (_, sort, page) => {
    const { partnerID, globalSearchQuery } = this.props;

    this.props.fetchEmployers(
      partnerID,
      false,
      sort,
      page,
      globalSearchQuery,
      fields
    );

    this.setState({ currentPage: page });
  };

  renderShown() {
    const { currentPage } = this.state;
    const { total, entityIdentifiers } = this.props;

    if (!total) {
      return `no ${entityIdentifiers.employerIdentifier} found`;
    } else {
      const maxShown = 25 * currentPage;
      const top = 25 * (currentPage - 1) + 1;
      const bottom = maxShown > total ? total : maxShown;
      return `showing ${top}-${bottom} of ${total}`;
    }
  }

  render() {
    const {
      employers,
      total,
      isLoading,
      partnerID,
      entityIdentifiers
    } = this.props;

    const primaryColor = getMuiTheme(theme).palette["primary1Color"];

    return (
      <Card>
        <CardHeader
          title={
            <div>
              <span
                style={{ fontSize: "1.5em" }}
              >{`${entityIdentifiers.employerIdentifier}s`}</span>
              <SearchStatusComponent text={this.renderShown()} />
            </div>
          }
          style={styles.header}
        />
        <CardText>
          <Grid container direction="column" spacing={16}>
            <Grid item xs={12}>
              <SLGList
                fetchData={this.fetchEmployers}
                params={[partnerID]}
                sort={defaultSort}
                data={employers}
                total={total}
                tableStyle={styles.table}
                isLoading={isLoading}
                noDataText={`No ${entityIdentifiers.employerIdentifier}`}
              >
                <SLGTableColumn
                  sortable={true}
                  field="name"
                  render={val => {
                    return (
                      <Link
                        to={`/employers/${val.id}/dashboard`}
                        style={{
                          color: primaryColor,
                          textDecoration: "none"
                        }}
                      >
                        {val.name}
                      </Link>
                    );
                  }}
                />
              </SLGList>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </CardText>
      </Card>
    );
  }
}

function mapStateToProps(state) {
  const partnerID = state.partners.selectedID;
  const globalSearchQuery = selectSearchQuery(state);
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    partner: selectCurrentPartner(state),
    employers: selectEmployersListByPartner(state, partnerID),
    total: state.employers.total,
    isLoading: state.employers.isFetching,
    partnerID,
    globalSearchQuery,
    entityIdentifiers
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchEmployers: fetchEmployersAction
    }
  )
);

export default enhance(EmployersList);
