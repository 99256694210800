import React, { Component } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { connect } from "react-redux";
import RaisedButton from "material-ui/RaisedButton";
import { impersonateUser as impersonateUserAction } from "../employees";

const styles = {
  actionButton: { borderRadius: "15px", float: "right" }
};

export class ImpersonationButton extends Component {
  onImpersonateClick() {
    const { impersonateUser, employeeID } = this.props;
    impersonateUser(employeeID);
  }

  render() {
    return (
      <RaisedButton
        primary={true}
        buttonStyle={{ borderRadius: "15px" }}
        style={styles.actionButton}
        onClick={this.onImpersonateClick.bind(this)}
        label="IMPERSONATE"
      />
    );
  }
}

ImpersonationButton.propTypes = {
  employeeID: PropTypes.string.isRequired,
  impersonateUser: PropTypes.func
};

ImpersonationButton.defaultProps = {
  employeeID: ""
};

const enhance = compose(
  connect(null, {
    impersonateUser: impersonateUserAction
  })
);

export default enhance(ImpersonationButton);
