import React from "react";
import * as R from "ramda";
import { withStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Warning from "@material-ui/icons/Warning";
import Error from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";

import withColor from "../../icons/withColor";
import Grid from "../../layouts/Grid";
import layHorizontal from "../../layouts/layHorizontal";
import alignCenter from "../../layouts/alignCenter";
import justifyBetween from "../../layouts/justifyBetween";
import withSmSpacing from "../../layouts/withSmSpacing";
import CloseButton from "../../buttons/action/Close";

const styles = {
  root: {
    margin: 0,
    padding: 0
  }
};

const TitleBarGroup = alignCenter(justifyBetween(layHorizontal(Grid)));
const TitleGroup = withSmSpacing(alignCenter(layHorizontal(Grid)));

const WarningIcon = withColor("yellow")(Warning);
const ErrorIcon = withColor("red")(Error);
const renderIcon = R.cond([
  [R.equals("info"), () => <InfoIcon color="primary" />],
  [R.equals("warning"), () => <WarningIcon />],
  [R.equals("error"), () => <ErrorIcon />]
]);

function ConfirmationTitle({ classes, type, children, onClose }) {
  return (
    <DialogTitle className={classes.root}>
      <TitleBarGroup>
        <TitleGroup>
          {renderIcon(type)}
          {children}
        </TitleGroup>
        <CloseButton onClick={onClose} />
      </TitleBarGroup>
    </DialogTitle>
  );
}

export default withStyles(styles)(ConfirmationTitle);
