import React from "react";
import * as R from "ramda";
import compose from "recompose/compose";
import { format } from "date-fns";
import { connect } from "react-redux";
import UploadIcon from "@material-ui/icons/CloudUpload";
import Tooltip from "@material-ui/core/Tooltip";

/** legacy code, will remove once new form is done */
import { openModal } from "app/uiActions";
import { closeAllModals } from "app/uiActions";
import { batchCreateEligibleEmployeesRequest } from "employers/actions/bulkUploadActions";
import { selectEntityIdentifiers } from "partners/selectors";
import { allowEmployerManager } from "services/permission/service";
import useDownloadFile from "components/containers/utils/useDownloadFile";
import Button from "components/buttons/action/CardActionButton";
import withStartIcon from "components/buttons/action/withStartIcon";
import NewButton from "components/buttons/action/New";
import DownloadButton from "components/buttons/action/Download";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { EmployeeCreateModal } from "../modals";
import EligibilityFullFileUploadDialog from "../modals/EligibilityFullFileUpload";
import EligibilityFileUploadDialog from "../modals/EligibilityFileUpload";

const UploadButton = withStartIcon(UploadIcon)(Button);
const PageAction = ({
  employerId,
  employer,
  openModal,
  batchCreateEligibleEmployeesRequest,
  stateProps: { requestData },
  entityIdentifiers,
  fileChange,
  history,
  closeAllModals,
  fullFileUploadEmployees,
  ...props
}) => {
  const { download } = useDownloadFile();
  const ultiproEnabled = R.pathOr(false, ["ultipro_ready"], employer);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEligibilityFileUploadClick = () => {
    openModal(
      <EligibilityFileUploadDialog
        onUploadClick={handleEligibilityFileUpload}
        employerID={employerId}
      />
    );
  };
  const handleEligibilityFileUpload = (file, employeesList) => {
    batchCreateEligibleEmployeesRequest(employerId, file, employeesList);
  };
  const handleEligibilityFullFileUploadClick = () => {
    openModal(
      <EligibilityFullFileUploadDialog
        onUploadClick={handleEligibilityFullFileUpload}
        employerID={employerId}
      />
    );
  };

  const handleEligibilityFullFileUpload = (file, employeesList) => {
    batchCreateEligibleEmployeesRequest(employerId, file, employeesList);
  };

  const handleDownloadClick = () => {
    const saveAs = `${entityIdentifiers.employeeIdentifier}s_${format(
      new Date(),
      "yyyy-MM-dd"
    )}.csv`;
    const pathName = entityIdentifiers.employeeIdentifier
      ? entityIdentifiers.employeeIdentifier.toLowerCase()
      : "employee";
    const path = `/${pathName}s.csv`;
    const filter = { "0": { employer_id_eq: employerId } };
    download({ path, saveAs, fileType: "csv", params: { filter } });
  };

  const handleNewClick = () => {
    const handleSuccess = () => requestData();

    openModal(
      <EmployeeCreateModal employerID={employerId} onSuccess={handleSuccess} />
    );
  };

  const AddEmployee = React.forwardRef((localProps, ref) => (
    <span {...localProps} ref={ref}>
      <NewButton
        disabled={ultiproEnabled || props.disabled}
        onClick={handleNewClick}
      >
        Add/Update Employee
      </NewButton>
    </span>
  ));

  return allowEmployerManager() ? (
    <>
      <DownloadButton disabled={props.disabled} onClick={handleDownloadClick}>
        {`Download ${entityIdentifiers.employeeIdentifier} CSV`}
      </DownloadButton>

      <NewButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Add Employee(s)
      </NewButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          {ultiproEnabled ? (
            <Tooltip
              title={`${entityIdentifiers.employeeIdentifier} creation is disabled due to Ultimate Integration`}
            >
              <AddEmployee />
            </Tooltip>
          ) : (
            <AddEmployee />
          )}
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <NewButton
            disabled={props.disabled}
            onClick={handleEligibilityFileUploadClick}
          >
            Add/Update Multiple Employees
          </NewButton>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <UploadButton
            disabled={props.disabled}
            onClick={handleEligibilityFullFileUploadClick}
          >
            Full file upload
          </UploadButton>
        </MenuItem>
      </Menu>
    </>
  ) : null;
};

const mapStateToProps = state => {
  const entityIdentifiers = selectEntityIdentifiers(state);

  return {
    entityIdentifiers
  };
};
const mapDispatchToProps = () => ({
  openModal,
  batchCreateEligibleEmployeesRequest,
  closeAllModals
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps()
  )
)(PageAction);
