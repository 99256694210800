import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import InfoIcon from "../buttons/icon/Info";

function Info({ title }) {
  return (
    <Tooltip title={title}>
      <span>
        <InfoIcon disabled={true} />
      </span>
    </Tooltip>
  );
}

export default Info;
