import { createSelector } from "reselect";
import { map } from "lodash";

export const selectEmployersIsFetched = state => state.employers.isFetched;
export const selectEmployers = state => state.employers.byID || {};
export const selectEmployerIDList = state => state.employers.items || [];
const selectEmployerStats = state => {
  return state.employerStats.byEmployerID;
};
const selectFundsRequests = state => {
  return state.fundsRequests.byID;
};
const selectFundsRequestsIDList = state => {
  return state.fundsRequests.items;
};

export const selectEmployersListByPartner = createSelector(
  [selectEmployers, selectEmployerIDList, (_, partnerID) => partnerID],
  (employers, employerIDList, partnerID) => {
    const mappedList = employerIDList.map(val => {
      return employers[val];
    });
    return mappedList.filter(val => val && partnerID === val.partner_id);
  }
);

export const selectEmployerByID = createSelector(
  [selectEmployers, (_, employerID) => employerID],
  (employers, employerID) => employers[employerID]
);

export const createSelectEmployerByID = createSelector(
  [selectEmployers],
  employers => employerID => employers[employerID]
);

export const selectEmployerStatsByID = createSelector(
  [selectEmployerStats, (_, employerID) => employerID],
  (employerStats, employerID) => employerStats[employerID]
);

export const selectFundsRequestsByEmployer = createSelector(
  [
    selectFundsRequests,
    selectFundsRequestsIDList,
    (_, employerID) => employerID
  ],
  (fundsRequests, fundsRequestsIDList, employerID) => {
    return map(fundsRequestsIDList, val => fundsRequests[val]).filter(
      val => employerID === val.employer_id
    );
  }
);

export const selectUltiproIntegrations = createSelector(
  [selectEmployers, (_, employerID) => employerID],
  (employers, employerID) => {
    return employers[employerID] && employers[employerID].ultipro_integration;
  }
);
