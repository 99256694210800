import React from "react";

import ListTable from "../../../components/tables/list-table/ListTable";

function Panel({
  columns,
  showSelection = true,
  defaultSort = { order: "asc", orderBy: "name" },
  data,
  loading,
  selections,
  onSelectChange,
  onSelectAllChange,
  allowEdit,
  getRowId
}) {
  return (
    <ListTable
      hideTitleBar
      hideSearch
      showSelection={showSelection}
      disableSort
      defaultSort={defaultSort}
      columns={columns}
      rows={data}
      total={data.length}
      disabled={!allowEdit}
      loading={loading}
      getRowId={getRowId}
      onSelectChange={onSelectChange}
      onSelectAllChange={onSelectAllChange}
      selected={selections}
    />
  );
}

export const withProps = extraProps => WC => props => (
  <WC {...props} {...extraProps} />
);
export default Panel;
