import { findIndex } from "lodash";

import createReducer from "../../helpers/createReducer";
import {
  GET_CONTACTS_REQUEST,
  GET_CONTACTS_SUCCESS,
  GET_CONTACTS_FAILURE,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  CREATE_FAILURE,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE
} from "../actions/contactActions";

const defaultState = {
  byEmployerId: {},
  isFetching: false,
  fetchingErrors: null,
  updating: false,
  updateErrors: null
};

function getContactsRequest(state) {
  return Object.assign({}, state, { isFetching: true, fetchingErrors: null });
}

function getContactsSuccess(state, { contacts }) {
  return Object.assign({}, state, {
    isFetching: false,
    fetchingErrors: null,
    byEmployerId: Object.assign({}, state.byEmployerId, { ...contacts })
  });
}

function getContactsFailure(state, action) {
  return Object.assign({}, state, {
    isFetching: false,
    fetchingErrors: action.errors
  });
}

function updateRequest(state) {
  return Object.assign({}, state, { updating: true, updateErrors: null });
}

function updateSuccess(
  state,
  { employerId, contactId, first_name, last_name, job_title, phone }
) {
  const contactsById = Object.assign({}, state.byEmployerId[employerId].byId, {
    [contactId]: { first_name, last_name, job_title, phone }
  });

  const contactList = state.byEmployerId[employerId].all;
  const updateIndex = findIndex(contactList, ["id", contactId]);
  contactList[updateIndex] = Object.assign({}, contactList[updateIndex], {
    first_name,
    last_name,
    job_title,
    phone
  });
  return Object.assign({}, state, {
    updating: false,
    updateErrors: null,
    byEmployerId: Object.assign({}, state.byEmployerId, {
      [employerId]: {
        byId: contactsById,
        all: contactList
      }
    })
  });
}

function updateFailure(state, action) {
  return Object.assign({}, state, {
    updating: false,
    updateErrors: action.errors
  });
}

function createRequest(state) {
  return Object.assign({}, state, { updating: true, updateErrors: null });
}

function createSuccess(state) {
  return Object.assign({}, state, {
    updating: false,
    updateErrors: null
  });
}

function createFailure(state, action) {
  return Object.assign({}, state, {
    updating: false,
    updateErrors: action.errors
  });
}

export default createReducer(defaultState, {
  [GET_CONTACTS_REQUEST]: getContactsRequest,
  [GET_CONTACTS_SUCCESS]: getContactsSuccess,
  [GET_CONTACTS_FAILURE]: getContactsFailure,
  [CREATE_REQUEST]: createRequest,
  [CREATE_SUCCESS]: createSuccess,
  [CREATE_FAILURE]: createFailure,
  [UPDATE_REQUEST]: updateRequest,
  [UPDATE_SUCCESS]: updateSuccess,
  [UPDATE_FAILURE]: updateFailure
});
