import React from "react";

import useFetch from "./utils/useFetch";

export default WC => ({
  path,
  sort,
  filter,
  page,
  refresh,
  reportsFilter,
  ...props
}) => {
  const [data, setData] = React.useState([]);
  const [total, setTotal] = React.useState(0);

  const getData = useFetch({ method: "get" });

  let latestFetch = React.useRef(getData.makeFetch);
  React.useEffect(() => {
    latestFetch.current = getData.makeFetch;
  });
  const requestData = React.useCallback(() => {
    if (path) {
      function handleSuccess({ data, total }) {
        setData(data);
        setTotal(total || data.length);
      }
      latestFetch.current({
        path,
        onSuccess: handleSuccess,
        sort,
        filter,
        page: { number: page + 1 }
      });
    }
  }, [path, sort, filter, page]);

  React.useEffect(() => {
    requestData();
  }, [requestData]);

  React.useEffect(() => {
    if (refresh) {
      requestData();
    }
  }, [requestData, refresh]);

  const stateProps = {
    ...props.stateProps,
    dataState: [data, setData],
    totalState: [total, setTotal],
    getData,
    requestData
  };

  const row = reportsFilter ? reportsFilter({ ...props, rows: data }) : data;
  return (
    <WC
      {...props}
      stateProps={stateProps}
      total={total}
      rows={row}
      loading={getData.loading || props.loading}
    />
  );
};
