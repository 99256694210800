import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";

import NumberFormat from "react-number-format";
import { TextField as TextInput } from "redux-form-material-ui";
import TextField from "material-ui/TextField";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";

import {
  required,
  maxLength50,
  maxLength20,
  email
} from "../../helpers/validation";

const styles = {
  inputUnderline: { borderColor: "#258ffa" },
  inputLabel: { color: "#00acc1" },
  raisedButton: { borderRadius: "3px" },
  halfWidth: { width: "50%" },
  formButtonsBar: { marginTop: "20px", float: "right" }
};

const renderPhoneInput = ({
  input,
  label,
  meta: { touched, error },
  ...props
}) => (
  <NumberFormat
    floatingLabelText={label}
    floatingLabelFixed={true}
    floatingLabelShrinkStyle={styles.inputLabel}
    customInput={TextField}
    underlineStyle={styles.inputUnderline}
    type="tel"
    hintText="(123) 555 - 5555"
    format="(###) ### - ####"
    errorText={touched && error}
    {...input}
    {...props}
  />
);

export class EmployerContactForm extends Component {
  render() {
    const {
      handleSubmit,
      handlePrev,
      backButtonLabel,
      submitButtonLabel
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <Field
              name="contacts.[0].name"
              component={TextInput}
              floatingLabelText="Contact Full Name *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={[required, maxLength50]}
              autoFocus={true}
            />
          </div>
          <div>
            <Field
              name="contacts.[0].job_title"
              component={TextInput}
              floatingLabelText="Contact Job Title"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={maxLength20}
            />
          </div>
          <div>
            <Field
              name="contacts.[0].email"
              component={TextInput}
              floatingLabelText="Email *"
              floatingLabelShrinkStyle={styles.inputLabel}
              underlineStyle={styles.inputUnderline}
              style={styles.halfWidth}
              validate={[required, email]}
            />
          </div>
          <div>
            <Field
              name="contacts.[0].phone"
              component={renderPhoneInput}
              label="Phone Number"
              style={{ width: "30%" }}
              parse={val => val.replace(/[^0-9]/g, "")}
            />
          </div>
          <div style={styles.formButtonsBar}>
            {handlePrev ? (
              <FlatButton
                label={backButtonLabel}
                type="button"
                onClick={handlePrev}
                style={{ marginRight: "12px" }}
              />
            ) : null}
            <RaisedButton
              label={submitButtonLabel}
              type="submit"
              buttonStyle={styles.raisedButton}
              style={styles.raisedButton}
              primary={true}
            />
          </div>
        </form>
      </div>
    );
  }
}

EmployerContactForm.propTypes = {
  formName: PropTypes.string.isRequired,
  handlePrev: PropTypes.func,
  backButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string
};

EmployerContactForm.defaultProps = {
  backButtonLabel: "Back",
  submitButtonLabel: "Save"
};

function mapStateToProps(state, props) {
  return {
    form: props.formName
  };
}

EmployerContactForm = reduxForm({
  // destroyOnMount=false and forceUnregisterOnUnmount=true required for stepper
  // wizard so that form data is not lost across steps.
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(EmployerContactForm);

export default connect(mapStateToProps)(EmployerContactForm);
