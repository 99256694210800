import React, { useRef, useState, useEffect } from "react";
import Tooltip from "@material-ui/core/Tooltip";

const OverflowTip = ({ maxWidth, ...props }) => {
  // Create Ref
  const textElementRef = useRef();

  useEffect(() => compareSize());

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  return (
    <Tooltip
      title={props.value}
      interactive
      disableHoverListener={!hoverStatus}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          maxWidth: maxWidth || "100px",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {props.children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
