import { DOWNLOAD } from "../rest/types";

export const DOWNLOAD_EMPLOYER_REPORT = "DOWNLOAD_EMPLOYER_REPORT";
export const DOWNLOAD_EMPLOYER_REPORT_FAILURE =
  "DOWNLOAD_EMPLOYER_REPORT_FAILURE";
export const DOWNLOAD_EMPLOYER_REPORT_SUCCESS =
  "DOWNLOAD_EMPLOYER_REPORT_SUCCESS";

export const downloadEmployerReport = (id, basePath) => ({
  type: DOWNLOAD_EMPLOYER_REPORT,
  payload: { id },
  meta: { resource: "employers", fetch: DOWNLOAD, cancelPrevious: false },
});

export const FETCH_EMPLOYERS = "FETCH_EMPLOYERS";
export const FETCH_EMPLOYERS_FAILURE = "FETCH_EMPLOYERS_FAILURE";
export const FETCH_EMPLOYERS_SUCCESS = "FETCH_EMPLOYERS_SUCCESS";

export const fetchEmployers = (
  id,
  ignorePagination,
  sort,
  page,
  query,
  fields
) => ({
  type: FETCH_EMPLOYERS,
  payload: { partnerID: id, ignorePagination, sort, page, query, fields },
});

export const FETCH_EMPLOYER_STATS = "FETCH_EMPLOYER_STATS";
export const FETCH_EMPLOYER_STATS_FAILURE = "FETCH_EMPLOYER_STATS_FAILURE";
export const FETCH_EMPLOYER_STATS_SUCCESS = "FETCH_EMPLOYER_STATS_SUCCESS";

export const fetchEmployerStats = (id) => ({
  type: FETCH_EMPLOYER_STATS,
  payload: { employerID: id },
});

export const FETCH_EMPLOYER = "FETCH_EMPLOYER";
export const FETCH_EMPLOYER_FAILURE = "FETCH_EMPLOYER_FAILURE";
export const FETCH_EMPLOYER_SUCCESS = "FETCH_EMPLOYER_SUCCESS";

export const fetchEmployer = (id) => ({
  type: FETCH_EMPLOYER,
  payload: { employerID: id },
});

export const CREATE_EMPLOYER = "CREATE_EMPLOYER";
export const CREATE_EMPLOYER_FAILURE = "CREATE_EMPLOYER_FAILURE";
export const CREATE_EMPLOYER_SUCCESS = "CREATE_EMPLOYER_SUCCESS";

export const createEmployer = (data, formName) => ({
  type: CREATE_EMPLOYER,
  payload: { data },
  formName: formName,
});

export const EDIT_EMPLOYER = "EDIT_EMPLOYER";
export const EDIT_EMPLOYER_FAILURE = "EDIT_EMPLOYER_FAILURE";
export const EDIT_EMPLOYER_SUCCESS = "EDIT_EMPLOYER_SUCCESS";

export const editEmployer = (data, formName, initialFields) => ({
  type: EDIT_EMPLOYER,
  payload: { data },
  formName: formName,
  initialFields,
});

export const FETCH_FUNDS_REQUESTS = "FETCH_FUNDS_REQUESTS";
export const FETCH_FUNDS_REQUESTS_FAILURE = "FETCH_FUNDS_REQUESTS_FAILURE";
export const FETCH_FUNDS_REQUESTS_SUCCESS = "FETCH_FUNDS_REQUESTS_SUCCESS";

export const fetchFundsRequests = (employerID, sort, page, query) => ({
  type: FETCH_FUNDS_REQUESTS,
  payload: { employerID, sort, page, query },
});

export const FETCH_REGISTERED_CLICK = "FETCH_REGISTERED_CLICK";
export const FETCH_REGISTERED_CLICK_SUCCESS = "FETCH_REGISTERED_CLICK_SUCCESS";
export const FETCH_REGISTERED_CLICK_FAILURE = "FETCH_REGISTERED_CLICK_FAILURE";

export const fetchRegisteredClick = (employerID) => ({
  type: FETCH_REGISTERED_CLICK,
  payload: { employerID },
});

export const FETCH_EMPLOYERS_DETAILS= "FETCH_EMPLOYERS_DETAILS";
export const FETCH_EMPLOYERS_DETAILS_SUCCESS = "FETCH_EMPLOYERS_DETAILS_SUCCESS";
export const FETCH_EMPLOYERS_DETAILS_FAILURE = "FETCH_EMPLOYERS_DETAILS_FAILURE";

export const fetchEmployersDetails = (employerID ) => ({
  type: FETCH_EMPLOYERS_DETAILS,
  payload: { employerID },
});

export const FETCH_EMPLOYERS_USER_STATS = "FETCH_EMPLOYERS_USER_STATS";
export const FETCH_EMPLOYERS_USER_STATS_SUCCESS = "FETCH_EMPLOYERS_USER_STATS_SUCCESS";
export const FETCH_EMPLOYERS_USER_STATS_FAILURE = "FETCH_EMPLOYERS_USER_STATS_FAILURE";

export const fetchEmployersUserBehaviourStats = (employerID) => ({
  type: FETCH_EMPLOYERS_USER_STATS,
  payload: { employerID },
})

export const FETCH_EMPLOYER_JOURNEY_STATS = "FETCH_EMPLOYER_JOURNEY_STATS";
export const FETCH_EMPLOYER_JOURNEY_STATS_SUCCESS = "FETCH_EMPLOYER_JOURNEY_STATS_SUCCESS";
export const FETCH_EMPLOYER_JOURNEY_STATS_FAILURE = "FETCH_EMPLOYER_JOURNEY_STATS_FAILURE";

export const fetchEmployerJourneyStats = employerID => ({
  type: FETCH_EMPLOYER_JOURNEY_STATS,
  payload: { employerID }
});