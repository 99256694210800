import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { selectEntityIdentifiers } from "partners/selectors";
import {
  toYesNoStringFromProp,
  toDisplayDateStringFromProp
} from "services/formatters";
import withPartnerIdFromRouter from "components/containers/utils/withPartnerIdFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import PageAction from "./PageAction";
import NameCell from "./NameCell";

const formatPayEnrolled = toYesNoStringFromProp("pay_enabled?");
const formatCreatedAt = toDisplayDateStringFromProp("created_at");
const columns = [
  { name: "name", label: "Name", render: r => <NameCell {...r} /> },
  {
    name: "pay_enabled?",
    label: "Pay Enrolled",
    disableSort: true,
    format: formatPayEnrolled
  },
  {
    name: "created_at",
    label: "Created",
    format: formatCreatedAt
  }
];

function extraProps({ partnerId, stateProps, entityIdentifiers }) {
  return {
    title: `${entityIdentifiers.employerIdentifier}s`,
    columns,
    actionSlot: ({ disabled }) => (
      <PageAction
        disabled={disabled}
        partnerId={partnerId}
        stateProps={stateProps}
      />
    )
  };
}

const createGetSearchFilterWith = partnerId =>
  R.applySpec({
    "0": { partner_id_eq: R.always(partnerId) },
    "1": {
      name_cont: R.identity
    }
  });

function searchProps({ partnerId }) {
  return {
    path: partnerId && `/employers/`,
    getSearchFilter: createGetSearchFilterWith(partnerId)
  };
}

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return {
    entityIdentifiers
  };
}

export default compose(
  withPartnerIdFromRouter,
  connect(mapStateToProps),
  withProps(searchProps),
  withSearch,
  withSort,
  withPagination,
  withGetFetch,
  withProps(extraProps)
)(PageComponent);
