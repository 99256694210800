import React from "react";

import Select from "./selects/EnhancedSelect";

const options = [
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "annually", label: "Annually" }
];

const rules = {
  required: "required"
};

export default function MonthSelect(props) {
  return (
    <Select
      name="interval"
      label="Interval"
      required
      rules={rules}
      options={options}
      {...props}
    />
  );
}
