import React from "react";

import TextField from "./text-field/Field";

const rules = {
  min: {
    value: 0,
    message: "must be positive number"
  }
};

const attributes = {
  name: "number",
  label: "Number",
  required: false,
  type: "number",
  rules
};

export default function NumberField(props) {
  return <TextField {...attributes} {...props} />;
}
