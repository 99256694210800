import BaseRequest from "../../shared/BaseRequest";

export default class TestUltiproCredentialsRequest extends BaseRequest {
  method = "get";

  constructor({ url }) {
    super({ url });

    this.data = {
      // api_url: "https://servicet.ultipro.com",
      // api_username: "PTUSG30SVCAPI-V",
      // api_password: "password1V!",
      // api_key: "4WOUK"
    };
  }
}
