import { SHOW_TWO_FACTOR, HIDE_TWO_FACTOR } from "./twoFactorActions";

const defaultState = {
  show: false
};

export default (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case SHOW_TWO_FACTOR:
      return { show: payload.show };
    case HIDE_TWO_FACTOR:
      return { show: payload.show };
    default:
      return previousState;
  }
};
