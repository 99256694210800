import {
  assign,
  reduce,
  filter,
  find,
  get,
  has,
  isArray,
  keys,
  map,
  mapValues
} from "lodash";
import moment from "moment";

/**
 *  Format params object for UPDATE action for employers resource
 *
 * @param {object} params Params object from rest client
 * @returns {object} Properly formatted params object for the api to consume
 */
export const formatEmployersUpdate = params => {
  let transformedParams = assign({}, params);

  if (transformedParams.data["pay_enabled?"]) {
    // If contribution_date is not changed but payroll_cycle is changed,
    // contribution_date needs to be submitted as well so payroll_dates can be updated based on new payroll_cycle.
    // Because payroll_cycle update requires selected_date. (ref. `skip_date_validate` method in sl-pay)
    if (
      transformedParams.data["payroll_cycle"] &&
      !transformedParams.data["contribution_date"]
    ) {
      transformedParams.data["contribution_date"] =
        params.previousData.contribution_date;
    }

    if (transformedParams.data["selected_date"]) {
      // This sets the date passed in to the accurate attribute name(selected_date).
      transformedParams.data["selected_date"] =
        params.data["next_payroll_date"]["date"];
    } else if (get(transformedParams, "data.contribution_date")) {
      let selected_date = moment()
        .year(get(transformedParams.data, "contribution_date.year"))
        .month(get(transformedParams.data, "contribution_date.month"))
        .date(9);

      transformedParams.data["selected_date"] = selected_date;
      delete transformedParams.data["contribution_date"];
    }
  } else if (transformedParams.data["pay_enabled?"] === false) {
    transformedParams.data["payroll_cycle"] = null;
    transformedParams.data["selected_date"] = null;
  }

  // Formatting this in the REST client for now since Redux form overrides null inputs as empty strings.
  if (params.data["whitelisted_domains"] === "") {
    transformedParams.data["whitelisted_domains"] = null;
  }

  if (transformedParams.data["employer_security"]) {
    transformedParams.data["two_factor_required"] =
      transformedParams.data["employer_security"] === "two_factor";
  }

  return transformedParams;
};

/**
 *  Format params object for CREATE action for employers resource
 *
 * @param {object} params Params object from rest client
 * @returns {object} Properly formatted params object for the api to consume
 */
export const formatEmployersCreate = params => {
  let transformedParams = assign({}, params);

  if (transformedParams.data["pay_enabled?"]) {
    if (transformedParams.data["contribution_date"]) {
      let selected_date = moment()
        .year(get(transformedParams.data, "contribution_date.year"))
        .month(get(transformedParams.data, "contribution_date.month"))
        .date(9);

      transformedParams.data["selected_date"] = selected_date;
      delete transformedParams.data["contribution_date"];
    }

    if (!transformedParams.data["selected_date"]) {
      let selected_date = moment()
        .date(1)
        .add(1, "months")
        .add(8, "days");
      transformedParams.data["selected_date"] = selected_date;
    }
  }
  delete transformedParams.data["pay_enabled?"];

  // If a supplied partner id exists, use it
  if (transformedParams.data.id) {
    transformedParams.data.partner_id = transformedParams.data.id;
    delete transformedParams.data.id;
  }

  if (transformedParams.data["employer_security"]) {
    transformedParams.data["two_factor_required"] =
      transformedParams.data["employer_security"] === "two_factor";
  }

  return transformedParams;
};

/**
 * Formats contact and address attributes.
 *
 * @param {Object} employerJson
 * @param {Object} addressOrContactJson
 * @returns {Object}
 * @private
 */
const _formatContactAddressAttrs = (employerJson, addressOrContactJson) => {
  const nestedAttr = {};
  let newKey;

  if (addressOrContactJson.type === "addresses") {
    newKey = "billing_address_attributes";
    nestedAttr[newKey] = addressOrContactJson.attributes;
  } else if (addressOrContactJson.type === "contacts") {
    newKey = "contacts_attributes";
    const contactJson = {
      id: addressOrContactJson.id,
      ...addressOrContactJson.attributes
    };

    // No longer need contactable_id as that was just for comparison
    delete contactJson.contactable_id;

    // Temporary hack for multiple contacts -- api is only returning the first employer's contact until we get full
    // CRUD support for multiple employer contacts
    nestedAttr[newKey] = [contactJson];
  } else {
    newKey = addressOrContactJson.type;
    nestedAttr[newKey] = addressOrContactJson.attributes;
  }

  return assign(employerJson, nestedAttr);
};

export const formatEmployersGetList = (returnData, includedData) => {
  return map(returnData, employer => {
    // Fetch all contacts/addresses that belong to the given employer
    const filteredIncludedData = filter(includedData, val => {
      return (
        val.attributes.addressable_id === employer.id ||
        val.attributes.contactable_id === employer.id
      );
    });

    return reduce(filteredIncludedData, _formatContactAddressAttrs, employer);
  });
};

/**
 *  Formats response object for GET_ONE action for partners resource
 *
 * @param {object} returnData api, attributes and meta fields from API response
 * @param {object} includedData included fieldfrom API response
 * @returns {object} Properly formatted response object for the application to consume
 */
export const formatPartnersGetOne = (returnData, includedData) => {
  let transformedReturnData = assign({}, returnData);
  return reduce(
    includedData,
    _formatContactAddressAttrs,
    transformedReturnData
  );
};

export const formatEmployeesCreate = params => {
  const user_attributes = { email: params.data.email };
  delete params.data.email;

  params.data = {
    ...params.data,
    user_attributes
  };
  return params;
};

export const formatEmployeesUpdate = params => {
  let employeeStatus = {};
  if (has(params, "data.status")) {
    employeeStatus = {
      employee_status: get(params, "data.employee_status")
    };
  }

  params.data = { ...params.data, ...employeeStatus };
  return params;
};

// gets an single object of related attributes (e.g. partner contact)
export const getRelatedAttributes = (json, relation) => {
  const relatedAttributeID = get(
    json,
    `data.relationships.${relation}.data.id`
  );
  const included = get(json, "included", []);
  return get(included.find(o => o.id === relatedAttributeID), "attributes");
};

// gets an array of related attributes (e.g. partner refi Vendors)
export const getManyRelatedAttributes = (json, relation) => {
  const relatedAttributeData = get(json, `data.relationships.${relation}.data`);
  const relatedAttributeIDs = map(relatedAttributeData, "id");
  const included = get(json, "included", []);
  return map(relatedAttributeIDs, id =>
    assign({ id: id }, get(included.find(o => o.id === id), "attributes"))
  );
};

export const getAllRelationships = json => {
  return mapValues(get(json, "data.relationships"), (value, key) => {
    return Array.isArray(value.data)
      ? getManyRelatedAttributes(json, key)
      : getRelatedAttributes(json, key);
  });
};

// Goal is to eventually replace the above relationship functions with below
export const getIncludedAttributes = (json, id, type) => {
  /** work around for plural relationships and singular type mismatch */
  const singularIncluded = find(get(json, "included"), {
    id,
    type
  });
  const pluralIncluded = find(get(json, "included"), {
    id,
    type: type.concat("s")
  });
  const included = singularIncluded || pluralIncluded;
  const includedAttributes = get(included, "attributes");
  const includedRelatedAttributes = getRelationshipsAttributes(json, included);
  return {
    id: id,
    ...includedAttributes,
    ...includedRelatedAttributes
  };
};

export const getRelationshipsAttributes = (fullJsonResponse, data) => {
  const relationKeys = keys(get(data, "relationships"));
  const relations = map(relationKeys, key => {
    // Will always be empty for has_many relationships since the json-api standard dictates putting those records in
    // the top-level "included" key -- has_one/belongs_to relations will be here though
    const relationData = get(data, `relationships.${key}.data`);
    // Handles both many-relationships (e.g. notes, refi_vendors_vendorables)
    // and single-relationships (e.g. note author)
    let relationAttributes = {};
    if (isArray(relationData)) {
      relationAttributes = map(relationData, data => {
        // Some of the responses are inconsistent, so we're assuming the data is present if no type
        if (!data.type) return data;

        return getIncludedAttributes(fullJsonResponse, data.id, data.type);
      });
    } else {
      if (!data.type) return data;
      relationAttributes = getIncludedAttributes(
        fullJsonResponse,
        get(relationData, "id"),
        get(relationData, "type", key)
      );
    }

    return { [key]: relationAttributes };
  });
  // Reducing the array of relationships to merge into top level attributes
  return reduce(relations, (res, val) => assign(res, val), {});
};

export const getListWithRelatedData = json => {
  return map(get(json, "data"), jsonData => {
    const relationships = getRelationshipsAttributes(json, jsonData);
    return {
      id: jsonData.id,
      ...jsonData.attributes,
      ...relationships
    };
  });
};

export const getSingleWithRelatedData = json => {
  const jsonData = get(json, "data");
  const relationships = getRelationshipsAttributes(json, jsonData);
  return {
    id: jsonData.id,
    ...jsonData.attributes,
    ...relationships
  };
};

export const parseJSONResp = json => {
  return isArray(get(json, "data"))
    ? getListWithRelatedData(json)
    : getSingleWithRelatedData(json);
};
