import React from "react";
import PropTypes from "prop-types";
import Intercom from "react-intercom";
import { connect } from "react-redux";
import { userCheck as userCheckAction } from "./authActions";
import { getIntercomPayload } from "../helpers/authHelpers";

/**
 * @name withAuth
 * @description High order component that handles checking authentication
 */

const withAuth = Component => {
  class Authentication extends React.Component {
    static propTypes = {
      isAuthenticated: PropTypes.bool.isRequired
    };

    componentDidMount() {
      this.checkAuth();
    }

    componentDidUpdate(prevProps) {
      this.checkAuth();

      // eslint-disable-next-line react/prop-types, react/destructuring-assignment
      const { pathname: newPath } = this.props.location;
      // eslint-disable-next-line react/prop-types
      const { pathname: previousPath } = prevProps.location;

      if (newPath !== previousPath) {
        if (!!window.Intercom) window.Intercom("update");
      }
    }

    checkAuth() {
      // eslint-disable-next-line react/prop-types
      const { isAuthenticated, userCheck } = this.props;

      if (!isAuthenticated) {
        userCheck();
      }
    }

    render() {
      const { isAuthenticated } = this.props;

      if (isAuthenticated) {
        const user = getIntercomPayload();
        const intercomAppId = process.env.REACT_APP_INTERCOM_APP_ID;
        return (
          <div>
            <Component {...this.props} />
            <Intercom appID={intercomAppId} {...user} />
          </div>
        );
      }
      return null;
    }
  }

  const mapStateToProps = state => ({
    isAuthenticated: state.userContext.isAuthenticated
  });

  return connect(
    mapStateToProps,
    { userCheck: userCheckAction }
  )(Authentication);
};

export default withAuth;
