import axios from "axios";
import Qs from "qs";

import tokenService from "./tokenService";
import BaseResponse from "../shared/BaseResponse";

function fetchApiService({ fetchService, tokenService }) {
  const _fetchService = fetchService;
  const _tokenService = tokenService;

  const _createHeader = (options = {}) => {
    const headers = {
      Accept: "application/vnd.slg+json",
      "Content-Type": options["Content-Type"] || "application/vnd.api+json"
    };

    const token = _tokenService.get();
    if (token) {
      headers.Authorization = token;
    }
    return headers;
  };

  const _serializer = params => {
    return Qs.stringify(params, { arrayFormat: "brackets" });
  };

  const _makeRequest = async (
    request,
    ResponseClassName = null,
    optionProps = {}
  ) => {
    try {
      let url;
      let method = "get";
      let options = optionProps;
      let headerOptions;
      let data = {};
      let params = {};
      if (typeof request === "string") {
        url = request;
      } else {
        url = request.url;
        method = request.method;
        data = request.data;
        params = request.params;
        if (request.options) options = request.options;
        headerOptions = options.headers;
      }
      const headers = _createHeader(headerOptions);
      const config = {
        ...options,
        method,
        headers,
        data,
        params,
        paramsSerializer: _serializer
      };

      const result = await _fetchService(url, config);
      if (!ResponseClassName) {
        return new BaseResponse({ result });
      } else {
        return new ResponseClassName({ result });
      }
    } catch (e) {
      const data = e.response.data;
      let message = e.message;
      if (data && data.errors && data.errors[0]) {
        message = data.errors[0].detail[0];
      }
      throw new Error(message);
    }
  };

  const _requestFile = async url => {
    return await _fetchService(url, {
      method: "get",
      responseType: "blob"
    });
  };

  const _createTempFileDownload = (data, fileName, mimeType = "text/plain") => {
    const useData = Array.isArray(data) ? data : [data];
    const blob = new Blob(useData, { type: mimeType });
    const downloadUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.style.display = "none";
    link.href = downloadUrl;
    link.setAttribute("download", fileName);

    // Safari thinks _blank anchor are pop ups. We only want to set _blank
    // target if the browser does not support the HTML5 download attribute.
    // This allows you to download files in desktop safari if pop up blocking
    // is enabled.
    if (typeof link.download === "undefined") {
      link.setAttribute("target", "_blank");
    }
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);
  };

  const fetch = (request, ResponseClassName = null) => {
    return _makeRequest(request, ResponseClassName);
  };

  const fetchFile = async (
    url,
    fileName,
    type = "application/octet-stream"
  ) => {
    try {
      const response = await _makeRequest(url, BaseResponse, {});
      const data = response.data.data;
      const fileResponse = await _requestFile(data.url);
      _createTempFileDownload(fileResponse.data, fileName, type);

      return response;
    } catch (e) {
      throw new Error(e);
    }
  };

  const fetchFileAttachment = async (request, fileName, type = "text/csv") => {
    try {
      const response = await _makeRequest(request, BaseResponse, {});
      _createTempFileDownload(response.data, fileName, type);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  };

  const downloadFile = (data = [], fileName, type = "text/csv") => {
    try {
      _createTempFileDownload(data, fileName, type);
    } catch (e) {
      throw new Error(e);
    }
  };

  const enableRefiVendor = (request, ResponseClassName = null) => {
    const { vendorId, type } = request.data;
    const data = {
      type,
      attributes: {
        refi_vendors_vendorables_attributes: [{ refi_vendor_id: vendorId }]
      }
    };
    request.setData({ data });
    return _makeRequest(request, ResponseClassName);
  };

  const disableRefiVendor = (request, ResponseClassName = null) => {
    const { vendorableRecordId, type } = request.data;
    const data = {
      type,
      attributes: {
        refi_vendors_vendorables_attributes: [
          { id: vendorableRecordId, _destroy: true }
        ]
      }
    };
    request.setData({ data });
    return _makeRequest(request, ResponseClassName);
  };

  const toggleRefiVendor = (request, ResponseClassName = null) => {
    const { toggle } = request.data;
    return toggle
      ? enableRefiVendor(request, ResponseClassName)
      : disableRefiVendor(request, ResponseClassName);
  };

  const update = (request, ResponseClassName = null) => {
    const { type, id, ...rest } = request.data;
    const data = {
      type,
      id,
      attributes: {
        ...rest
      }
    };
    request.setData({ data });
    request.setMethod("patch");

    return _makeRequest(request, ResponseClassName);
  };

  const uploadFile = (request, ResponseClassName = null) => {
    const options = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    return _makeRequest(request, ResponseClassName, options);
  };

  return {
    fetch,
    fetchFile,
    fetchFileAttachment,
    toggleRefiVendor,
    update,
    uploadFile,
    downloadFile
  };
}

export default fetchApiService({
  fetchService: axios,
  tokenService
});
