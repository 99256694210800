/*import {  
  FULL_FILE_UPLOAD
} from "./types";
export const fullFileUpload = (data, employerId) => {
  return {
    type: FULL_FILE_UPLOAD,
    payload: { data },
    employerId
  };
};*/

import makeActionCreator from "helpers/makeActionCreator";

export const FULL_FILE_UPLOAD_EMPLOYEES_REQUEST = 'FULL_FILE_UPLOAD_EMPLOYEES_REQUEST';
export const FULL_FILE_UPLOAD_EMPLOYEES_SUCCESS = 'FULL_FILE_UPLOAD_EMPLOYEES_SUCCESS';
export const FULL_FILE_UPLOAD_SUCCESS = 'FULL_FILE_UPLOAD_SUCCESS';
export const CREATE_EMPLOYEES = 'CREATE_EMPLOYEES';
export const CREATE_EMPLOYEES_SUCCESS = 'CREATE_EMPLOYEES_SUCCESS';

export const fullFileUploadEmployees = makeActionCreator(
  FULL_FILE_UPLOAD_EMPLOYEES_REQUEST,
  "employerId",
  "file"
);
export const fullFileUploadEmployeesSuccess = makeActionCreator(
  FULL_FILE_UPLOAD_EMPLOYEES_SUCCESS,
  "data"
);
export const createEmployeesSuccess = makeActionCreator(
  CREATE_EMPLOYEES_SUCCESS,
  "data"
);
export const createEmployees = (data, employerId) => {
  return {
    type: CREATE_EMPLOYEES,
    payload: { data },
    employerId
  };
}