import BaseResponse from "../../shared/BaseResponse";

export default class GetInvoiceResponse extends BaseResponse {
  getData() {
    const data = super.getData();

    return {
      ...data,
      pdr_line_items: data.invoice_line_items
    };
  }
}
