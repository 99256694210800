import React, { Component } from "react";
import PropTypes from "prop-types";
import { pick, map } from "lodash";
import SLGModalForm from "components/modals/SLGModalForm";
import { GroupForm } from "../forms";

export class GroupEditModal extends Component {
  static propTypes = {
    group: PropTypes.object.isRequired,
    employerID: PropTypes.string.isRequired,
    employerPayEnabled: PropTypes.bool
  };

  render() {
    const { onClose, group, employerID, employer, employerPayEnabled, employerFtnEnabled, employerTrEnabled } = this.props;
    const initialFields = pick(group, [
      "name",
      "pay_enabled?",
      "automatic_invite",
      "employer"
    ]);
    const servicesAttributes = pick(group, [
      "services_attributes"
    ]);

    // `match_amount` is only for GroupForm.
    //
    // TODO: This transformation between dollars/cents shouldn't live in a component, and not in the render
    // function. A serializer/deserializer service needs to be injected at the API abstraction layer to handle
    // these type of transformations.

   /* if (initialFields.services_attributes[0]) {
      initialFields.services_attributes[0].match_amount =
        initialFields.services_attributes[0].match_amount_cents / 100;

      if (initialFields.services_attributes[0].lifetime_cap_cents) {
        initialFields.services_attributes[0].lifetime_cap =
          initialFields.services_attributes[0].lifetime_cap_cents / 100;
      }
    }*/

    let payObj = servicesAttributes.services_attributes.find(o => o.type === 'EmployerService::FlatMatch');
    let ftnObj = servicesAttributes.services_attributes.find(o => o.type === 'EmployerService::Savings');
    let trObj = servicesAttributes.services_attributes.find(o => o.type === 'EmployerService::Tuition');

    initialFields.services_attributes = [];
    if(payObj===undefined){
      initialFields.services_attributes.push({'match_amount':0,'lifetime_cap':0,'type':'EmployerService::FlatMatch'});
    }else{
      initialFields.services_attributes.push(payObj);
    }
    if(ftnObj===undefined){
      initialFields.services_attributes.push({'match_amount':0,'lifetime_cap':0,'type':'EmployerService::Savings'});
    }else{
      initialFields.services_attributes.push(ftnObj);
    }
    if(trObj===undefined){
      initialFields.services_attributes.push({'match_amount':0,'lifetime_cap':0,'type':'EmployerService::Tuition'});
    }else{
      initialFields.services_attributes.push(trObj);
    }
    if(trObj===undefined){
      initialFields.services_attributes.push({'match_amount':0,'lifetime_cap':0,'type':'EmployerService::Tuition'});
    }else{
      initialFields.services_attributes.push(trObj);
    }

    for (var key in initialFields.services_attributes) {
      if(initialFields.services_attributes[key]['match_amount_cents']){
        initialFields.services_attributes[key]['match_amount'] = initialFields.services_attributes[key]['match_amount_cents'] / 100;
      }
      if(initialFields.services_attributes[key]['lifetime_cap_cents']){
        initialFields.services_attributes[key]['lifetime_cap'] = initialFields.services_attributes[key]['lifetime_cap_cents'] / 100;
      }
    }
    
    if(initialFields.employer){
      initialFields.pay_enabled = initialFields.employer.subscription.pay_enabled
      initialFields.ftn_enabled = initialFields.employer.subscription.ftn_enabled
      initialFields.tr_enabled = initialFields.employer.subscription.tr_enabled
    }else{
      initialFields.pay_enabled = group['pay_enabled?'] ? true :false;
      initialFields.ftn_enabled = group['ftn_enabled?'] ? true :false;
      initialFields.tr_enabled  = group['tr_enabled?'] ? true :false;  
    }
    return (
      <SLGModalForm
        title="Edit Group"
        formName="groupEdit"
        close={onClose}
        contentStyle={{ width: "700px" }}
      >
        <GroupForm
          formName="groupEdit"
          label="Edit Group"
          backButtonLabel="Cancel"
          submitButtonLabel="Save"
          enableReinitialize={true}
          initialValues={initialFields}
          employerID={employerID}
          employer={employer}
          employerPayEnabled={employerPayEnabled}
          groupID={group.id}
          isDefaultGroup={group.name === "default"}
          initialEmployeeIDs={map(group.employees, employee => employee.id)}
          employerFtnEnabled={employerFtnEnabled}
          employerTrEnabled={employerTrEnabled}
        />
      </SLGModalForm>
    );
  }
}

export default GroupEditModal;
