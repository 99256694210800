import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { openModal } from "../../app/uiActions";
import Link from "../../components/links/Link";

const PageAction = ({ openModal, ...data }) => {
  return (
    <a
      href={data.attributes.file_link}
      rel="noopener noreferrer"
      className="MuiTypography-colorPrimary"
    >
      Download
    </a>
  );
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(PageAction);
