import React from "react";
import BackIcon from "@material-ui/icons/ArrowBack";

import GroupActions from "../../../components/layouts/list/Actions";
import withStartIcon from "../../../components/buttons/action/withStartIcon";
import ButtonComponent from "../../../components/buttons/action/CardActionButton";
import LinkComponent from "../../../components/links/Link";
import ApproveInvoice from "./ApproveInvoice";

const BackButton = withStartIcon(BackIcon)(ButtonComponent);

function Actions({ employerId, allowApprove, onSubmit }) {
  const to = `/employers/${employerId}/invoices/list`;

  return (
    <GroupActions>
      <LinkComponent to={to}>
        <BackButton>Back To Invoices</BackButton>
      </LinkComponent>
      {allowApprove && <ApproveInvoice onSubmit={onSubmit} />}
    </GroupActions>
  );
}

export default Actions;
