import { createSelector } from "reselect";
import { map } from "lodash";
import { selectGroups, selectGroupsIDList } from "./helpers";

export const selectGroupsByEmployer = createSelector(
  [selectGroups, selectGroupsIDList, (_, employerID) => employerID],
  (groups, groupsIDList, employerID) => {
    return map(groupsIDList, val => groups[val]).filter(
      val => employerID === val.employer_id
    );
  }
);
