import React from "react";
import { format } from "date-fns";
import compose from "recompose/compose";
import { connect } from "react-redux";

/** legacy code, will remove once new form is done */
import { openModal as openModalAction } from "../../app/uiActions";
import { AdminUserCreateModal } from "../../adminUsers/modals";
import { selectEmployersListByPartner } from "../../employers/selectors";

import { allowToPartnerAdmin } from "../../services/permission/service";
import useDownloadFile from "../../components/containers/utils/useDownloadFile";
import NewButton from "../../components/buttons/action/New";
import DownloadButton from "../../components/buttons/action/Download";

function PageAction({
  partnerId,
  employers,
  employersLoading,
  loading,
  openModal,
  stateProps: { requestData, searchState },
  ...props
}) {
  const { download } = useDownloadFile();

  function handleDownloadClick() {
    const saveAs = `AdminUsers_${format(new Date(), "yyyy-MM-dd")}.csv`;
    const path = `/admin_users.csv`;
    const filter = searchState[0];
    download({ path, saveAs, fileType: "csv", params: { filter } });
  }

  function handleNewClick() {
    function handleSuccess() {
      requestData();
    }

    openModal(
      <AdminUserCreateModal
        partnerID={partnerId}
        employers={employers}
        context="partner_admin"
        onSuccess={handleSuccess}
      />
    );
  }

  const disabled = props.disabled || loading || employersLoading;
  return (
    <>
      <DownloadButton disabled={disabled} onClick={handleDownloadClick}>
        Download Admin Users CSV
      </DownloadButton>
      {allowToPartnerAdmin() && (
        <NewButton disabled={disabled} onClick={handleNewClick}>
          New Admin User
        </NewButton>
      )}
    </>
  );
}

function mapStateToProps(state, props) {
  const partnerId = props.partnerId;
  return {
    employers: selectEmployersListByPartner(state, partnerId),
    employersLoading: state.employers.isFetching
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      openModal: openModalAction
    }
  )
)(PageAction);
