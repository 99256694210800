import {
  CREATE_EMPLOYEES_SUCCESS
} from "../actions/types";

const initialState = {
  createEmployeesData:{
    createEmployeeStatus:0
  }
};

const creareEmployeesReducers = (
  previousState = initialState.createEmployeesData,
  { type, payload }
) => {
  switch (type) {
    case CREATE_EMPLOYEES_SUCCESS:
      return {
        ...previousState,
        createEmployeeStatus: payload 
      };        
    default:
      return previousState;
  }
};

export default creareEmployeesReducers;
