import * as React from "react";
import PropTypes from "prop-types";
import AutosuggestComponent from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

import WrapWithLoading from "../loading/WrapWithLoading";

const renderInputComponent = inputProps => {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input
        }
      }}
      {...other}
    />
  );
};

const createRenderSuggestion = (getSuggestionValue, loading) => (
  suggestion,
  { query, isHighlighted }
) => {
  const suggestionValue = getSuggestionValue(suggestion);
  const matches = match(suggestionValue, query);
  const parts = parse(suggestionValue, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        <WrapWithLoading size={24} loading={loading}>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 900 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </strong>
            );
          })}
        </WrapWithLoading>
      </div>
    </MenuItem>
  );
};

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 10,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    backgroundColor: "#eeeeeeee"
  },
  suggestion: {
    display: "block",
    backgroundColor: "#eeeeeeee"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  divider: {
    height: theme.spacing.unit * 2
  }
});

class AutoSuggest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.initialValue,
      suggestions: []
    };
  }

  componentDidMount() {
    const { data } = this.props;

    if (data.length) {
      this.setState({ suggestions: this.getSuggestions(this.state.value) });
    }
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;

    if (data.length !== prevProps.data.length) {
      this.setState({ suggestions: this.getSuggestions(this.state.value) });
    }
  }

  getSuggestions = value => {
    const { data, getSuggestionValue } = this.props;

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return data.filter(item => {
      const itemValue = getSuggestionValue(item);
      return itemValue.toLowerCase().slice(0, inputLength) === inputValue;
    });
  };

  onChange = (event, { newValue }) => {
    const onChangeProp = this.props.onChange;

    if (onChangeProp) onChangeProp(newValue);

    this.setState({
      value: newValue
    });
  };

  onSuggestionSelected = (event, { suggestionValue }) => {
    const onSuggestionSelectedProp = this.props.onSuggestionSelected;

    if (onSuggestionSelectedProp) onSuggestionSelectedProp(suggestionValue);

    this.setState({
      value: suggestionValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { classes, getSuggestionValue, placeholder, loading } = this.props;
    const { value } = this.state;
    const suggestions =
      this.state.suggestions.length > 0 ? this.state.suggestions : [{}];

    const autosuggestProps = {
      renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.onSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.onSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion: createRenderSuggestion(getSuggestionValue, loading),
      onSuggestionSelected: this.onSuggestionSelected
    };

    return (
      <div className={classes.root}>
        <AutosuggestComponent
          {...autosuggestProps}
          inputProps={{
            classes,
            placeholder,
            value,
            onChange: this.onChange
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion
          }}
          renderSuggestionsContainer={options => {
            return (
              <Paper {...options.containerProps} square>
                {options.children}
              </Paper>
            );
          }}
        />
      </div>
    );
  }
}

AutoSuggest.propTypes = {
  data: PropTypes.array,
  getSuggestionValue: PropTypes.func.isRequired,
  renderSuggestion: PropTypes.func,
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(AutoSuggest);
