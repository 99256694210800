import useMakeFetch from "../../../services/api/useMakeFetch";

function useDownload() {
  const { makeFetch } = useMakeFetch({ method: "fetchFile" });

  const download = ({ path, saveAs, fileType, params, ...options }) => {
    makeFetch({ path, saveAs, fileType, params, ...options });
  };

  return {
    download
  };
}

export default useDownload;
