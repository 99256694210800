import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { get } from "lodash";
import compose from "recompose/compose";
import CircularProgress from "material-ui/CircularProgress";

import { openModal as openModalAction } from "../app/uiActions";
import { RefiVendorsList } from "../refiVendors";
import { togglePartnerRefiVendor as togglePartnerRefiVendorAction } from "./partnerActions";
import ThemedCard from "../components/cards/ThemedCard";

export const RefiSettings = ({
  partner,
  togglePartnerRefiVendor,
}) => {
  const partnerRefiVendors = get(partner, "refi_vendors_vendorables");
  const loadingIcon = <CircularProgress size={25} thickness={2} />;
  return (
    <ThemedCard withHeader={{ title: "Refi Vendors" }}>
      {partner ? (
        <RefiVendorsList
          partnerRefiVendors={partnerRefiVendors}
          partnerID={get(partner, "id")}
          toggleRefiVendor={togglePartnerRefiVendor}
        />
      ) : (
        loadingIcon
      )}
    </ThemedCard>
  );
};

RefiSettings.propTypes = {
  partner: PropTypes.object,
  togglePartnerRefiVendor: PropTypes.func.isRequired,
};

RefiSettings.defaultProps = {
  partner: null,
};

function mapStateToProps(state, props) {
  return {
    isLoading: state.employers.isFetching,
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      togglePartnerRefiVendor: togglePartnerRefiVendorAction,
      openModal: openModalAction,
    },
  ),
);

export default enhance(RefiSettings);
