import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import BaseSearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    position: "absolute",
    zIndex: 1
  }
}));

function SearchIcon({ loading, disabled, onClick }) {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} disabled={disabled || loading}>
      <BaseSearchIcon />
      {loading && (
        <CircularProgress size={40} className={classes.buttonProgress} />
      )}
    </IconButton>
  );
}

SearchIcon.propTypes = {
  loading: PropTypes.bool
};

export default SearchIcon;
