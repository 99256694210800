import { all, put, call, takeEvery } from "redux-saga/effects";
import { push, replace } from "react-router-redux";

import log from "../services/logging/service";
import { showNotification, hideNotification } from "../app/uiActions";
import { AUTH_LOGIN, AUTH_CHECK, AUTH_ERROR, AUTH_LOGOUT, INVALIDATE_SESSION } from "./types";
import {
  USER_LOGIN,
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_CHECK,
  USER_LOGOUT,
  FETCH_ERROR,
  USER_CHECK_SUCCESS
} from "./authActions";
import authClient from "./authClient";

const authSaga = () => {
  function* handleAuth(action) {
    const { type, payload, e: error, meta } = action;

    switch (type) {
      case USER_LOGIN: {
        try {
          yield put({ type: USER_LOGIN_LOADING });
          const authPayload = yield call(authClient, AUTH_LOGIN, payload);
          yield put({
            type: USER_LOGIN_SUCCESS,
            payload: authPayload
          });
          yield put(push(meta.pathName || "/"));
        } catch (e) {
          yield put({
            type: USER_LOGIN_FAILURE,
            error: e,
            meta: { auth: true }
          });
          const errorMessage =
            typeof e === "string"
              ? e
              : typeof e === "undefined" || !e.message
              ? "Error signing in"
              : e.message;
          yield put(showNotification(errorMessage, "warning"));
          log.error(errorMessage);
        }
        break;
      }
      case USER_CHECK: {
        try {
          const userDetails = yield call(authClient, AUTH_CHECK, payload);
          yield put({ type: USER_CHECK_SUCCESS, payload: userDetails });
        } catch (e) {
          yield call(authClient, AUTH_LOGOUT);
          yield put(
            replace({
              pathname: (e && e.redirectTo) || "/login",
              state: { nextPathname: meta.pathName }
            })
          );
          log.error(e ? e.message : e);
        }
        break;
      }
      case USER_LOGOUT: {
        let auth = localStorage.getItem("token");
        yield call(authClient, AUTH_LOGOUT);
        yield call(authClient, INVALIDATE_SESSION, auth);
        yield put(push("/login"));
        const message = action.payload || "You have logged out successfully!";
        yield put(showNotification(message));
        break;
      }
      case FETCH_ERROR:
        try {
          yield call(authClient, AUTH_ERROR, error);
        } catch (e) {
          yield put(push("/login"));
          yield put(hideNotification());
          log.error(e.message);
        }
        break;
      default:
        break;
    }
  }

  return function* watchAuthActions() {
    yield all([
      takeEvery(action => action.meta && action.meta.auth, handleAuth),
      takeEvery(FETCH_ERROR, handleAuth)
    ]);
  };
};

export default authSaga();
