import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { get } from "lodash";
import {
  fetchEmployer as fetchEmployerAction,
  fetchEmployerStats as fetchEmployerStatsAction,
} from "./employerActions";
//import { reset } from "../employees/actions/index";
import { selectEmployerByID, selectEmployerStatsByID } from "./selectors";
import { fetchSecondApproval as fetchSecondApprovalAction } from "../employees/actions";

export class Employer extends Component {
  componentDidMount() {
    const {
      match,
      fetchEmployer,
      fetchEmployerStats,
      fetchSecondApproval,
    } = this.props;
    const { employerID } = match.params;
    fetchEmployerStats(employerID);

    if (employerID) {
      fetchEmployer(employerID);
      fetchSecondApproval(employerID);
    }
  }

  componentDidUpdate(previousProps) {
    const pEmployerId = previousProps.employerID;
    const employerId = this.props.employerID;

    if (pEmployerId !== employerId) {
      this.props.fetchEmployer(employerId);
      //this.props.fetchSecondApproval(employerId);
    }
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.tRStatus === 1) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };
  render() {
    const {
      employerID,
      employer,
      employerStats,
      employerStatsLoading,
      history,
      second_approval,
      render,
    } = this.props;

    return render({
      employerID,
      employer,
      employerStats,
      employerStatsLoading,
      second_approval,
      history,
    });
  }
}

Employer.propTypes = {
  employer: PropTypes.object,
  employerStats: PropTypes.object,
  employerStatsLoading: PropTypes.bool.isRequired,
  fetchEmployerStats: PropTypes.func.isRequired,
  fetchEmployer: PropTypes.func.isRequired,
  render: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  //fetchSecondApproval:PropTypes.object.isRequired
};

Employer.defaultProps = {
  employerStats: {
    benefitStats: {},
    advisorStats: {},
  },
  employer: {},
  subscriptionEnabled: false,
};

function mapStateToProps(state, props) {
  const { employerID } = props.match.params;
  return {
    employerID,
    employer: selectEmployerByID(state, employerID),
    employerStats: selectEmployerStatsByID(state, employerID),
    employerStatsLoading: state.employerStats.isFetching,
    tRStatus: state.tutionsReducer.tRStatus,
    second_approval: state.tutionsReducer.getSecondApproval,
  };
}

const enhance = compose(
  connect(mapStateToProps, {
    fetchEmployerStats: fetchEmployerStatsAction,
    fetchEmployer: fetchEmployerAction,
    fetchSecondApproval: fetchSecondApprovalAction,
  })
);

export default enhance(Employer);
