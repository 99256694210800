import React from "react";
import Button from "@material-ui/core/IconButton";
import Icon from "@material-ui/icons/Close";

function Close(props) {
  return (
    <Button aria-label="close" {...props}>
      <Icon />
    </Button>
  );
}

export default Close;
