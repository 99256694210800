import React from "react";
import PropTypes from "prop-types";
import GenerateIcon from "@material-ui/icons/Description";

import CardActionButton from "../buttons/action/CardActionButton";

function GenerateReportButton({ disabled, onClick }) {
  return (
    <CardActionButton
      disabled={disabled}
      onClick={onClick}
      startIcon={<GenerateIcon />}
    >
      Generate Report
    </CardActionButton>
  );
}

GenerateReportButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default GenerateReportButton;
