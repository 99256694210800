import React from "react";
import PropTypes from "prop-types";

import Grid from "./Grid";

function HVCentered({ direction = "column", spacing = 2, children }) {
  return (
    <Grid
      direction={direction}
      spacing={spacing}
      justify="center"
      alignItems="center"
    >
      {children}
    </Grid>
  );
}

HVCentered.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]),
  spacing: PropTypes.oneOf([0, 2, 4, 6, 8, 10]),
  children: PropTypes.node.isRequired
};

export default HVCentered;
