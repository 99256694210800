import * as R from "ramda";
import toMoneyString, { isEmptyOrNull } from "./toMoneyString";

const dotPath = R.useWith(R.path, [R.split(".")]);
const centsToMoneyString = R.ifElse(
  isEmptyOrNull,
  toMoneyString,
  R.pipe(
    R.divide(R.__, 100),
    toMoneyString
  )
);

export const centsToMoneyStringFromProp = R.curryN(
  2,
  R.compose(
    centsToMoneyString,
    dotPath
  )
);
export default centsToMoneyString;
