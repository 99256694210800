import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { values } from "lodash";

import AutoSuggest from "../../components/inputs/AutoSuggest";
import { fetchAdminUsersByEmail } from "../../adminUsers/adminUserActions";
import { selectAdminUsers } from "../../adminUsers/selectors";

const getSuggestionValue = suggestion => suggestion.email;

class EmailSuggest extends React.Component {
  handleChange = value => {
    const {
      employerId,
      fetchAdminUsersByEmail,
      onChange,
      emailLoading
    } = this.props;
    const query = value;

    if (!emailLoading) {
      fetchAdminUsersByEmail({ employerID: employerId }, query);
    }
    if (onChange) onChange(value);
  };

  handleSuggestionSelected = value => {
    const { onSuggestionSelected } = this.props;

    if (onSuggestionSelected) onSuggestionSelected(value);
  };

  render() {
    const { emailLoading, initialValue } = this.props;

    const adminUsers = this.props.adminUsers
      ? values(this.props.adminUsers)
      : [];

    return (
      <AutoSuggest
        placeholder="Type Email..."
        data={adminUsers}
        getSuggestionValue={getSuggestionValue}
        onChange={this.handleChange}
        onSuggestionSelected={this.handleSuggestionSelected}
        loading={emailLoading}
        initialValue={initialValue}
        autoFocus={true}
      />
    );
  }
}

EmailSuggest.propTypes = {
  onChange: PropTypes.func
};

function mapStateToProps(state, props) {
  const adminUsers = selectAdminUsers(state);
  const emailLoading = state.adminUsers.isFetching;

  return {
    adminUsers,
    emailLoading
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchAdminUsersByEmail
    }
  )
);

export default enhance(EmailSuggest);
