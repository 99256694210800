import { createSelector } from "reselect";

const reducerName = "bulkUpload";

export const selectBatchCreateEligibleEmployeesFetching = state => {
  return state[reducerName].batchCreateEligibleEmployeesFetching;
};

export const selectJobFetching = state => {
  return state[reducerName].jobFetching;
};

export const selectJobDetailsFetching = state => {
  return state[reducerName].jobDetailsFetching;
};

export const selectHistoryFetching = state => {
  return state[reducerName].historyFetching;
};

export const csvDownloadFetching = state => {
  return state[reducerName].csvDownloadFetching;
};

const selectHistoryBatches = state =>
  state[reducerName].historyBatches.byEmployerId;

export const selectHistoryByEmployerId = createSelector(
  [selectHistoryBatches, (_, employerId) => employerId],
  (batches, employerId) => {
    return batches[employerId] ? batches[employerId].all : [];
  }
);

const selectJobs = state => state[reducerName].jobs.byBatchId;

export const selectJobByBatchId = createSelector(
  [selectJobs, (_, batchId) => batchId],
  (jobs, batchId) => {
    return jobs[batchId] || {};
  }
);

const selectJobDetails = state => state[reducerName].jobDetails.byBatchId;

export const selectJobDetailsByBatchId = createSelector(
  [selectJobDetails, (_, batchId) => batchId],
  (jobDetails, batchId) => {
    return jobDetails[batchId] || {};
  }
);
