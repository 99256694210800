import createReducer from "../../helpers/createReducer";
import {
  GET_INVOICES_REQUEST,
  GET_INVOICES_SUCCESS,
  GET_INVOICES_FAILURE,
  APPROVE_REQUEST,
  APPROVE_SUCCESS,
  APPROVE_FAILURE,
  SELECTED_INVOICE_UPDATE,
  GET_INVOICE_REQUEST,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  GET_EXCLUSION_REQUEST,
  GET_EXCLUSION_SUCCESS,
  GET_EXCLUSION_FAILURE,
  GET_COMBINED_EXCLUSION_REQUEST,
  GET_COMBINED_EXCLUSION_SUCCESS,
  GET_COMBINED_EXCLUSION_FAILURE,
  RESTORE_REQUEST,
  RESTORE_SUCCESS,
  RESTORE_FAILURE,
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  GET_COMBINED_INVOICE_FAILURE,
  GET_COMBINED_INVOICE_REQUEST,
  GET_COMBINED_INVOICE_SUCCESS
} from "../actions/invoices";

const defaultState = {
  invoices: {
    byId: {},
    all: [],
    total: 0
  },
  exclusions: {
    byInvoiceId: {
      byId: {},
      all: [],
      total: 0
    }
  },
  selectedInvoice: null,
  selectedExclusion: null,
  restoreStatus: {
    byItemId: {}
  },
  deleteItemsStatus: {
    byItemId: {}
  },
  getInvoicesFetching: false,
  getInvoicesErrors: null,
  getExclusionFetching: false,
  getExclusionErrors: null,
  aprroveFetching: false,
  approveErrors: null,
  getInvoiceFetching: false,
  getInvoiceErrors: null
};

function getInvoicesRequest(state) {
  return Object.assign({}, state, {
    getInvoicesFetching: true,
    getInvoicesErrors: null
  });
}

function getInvoicesSuccess(state, { invoices }) {
  return Object.assign({}, state, {
    invoices,
    getInvoicesFetching: false,
    getInvoicesErrors: null
  });
}
function getInvoicesFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    getInvoicesFetching: false,
    getInvoicesErrors: error
  });
}

function getCombinedInvoiceRequest(state) {
  return Object.assign({}, state, {
    getInvoiceFetching: true,
    getInvoiceErrors: null
  });
}

function getCombinedInvoiceSuccess(state, { invoiceId, invoice }) {
  return {
    ...state,
    selectedInvoice: { ...state.selectedInvoice, ...invoice },
    getInvoiceFetching: false,
    getInvoiceErrors: null
  };
}
function getCombinedInvoiceFailure(state, action) {
  const error = action.error;
  return Object.assign({}, state, {
    getInvoiceFetching: false,
    getInvoiceErrors: error
  });
}

function getInvoiceRequest(state) {
  return Object.assign({}, state, {
    getInvoiceFetching: true,
    getInvoiceErrors: null
  });
}

function getInvoiceSuccess(state, { invoice }) {
  const selectedInvoice = { ...state.selectedInvoice, ...invoice };
  return {
    ...state,
    selectedInvoice,
    getInvoiceFetching: false,
    getInvoiceErrors: null
  };
}
function getInvoiceFailure(state, action) {
  const error = action.error;
  return { ...state, getInvoiceFetching: false, getInvoiceErrors: error };
}

function getExclusionRequest(state) {
  return { ...state, getExclusionFetching: true, getExclusionErrors: null };
}

function getExclusionSuccess(state, { exclusion }) {
  return {
    ...state,
    selectedExclusion: exclusion,
    getExclusionFetching: false,
    getExclusionErrors: null
  };
}
function getExclusionFailure(state, action) {
  const error = action.error;
  return { ...state, getExclusionFetching: false, getExclusionErrors: error };
}

function getCombinedExclusionRequest(state) {
  return { ...state, getExclusionFetching: true, getExclusionErrors: null };
}

function getCombinedExclusionSuccess(state, { exclusion }) {
  return {
    ...state,
    selectedExclusion: exclusion,
    getExclusionFetching: false,
    getExclusionErrors: null
  };
}
function getCombinedExclusionFailure(state, action) {
  const error = action.error;
  return { ...state, getExclusionFetching: false, getExclusionErrors: error };
}

function approveRequest(state) {
  return Object.assign({}, state, {
    approveFetching: true,
    approveErrors: null
  });
}

function approveSuccess(state, { invoiceId }) {
  const invoice = state.selectedInvoice;
  invoice.status = "approved";

  return {
    ...state,
    selectedInvoice: invoice,
    approveFetching: false,
    approveErrors: null
  };
}
function approveFailure(state, { error }) {
  return Object.assign({}, state, {
    approveFetching: false,
    approveErrors: error
  });
}

function restoreRequest(state, { itemId }) {
  const restoreStatus = {
    ...state.restoreStatus,
    byItemId: {
      [itemId]: {
        fetching: true,
        error: null
      }
    }
  };
  return { ...state, restoreStatus };
}

function restoreSuccess(state, { invoiceId, itemId }) {
  const exclusions = state.selectedExclusion.filter(
    exclusion => exclusion.id !== itemId
  );

  const restoreStatus = {
    ...state.restoreStatus,
    byItemId: {
      [itemId]: {
        fetching: false,
        error: null
      }
    }
  };

  return {
    ...state,
    selectedExclusion: exclusions,
    restoreStatus
  };
}

function restoreFailure(state, { itemId, error }) {
  const restoreStatus = {
    ...state.restoreStatus,
    byItemId: {
      [itemId]: {
        fetching: false,
        error
      }
    }
  };
  return { ...state, restoreStatus };
}

function deleteItemRequest(state, { invoiceItemId }) {
  const deleteItemsStatus = Object.assign({}, state.deleteItemsStatus, {
    byItemId: {
      [invoiceItemId]: {
        fetching: true,
        error: null
      }
    }
  });
  return Object.assign({}, state, {
    deleteItemsStatus
  });
}

function deleteItemSuccess(state, { invoiceId, invoiceItemId, pullingForId }) {
  const deleteItemsStatus = Object.assign({}, state.deleteItemsStatus, {
    byItemId: {
      [invoiceItemId]: {
        fetching: false,
        error: null
      }
    }
  });

  const lineItem = state.selectedInvoice.pdr_line_items.filter(
    item => item.id === invoiceItemId
  );
  const lineItems = state.selectedInvoice.pdr_line_items.filter(
    item => item.id !== invoiceItemId
  );
  const feeItems = state.selectedInvoice.fee_line_items.filter(
    item => item.pulling_for_id !== pullingForId
  );
  const exclusions = state.selectedExclusion.concat([
    {
      id: lineItem[0].pulling_for_id,
      full_name: lineItem[0].pulling_for_name,
      reason: "Removed",
      invoice_line_item_id: lineItem[0].id,
      amount_cents: lineItem[0].amount_cents
    }
  ]);

  return {
    ...state,
    deleteItemsStatus,
    selectedExclusion: exclusions,
    selectedInvoice: {
      ...state.selectedInvoice,
      pdr_line_items: lineItems,
      fee_line_items: feeItems
    }
  };
}
function deleteItemFailure(state, { invoiceItemId, error }) {
  const deleteItemsStatus = Object.assign({}, state.deleteItemsStatus, {
    byItemId: {
      [invoiceItemId]: {
        fetching: false,
        error
      }
    }
  });
  return Object.assign({}, state, {
    deleteItemsStatus
  });
}

function selectedInvoiceUpdate(state, { invoiceId }) {
  return {
    ...state,
    selectedInvoice: state.invoices.byId[invoiceId]
  };
}

export default createReducer(defaultState, {
  [GET_INVOICES_REQUEST]: getInvoicesRequest,
  [GET_INVOICES_SUCCESS]: getInvoicesSuccess,
  [GET_INVOICES_FAILURE]: getInvoicesFailure,
  [SELECTED_INVOICE_UPDATE]: selectedInvoiceUpdate,
  [GET_INVOICE_REQUEST]: getInvoiceRequest,
  [GET_INVOICE_SUCCESS]: getInvoiceSuccess,
  [GET_INVOICE_FAILURE]: getInvoiceFailure,
  [GET_COMBINED_INVOICE_REQUEST]: getCombinedInvoiceRequest,
  [GET_COMBINED_INVOICE_SUCCESS]: getCombinedInvoiceSuccess,
  [GET_COMBINED_INVOICE_FAILURE]: getCombinedInvoiceFailure,
  [GET_EXCLUSION_REQUEST]: getExclusionRequest,
  [GET_EXCLUSION_SUCCESS]: getExclusionSuccess,
  [GET_EXCLUSION_FAILURE]: getExclusionFailure,
  [GET_COMBINED_EXCLUSION_REQUEST]: getCombinedExclusionRequest,
  [GET_COMBINED_EXCLUSION_SUCCESS]: getCombinedExclusionSuccess,
  [GET_COMBINED_EXCLUSION_FAILURE]: getCombinedExclusionFailure,
  [APPROVE_REQUEST]: approveRequest,
  [APPROVE_SUCCESS]: approveSuccess,
  [APPROVE_FAILURE]: approveFailure,
  [RESTORE_REQUEST]: restoreRequest,
  [RESTORE_SUCCESS]: restoreSuccess,
  [RESTORE_FAILURE]: restoreFailure,
  [DELETE_ITEM_REQUEST]: deleteItemRequest,
  [DELETE_ITEM_SUCCESS]: deleteItemSuccess,
  [DELETE_ITEM_FAILURE]: deleteItemFailure
});
