import BaseRequest from "../../shared/BaseRequest";

export default class BulkUploadHistoryRequest extends BaseRequest {
  constructor({ url, params, query }) {
    const filter = [];
    if (query) {
      filter.push({ created_by_name_cont: query });
      filter.push({ file_name_cont: query });
    }

    super({ url, params: { ...params, filter } });
  }
}
