function requestPermission() {
  const _getPermissions = () => {
    return localStorage.getItem("role");
  };

  const allowWith = given => {
    const permissions = _getPermissions();
    return given.includes(permissions);
  };

  return {
    allowWith
  };
}

export default requestPermission();
