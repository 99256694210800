import { put, takeLatest, all, call, takeEvery } from "redux-saga/effects";

import log from "../../services/logging/service";
import routeService from "../services/RouteService";
import fetchApiService from "../../rest/fetchApiService";

/** migrating to this service from fechApiService */
import fetchService from "../../services/api/fetchService";
import { showError, showSuccess } from "../../shared/sagas/notifications";
import {
  GET_IMPACT_REPORTS_REQUEST,
  GET_EMPLOYER_REPORTS_REQUEST,
  getReportsSuccess,
  getReportsFailure,
  DOWNLOAD_REPORT_REQUEST,
  downloadReportSuccess,
  downloadReportFailure
} from "../actions";
import GetReportRequest from "../services/GetReportsRequest";
import GetReportResponse from "../services/GetReportsResponse";

function* getEmployerReports({ employerId }) {
  try {
    const endpoint = routeService.getEmployerReports(employerId);
    const request = new GetReportRequest({ url: endpoint });
    const response = yield fetchApiService.fetch(request, GetReportResponse);
    if (response.ok) {
      const data = response.getListData(employerId);

      yield put(getReportsSuccess(data));
    } else {
      yield call(showError, response.statusText);
      yield put(getReportsFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getReportsFailure(e.message));
    log.error(e.message);
  }
}

/** download report */

function* downloadReport({ employerId, reportId, fileName }) {
  try {
    const url = routeService.getEmployerReportDownload(employerId, reportId);
    const response = yield fetchApiService.fetchFile(url, fileName);
    if (response.ok) {
      yield call(showSuccess, "Report successfully downloaded!");
      yield put(downloadReportSuccess());
    } else {
      yield call(showError, response.statusText);
      yield put(downloadReportFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(downloadReportFailure(e.message));
    log.error(e.message);
  }
}

function* getImpactReport({ employerId, fileName }) {
  try {
    const url = routeService.getImpactReport(employerId);
    yield fetchService.fetchFile(url, {
      saveAs: fileName,
      mimeType: "application/pdf"
    });
    yield call(showSuccess, "Report successfully downloaded!");
  } catch (e) {
    yield call(showError, e.message);
    log.error(e.message);
  }
}

export default function* getReportsSagas() {
  yield all([
    takeEvery(GET_EMPLOYER_REPORTS_REQUEST, getEmployerReports),
    takeLatest(DOWNLOAD_REPORT_REQUEST, downloadReport),
    takeLatest(GET_IMPACT_REPORTS_REQUEST, getImpactReport)
  ]);
}
