import { CREATE, UPDATE } from "../rest/types";

export const HIDE_TWO_FACTOR = "HIDE_TWO_FACTOR";
export const SHOW_TWO_FACTOR = "SHOW_TWO_FACTOR";

export const hideTwoFactor = () => ({
  type: HIDE_TWO_FACTOR,
  payload: { show: false }
});

export const showTwoFactor = () => ({
  type: SHOW_TWO_FACTOR,
  payload: { show: true }
});

export const VERIFY_TWO_FACTOR = "VERIFY_TWO_FACTOR";
export const VERIFY_TWO_FACTOR_FAILURE = "VERIFY_TWO_FACTOR_FAILURE";
export const VERIFY_TWO_FACTOR_SUCCESS = "VERIFY_TWO_FACTOR_SUCCESS";

export const verifyTwoFactor = values => ({
  type: VERIFY_TWO_FACTOR,
  payload: { data: { ...values } },
  meta: { resource: "verify", fetch: CREATE, cancelPrevious: false }
});

export const ENABLE_TWO_FACTOR = "ENABLE_TWO_FACTOR";
export const ENABLE_TWO_FACTOR_FAILURE = "ENABLE_TWO_FACTOR_FAILURE";
export const ENABLE_TWO_FACTOR_SUCCESS = "ENABLE_TWO_FACTOR_SUCCESS";

export const enableTwoFactor = (countryCode, cellphone) => ({
  type: ENABLE_TWO_FACTOR,
  payload: { data: { country_code: countryCode, cellphone } },
  meta: { resource: "enable", fetch: UPDATE, cancelPrevious: false }
});

export const DISABLE_TWO_FACTOR = "DISABLE_TWO_FACTOR";
export const DISABLE_TWO_FACTOR_FAILURE = "DISABLE_TWO_FACTOR_FAILURE";
export const DISABLE_TWO_FACTOR_SUCCESS = "DISABLE_TWO_FACTOR_SUCCESS";

export const disableTwoFactor = token => ({
  type: DISABLE_TWO_FACTOR,
  payload: { data: { token } },
  meta: { resource: "disable", fetch: UPDATE, cancelPrevious: false }
});
