import { cloneElement } from "react";
import PropTypes from "prop-types";

export const Modal = ({ onClose, item }) => {
  return cloneElement(item.content, {
    // Default actions passed into each modal, these can be overwritten on each element to provide alternative behavior
    onClose: () => onClose(item)
  });
};

Modal.propTypes = {
  close: PropTypes.func,
  item: PropTypes.shape({
    content: PropTypes.element,
    id: PropTypes.string
  })
};

export default Modal;
