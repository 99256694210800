import * as Sentry from "@sentry/browser";

function info(message, logInConsole = false) {
  Sentry.captureException(message);
  if (logInConsole) console.info(message);
}

function error(message, logInConsole = true) {
  Sentry.captureException(message);
  if (logInConsole) console.error(message);
}

export default {
  info,
  error
};
