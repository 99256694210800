import urlService from "rest/urlService";

const routeService = ({ urlService }) => {
  const _urlService = urlService;
  const _baseUrl = _urlService.getBase();

  const sendInvites = ({ employerId, groupId }) => {
    return `${_baseUrl}/employers/${employerId}/groups/${groupId}/send_invites`;
  };

  return {
    sendInvites
  };
};

export default routeService({ urlService });
