import React from "react";
import * as R from "ramda";
import { makeStyles } from "@material-ui/core/styles";
import BaseTableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { TableHeadProps } from "../types";
import useSort from "./useSort";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    fontWeight: "bold"
  }
}));

function TableHead({
  loading,
  disabled,
  columns,
  disableSort,
  defaultSort,
  onSortChange,
  showSelection,
  selected,
  onSelectChange
}) {
  const classes = useStyles();
  const { createSortHandler, orderBy, order } = useSort({
    defaultSort,
    onSort: onSortChange
  });

  const filteredColumns = R.filter(c => c.hideColumn !== true, columns);
  return (
    <BaseTableHead>
      <TableRow>
        {showSelection && !loading && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={selected}
              onChange={onSelectChange}
              disabled={disabled}
            />
          </TableCell>
        )}
        {filteredColumns.map((column, index) => (
          <TableCell
            key={column.name + index}
            style={column.style}
            sortDirection={false}
          >
            <TableSortLabel
              className={classes.label}
              active={orderBy === column.name}
              direction={order}
              onClick={createSortHandler(column.name)}
              disabled={disableSort || column.disableSort || disabled}
            >
              {column.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </BaseTableHead>
  );
}

TableHead.propTypes = TableHeadProps;

export default TableHead;
