import JSZip from "jszip";
import FileSaver from "file-saver";

import fetchService from "../../../services/api/fetchService";

function getPath(id, type = "pdf") {
  const endpoint = type === "pdf" ? "download" : "download_xls";
  return `/invoices/${id}/${endpoint}`;
}

export default ({ pdr_id, fee_id }) => (type = "pdf") => {
  const pdrPath = getPath(pdr_id, type);
  const feesPath = getPath(fee_id, type);
  const pdrFile = fetchService.fetchFileData(pdrPath);
  const feesFile = fetchService.fetchFileData(feesPath);

  const fileExt = type === "pdf" ? "pdf" : "xlsx";
  const zip = new JSZip();
  zip.file(`pdr.${fileExt}`, pdrFile);
  zip.file(`fees.${fileExt}`, feesFile);
  zip.generateAsync({ type: "blob" }).then(function(content) {
    FileSaver(content, "invoices.zip");
  });
};
