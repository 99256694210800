import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";

import { Card, CardText, CardHeader } from "material-ui/Card";
import { Link } from "react-router-dom";
import getMuiTheme from "material-ui/styles/getMuiTheme";

import { getLogsRequest, getLogsCsvRequest } from "../actions/ultiproActions";
import { SLGTableColumn, SLGList } from "../../components";
import { SORT_DESC } from "../../constants";
import {
  selectGetLogsFetching,
  selectLogsByEmployerId
} from "../selectors-ext/ultiproSelectors";
import SearchForm from "../../components/SearchForm";
import { theme } from "../../app";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  rowFieldCenter: { textAlign: "center" },
  raisedButton: { borderRadius: "10px" },
  table: { minWidth: "100%", width: "auto" }
};

export class UltiproLogs extends Component {
  fetchLogsQuery = query => {
    const defaultSort = { field: "created_at", order: SORT_DESC };
    return this.props.getLogsRequest(
      this.props.employerId,
      defaultSort,
      1,
      query
    );
  };

  static propTypes = {
    employerId: PropTypes.string.isRequired,
    getLogsRequest: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    logs: PropTypes.array,
    total: PropTypes.number
  };

  requestLogs = (employerId, ignorePagination, sort, page) => {
    const { getLogsRequest } = this.props;

    getLogsRequest(employerId, sort, page);
  };

  downloadLogs(e) {
    e.preventDefault();
    const { employerId } = this.props;
    this.props.getLogsCsvRequest(employerId);
  }

  render() {
    const { employerId, logs, isLoading, total } = this.props;
    const primaryColor = getMuiTheme(theme).palette["primary1Color"];

    return (
      <Card>
        <CardHeader
          title={
            <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
              Ultipro Logs
            </span>
          }
          style={styles.header}
        >
          <CardText>
            <Link
              to="#"
              style={{
                ...styles.textLink,
                color: primaryColor,
                float: "right",
              }}
              onClick={e => this.downloadLogs(e)}
            >
              Download Ultipro Logs CSV
            </Link>
          </CardText>
        </CardHeader>
        <CardText>
          <SearchForm onSubmit={this.fetchLogsQuery} />

          <SLGList
            fetchData={this.requestLogs}
            params={[employerId, false]}
            data={logs}
            tableStyle={styles.table}
            isLoading={isLoading}
            total={total}
          >
            <SLGTableColumn
              field="description"
              title="description"
              rowStyle={styles.rowField}
              headerStyle={styles.headerColumn}
            />
            <SLGTableColumn
              sortable={true}
              field="created_at"
              title="Created On"
              rowStyle={styles.rowField}
              headerStyle={styles.headerColumn}
              render={val => moment(val.created_at).format("MMM D, YYYY h:mm:ss A")}
            />
          </SLGList>
        </CardText>
      </Card>
    );
  }
}

function mapStateToProps(state, props) {
  const employerId = props.employerId;
  const employerLogs = selectLogsByEmployerId(state, employerId);
  return {
    employerId,
    isLoading: selectGetLogsFetching(state),
    logs: employerLogs ? employerLogs.all : [],
    total: employerLogs ? employerLogs.total : 0
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      getLogsRequest,
      getLogsCsvRequest
    }
  )
);

export default enhance(UltiproLogs);
