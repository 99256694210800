import React from "react";

import { ListTableProps } from "../types";
import Card from "../../cards/basic-card/Card";
import Table from "./Table";

function ListTable({
  hideTitleBar,
  title,
  columns,
  rows,
  total,
  disabled,
  loading,
  actionSlot,
  subActionSlot,
  subAction1Slot,
  disableSort,
  defaultSort,
  headerActionHandlers,
  hideSearch,
  searchFormProps,
  showSelection,
  onSelectChange,
  onSelectAllChange,
  onChangePage,
  selected,
  getRowId
}) {
  return (
    <Card
      hideTitleBar={hideTitleBar}
      title={title}
      loading={loading}
      disabled={disabled || loading}
      actionSlot={actionSlot}
      subActionSlot={subActionSlot}
      subAction1Slot={subAction1Slot}
      hideSearch={hideSearch}
      searchFormProps={searchFormProps}
      contentSlot={() => (
        <Table
          columns={columns}
          rows={rows}
          total={total}
          loading={loading}
          disabled={disabled}
          disableSort={disableSort}
          defaultSort={defaultSort}
          headerActionHandlers={headerActionHandlers}
          showSelection={showSelection}
          onSelectChange={onSelectChange}
          onSelectAllChange={onSelectAllChange}
          onChangePage={onChangePage}
          selected={selected}
          getRowId={getRowId}
        />
      )}
    />
  );
}

ListTable.propTypes = ListTableProps;

export default ListTable;
