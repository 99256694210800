import { centsToMoneyStringFromProp } from "services/formatters";
import Panel, { withProps } from "./Panel";

const getRowId = r => r.id;
const formatAmount = centsToMoneyStringFromProp("amount_cents");
const columns = [
  {
    name: "personal_ids",
    label: "Personal ID"
  },
  {
    name: "pulling_for_name",
    label: "Name"
  },
  {
    name: "amount_cents",
    label: "Amount",
    render: formatAmount
  }
];

export default withProps({ columns, getRowId })(Panel);
