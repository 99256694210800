import { put, takeLatest, all, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { destroy } from "redux-form";
import { concat, map, isEmpty } from "lodash";

import log from "../services/logging/service";
import { SYSTEM_ADMIN } from "../constants";
import {
  REGISTER_ADMIN_USER,
  REGISTER_ADMIN_USER_SUCCESS,
  REGISTER_ADMIN_USER_FAILURE,
  FETCH_ADMIN_USERS_SUCCESS,
  FETCH_ADMIN_USERS_FAILURE,
  FETCH_ADMIN_USERS,
  FETCH_ADMIN_USERS_BY_EMAIL,
  CREATE_ADMIN_USER_SUCCESS,
  CREATE_ADMIN_USER_FAILURE,
  CREATE_ADMIN_USER,
  EDIT_ADMIN_USER_SUCCESS,
  EDIT_ADMIN_USER_FAILURE,
  EDIT_ADMIN_USER
} from "./adminUserActions";
import { showNotification, closeAllModals } from "../app/uiActions";
import { FETCH_ERROR } from "../auth/authActions";
import slgRestClient from "../rest/slgRestClient";
import { GET_LIST, CREATE, UPDATE } from "../rest/types";
import { SORT_DESC, PER_PAGE } from "../constants";
import authClient from "../helpers/apiClient";

function _submissionErrorNotification(errorMessage) {
  log.error(errorMessage);
  return showNotification(
    errorMessage + ", please update and resubmit.",
    "warning"
  );
}

function* registerAdminUser(action) {
  try {
    yield call(authClient, "registrations", action.payload.data);
    yield put({ type: REGISTER_ADMIN_USER_SUCCESS });
    yield put(showNotification("Admin user registered successfully!"));
    yield put(push("/"));
  } catch (e) {
    yield put({
      type: REGISTER_ADMIN_USER_FAILURE,
      message: e.message
    });
    yield put(
      showNotification(
        e.message ? e.message : "An error occurred while registering your admin user.",
        "warning"
      )
    );
    log.error(e.message);
  }
}

function requestAdminUsers(
  adminUserType,
  employerID,
  partnerID,
  sort,
  page = 1,
  query
) {
  const sortParams = sort || { field: "id", order: SORT_DESC };
  const searchObject = {};

  const params = {
    filter: [],
    pagination: { page, perPage: PER_PAGE }, // Should match api
    sort: sortParams
  };

  // TODO: adminUserType null default should be moved to here, and removed from adminUserActions
  // ransack search predicate syntax
  // if (adminUserType) {
  //   typeSearchObject = { type_eq: adminUserType };
  //   params.filter.push(typeSearchObject);
  // } else {
  //   typeSearchObject = {};
  // }

  if (employerID) {
    // Not used directly in ransack searching, hence no "_eq" suffix
    searchObject.employer_id = employerID;
  }
  if (partnerID) {
    // ransack search predicate syntax
    searchObject.admin_users_partner_partner_id_eq = partnerID;
  }

  if (!isEmpty(searchObject)) {
    params.filter.push(searchObject);
  }

  if (query) {
    const fullTextSearchObject = {
      email_cont: query,
      first_name_cont: query,
      last_name_cont: query,
      m: "or"
    };

    params.filter.push(fullTextSearchObject);
  }

  return slgRestClient(GET_LIST, "admin_users", params)
    .then(resp => {
      return { data: resp.data, total: resp.total };
    })
    .catch(e => log.error(e.message));
}

function* fetchAdminUsers(action) {
  try {
    const adminUsers = yield call(
      requestAdminUsers,
      action.payload.adminUserType,
      action.payload.employerID,
      action.payload.partnerID,
      action.payload.sort,
      action.payload.page,
      action.payload.query
    );
    yield put({
      type: FETCH_ADMIN_USERS_SUCCESS,
      payload: adminUsers
    });
  } catch (e) {
    yield put({ type: FETCH_ADMIN_USERS_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification(
        "An error occurred while fetching admin users",
        "warning"
      )
    );
    log.error(e.message);
  }
}

function requestAdminUsersByEmail(adminUserType, employerID, partnerID, query) {
  const sort = { field: "email", order: SORT_DESC };
  const searchObject = {};

  const params = {
    filter: [],
    pagination: { page: 1, perPage: PER_PAGE },
    sort
  };

  if (employerID) {
    // Not used directly in ransack searching, hence no "_eq" suffix
    searchObject.employer_id = employerID;
  }
  if (partnerID) {
    // ransack search predicate syntax
    searchObject.admin_users_partner_partner_id_eq = partnerID;
  }

  if (!isEmpty(searchObject)) {
    params.filter.push(searchObject);
  }

  if (query) {
    const fullTextSearchObject = {
      email_cont: query
    };

    params.filter.push(fullTextSearchObject);
  }

  return slgRestClient(GET_LIST, "admin_users", params)
    .then(resp => {
      return { data: resp.data, total: resp.total };
    })
    .catch(e => log.error(e.message));
}

function* fetchAdminUsersByEmail(action) {
  try {
    const adminUsers = yield call(
      requestAdminUsersByEmail,
      action.payload.adminUserType,
      action.payload.employerID,
      action.payload.partnerID,
      action.payload.query
    );
    yield put({
      type: FETCH_ADMIN_USERS_SUCCESS,
      payload: adminUsers
    });
  } catch (e) {
    yield put({ type: FETCH_ADMIN_USERS_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification(
        "An error occurred while fetching admin users",
        "warning"
      )
    );
    log.error(e.message);
  }
}

function requestAdminUserCreate(data, context, partnerID, employerFilterIDs) {
  const adminUserPartner = partnerID
    ? {
        admin_users_partner_attributes: {
          partner_id: partnerID
        }
      }
    : null;

  let filtersAttributes = null;

  if (employerFilterIDs) {
    const employerIDs = map(employerFilterIDs, employerID => {
      return { employer_id: employerID, whitelist: true };
    });

    filtersAttributes = { filters_attributes: employerIDs };
  }

  const adminUserAttributes = {
    ...adminUserPartner,
    ...filtersAttributes,
    context
  };

  return slgRestClient(CREATE, "admin_users", {
    data: { ...data, ...adminUserAttributes }
  })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* createAdminUser(action) {
  try {
    const adminUserCreate = yield call(
      requestAdminUserCreate,
      action.payload.data,
      action.context,
      action.partnerID,
      action.employerFilterIDs
    );

    //If user is system_admin and employerFilterIDs exists, then map it
    // otherwise, keep the same logic as before
    if (action.context === SYSTEM_ADMIN) {
      if (action.employerFilterIDs) {
        adminUserCreate.filters = action.employerFilterIDs.map(v => ({
          employer_id: v
        }));
      }
    } else {
      adminUserCreate.filters = action.employerFilterIDs.map(v => ({
        employer_id: v
      }));
    }
    yield put({ type: CREATE_ADMIN_USER_SUCCESS, payload: adminUserCreate });

    if (action.onSuccess && typeof action.onSuccess === "function") {
      yield call(action.onSuccess);
    }

    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Admin User created successfully"));
  } catch (e) {
    yield put({ type: CREATE_ADMIN_USER_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

function requestAdminUserEdit(
  data,
  adminUserID,
  context,
  employersToAdd = [],
  employersToRemove = [],
  currentEmployers = []
) {
  // TODO: This needs to be changed in AdminUserEditModal -- "context" is the key for the new role, "type" is derived on
  //  the backend from type_id
  // const contextFromType = context === data.role ? data.type : context;
  const contextFromType = data.type;

  const filtersList =
    context === "partner_admin"
      ? []
      : concat(
          map(currentEmployers, emp => {
            return { id: emp.id };
          }),
          map(employersToAdd, emp => {
            return { employer_id: emp.employer_id };
          }),
          map(employersToRemove, emp => {
            return { id: emp.id, _destroy: true };
          })
        );

  return slgRestClient(UPDATE, "admin_users", {
    id: adminUserID,
    // data: { ...data, context: contextFromType, filters_attributes: filtersList }
    data: {
      ...data,
      context: contextFromType,
      filters_attributes: filtersList
    }
  })
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* editAdminUser(action) {
  try {
    const adminUserEdit = yield call(
      requestAdminUserEdit,
      action.payload.data,
      action.adminUserID,
      action.context,
      action.employersToAdd,
      action.employersToRemove,
      action.currentEmployers
    );
    yield put({ type: EDIT_ADMIN_USER_SUCCESS, payload: adminUserEdit });
    if (action.onSuccess) {
      yield call(action.onSuccess);
    }

    yield put(destroy(action.formName));
    yield put(closeAllModals());
    yield put(showNotification("Admin User edited successfully"));
  } catch (e) {
    yield put({ type: EDIT_ADMIN_USER_FAILURE, message: e.message });
    yield put(_submissionErrorNotification(e.message));
    log.error(e.message);
  }
}

export default function* adminUserSaga() {
  yield all([
    takeLatest(REGISTER_ADMIN_USER, registerAdminUser),
    takeLatest(FETCH_ADMIN_USERS, fetchAdminUsers),
    takeLatest(FETCH_ADMIN_USERS_BY_EMAIL, fetchAdminUsersByEmail),
    takeLatest(CREATE_ADMIN_USER, createAdminUser),
    takeLatest(EDIT_ADMIN_USER, editAdminUser)
  ]);
}
