import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";
import { includes, reject, startCase } from "lodash";

import FlatButton from "material-ui/FlatButton";
import EditContent from "material-ui/svg-icons/editor/mode-edit";

import { AdminUserEditModal } from "../adminUsers/modals";
import { fetchAdminUsers as fetchAdminUsersAction } from "../adminUsers/adminUserActions";
import { openModal as openModalAction } from "../app/uiActions";
import { SLGTableColumn, SLGList, InfoIcon } from "../components";
import SearchForm from "../components/SearchForm";
import {
  SYSTEM_ADMIN,
  BENEFITS_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SUPPORT_REP,
  PARTNER_MANAGER
} from "../constants";
import { adminTypeTooltipMsg } from "../helpers";
import { makeSelectAdminUsersByPartner } from "../adminUsers/selectors";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  rowField: { textAlign: "center" },
  columnHeader: { textAlign: "center" },
  inactive: { color: "#FF0000" },
  editButton: { color: "#00acc1" }
};

const renderEditButton = (
  adminUser,
  openModal,
  partnerID,
  currentUserRole,
  currentUserID
) => {
  const editButton = (
    <FlatButton
      label="Edit"
      labelStyle={styles.editButton}
      icon={
        <EditContent
          color={styles.editButton.color}
          style={styles.buttonIcon}
        />
      }
      onClick={() =>
        openModal(
          <AdminUserEditModal
            adminUser={adminUser}
            context={PARTNER_ADMIN}
            partnerID={partnerID}
          />
        )
      }
      style={{ overflow: "inherit" }}
    />
  );

  // Partner Admin can only edit lower level partner admins
  const lowerPartnerAdmin = includes(
    [EMPLOYERS_ADMIN, EMPLOYER_MANAGER, SUPPORT_REP, PARTNER_MANAGER],
    adminUser.type
  );

  const canEditAdmin =
    currentUserRole === SYSTEM_ADMIN ||
    currentUserID === adminUser.id ||
    (currentUserRole === PARTNER_ADMIN && lowerPartnerAdmin);

  return canEditAdmin && editButton;
};

const excludeBenefitsAdmins = adminUsers => {
  return reject(adminUsers, a => a.type === BENEFITS_ADMIN);
};

export class AdminUsersList extends Component {
  /**
   * Fetch admin users with a query search parameter.
   * TODO: Move query params to redux for all actions that use them.
   *
   * @param {string} searchTerm
   * @return {Promise}
   */
  fetchAdminUsersQuery = async searchTerm => {
    const { partnerID } = this.props;
    return await this.props.fetchAdminUsers({ partnerID }, null, 1, searchTerm);
  };

  static propTypes = {
    adminUsers: PropTypes.array.isRequired,
    total: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    fetchAdminUsers: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    partnerID: PropTypes.string
  };

  static defaultProps = {
    adminUsers: [],
    partnerID: ""
  };

  render() {
    const {
      adminUsers,
      fetchAdminUsers,
      openModal,
      partnerID,
      currentUserRole,
      currentUserID,
      isLoading,
      total
    } = this.props;

    const params = [];

    // See AdminUserActions props
    // Setting these up in the same object because that's what is required of the fetch action
    const typePartner = partnerID ? { partnerID } : {};
    typePartner.adminUserType = null;

    params.push(typePartner);

    return (
      <div>
        <SearchForm onSubmit={this.fetchAdminUsersQuery} />
        <SLGList
          fetchData={fetchAdminUsers}
          params={params}
          data={excludeBenefitsAdmins(adminUsers)}
          isLoading={isLoading}
          total={total}
        >
          <SLGTableColumn
            field="first_name"
            title="name"
            sortable={true}
            render={val => `${val.first_name} ${val.last_name}`}
          />
          <SLGTableColumn
            field="type_id"
            title="Role"
            render={val => (
              <React.Fragment>
                {startCase(val.type)}
                <InfoIcon
                  tooltip={<span>{adminTypeTooltipMsg(val.type)}</span>}
                />
              </React.Fragment>
            )}
            rowStyle={{ overflow: "visible" }}
          />
          <SLGTableColumn field="email" title="Email" sortable={true} />
          <SLGTableColumn
            field="active"
            sortable={true}
            rowStyle={styles.rowField}
            headerStyle={styles.columnHeader}
            render={val =>
              val.active ? "Yes" : <span style={styles.inactive}>No</span>
            }
          />
          <SLGTableColumn
            field="invitation_accepted_at"
            title="Registered"
            sortable={true}
            rowStyle={styles.rowField}
            headerStyle={styles.columnHeader}
            render={val =>
              val.invitation_accepted_at
                ? moment(val.invitation_accepted_at).format("MM/DD/YYYY")
                : "No"
            }
          />
          <SLGTableColumn
            rowStyle={styles.rowField}
            headerStyle={styles.columnHeader}
            render={val =>
              renderEditButton(
                val,
                openModal,
                partnerID,
                currentUserRole,
                currentUserID
              )
            }
          />
        </SLGList>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const selectAdminUsersByPartner = makeSelectAdminUsersByPartner();
  return {
    adminUsers: selectAdminUsersByPartner(state, props.partnerID),
    currentUserRole: state.userContext.userRole,
    currentUserID: state.userContext.userID,
    total: state.adminUsers.total,
    isLoading: state.adminUsers.isFetching
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    {
      fetchAdminUsers: fetchAdminUsersAction,
      openModal: openModalAction
    }
  )
);
export default enhance(AdminUsersList);
