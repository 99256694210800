export const EMAIL_RESET_PASSWORD = "EMAIL_RESET_PASSWORD";
export const EMAIL_RESET_PASSWORD_FAILURE = "EMAIL_RESET_PASSWORD_FAILURE";
export const EMAIL_RESET_PASSWORD_SUCCESS = "EMAIL_RESET_PASSWORD_SUCCESS";

export const emailResetPassword = email => ({
  type: EMAIL_RESET_PASSWORD,
  payload: { data: { email } }
});

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const resetPassword = data => ({
  type: RESET_PASSWORD,
  payload: { data: { ...data } }
});
