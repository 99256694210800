import React from "react";
import * as R from "ramda";
import compose from "recompose/compose";
import { connect } from "react-redux";

/** legacy code, will remove once new form is done */
import { openModal as openModalAction } from "../../app/uiActions";
import { AdminUserEditModal } from "../../adminUsers/modals";
import { selectEmployersListByPartner } from "../../employers/selectors";

import { allowToPartnerAdmin } from "../../services/permission/service";
import EditButton from "../../components/buttons/action/Edit";

function ActionsCell({
  partnerId,
  currentUserId,
  openModal,
  stateProps: { requestData },
  ...adminUser
}) {
  function handleEditClick() {
    function handleSuccess() {
      requestData();
    }

    openModal(
      <AdminUserEditModal
        adminUser={adminUser}
        partnerID={partnerId}
        context="partner_admin"
        onSuccess={handleSuccess}
      />
    );
  }

  const allowLowerPartnerAdmin = R.allPass([
    R.propEq("type", "partner_admin"),

    R.pipe(
      R.prop("type"),
      R.includes(R.__, [
        "employers_admin",
        "partner_manager",
        "employer_manager",
        "support_rep"
      ])
    )
  ])(adminUser);
  const allowOwner = currentUserId === adminUser.id;
  const allowEdit =
    allowOwner || allowToPartnerAdmin() || allowLowerPartnerAdmin;
  return (
    <>{allowEdit && <EditButton onClick={handleEditClick}>Edit</EditButton>}</>
  );
}

function mapStateToProps(state, props) {
  const partnerId = props.partnerId;
  const employers = selectEmployersListByPartner(state, partnerId);
  return {
    employers,
    currentUserId: state.userContext.userID
  };
}

export default compose(
  connect(
    mapStateToProps,
    {
      openModal: openModalAction
    }
  )
)(ActionsCell);
