import React from "react";
import { withStyles } from "@material-ui/core/styles";
import BaseButton from "@material-ui/core/Button";

const styles = {
  root: {
    whiteSpace: "nowrap"
  }
};

function Button(props) {
  return <BaseButton variant="contained" {...props} />;
}

export default withStyles(styles)(Button);
