export * from "./userRoles";
export const TWO_FACTOR = "two_factor";
export const TWO_FACTOR_ENABLED = "two_factor_enabled";
export const ROLE = "role";
export const EMPLOYER_ID = "employer_id";
export const PARTNER_ID = "partner_id";
export const ENTITY = "entity";
export const USER_NAME = "user_name";
export const USER_EMAIL = "email";
export const INTERCOM_HASH = "intercom_hash";
export const USER_ID = "user_id";
export const TOKEN = "token";
export const DEFAULT_LOGOUT_WARN = 55;
export const DEFAULT_LOGOUT_IDLE = 60;
export const SORT_DESC = "DESC";
export const SORT_ASC = "ASC";
export const PER_PAGE = 25;
export const SESSIONS = "sessions";
export const CITIZENS_PRIMARY = "#009d78";
