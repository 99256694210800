import React from "react";
import PropTypes from "prop-types";

function useSort({ defaultSort, onSort }) {
  const [order, setOrder] = React.useState(defaultSort.order);
  const [orderBy, setOrderBy] = React.useState(defaultSort.orderBy);

  const createSortHandler = id => () => {
    const isDesc = orderBy === id && order === "desc";
    const newOrder = isDesc ? "asc" : "desc";
    setOrder(newOrder);
    setOrderBy(id);
    if (onSort) onSort({ order: newOrder, orderBy: id });
  };

  return {
    order,
    orderBy,
    createSortHandler
  };
}

useSort.propTypes = {
  defaultSort: PropTypes.shape({
    orderBy: PropTypes.string,
    order: PropTypes.oneOf(["asc", "desc"])
  }).isRequired,
  onSort: PropTypes.func.isRequired
};

export default useSort;
