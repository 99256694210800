import urlService from "../../rest/urlService";

function routeService({ urlService }) {
  const _urlService = urlService;
  const _baseUrl = _urlService.getBase();
  const _collection = "partners";

  const _prefix = () => {
    return `${_baseUrl}/${_collection}`;
  };

  const getPartnerReports = partnerId => {
    return `${_prefix()}/${partnerId}/reports`;
  };

  const getPartnerReportDownload = (partnerId, reportId) => {
    return `${_prefix()}/${partnerId}/reports/${reportId}/download`;
  };

  return {
    getPartnerReports,
    getPartnerReportDownload,
  };
}

export default routeService({ urlService });
