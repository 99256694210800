import BaseRequest from "../../shared/BaseRequest";

export default class GetUltiproLogsRequest extends BaseRequest {
  constructor({ url, employerId, params, query }) {
    const filter = [{ employer_id_eq: employerId }];
    if (query) {
      filter.push({ description_cont: query });
    }

    super({ url, params: { ...params, filter } });
  }
}
