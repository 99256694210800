import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { selectEntityIdentifiers } from "partners/selectors";
import { toYesNoStringFromProp } from "services/formatters";
import withEmployerIdFromRouter from "components/containers/utils/withEmployerIdFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import PageAction from "./PageAction";
import ActionsCell from "./ActionsCell";
import NameCell from "./NameCell";
import GroupCell from "./GroupCell";

const renderPersonalIds = v => R.pathOr("", ["personal_ids", 0, "pid"], v);
export const columns = props => [
  {
    name: "personal_ids",
    label: "Personal ID",
    render: renderPersonalIds
  },
  {
    name: "last_name",
    label: "Last Name",
    ellipsis: true,
    maxWidth: 100,
    render: r => <NameCell {...r} {...{ attr: "last_name" }} />
  },
  {
    name: "first_name",
    label: "First Name",
    ellipsis: true,
    maxWidth: 100,
    render: r => <NameCell {...r} {...{ attr: "first_name" }} />
  },
  {
    name: "email",
    label: "Email",
    ellipsis: true
  },
  {
    name: "group_name",
    label: "Group",
    disableSort: true,
    render: r => <GroupCell {...r} />
  },
  {
    name: "employee_status",
    label: "Active",
    disableSort: true,
    format: toYesNoStringFromProp("employee_status")
  },
  {
    name: "pay_enrolled?",
    label: "Pay Enrolled?",
    disableSort: true,
    hideColumn: !R.pathOr(false, ["pay_enabled?"], props.group),
    format: toYesNoStringFromProp("pay_enrolled?")
  },
  {
    name: "invitation_status",
    label: "Invite",
    disableSort: true
  },
  {
    name: "id",
    label: "Actions",
    disableSort: true,
    render: r => <ActionsCell {...props} {...r} />
  }
];

const createGetSearchFilterWith = props =>
  R.applySpec({
    "0": { employer_id_eq: R.always(props.employerId) },
    "2": { group_id_eq: R.always(props.groupID) },
    "1": {
      full_name_cont: R.identity,
      notes_note_cont: R.identity,
      user_email_cont: R.identity,
      m: R.always("or")
    }
  });

const renderAction = props => ({ disabled }) => (
  <PageAction {...props} disabled={disabled} />
);
export const extraProps = props => {
  const entityIdentifiers = props.entityIdentifiers;
  const employerId = props.employerID || props.employerId;
  return {
    defaultSort: { order: "asc", orderBy: "last_name" },
    title: `${entityIdentifiers.employeeIdentifier}s`,
    path: employerId && "/employees",
    getSearchFilter: createGetSearchFilterWith({ ...props, employerId })
  };
};

const actionsProps = props => {
  //console.log(props)
  const employerId = props.employerID || props.employerId;
  const group = props.group;
  return {
    columns: columns(props),
    actionSlot: !group ? renderAction({ ...props, employerId }) : null
  };
};

const mapStateToProps = state => {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return { entityIdentifiers };
};

export default compose(
  withEmployerIdFromRouter,
  connect(mapStateToProps),
  withProps(extraProps),
  withSort,
  withSearch,
  withPagination,
  withGetFetch,
  withProps(actionsProps)
)(PageComponent);
