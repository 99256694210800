import React from "react";
import { connect } from "react-redux";
import { findIndex } from "lodash";

import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  PARTNER_MANAGER,
  SUPPORT_REP
} from "../../constants";
import permissionService from "../../rest/permissionService";
import {
  makeSelectAllVendorsByEmployerId,
  selectIsFetching,
  selectToggleFetchingByEmployerId
} from "../selectors-ext/refiVendorsSelectors";
import RefiListCard from "../../components/cards/RefiListCard";
import { fetchRefiVendors as getGlobalRefiVendors } from "../../refiVendors/refiVendorActions";
import {
  requestGetRefiVendors as getRefiVendors,
  toggleRequest,
  toggleVisibilityRequest
} from "../actions/refiVendorActions";
import { selectAll as selectAllGlobalRefiVendors } from "../../refiVendors/selectors";
import Switch from "../../components/inputs/BaseSwitch";
import InfoTooltip from "../../components/tooltips/Info";
import Grid from "../../components/layouts/Grid";
import layHorizontal from "../../components/layouts/layHorizontal";
import alignCenter from "../../components/layouts/alignCenter";

const cardTitle = "Refi Vendors";
const SwitchLabelGroup = layHorizontal(alignCenter(Grid));

class Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      enableOwnRefiVendors: false
    };

    this.allowEdit = permissionService.allowWith([
      SYSTEM_ADMIN,
      PARTNER_ADMIN,
      EMPLOYERS_ADMIN,
      EMPLOYER_MANAGER,
      PARTNER_MANAGER
    ]);

    this.allowGetRefiVendors = permissionService.allowWith([
      SYSTEM_ADMIN,
      PARTNER_ADMIN,
      PARTNER_MANAGER,
      EMPLOYERS_ADMIN,
      EMPLOYER_MANAGER,
      SUPPORT_REP
    ]);
  }

  componentDidMount() {
    const {
      employerId,
      getGlobalRefiVendors,
      enableOwnRefiVendors
    } = this.props;

    if (this.allowGetRefiVendors && employerId) {
      getGlobalRefiVendors();
    }

    if (
      typeof enableOwnRefiVendors === "boolean" &&
      enableOwnRefiVendors === true
    ) {
      this.setState({ enableOwnRefiVendors: true });
    }
  }

  componentDidUpdate(previousProps) {
    const { employerId } = this.props;
    const pEmployerId = previousProps.employerId;

    if (this.allowGetRefiVendors && pEmployerId !== employerId) {
      getGlobalRefiVendors();
    }

    if (
      previousProps.enableOwnRefiVendors !== this.props.enableOwnRefiVendors &&
      this.props.enableOwnRefiVendors === true
    ) {
      this.setState({ enableOwnRefiVendors: true });
    }
  }

  /** best to merge at saga level after a successful employer fetch */
  getRefiVendors() {
    const { globalRefiVendors } = this.props;

    return globalRefiVendors;
  }

  handleInAdvisorChanged = ({ row, toggle }) => {
    const { toggleRequest, employerId, employerVendorableList } = this.props;
    const refiVendorId = row.id;
    const vendorableRecord =
      !toggle &&
      employerVendorableList.find(
        record => record.refi_vendor_id === refiVendorId
      );

    toggleRequest(employerId, refiVendorId, vendorableRecord.id, toggle);
  };

  handleVisibilityChanged = () => {
    const { employerId, toggleVisibilityRequest } = this.props;
    const enableOwnRefiVendors = !this.state.enableOwnRefiVendors;
    this.setState({ enableOwnRefiVendors });
    toggleVisibilityRequest(employerId, enableOwnRefiVendors);
  };

  renderCardActions = () => {
    const label = `${
      this.state.enableOwnRefiVendors ? "Revert to" : "Update"
    } Default Vendor Visibility`;

    const LabelComponent = (
      <SwitchLabelGroup>
        {label}
        <InfoTooltip title="Updating the REFI Vendor Visibility will immediately affect the advisor experience for your employees" />
      </SwitchLabelGroup>
    );

    return (
      <Switch
        disabled={!this.allowEdit}
        checked={this.state.enableOwnRefiVendors}
        label={LabelComponent}
        onChange={this.handleVisibilityChanged}
      />
    );
  };

  getCellProps = ({ row }) => {
    const { employerRefiVendors, enableOwnRefiVendors } = this.props;

    const id = this.getCellId({ row });

    const searchIndex = findIndex(
      employerRefiVendors,
      vendor => vendor.id === id
    );
    const inAdvisor = searchIndex !== -1;
    const disableInAdvisorToggle = !enableOwnRefiVendors;

    return {
      inAdvisor,
      disableInAdvisorToggle
    };
  };

  getCellId = ({ row }) => {
    return row.id;
  };

  render() {
    const { loading } = this.props;
    const refiVendors = this.getRefiVendors();

    return (
      <RefiListCard
        data={refiVendors}
        loading={loading}
        withHeader={{
          title: cardTitle,
          renderActions: this.renderCardActions
        }}
        withDelegates={{
          handleInAdvisorChanged: this.handleInAdvisorChanged,
          handleVisibilityChanged: this.handleVisibilityChanged,
          getCellProps: this.getCellProps,
          getCellId: this.getCellId
        }}
      />
    );
  }
}

Page.defaultProps = {
  employer: {}
};

function mapStateToProps(state, props) {
  const employer = props.employer.employer || {};
  const employerId = props.employer.employerID;
  const employerVendorableList = employer.refi_vendors_vendorables;
  const enableOwnRefiVendors = employer.enable_own_refi_vendors;
  const employerRefiVendors = makeSelectAllVendorsByEmployerId(
    state,
    employerId
  );
  const isEmployerFetching = state.employers.isFetching;
  const isToggling = selectToggleFetchingByEmployerId(state, employerId);
  const isFetching = selectIsFetching(state);
  const loading = isToggling || isFetching || isEmployerFetching;
  const globalRefiVendors = selectAllGlobalRefiVendors(state);
  return {
    employer,
    employerId,
    employerRefiVendors,
    employerVendorableList,
    isFetching,
    isEmployerFetching,
    enableOwnRefiVendors,
    isToggling,
    loading,
    globalRefiVendors
  };
}

export default connect(
  mapStateToProps,
  {
    getGlobalRefiVendors,
    getRefiVendors,
    toggleRequest,
    toggleVisibilityRequest
  }
)(Page);
