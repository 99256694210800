import React from "react";
import styled from "styled-components";

const StyledText = styled.h4`
  font-weight: 300;
  text-align: ${props => props.align || "left"};
`;

const CardTitleText = props => {
  return <StyledText {...props} />;
};

export default CardTitleText;
