import React from "react";

import GroupActions from "../../../components/layouts/list/Actions";
import IconButton from "../../../components/buttons/icon/IconButton";
import withButtonIcon from "../../../components/buttons/icon/withIcon";
import withColor from "../../../components/icons/withColor";
import ConfirmationDialog from "../../../components/dialogs/form-dialogs/ConfirmationDialog";
import Typography from "../../../components/typography/Typography";
import withFontSize from "../../../components/typography/withFontSize";
import withWhiteText from "../../../components/typography/withWhiteText";

const styles = {
  namesContainer: {
    marginTop: 20,
    marginBottom: 20
  }
};

const Text = withWhiteText(withFontSize(16)(Typography));

function ConfirmDialog({
  title,
  buttonLabel,
  submitButtonLabel,
  data,
  selections,
  onSubmit,
  Icon,
  names,
  contentText
}) {
  const [open, setOpen] = React.useState(false);

  function handleClick() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    setOpen(false);
    if (onSubmit) onSubmit(selections);
  }

  const Button = withButtonIcon(withColor("white")(Icon))(IconButton);
  return (
    <GroupActions>
      <Text>{buttonLabel}</Text>
      <Button style={{ color: "white" }} onClick={handleClick} />
      <ConfirmationDialog
        open={open}
        type="error"
        title={title}
        submitButtonLabel={submitButtonLabel}
        onClose={handleClose}
        onSubmit={handleSubmit}
      >
        {contentText}
        <div style={styles.namesContainer}>
          {names.map((name, i) => (
            <div key={i}>{name}</div>
          ))}
        </div>
      </ConfirmationDialog>
    </GroupActions>
  );
}

export const withIcon = IC => WC => props => <WC Icon={IC} {...props} />;
export const withProps = extraProps => WC => props => (
  <WC {...props} {...extraProps(props)} />
);

export default ConfirmDialog;
