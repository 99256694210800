import React from "react";
import { Link } from "react-router-dom";

import RaisedButton from "material-ui/RaisedButton";
import HotTub from "material-ui/svg-icons/places/hot-tub";
import HomeIcon from "material-ui/svg-icons/action/home";

const styles = {
  container: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center"
  },
  icon: {
    width: "9em",
    height: "9em"
  },
  message: {
    textAlign: "center",
    fontFamily: "Roboto, sans-serif",
    opacity: 0.5,
    margin: "0 1em"
  },
  toolbar: {
    textAlign: "center",
    marginTop: "2em"
  }
};

const NotFound = history => (
  <div style={styles.container}>
    <div style={styles.message}>
      <HotTub style={styles.icon} />
      <h1>Not Found</h1>
      <div> Either you typed a wrong URL, or you followed a bad link.</div>
    </div>
    <div style={styles.toolbar}>
      <RaisedButton
        label="Home"
        icon={<HomeIcon />}
        containerElement={
          <Link
            to={{
              pathname: `/`
            }}
          />
        }
      />
    </div>
  </div>
);

export default NotFound;
