import React from "react";

export default ActionComponent => WC => ({
  loading,
  onSubmit,
  submitButtonLabel,
  ...props
}) => {
  return (
    <WC
      actionSlot={
        <ActionComponent loading={loading} onClick={onSubmit}>
          {submitButtonLabel}
        </ActionComponent>
      }
      loading={loading}
      onSubmit={onSubmit}
      {...props}
    />
  );
};
