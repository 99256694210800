import React from "react";
import * as R from "ramda";
import useFetch from "components/containers/utils/useFetch";
import AsyncSelect from "components/inputs/selects/EnhancedAsyncSelect";

const createGroupIdOption = R.applySpec({
  label: R.prop("name"),
  value: R.prop("id")
});
const optionsFrom = R.map(createGroupIdOption);
const rules = { required: "required" };

export default ({ employerId, ...props }) => {
  const getGroups = useFetch({ method: "get" });
  const [groups, setGroups] = React.useState([]);

  let latestGet = React.useRef(getGroups.makeFetch);
  React.useEffect(() => {
    latestGet.current = getGroups.makeFetch;
  });
  React.useEffect(() => {
    if (employerId) {
      function handleSuccess({ data }) {
        setGroups(data);
      }
      const filter = {
        "0": {
          employer_id_eq: employerId
        }
      };
      const path = `/employers/${employerId}/groups`;
      latestGet.current({ path, filter, onSuccess: handleSuccess });
    }
  }, [employerId]);

  return (
    <AsyncSelect
      label="Group"
      name="group_id"
      path="/groups"
      required
      rules={rules}
      loading={getGroups.loading}
      options={optionsFrom(groups)}
      {...props}
    />
  );
};
