import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

/**
 * @name SearchForm
 * @type {Component}
 *
 * Used to provide search functionality for different data tables throughout the app.
 *
 * @param {function} onSubmit Main onSubmit handler.
 * @param {object} otherParams Other params you wish to pass to the onSubmit handler.
 *
 * TODO: Eventually, all state for table ordering, custom columns, etc should live in redux. This way we can also
 * have the search functionality integrate with it and make it more compososable so we can mix parameters. Right
 * now pagination will not work for search results as per stated on the work ticket.
 * https://studentloangenius.atlassian.net/browse/GENIUS-3764
 *
 */

const styles = theme => ({
  container: {
    borderWidth: 1,
    display: 1,
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  textField: {
    width: 200
  }
});

class SearchForm extends Component {
  state = {
    searchValue: "",
    loading: false
  };

  componentDidMount() {
    const { resetForm, onResetFormCompleted } = this.props;

    if (resetForm) {
      this.setState({ searchValue: "" });
      if (onResetFormCompleted) onResetFormCompleted();
    }
  }

  componentDidUpdate(previousProps) {
    const pResetForm = previousProps.resetForm;
    const { resetForm, onResetFormCompleted } = this.props;

    if (pResetForm !== resetForm && resetForm) {
      this.setState({ searchValue: "" });
      if (onResetFormCompleted) onResetFormCompleted();
    }
  }

  updateValue = event => {
    this.setState({ searchValue: event.target.value });
  };

  submitSearch = async event => {
    event.preventDefault();
    this.setState({ loading: true });
    const result = await this.props.onSubmit(
      this.state.searchValue.trim(),
      this.props.otherParams
    );
    this.setState({ loading: false });
    return result;
  };

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    otherParams: PropTypes.object,
    resetForm: PropTypes.bool,
    onResetFormCompleted: PropTypes.func
  };

  static defaultProps = {
    otherParams: {}
  };

  render() {
    const { loading, searchValue } = this.state;
    const { classes } = this.props;
    return (
      <form className={classes.container} onSubmit={this.submitSearch}>
        <TextField
          className={classes.textField}
          name="search"
          value={searchValue}
          label="Search..."
          onChange={this.updateValue}
          disabled={loading}
        />
      </form>
    );
  }
}

export default withStyles(styles)(SearchForm);
