import { put, all, call, takeEvery } from "redux-saga/effects";
import { push } from "react-router-redux";

import log from "../../services/logging/service";
import routeService from "../services/RouteService";
import fetchApiService from "../../rest/fetchApiService";
import { showError, showSuccess } from "../../shared/sagas/notifications";
import { closeAllModals } from "../../app/uiActions";
import {
  BATCH_CREATE_ELIGIBLE_EMPLOYEES_REQUEST,
  batchCreateEligibleEmployeesSuccess,
  batchCreateEligibleEmployeesFailure,
  HISTORY_REQUEST,
  historySuccess,
  historyFailure,
  JOBS_REQUEST,
  jobsSuccess,
  jobsFailure,
  JOB_DETAILS_REQUEST,
  jobDetailsSuccess,
  jobDetailsFailure,
  CSV_DOWNLOAD_REQUEST,
  csvDownloadSuccess,
  csvDownloadFailure
} from "../actions/bulkUploadActions";
import BatchCreateEligibleEmployeesRequest from "../services/BatchCreateEligibleEmployeesRequest";
import BatchCreateEligibleEmployeesResponse from "../services/BatchCreateEligibleEmployeesResponse";
import BulkUploadHistoryRequest from "../services/BulkUploadHistoryRequest";
import BulkUploadHistoryResponse from "../services/BulkUploadHistoryResponse";
import BulkUploadJobsRequest from "../services/BulkUploadJobsRequest";
import BulkUploadJobsResponse from "../services/BulkUploadJobsResponse";
import BulkUploadDetailsRequest from "../services/BulkUploadDetailsRequest";
import BulkUploadDetailsResponse from "../services/BulkUploadDetailsResponse";

function* batchCreateEligibleEmployees({ employerId, file, employeesList }) {
  try {
    const endpoint = routeService.bulkUpload(employerId);
    const request = new BatchCreateEligibleEmployeesRequest({
      url: endpoint,
      file,
      employeesList
    });
    const response = yield fetchApiService.uploadFile(
      request,
      BatchCreateEligibleEmployeesResponse
    );

    if (response.ok) {
      const data = response.getData();
      const batchId = data && data.batch_id;
      yield call(showSuccess, "Batch create Eligible employees successful!");
      yield put(batchCreateEligibleEmployeesSuccess(employerId));

      if (batchId) {
        yield put(push(`/employers/${employerId}/employees/bulkHistory`));
      }
    } else {
      yield call(showError, response.statusText);
      yield put(batchCreateEligibleEmployeesFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(batchCreateEligibleEmployeesFailure(e.message));
    log.error(e.message);
  }

  yield put(closeAllModals());
}

function* bulkUploadHistory({ employerId, sort, page, query }) {
  try {
    const endpoint = routeService.bulkUploadHistory(employerId);
    const request = new BulkUploadHistoryRequest({
      url: endpoint,
      employerId,
      params: { sort, pagination: { page } },
      query
    });

    const response = yield fetchApiService.fetch(
      request,
      BulkUploadHistoryResponse
    );
    if (response.ok) {
      const data = response.getListData();
      yield call(showSuccess, "Bulk upload history request successful!");
      yield put(historySuccess(employerId, data));
    } else {
      yield call(showError, response.statusText);
      yield put(historyFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(historyFailure(e.message));
    log.error(e.message);
  }
}

function* bulkUploadJobs({ employerId, batchId }) {
  try {
    const endpoint = routeService.bulkUploadJobs(employerId, batchId);
    const request = new BulkUploadJobsRequest({
      url: endpoint
    });

    const response = yield fetchApiService.fetch(
      request,
      BulkUploadJobsResponse
    );
    if (response.ok) {
      const data = response.getData();
      yield call(showSuccess, "Job request successful!");
      yield put(jobsSuccess(batchId, data));
    } else {
      yield call(showError, response.statusText);
      yield put(jobsFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(jobsFailure(e.message));
    log.error(e.message);
  }
}

function* jobDetails({ employerId, batchId, sort, page, query }) {
  try {
    const endpoint = routeService.jobDetails(employerId, batchId);
    const request = new BulkUploadDetailsRequest({
      url: endpoint,
      employerId,
      batchId,
      sort,
      page,
      query
    });

    const response = yield fetchApiService.fetch(
      request,
      BulkUploadDetailsResponse
    );
    if (response.ok) {
      const data = response.getListData();
      yield call(showSuccess, "Batch details request successful!");
      yield put(jobDetailsSuccess(batchId, data));
    } else {
      yield call(showError, response.statusText);
      yield put(jobDetailsFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(jobDetailsFailure(e.message));
    log.error(e.message);
  }
}

function* csvDownload({ employerId, batchId, filename }) {
  try {
    const request = routeService.csvDownload(employerId, batchId);
    yield fetchApiService.fetchFileAttachment(request, filename);
    yield call(showSuccess, "CSV download successful!");
    yield put(csvDownloadSuccess());
  } catch (e) {
    yield call(showError, e.message);
    yield put(csvDownloadFailure(e.message));
    log.error(e.message);
  }
}

export default function* sagas() {
  yield all([
    takeEvery(
      BATCH_CREATE_ELIGIBLE_EMPLOYEES_REQUEST,
      batchCreateEligibleEmployees
    ),
    takeEvery(HISTORY_REQUEST, bulkUploadHistory),
    takeEvery(JOBS_REQUEST, bulkUploadJobs),
    takeEvery(JOB_DETAILS_REQUEST, jobDetails),
    takeEvery(CSV_DOWNLOAD_REQUEST, csvDownload)
  ]);
}
