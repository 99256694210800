import { withStyles } from "@material-ui/core/styles";

export default withStyles({
  root: {
    fontSize: 16,
    fontWeight: "bold"
  },
  indicator: {
    backgroundColor: "red"
  }
});
