import React from "react";

import { DialogActionsProps } from "./types";
import AsyncButton from "../../buttons/async/AsyncButton";
import CancelButton from "../../buttons/action/Cancel";

function Actions({
  cancelLabel = "Cancel",
  submitLabel = "Submit",
  loading,
  onSubmit,
  onCancel
}) {
  return (
    <>
      <AsyncButton onClick={onSubmit} loading={loading}>
        {submitLabel}
      </AsyncButton>
      <CancelButton onClick={onCancel}>{cancelLabel}</CancelButton>
    </>
  );
}

Actions.propTypes = DialogActionsProps;

export default Actions;
