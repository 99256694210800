import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
import { openModal } from "../../app/uiActions";
import ViewButton from "../../components/buttons/action/View";
import TRViewModal  from "../components/modals/TRViewModal";


const ViewCell = ({
  openModal,
  ...trData
}) => {
  const handleViewClick = () => {
    openModal(<TRViewModal trData={trData}/>);
  };
  return (
    <ViewButton onClick={handleViewClick}></ViewButton>
  );
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal, };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(ViewCell);