import React from "react";
import Typography from "@material-ui/core/Typography";

const styles = {
  color: "white"
};

export default function CardTitle(props) {
  return <Typography variant="button" style={styles} {...props} />;
}
