import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";

/** legacy code, will remove once new form is done */
import { openModal as openModalAction } from "../../app/uiActions";
import { AdminUserCreateModal } from "../../adminUsers/modals";

import NewButton from "../../components/buttons/action/New";

function PageAction({ loading, openModal, stateProps: { requestData } }) {
  function handleNewClick() {
    function handleSuccess() {
      requestData();
    }

    openModal(
      <AdminUserCreateModal context="system_admin" onSuccess={handleSuccess} />
    );
  }

  return (
    <>
      <NewButton disabled={loading} onClick={handleNewClick}>
        New Admin User
      </NewButton>
    </>
  );
}

export default compose(
  connect(
    null,
    {
      openModal: openModalAction
    }
  )
)(PageAction);
