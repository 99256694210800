import * as R from "ramda";
import AddIcon from "@material-ui/icons/AddCircle";

import ConfirmDialog, { withIcon, withProps } from "./ConfirmDialog";

const getSelectedData = R.innerJoin(
  (record, id) => record.invoice_line_item_id === id
);
const getNamesFromData = R.map(R.prop("full_name"));
const getSelectedNames = R.pipe(
  getSelectedData,
  getNamesFromData
);

export default withIcon(AddIcon)(
  withProps(props => ({
    title: "Restore Users to Invoice",
    buttonLabel: `Restore ${props.selections.length} users to invoice`,
    submitButtonLabel: "Restore Users",
    names: getSelectedNames(props.data, props.selections),
    contentText: `By restoring users, you will be adding them back to the funds request to
    receive the associated contribution.`
  }))(ConfirmDialog)
);
