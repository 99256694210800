import { put, all, call, takeLatest } from "redux-saga/effects";
import { showNotification } from "../app/uiActions";

import log from "../services/logging/service";
import slgRestClient from "../rest/slgRestClient";
import { GET_LIST } from "../rest/types";
import { SORT_ASC, PER_PAGE } from "../constants";

import {
  FETCH_REFI_VENDORS,
  FETCH_REFI_VENDORS_SUCCESS,
  FETCH_REFI_VENDORS_FAILURE
} from "./refiVendorActions";
import { FETCH_ERROR } from "../auth/authActions";

function requestRefiVendors(sort, page = 1) {
  const sortParams = sort || { field: "name", order: SORT_ASC };

  const params = {
    filter: [],
    pagination: { page, perPage: PER_PAGE },
    sort: sortParams
  };

  return slgRestClient(GET_LIST, "refi_vendors", params)
    .then(resp => resp.data)
    .catch(e => log.error(e.message));
}

function* fetchRefiVendors(action) {
  try {
    const refiVendors = yield call(requestRefiVendors);
    yield put({
      type: FETCH_REFI_VENDORS_SUCCESS,
      payload: refiVendors
    });
  } catch (e) {
    yield put({ type: FETCH_REFI_VENDORS_FAILURE, message: e.message });
    yield put({ type: FETCH_ERROR, e });
    yield put(
      showNotification(
        "An error occurred while fetching Refi Vendors",
        "warning"
      )
    );
    log.error(e.message);
  }
}

export default function* refiVendorSaga() {
  yield all([takeLatest(FETCH_REFI_VENDORS, fetchRefiVendors)]);
}
