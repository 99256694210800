import React from "react";

import NameField from "./Name";

const rules = {
  pattern: {
    value: /^\S+$/,
    message: "spaces not allowed"
  }
};

const attributes = {
  name: "landing_page_slug",
  label: "Custom Landing Page URL",
  required: false,
  rules
};

export default function LandingPageSlug(props) {
  return <NameField {...attributes} {...props} />;
}
