import * as React from "react";
import styled from "styled-components";
import MuiButton from "@material-ui/core/Button";

const Button = styled(MuiButton)`
  && {
    background-color: #00acc1;
    margin-left: 15px
    &:hover {
      background-color: #00acc9;
    }
  }
`;

export default props => {
  return (
    <Button variant="contained" color="primary" size="medium" {...props} />
  );
};
