import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import green from "@material-ui/core/colors/green";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  wrapper: {
    position: "relative"
  },
  disabledWrapper: {
    pointerEvents: "none"
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class WrapWithLoading extends React.PureComponent {
  render() {
    const { classes, loading, type, size, children } = this.props;
    const useSize = size || (type === "container" ? 50 : 24);

    return (
      <div
        className={classnames(classes.wrapper, {
          [classes.disabledWrapper]: loading
        })}
      >
        {children}
        {loading && (
          <CircularProgress size={useSize} className={classes.buttonProgress} />
        )}
      </div>
    );
  }
}

WrapWithLoading.propTypes = {
  loading: PropTypes.bool,
  type: PropTypes.oneOf(["button", "container"]),
  size: PropTypes.number
};

WrapWithLoading.defaultProps = {
  loading: false,
  type: "container"
};

export default withStyles(styles)(WrapWithLoading);
