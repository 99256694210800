import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import getMuiTheme from "material-ui/styles/getMuiTheme";
import Drawer from "material-ui/Drawer";
import ChevronLeft from "material-ui/svg-icons/navigation/chevron-left";
import ChevronRight from "material-ui/svg-icons/navigation/chevron-right";

import { toggleSubNav as toggleSubNavAction } from "../app/uiActions";

import { theme } from "../app";

const styles = {
  drawerStyle: {
    display: "flex",
    position: "absolute",
    height: "100%",
    backgroundColor: "#354351"
  },
  openDrawer: {
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden"
  },
  content: { display: "flex", flex: "1" },
  closedDrawer: { display: "flex", alignSelf: "center", flex: "100%" },
  chevronLeft: {
    position: "relative",
    left: "90%"
  },
  chevronLeftWrapper: { flexBasis: "50%" }
};

// In order to use expandable SLGDrawer, set the main content marginLeft equal to
// the drawer width if subnav is open
export class SLGDrawer extends Component {
  render() {
    const { children, subNavOpen, subNavWidth, toggleSubNav } = this.props;

    const muiTheme = getMuiTheme(theme);
    const menuWidth = muiTheme.drawer.width;
    const width = subNavOpen ? subNavWidth : "25px";

    const drawerContent = (
      <div style={{ ...styles.openDrawer, width }}>
        <div style={styles.content}>{children}</div>
        <div style={styles.chevronLeftWrapper} onClick={toggleSubNav}>
          <ChevronLeft color="#FFFFFF" style={styles.chevronLeft} />
        </div>
      </div>
    );

    const closedDrawer = (
      <div style={styles.closedDrawer} onClick={toggleSubNav}>
        <ChevronRight color="#FFFFFF" />
      </div>
    );

    return (
      <Drawer
        containerStyle={{
          left: menuWidth,
          width,
          ...styles.drawerStyle
        }}
      >
        {subNavOpen ? drawerContent : closedDrawer}
      </Drawer>
    );
  }
}

SLGDrawer.propTypes = {
  children: PropTypes.node,
  toggleSubNav: PropTypes.func,
  subNavOpen: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    subNavOpen: state.ui.subNavOpen,
    subNavWidth: state.ui.subNavWidth
  };
}

const enhance = compose(
  connect(mapStateToProps, {
    toggleSubNav: toggleSubNavAction
  })
);

export default enhance(SLGDrawer);
