import React from "react";
import { FormContext, useForm } from "react-hook-form";

export default WC => ({
  mode = "onBlur",
  defaultValues,
  validationSchema,
  onSubmit,
  ...props
}) => {
  const methods = useForm({ mode, defaultValues, validationSchema });
  function handleSubmit(data) {
    if (onSubmit) onSubmit(data);
  }
  const handleSubmitUsingHookForm = methods.handleSubmit(handleSubmit);

  return (
    <FormContext {...methods}>
      <WC
        {...props}
        onSubmit={handleSubmitUsingHookForm}
        control={methods.control}
      />
    </FormContext>
  );
};
