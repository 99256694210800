import React, { Component } from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";
import { get } from "lodash";

import { Card, CardHeader, CardText } from "material-ui/Card";
import muiThemeable from "material-ui/styles/muiThemeable";

const styles = {
  card: {
    display: "flex",
    borderRadius: "6px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "3px 3px 3px 8px",
    borderRadius: "6px 6px 0px 0px",
    minHeight: "45px"
  },
  headerTitle: {
    color: "white",
    fontSize: "1em",
    marginLeft: "8px"
  },
  button: {
    display: "flex"
  }
};

export class SLGCard extends Component {
  render() {
    const { title, children, flexPercent, button, style } = this.props;
    const primaryColor = get(this.props, "muiTheme.palette.primary1Color");

    return (
      <Card
        containerStyle={{ flex: "100%" }}
        style={Object.assign(
          {
            flex: `${flexPercent}%`
          },
          styles.card,
          style
        )}
      >
        <CardHeader
          title={<div style={styles.headerTitle}>{title}</div>}
          style={Object.assign(
            {
              background: primaryColor
            },
            styles.header
          )}
        >
          {button ? <div style={styles.button}>{button}</div> : null}
        </CardHeader>
        <CardText>{children}</CardText>
      </Card>
    );
  }
}

SLGCard.propTypes = {
  title: PropTypes.string,
  flexPercent: PropTypes.number,
  button: PropTypes.element,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

SLGCard.defaultProps = {
  title: "",
  flexPercent: 50,
  style: {}
};

const enhance = compose(muiThemeable());

export default enhance(SLGCard);
