import React from "react";
import PropTypes from "prop-types";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

export default class IdleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleOpen = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  render() {
    const actions = [
      <FlatButton label="Ok" primary={true} onClick={this.handleClose} />
    ];

    return (
      <Dialog
        title="Session Timeout"
        actions={actions}
        modal={false}
        open={this.state.open}
        onRequestClose={this.handleClose}
      >
        You will be logged out after {this.props.idleTimeLeft} more minutes of
        inactivity.
      </Dialog>
    );
  }
}

IdleModal.propTypes = {
  idleTimeLeft: PropTypes.number.isRequired
};
