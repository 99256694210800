import BaseResponse from "../../shared/BaseResponse";

export default class GetContactsResponse extends BaseResponse {
  getListData(employerId) {
    const data = super.getListData();

    return {
      [employerId]: data
    };
  }
}
