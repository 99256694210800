const initialState = {
  employees: {
    byID: {},
    items: [],
    isFetching: false,
    receivedAt: null,
    total: 0
  },
  employeeNotes: {
    byID: {},
    items: [],
    isFetching: false,
    receivedAt: null,
    total: 0
  },
  tuitionReimbursement:{
    tRStatus:0,
    getSecondApproval:[]
  }
};

export default initialState;
