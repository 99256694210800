import React, { createElement, cloneElement, Children, Component } from "react";
import PropTypes from "prop-types";

import { AUTH_GET_PERMISSIONS } from "./";
import { resolvePermission } from "./resolvePermissions";
import authClient from "./authClient";

/**
 * Restricts access to children components
 *
 *  * Props:
 *   - Children: Components to restrict access to
 *   - exact: If user requires all roles
 *   - value: value to compare against permissions
 *   - resolve: Restrict access via a function instead of value
 *   - loading: Component to render during loading (default null)
 *   - notFound: Component to render if restricted (default null)
 *
 * @example
 * <WithPermission value="benefits_admin">
 *    <PermissionedComponent />
 * </WithPermission>
 */

export class WithPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNotFound: false,
      match: undefined,
      role: undefined
    };
  }

  async componentDidMount() {
    const { children, resolve, value: requiredPermissions, exact } = this.props;
    const permissions = await authClient(AUTH_GET_PERMISSIONS);
    const match = await resolvePermission({
      permissions
    })({
      exact,
      permissions: requiredPermissions,
      resolve,
      view: children
    });

    // If we have an auth match --- view is just the children
    if (match && match.matched) {
      this.setState({ match: match.view });
    } else {
      this.setState({ isNotFound: true, permissions });
    }
  }

  render() {
    const { isNotFound, match, role } = this.state;
    const {
      children,
      notFound,
      loading,
      resolve,
      value,
      exact,
      ...props
    } = this.props;

    // If has no permission, show no permissions component or null
    if (isNotFound || !match) {
      if (notFound) {
        return createElement(notFound, { role });
      }

      return null;
    }

    // if utilizing loading, show loading component
    if (!match && loading) {
      return createElement(loading);
    }

    // If many children, render them all
    if (Children.count(children) > 1) {
      return (
        <span>
          {Children.map(
            children,
            child => (child ? cloneElement(child, props) : null)
          )}
        </span>
      );
    }

    // if one child, just clone it
    return cloneElement(children, props);
  }
}

WithPermission.propTypes = {
  children: PropTypes.node.isRequired,
  exact: PropTypes.bool,
  loading: PropTypes.func,
  notFound: PropTypes.func,
  value: PropTypes.any,
  resolve: PropTypes.func
};

WithPermission.defaultProps = {
  loading: null
};

export default WithPermission;
