import React from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

import FormControl from "../../inputs/form-control/FormControl";
import CardTitleText from "../../typography/Typography";
import AsyncButton from "../../buttons/async/AsyncButton";
import CancelButton from "../../buttons/action/Cancel";
import CloseButton from "../../buttons/action/Close";

const useStyles = makeStyles(theme => ({
  dialogTitle: {}
}));

function DialogTitle({ title, onClose }) {
  const classes = useStyles();
  return (
    <MuiDialogTitle className={classes.dialogTitle} disableTypography>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <CardTitleText variant="h6">{title}</CardTitleText>
        </Grid>
        <Grid item>
          {onClose ? <CloseButton color="default" onClick={onClose} /> : null}
        </Grid>
      </Grid>
    </MuiDialogTitle>
  );
}

export default function FormDialog({
  maxWidth = "sm",
  title,
  open,
  onClose,
  onSubmit,
  defaultValues,
  loading,
  disabled,
  submitButtonProps: {
    label: submitLabel = "Submit",
    hide: hideSubmit = false
  } = {},
  cancelButtonProps: {
    label: cancelLabel = "Cancel",
    hide: hideCancel = false
  } = {},
  children
}) {
  const { handleSubmit: hookFormSubmit, control, ...formProps } = useForm({
    mode: "onBlur",
    defaultValues
  });

  function handleSubmit(values) {
    hookFormSubmit(onSubmit)(values);
  }

  function handleCancel() {
    if (onClose) onClose(formProps.getValues());
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth>
      <DialogTitle title={title} onClose={handleCancel} />
      <DialogContent dividers>
        <form onSubmit={handleSubmit}>
          {typeof children === "function"
            ? children({ formProps, control, loading, disabled, handleSubmit })
            : React.Children.toArray(children).map((child, index) => (
                <child.type
                  key={index}
                  {...child.props}
                  formProps={formProps}
                  control={control}
                  disabled={disabled}
                  onSubmit={handleSubmit}
                />
              ))}
        </form>
      </DialogContent>
      <DialogActions>
        <FormControl>
          <Grid container spacing={2}>
            <Grid item>
              {!hideCancel && (
                <CancelButton onClick={handleCancel} disabled={disabled}>
                  {cancelLabel}
                </CancelButton>
              )}
            </Grid>
            <Grid item>
              {!hideSubmit && (
                <AsyncButton
                  onClick={handleSubmit}
                  loading={loading}
                  disabled={disabled}
                >
                  {submitLabel}
                </AsyncButton>
              )}
            </Grid>
          </Grid>
        </FormControl>
      </DialogActions>
    </Dialog>
  );
}
