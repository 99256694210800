import React from "react";
import PropTypes from "prop-types";

import NumberFormat from "react-number-format";
import TextField from "material-ui/TextField";

const styles = {
  inputUnderline: { borderColor: "#258ffa" }
};

const CurrencyInput = ({
  input,
  label,
  meta: { touched, error },
  ...props
}) => {
  return (
    <NumberFormat
      floatingLabelText={label}
      floatingLabelFixed={true}
      customInput={TextField}
      errorText={touched && error}
      hintText="0.00"
      inputStyle={{ textAlign: "left" }}
      underlineStyle={styles.inputUnderline}
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
      allowNegative={false}
      {...input}
      {...props}
    />
  );
};

CurrencyInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  meta: PropTypes.object
};

export default CurrencyInput;
