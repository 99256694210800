import React from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";

import { InputProps } from "../text-field/types";
import HookFormContextContainer from "../containers/HookFormContextContainer";
import { TextFieldComponent } from "../text-field/TextField";

const noop = () => {};
const createValidate = ({ matchFieldName, errorMessage, watch }) => value => {
  return value === watch(matchFieldName) || errorMessage;
};

function ConfirmField({
  name,
  matchFieldName,
  errorMessage,
  required = true,
  validate = noop,
  ...props
}) {
  const methods = useFormContext();

  const validateWithProp =
    typeof validate === "function" ? validate({ ...props }) : validate;
  const validateWithMatchField = {
    ...validateWithProp,
    validate: createValidate({
      matchFieldName,
      errorMessage,
      watch: methods.watch
    })
  };

  return (
    <HookFormContextContainer
      name={name}
      required={required}
      validate={validateWithMatchField}
      {...props}
    >
      {({ hookFormMethods, ...renderProps }) => {
        return <TextFieldComponent {...renderProps} />;
      }}
    </HookFormContextContainer>
  );
}

export const ConfirmFieldProps = {
  ...InputProps,
  matchFieldName: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired
};

ConfirmField.propTypes = ConfirmFieldProps;

export default ConfirmField;
