import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import moment from "moment";
import compose from "recompose/compose";

import FlatButton from "material-ui/FlatButton";
import EditContent from "material-ui/svg-icons/editor/mode-edit";
import AddIcon from "material-ui/svg-icons/content/add";

import { selectEntityIdentifiers } from "../partners/selectors";
import { EmployerEditModalGeneral, EmployerEditModalPay } from "./modals";
import AdminUserCreateModal from "../adminUsers/modals/AdminUserCreateModal";
import { SLGCard, InfoBlock, InfoField } from "../components";
import { AdminUsersList } from "./";
import { WithPermission } from "../auth";
import { formatDateTimezone } from "../helpers/formattingHelpers";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  BENEFITS_ADMIN,
  PARTNER_MANAGER,
  SELF_SERVICE_ADMIN
} from "../constants";
import { openModal as openModalAction } from "../app/uiActions";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import GridList from "@material-ui/core/GridList";

import ConfirmationDialog from "../components/modals/ConfirmationDialog";
import useFetch from "../components/containers/utils/useFetch";
import { ROLE, TOKEN } from "../constants";
import { useSnackbar } from "notistack";

const styles = {
  container: { display: "flex", flexDirection: "column" },
  columnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%"
  },
  cardTitle: {
    marginBottom: "10px",
    fontSize: "1.5em",
    fontWeight: "bold"
  },
  infoBlockContainer: { display: "flex", flexWrap: "wrap" },
  settingsContainer: { display: "flex", flexDirection: "column" },
  buttonIcon: { height: "20px" },
  editLabel: { color: "#ffffff" }
};

export const EmployerSettings = ({
  employer: employerProp,
  openModal,
  entityIdentifiers
}) => {
  const employer = employerProp.employer;
  const { enqueueSnackbar } = useSnackbar();
  // Date from API is not in the correct format
  const nextDate = get(employer, "next_payroll_date.date");
  const formattedNextDate =
    nextDate && moment(formatDateTimezone(nextDate)).format("MM/DD/YYYY");
  const formattedStartDate =
    get(employer, "payroll_start_date") &&
    moment(formatDateTimezone(get(employer, "payroll_start_date"))).format(
      "MM/DD/YYYY"
    );

  const federatedEmployers = get(employer, "federated_employers", []);
  const payEnabled = get(employer, "pay_enabled?");
  const ssoByPass = get(employer, "sso_bypass");
  const partnerSSOenabled = get(employer, "partner_sso_enabled");
  const { makeFetch } = useFetch({ method: "post" });
  
  async function generateApiCredentials() {
    
    /*makeFetch({
      path: `/employers/${employer.id}/api_credentials`,
      data: {},
      onSuccess: response => {
        const { api_key, api_secret } = response.data;
        employer.api_key = api_key;
        employer.api_secret = api_secret;
      },
      onError: response => console.log("ERRORS: ", response)
    });*/
  
    
    const apiHost = process.env.REACT_APP_API_HOST;
    const request = new Request(`${apiHost}/employers/${employer.id}/api_credentials`, {
      method: "POST",
      body: JSON.stringify({ data: {} }),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/vnd.slg+json",
        Authorization: localStorage.getItem(TOKEN)
      })
    });
    const actualData = await fetch(request).then(response => response.json());
    if(actualData.data!==undefined){
      const { api_key, api_secret } = actualData.data.attributes;
      employer.api_key    = api_key;
      employer.api_secret = api_secret;
      enqueueSnackbar("API's settings generated successfully", { variant: "success" });
      
      setTimeout(()=>{
        window.location.reload();
      }, 3000);
    }
  };
  return (
    <div style={styles.container}>
    {employer && employer.api_allowed ?
      <div style={styles.columnContainer}>
        <SLGCard
          title="API SETTINGS"
          button={
            <FlatButton
              label="GENERATE API KEYS"
              labelStyle={styles.editLabel}
              onClick={() =>
                openModal(
                  <ConfirmationDialog
                    title="Are you sure?"
                    submitButtonProps={{
                      label: "Yes",
                      color: "primary",
                      onClick: _e => {
                        generateApiCredentials();
                      }
                    }}
                    content="Do you want generate new API credentials?"
                  />
                )
              }
              style={{ overflow: "inherit" }}
            />
          }
        >
          <React.Fragment>
            <InfoBlock header="API Access">
              <InfoField
                header="API Key"
                value={get(employer, "api_key")}
                xs={6}
              />
              <InfoField
                header="API Secret token"
                value={get(employer, "api_secret")}
                xs={6}
              />
            </InfoBlock>
          </React.Fragment>
        </SLGCard>
      </div>
    : null}
      <div style={styles.columnContainer}>
        <SLGCard
          title="GENERAL INFO"
          button={
            <WithPermission
              value={[
                SYSTEM_ADMIN,
                PARTNER_ADMIN,
                PARTNER_MANAGER,
                EMPLOYERS_ADMIN,
                EMPLOYER_MANAGER
              ]}
            >
              <FlatButton
                label="Edit"
                labelStyle={{ color: "#ffffff" }}
                icon={<EditContent color="white" style={styles.buttonIcon} />}
                onClick={() =>
                  openModal(
                    <EmployerEditModalGeneral
                      employer={employer}
                      partnerSSOenabled={partnerSSOenabled}
                    />
                  )
                }
                style={{ overflow: "inherit" }}
              />
            </WithPermission>
          }
        >
          <div style={styles.cardTitle}>{get(employer, "name")}</div>
          <React.Fragment>
            <InfoBlock header="General">
              <InfoField
                header="Partner"
                value={get(employer, "partner_name")}
                xs={4}
              />
              <InfoField
                header={`Total ${entityIdentifiers.employeeIdentifier}s`}
                value={get(employer, "total_employees")}
                hide={get(employer, "total_employees") == null}
                xs={4}
              />
              <InfoField
                header="SSO"
                value={ssoByPass ? "No" : "Yes"}
                hide={!get(employer, "partner_sso_enabled")}
                xs={4}
              />
              <InfoField
                header="2FA Required?"
                value={get(employer, "two_factor_required") ? "Yes" : "No"}
                hide={!get(employer, "sso_bypass")}
                xs={4}
              />
              <InfoField
                header="Password Age"
                value={get(employer, "password_expiration_term") || "None"}
                hide={!get(employer, "sso_bypass")}
                xs={4}
              />
              <InfoField
                header="PWs Remembered"
                value={get(employer, "old_password_count")}
                hide={!get(employer, "password_expiration_term")}
                xs={4}
              />
              <InfoField
                header="Landing Page"
                value={get(employer, "landing_page_url")}
                hide={!get(employer, "sso_bypass")}
                xs={4}
              />
              <InfoField
                header="Whitelisted Domains"
                value={get(employer, "whitelisted_domains") || "[empty]"}
                hide={!ssoByPass}
                xs={4}
              />
            </InfoBlock>
            {partnerSSOenabled && !ssoByPass && (
              <InfoBlock header="SSO">
                <GridList
                  cols={3}
                  cellHeight={50}
                  style={{ padding: "5px 10px 10px 15px", width: "100%" }}
                >
                  {federatedEmployers.map(val => (
                    <ListItem key={val.id}>
                      <ListItemText
                        primary={val.foreign_id}
                        secondary={val.foreign_name}
                      />
                    </ListItem>
                  ))}
                </GridList>
              </InfoBlock>
            )}
          </React.Fragment>
        </SLGCard>
      </div>

      <div style={styles.columnContainer}>
        <SLGCard
          title="BILLING SETTINGS"
          button={
            <WithPermission
              value={[
                SYSTEM_ADMIN,
                PARTNER_ADMIN,
                PARTNER_MANAGER,
                EMPLOYERS_ADMIN,
                EMPLOYER_MANAGER,
                SELF_SERVICE_ADMIN,
              ]}
            >
              <FlatButton
                label="Edit"
                labelStyle={{ color: "#ffffff" }}
                icon={<EditContent color="white" style={styles.buttonIcon} />}
                onClick={() =>
                  openModal(<EmployerEditModalPay employer={employer} />)
                }
                style={{ overflow: "inherit" }}
              />
            </WithPermission>
          }
        >
          <div style={styles.settingsContainer}>
            <InfoBlock header="Billing">
              <InfoField
                header="Address 1"
                value={get(employer, "billing_address.street_1")}
                xs={12}
              />
              <InfoField
                header="Address 2"
                value={get(employer, "billing_address.street_2")}
                xs={12}
              />
              <InfoField
                header="City"
                value={get(employer, "billing_address.city")}
                xs={4}
              />
              <InfoField
                header="State"
                value={get(employer, "billing_address.state")}
                xs={4}
              />
              <InfoField
                header="Zip Code"
                value={get(employer, "billing_address.zip_code")}
                xs={4}
              />
            </InfoBlock>
            <InfoBlock header={`Pay${payEnabled ? "" : " Disabled"}`}>
              {payEnabled ? (
                <React.Fragment>
                  <InfoField
                    header="Contribution Interval"
                    value={get(employer, "payroll_cycle")}
                    xs={4}
                  />
                  <InfoField
                    header="Contribution Start Date"
                    value={formattedStartDate}
                    xs={4}
                  />
                  <InfoField
                    header="Next Contribution Date"
                    value={formattedNextDate}
                    xs={4}
                  />
                </React.Fragment>
              ) : null}
            </InfoBlock>
          </div>
        </SLGCard>
      </div>
      {!SELF_SERVICE_ADMIN && (
          <div style={styles.columnContainer}>
            <SLGCard
              title="ADMIN USERS"
              button={
                <WithPermission
                  value={[
                    SYSTEM_ADMIN,
                    PARTNER_ADMIN,
                    EMPLOYERS_ADMIN,
                    EMPLOYER_MANAGER
                  ]}
                >
                  <FlatButton
                    label="New Admin User"
                    labelStyle={styles.editLabel}
                    icon={<AddIcon color="white" style={styles.buttonIcon} />}
                    onClick={() =>
                      openModal(
                        <AdminUserCreateModal
                          context={BENEFITS_ADMIN}
                          partnerID={get(employer, "partner_id")}
                          employerFilterIDs={[get(employer, "id")]}
                        />
                      )
                    }
                    style={{ overflow: "inherit" }}
                  />
                </WithPermission>
              }
              flexPercent={60}
            >
            <AdminUsersList
              employerID={get(employer, "id")}
              adminUserType={BENEFITS_ADMIN}
            />
          </SLGCard>
      </div>)}
    </div >
  );
};

EmployerSettings.propTypes = {
  employer: PropTypes.object
};

EmployerSettings.defaultProps = {
  employer: { contacts: [] }
};

function mapStateToProps(state) {
  const entityIdentifiers = selectEntityIdentifiers(state);
  return { entityIdentifiers };
}

const enhance = compose(
  connect(
    mapStateToProps,
    { openModal: openModalAction }
  )
);

export default enhance(EmployerSettings);
