import React from "react";
import * as R from "ramda";

import { toDisplayDateStringFromProp } from "services/formatters";
import withEmployerIdFromRouter from "components/containers/utils/withEmployerIdFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import NameCell from "./NameCell";

export const columns = props => [
  {
    name: "filename",
    label: "File",
    render: r => <NameCell {...r} {...props} />
  },
  {
    name: "total",
    label: "Total",
    disableSort: true
  },
  {
    name: "created",
    label: "Created",
    disableSort: true
  },
  {
    name: "updated",
    label: "Updated",
    disableSort: true
  },
  {
    name: "skipped",
    label: "Skipped",
    disableSort: true
  },
  {
    name: "failed",
    label: "Failed",
    disableSort: true
  },
  {
    name: "created_by_name",
    label: "Created By",
    disableSort: true
  },
  {
    name: "created_at",
    label: "Created On",
    format: toDisplayDateStringFromProp("created_at")
  }
];

const createGetSearchFilterWith = () =>
  R.applySpec({
    "0": {
      old_values_cont: R.identity,
      new_values_cont: R.identity,
      message_cont: R.identity,
      m: R.always("or")
    }
  });

export function extraProps(props) {
  const employerId = props.employerID;
  return {
    defaultSort: { order: "asc", orderBy: "created_at" },
    title: "Bulk History Details",
    path: employerId && `/employers/${employerId}/batch_upserts`,
    hideSearch: true,
    getSearchFilter: createGetSearchFilterWith()
  };
}

function actionsProps(props) {
  const employerId = props.employerID;

  return {
    columns: columns({ ...props, employerId })
  };
}

export default R.compose(
  withEmployerIdFromRouter,
  withProps(extraProps),
  withSort,
  withSearch,
  withPagination,
  withGetFetch,
  withProps(actionsProps)
)(PageComponent);
