import { concat, keyBy, omit, includes } from "lodash";
import {
  FETCH_GROUPS,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILURE,
  FETCH_GROUP,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FAILURE,
  CREATE_GROUP_SUCCESS,
  EDIT_GROUP_SUCCESS,
  DELETE_GROUP_SUCCESS
} from "groups/actions/types";

const defaultState = {
  groups: {
    byID: {},
    items: [],
    isFetching: false,
    receivedAt: null,
    total: 0
  }
};

export const groupsReducer = (
  previousState = defaultState.groups,
  { type, payload }
) => {
  switch (type) {
    case FETCH_GROUPS:
      return { ...previousState, isFetching: true };
    case FETCH_GROUPS_FAILURE:
      return { ...previousState, isFetching: false };
    case FETCH_GROUPS_SUCCESS:
      return {
        ...previousState,
        byID: {
          ...previousState.byID,
          ...keyBy(payload.groups.data, "id")
        },
        items: payload.groups.data.map(item => item.id),
        total: payload.groups.total,
        receivedAt: Date.now(),
        isFetching: false
      };
    case FETCH_GROUP:
      return { ...previousState, isFetching: true };
    case FETCH_GROUP_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: includes(previousState.items, payload.id)
          ? previousState.items
          : concat(previousState.items, payload.id),
        isFetching: false
      };
    case FETCH_GROUP_FAILURE:
      return { ...previousState, isFetching: false };
    case CREATE_GROUP_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: concat(previousState.items, payload.id),
        total: previousState.total + 1
      };
    case EDIT_GROUP_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload }
      };
    case DELETE_GROUP_SUCCESS:
      return {
        ...previousState,
        byID: omit(previousState.byID, payload),
        items: previousState.items.filter(item => item !== payload),
        total: previousState.total - 1
      };
    default:
      return previousState;
  }
};
