import React from "react";

export default WC => ({
  defaultSort = { orderBy: "created_at", order: "desc" },
  onSortChange,
  ...props
}) => {
  const [sort, setSort] = React.useState({
    field: defaultSort.orderBy,
    order: defaultSort.order
  });

  function handleSortChange({ order, orderBy }) {
    if (onSortChange) onSortChange({ order, orderBy });
    setSort({ order, field: orderBy });
  }

  const stateProps = {
    ...props.stateProps,
    sortState: [sort, setSort]
  };
  return (
    <WC
      {...props}
      stateProps={stateProps}
      defaultSort={defaultSort}
      sort={sort}
      onSortChange={handleSortChange}
    />
  );
};
