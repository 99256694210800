import React from "react";
import Input from "@material-ui/core/Input";

import { SearchFormProps } from "./types";
import SearchIcon from "./SearchIcon";

function SearchForm({
  placeholder = "Search...",
  name = "search",
  disabled,
  loading = false,
  onSubmit,
  onChange
}) {
  const [value, setValue] = React.useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (onSubmit) onSubmit(value);
  }

  function handleChange(e) {
    setValue(e.target.value);
    if (onChange) onChange(e.target.value);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Input
        disabled={disabled || loading}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={handleChange}
        endAdornment={
          <SearchIcon
            disabled={disabled}
            loading={loading}
            onClick={handleSubmit}
          />
        }
      />
    </form>
  );
}

SearchForm.propTypes = SearchFormProps;

export default SearchForm;
