import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

import { SelectProps } from "./types";
import LoadingWrapper from "../../feedback/component-loading/SMLoadingWrapper";
import Select from "./Select";
import withIconComponent from "./withIconComponent";

const AsyncIcon = ({ loading }) => (
  <LoadingWrapper loading={loading}>
    <ArrowDropDownIcon />
  </LoadingWrapper>
);

const AsyncSelect = withIconComponent(AsyncIcon)(Select);
AsyncSelect.propTypes = SelectProps;

export default AsyncSelect;
