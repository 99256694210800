import PropTypes from "prop-types";

export const TabsProps = {
  initialValue: PropTypes.number || PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string
    })
  ),
  appBarProps: PropTypes.object,
  children: PropTypes.node
};
