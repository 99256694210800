import React from "react";
import compose from "recompose/compose";
import { connect } from "react-redux";
/** legacy code, will remove once new form is done */
import { openModal } from "app/uiActions";
import { allowToSupportRep } from "services/permission/service";
import { DeleteConfirmationModal } from "components/modals/";
import GroupActions from "components/layouts/list/ActionsCell";
import EditButton from "components/buttons/action/Edit";
import DeleteButton from "components/buttons/action/Delete";
import { deleteEmployeeNote as deleteEmployeeNoteAction } from "employees/actions";
import { NoteEditModal } from "../modals";

const ActionsCell = ({
  openModal,
  sendEmployeeInvitation,
  deleteEmployeeNoteAction,
  stateProps: { requestData },
  ...note
}) => {
  const handleEditClick = () => {
    const handleSuccess = () => requestData();

    openModal(<NoteEditModal selectedNote={note} onSuccess={handleSuccess} />);
  };
  const handleDeleteClick = () => {
    const handleSuccess = () => requestData();

    openModal(
      <DeleteConfirmationModal
        title="Delete Note"
        deleteAction={() => deleteEmployeeNoteAction(note.id, handleSuccess)}
        resourceName="this note"
        onSuccess={handleSuccess}
      />
    );
  };

  return (
    <GroupActions>
      {allowToSupportRep() && (
        <>
          <EditButton onClick={handleEditClick}>Edit</EditButton>
          <DeleteButton onClick={handleDeleteClick}>Delete</DeleteButton>
        </>
      )}
    </GroupActions>
  );
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { openModal, deleteEmployeeNoteAction };
};

export default compose(
  connect(
    null,
    mapDispatchToProps()
  )
)(ActionsCell);
