import PropTypes from "prop-types";

import { InputProps } from "./text-field/types";
import TextField from "./text-field/TextField";
import withProps from "./text-field/withProps";

const validate = {
  required: "required",
  pattern: {
    value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    message: "Invalid email"
  }
};

const props = {
  name: "email",
  label: "Email",
  validate
};

const EmailField = withProps(props)(TextField);
EmailField.propTypes = {
  ...InputProps,
  name: PropTypes.string
};

export default EmailField;
