import makeActionCreator from "../../helpers/makeActionCreator";

const nameSpace = "employers/reports";

export const GET_IMPACT_REPORTS_REQUEST = `${nameSpace}/GET_IMPACT_REPORTS_REQUEST`;
export const GET_IMPACT_REPORTS_SUCCESS = `${nameSpace}/GET_IMPACT_REPORTS_SUCCESS`;
export const GET_IMPACT_REPORTS_FAILURE = `${nameSpace}/GET_IMPACT_REPORTS_FAILURE`;
export const GET_EMPLOYER_REPORTS_REQUEST = `${nameSpace}/GET_REPORTS_REQUEST`;
export const GET_EMPLOYER_REPORTS_SUCCESS = `${nameSpace}/GET_REPORTS_SUCCESS`;
export const GET_EMPLOYER_REPORTS_FAILURE = `${nameSpace}/GET_REPORTS_FAILURE`;
export const DOWNLOAD_REPORT_REQUEST = `${nameSpace}/DOWNLOAD_REPORT_REQUEST`;
export const DOWNLOAD_REPORT_SUCCESS = `${nameSpace}/DOWNLOAD_REPORT_SUCCESS`;
export const DOWNLOAD_REPORT_FAILURE = `${nameSpace}/DOWNLOAD_REPORT_FAILURE`;

export const requestGetImpactReports = makeActionCreator(
  GET_IMPACT_REPORTS_REQUEST,
  "employerId",
  "fileName"
);

export const requestGetReports = makeActionCreator(
  GET_EMPLOYER_REPORTS_REQUEST,
  "employerId"
);
export const getReportsSuccess = makeActionCreator(
  GET_EMPLOYER_REPORTS_SUCCESS,
  "data"
);
export const getReportsFailure = makeActionCreator(
  GET_EMPLOYER_REPORTS_FAILURE,
  "errors"
);

export const requestDownloadReport = makeActionCreator(
  DOWNLOAD_REPORT_REQUEST,
  "employerId",
  "reportId",
  "fileName"
);
export const downloadReportSuccess = makeActionCreator(DOWNLOAD_REPORT_SUCCESS);
export const downloadReportFailure = makeActionCreator(
  DOWNLOAD_REPORT_FAILURE,
  "errors"
);
