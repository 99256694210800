import reject from "lodash/reject";

import {
  TOGGLE_SUB_NAV,
  SET_ACTIVE_TAB_EMPLOYER,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  OPEN_MODAL,
  CLOSE_MODAL,
  CLOSE_ALL_MODALS
} from "./uiActions";

import {
  USER_LOGIN_LOADING,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE
} from "../auth/authActions";

const defaultState = {
  subNavOpen: true,
  subNavWidth: "225px",
  activeTabEmployer: "dashboard",
  notification: {
    text: "",
    type: "info" // one of 'info', 'confirm', 'warning'
  },
  loading: 0,
  modals: []
};

export const ui = (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case TOGGLE_SUB_NAV:
      return { ...previousState, subNavOpen: !previousState.subNavOpen };
    case SET_ACTIVE_TAB_EMPLOYER:
      return {
        ...previousState,
        activeTabEmployer: payload
      };
    case SHOW_NOTIFICATION:
      return {
        ...previousState,
        notification: { text: payload.text, type: payload.type }
      };
    case HIDE_NOTIFICATION:
      return { ...previousState, notification: { text: "", type: "info" } };

    case USER_LOGIN_LOADING:
      return { ...previousState, loading: previousState.loading + 1 };
    case USER_LOGIN_SUCCESS:
    case USER_LOGIN_FAILURE:
      return {
        ...previousState,
        loading: Math.max(previousState.loading - 1, 0)
      };
    case OPEN_MODAL:
      return {
        ...previousState,
        modals: previousState.modals.concat(payload),
        subNavOpen: false
      };
    case CLOSE_MODAL:
      return {
        ...previousState,
        modals: reject(previousState.modals, item => item.id === payload.id)
      };
    case CLOSE_ALL_MODALS:
      return {
        ...previousState,
        modals: []
      };
    default:
      return previousState;
  }
};
