import React from "react";

class PreviewEmployees extends React.Component {
  componentDidMount(){
    //console.log("Hi1");
  }
  componentDidUpdate(prevProps) {
    //console.log("Hi2");
  }
  componentWillReceiveProps(nextProps) {
    //console.log("Hi3");
  }
  render() {
    return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <div className="shopping-list">
        {/* eslint-disable-next-line react/react-in-jsx-scope */}
        <ul>
          <li>Instagram</li>
          <li>WhatsApp</li>
          <li>Oculus</li>
        </ul>
        <img src={ this.props.location.state ? this.props.location.state.filePreview : "" } className="wd-80" alt="" />
      </div>
    );
  }
}
export default PreviewEmployees;
