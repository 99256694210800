import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { Link } from "react-router-dom";
import { get } from "lodash";
import FlatButton from "material-ui/FlatButton";
import EditContent from "material-ui/svg-icons/editor/mode-edit";
import SendIcon from "material-ui/svg-icons/content/send";
import KeyboardBackspace from "@material-ui/icons/KeyboardBackspace";
import { SLGCard, InfoField, InfoIcon, InfoBlock } from "components";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SELF_SERVICE_ADMIN
} from "constants/index";
import { WithPermission } from "auth";
import { openModal } from "app/uiActions";
import { sendInvitesRequest } from "groups/actions/ultiproActions";
import { selectGroupByID } from "groups/selectors/index";
import { GroupEditModal } from "groups/components/modals";
import { fetchGroup } from "groups/actions";
import { formatCurrency } from "helpers";
import EmployeesList from "employees/components/list/EmployeesList";

const styles = {
  container: { display: "flex", flexDirection: "column" },
  columnContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "3%"
  },
  cardTitle: {
    marginBottom: "10px",
    fontSize: "1.5em",
    fontWeight: "bold"
  },
  settingsContainer: { display: "flex", flexDirection: "column", width: "50%" },
  buttonIcon: { height: "20px" },
  disabledButton: { color: "#e0e0e0" },
  editButton: { color: "#ffffff" }
};

export class GroupShow extends React.Component {
  static propTypes = {
    employer: PropTypes.object,
    group: PropTypes.object.isRequired,
    groupID: PropTypes.string.isRequired,
    employerID: PropTypes.string,
    employerLocked: PropTypes.bool,
    employerPayEnabled: PropTypes.bool,
    fetchGroup: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
  };

  static defaultProps = {
    group: {}
  };

  componentDidMount() {
    this.props.fetchGroup(this.props.groupID, this.props.employerID);
  }

  render() {
    const {
      employer,
      group,
      groupID,
      employerID,
      employerLocked,
      employerPayEnabled,
      openModal,
      sendInvitesRequest,
      employerFtnEnabled,
      employerTrEnabled
    } = this.props;

    //group.employer = employer;
    const payContributionAmountCents = get(
      group,
      "services_attributes.[0].match_amount_cents"
    );
    const payLifetimeCap = get(
      group,
      "services_attributes.[0].lifetime_cap_cents"
    );
    const locked = employerLocked || group["locked?"];
    const buttonStyling = locked ? styles.disabledButton : styles.editButton;
    const ultiproEnabled = employer && employer.ultipro_ready;

    const isSelfServiceUser = employer.partner_name === "SelfService";

    const showPayInfo = isSelfServiceUser
      ? employerPayEnabled === true
      : true;
    const showFtnInfo = isSelfServiceUser && employerFtnEnabled;
    const showTrInfo = isSelfServiceUser && employerTrEnabled;


    return (
      <div style={styles.container}>
        <div style={styles.cardTitle}>{group.name}</div>
        <div style={styles.columnContainer}>
          <SLGCard
            title="GROUP SETTINGS"
            button={
              <span>
                <Link to={`/employers/${employerID}/groups`}>
                  <FlatButton
                    labelStyle={{ color: "#ffffff" }}
                    label="Back to Groups"
                    icon={
                      <KeyboardBackspace color="inherit" nativeColor="white" />
                    }
                  />
                </Link>
                {ultiproEnabled && (
                  <FlatButton
                    labelStyle={{ color: "#ffffff" }}
                    label="Send Invites"
                    icon={
                      <SendIcon
                        color={styles.editButton.color}
                        style={styles.buttonIcon}
                      />
                    }
                    onClick={() => {
                      sendInvitesRequest(employerID, groupID);
                    }}
                    style={{ overflow: "inherit" }}
                  />
                )}
                <WithPermission
                  value={[
                    SYSTEM_ADMIN,
                    PARTNER_ADMIN,
                    EMPLOYERS_ADMIN,
                    EMPLOYER_MANAGER,
                    SELF_SERVICE_ADMIN
                  ]}
                >
                  <FlatButton
                    label="Edit"
                    labelStyle={buttonStyling}
                    icon={
                      <EditContent
                        color={buttonStyling.color}
                        style={styles.buttonIcon}
                      />
                    }
                    onClick={() =>
                      openModal(
                        <GroupEditModal
                          group={group}
                          employerID={employerID}
                          employer={employer}
                          employerPayEnabled={employerPayEnabled}
                          employerFtnEnabled={employer.subscription ? employer.subscription.ftn_enabled : false}
                          employerTrEnabled={employer.subscription ? employer.subscription.tr_enabled : false}
                        />
                      )
                    }
                    style={{ overflow: "inherit" }}
                    disabled={locked}
                  />
                  {locked ? (
                    <InfoIcon
                      tooltipPosition="top-left"
                      iconStyle={styles.editButton}
                      tooltip={
                        <span>
                          Contributions are currently being processed.
                          <br />
                          Cannot update groups at this time.
                        </span>
                      }
                    />
                  ) : null}
                </WithPermission>
              </span>
            }
          >
            <InfoBlock>
              <InfoField
                header="Name"
                value={group.name}
                fullWidth={false}
                xs={6}
              />
            </InfoBlock>
            {showPayInfo &&
              <InfoBlock>
                <InfoField
                  header="Pay"
                  value={group["pay_enabled?"] ? "Enabled" : "Disabled"}
                  fullWidth={false}
                  xs={4}
                />
                <InfoField
                  header="Pay Contribution Amount"
                  value={
                    payContributionAmountCents
                      ? formatCurrency(payContributionAmountCents, true)
                      : "N/A"
                  }
                  fullWidth={false}
                  xs={4}
                />
                <InfoField
                  header="Pay Lifetime Cap"
                  value={payLifetimeCap ? formatCurrency(payLifetimeCap, true) : "N/A"}
                  fullWidth={false}
                  xs={4}
                />
              </InfoBlock>
            }
            {showFtnInfo &&
              <InfoBlock>
                <InfoField
                  header="529"
                  value={group["ftn_enabled?"] ? "Enabled" : "Disabled"}
                  fullWidth={false}
                  xs={4}
                />
                <InfoField
                  header="529 Contribution Amount"
                  value={
                    group["ftn_enabled?"]
                      ? formatCurrency(
                          group["ftn_enabled?"]["match_amount_cents"],
                          true
                        )
                      : "N/A"
                  }
                  fullWidth={false}
                  xs={4}
                />
                <InfoField
                  header="529 Lifetime Cap"
                  value={group["ftn_enabled?"] ? formatCurrency(group["ftn_enabled?"]['lifetime_cap_cents'], true) : "N/A"}
                  fullWidth={false}
                  xs={4}
                />
              </InfoBlock>
              }
            <InfoBlock>
              {showTrInfo &&
                <>
                  <InfoField
                    header="TR"
                    value={group["tr_enabled?"] ? "Enabled" : "Disabled"}
                    fullWidth={false}
                    xs={4}
                  />
                  <InfoField
                    header="TR Lifetime Cap"
                    value={group["tr_enabled?"] ? formatCurrency(group["tr_enabled?"]['lifetime_cap_cents'], true) : "N/A"}
                    fullWidth={false}
                    xs={4}
                  />
                </>
              }
              {ultiproEnabled && (
                <InfoField
                  header="Auto Send Invites"
                  value={group.automatic_invite ? "Yes" : "No"}
                  fullWidth={false}
                  xs={4}
                />
              )}
            </InfoBlock>
          </SLGCard>
        </div>

        <EmployeesList
          groupID={groupID}
          groupName={group.name}
          group={group}
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    group: selectGroupByID(state, props.groupID)
  };
};

const mapDispatchToProps = (_dispatch, _ownProps) => {
  return {
    fetchGroup,
    openModal,
    sendInvitesRequest
  };
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps()
  )
);

export default enhance(GroupShow);
