import React from "react";
import PropTypes from "prop-types";
import BaseGrid from "@material-ui/core/Grid";

function Grid({ children, ...props }) {
  return (
    <BaseGrid container {...props}>
      {React.Children.toArray(children).map((child, index) => (
        <BaseGrid key={index} item>
          {child}
        </BaseGrid>
      ))}
    </BaseGrid>
  );
}

Grid.propTypes = {
  direction: PropTypes.oneOf(["row", "column"]),
  justify: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-around",
    "space-evenly"
  ]),
  spacing: PropTypes.oneOf([0, 2, 4, 6, 8, 10]),
  children: PropTypes.node.isRequired
};

export default Grid;
