import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import uuidv4 from "uuid/v4";

import { userLogout as userLogoutAction } from "../auth/authActions";
import IdleContainer from "./IdleContainer";
import { IdleModal } from "./";
import {
  DEFAULT_LOGOUT_IDLE,
  DEFAULT_LOGOUT_WARN,
  SESSIONS
} from "../constants";
import every from "lodash/every";
import omit from "lodash/omit";

class Idle extends Component {
  _tabID = uuidv4();
  _warnTimeout = null;
  _logoutTimeout = null;

  setSessions = allSessions => {
    localStorage.setItem(SESSIONS, JSON.stringify(allSessions));
  };

  getSessions = () => {
    return JSON.parse(localStorage.getItem(SESSIONS));
  };

  _minutesInMS = minutes => minutes * 60000;

  _allSessionsIdle = () => {
    const allSessions = this.getSessions();
    return every(allSessions, val => {
      return val === true;
    });
  };

  componentCleanUp = () => {
    const updatedSessions = omit(this.getSessions(), [this._tabID]);
    this.setSessions(updatedSessions);
  };

  componentDidMount() {
    // Gets around stranded tabs if user refreshes / exits tab
    window.addEventListener("beforeunload", this.componentCleanUp);
  }

  _handleIdleChange = idle => {
    const { minutesToLogout, minutesToWarn, userLogout } = this.props;
    this.setSessions({ ...this.getSessions(), [this._tabID]: idle });

    if (idle && this._allSessionsIdle()) {
      const warnTimeoutRef = setTimeout(() => {
        this.child.handleOpen();
      }, this._minutesInMS(minutesToWarn));
      const logoutTimeoutRef = setTimeout(() => {
        userLogout("You have been logged out due to inactivity");
      }, this._minutesInMS(minutesToLogout));
      this._warnTimeout = warnTimeoutRef;
      this._logoutTimeout = logoutTimeoutRef;
    }

    if (!idle) {
      clearTimeout(this._warnTimeout);
      clearTimeout(this._logoutTimeout);
    }
  };

  componentWillUnmount() {
    this.componentCleanUp();
    window.removeEventListener("beforeunload", this.componentCleanUp);
    clearTimeout(this._warnTimeout);
    clearTimeout(this._logoutTimeout);
  }

  render() {
    return (
      <IdleContainer
        onChange={({ idle }) => this._handleIdleChange(idle)}
        render={({ idle }) => (
          <IdleModal
            ref={instance => {
              this.child = instance;
            }}
            idleTimeLeft={this.props.minutesToLogout - this.props.minutesToWarn}
          />
        )}
      />
    );
  }
}

Idle.propTypes = {
  minutesToLogout: PropTypes.number.isRequired
};

Idle.defaultProps = {
  minutesToWarn: DEFAULT_LOGOUT_WARN,
  minutesToLogout: DEFAULT_LOGOUT_IDLE
};

export default connect(null, { userLogout: userLogoutAction })(Idle);
