import makeActionCreator from "../../helpers/makeActionCreator";

const nameSpace = "employers/refiVendor";

export const GET_REFI_VENDORS_REQUEST = `${nameSpace}/GET_REFI_VENDORS_REQUEST`;
export const GET_REFI_VENDORS_SUCCESS = `${nameSpace}/GET_REFI_VENDORS_SUCCESS`;
export const GET_REFI_VENDORS_FAILURE = `${nameSpace}/GET_REFI_VENDORS_FAILURE`;

export const TOGGLE_REQUEST = `${nameSpace}/TOGGLE_REQUEST`;
export const TOGGLE_SUCCESS = `${nameSpace}/TOGGLE_SUCCESS`;
export const TOGGLE_FAILURE = `${nameSpace}/TOGGLE_FAILURE`;

export const TOGGLE_VISIBILITY_REQUEST = `${nameSpace}/TOGGLE_VISIBILITY_REQUEST`;
export const TOGGLE_VISIBILITY_SUCCESS = `${nameSpace}/TOGGLE_VISIBILITY_SUCCESS`;
export const TOGGLE_VISIBILITY_FAILURE = `${nameSpace}/TOGGLE_VISIBILITY_FAILURE`;

export const requestGetRefiVendors = makeActionCreator(
  GET_REFI_VENDORS_REQUEST,
  "employerId"
);
export const getRefiVendorsSuccess = makeActionCreator(
  GET_REFI_VENDORS_SUCCESS,
  "data"
);
export const getRefiVendorsFailure = makeActionCreator(
  GET_REFI_VENDORS_FAILURE,
  "errors"
);

export const toggleRequest = makeActionCreator(
  TOGGLE_REQUEST,
  "employerId",
  "vendorId",
  "vendorableRecordId",
  "toggle"
);
export const toggleSuccess = makeActionCreator(TOGGLE_SUCCESS, "vendorId");
export const toggleFailure = makeActionCreator(
  TOGGLE_FAILURE,
  "vendorId",
  "error"
);

export const toggleVisibilityRequest = makeActionCreator(
  TOGGLE_VISIBILITY_REQUEST,
  "employerId",
  "toggle"
);
export const toggleVisibilitySuccess = makeActionCreator(
  TOGGLE_VISIBILITY_SUCCESS,
  "employerId"
);
export const toggleVisibilityFailure = makeActionCreator(
  TOGGLE_VISIBILITY_FAILURE,
  "employerId",
  "error"
);
