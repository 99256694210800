import createReducer from "../../helpers/createReducer";
import {
  GET_PARTNER_REPORTS_REQUEST,
  GET_PARTNER_REPORTS_SUCCESS,
  GET_PARTNER_REPORTS_FAILURE,
} from "../actions";

const defaultState = {
  byPartnerId: {},
  isFetching: false,
  fetchingErrors: null,
  isDownloadingReport: false,
  downloadReportErrors: null,
};

function requestReports(state) {
  return Object.assign({}, state, { isFetching: true, fetchingErrors: null });
}

function requestReportsSuccess(state, action) {
  return Object.assign({}, state, {
    isFetching: false,
    fetchingErrors: null,
    byPartnerId: Object.assign({}, state.byPartnerId, action.data),
  });
}

function requestReportsError(state, action) {
  return Object.assign({}, state, {
    isFetching: false,
    fetchingErrors: action.errors,
  });
}

export default createReducer(defaultState, {
  [GET_PARTNER_REPORTS_REQUEST]: requestReports,
  [GET_PARTNER_REPORTS_SUCCESS]: requestReportsSuccess,
  [GET_PARTNER_REPORTS_FAILURE]: requestReportsError,
});
