export default ({ partnerName }) => {
  switch (partnerName) {
    case "Pru - AFA":
      return {
        employerIdentifier: "Prudential Financial Professional",
        employeeIdentifier: "Individual"
      };
    default:
      return {
        employerIdentifier: "Employer",
        employeeIdentifier: "Employee"
      };
  }
};
