import { put, all, call, takeEvery, takeLatest } from "redux-saga/effects";

import log from "../../services/logging/service";
import routeService from "../services/RouteService";
import fetchApiService from "../../rest/fetchApiService";
import { showError } from "../../shared/sagas/notifications";
import {
  GET_INVOICES_REQUEST,
  getInvoicesSuccess,
  getInvoicesFailure,
  GET_EXCLUSION_REQUEST,
  getExclusionSuccess,
  getExclusionFailure,
  GET_INVOICE_REQUEST,
  getInvoiceSuccess,
  getInvoiceFailure,
  GET_COMBINED_INVOICE_REQUEST,
  getCombinedInvoiceSuccess,
  getCombinedInvoiceFailure,
  GET_COMBINED_EXCLUSION_REQUEST,
  getCombinedExclusionSuccess,
  getCombinedExclusionFailure,
  APPROVE_REQUEST,
  approveSuccess,
  approveFailure,
  RESTORE_REQUEST,
  restoreSuccess,
  restoreFailure,
  DELETE_ITEM_REQUEST,
  deleteItemSuccess,
  deleteItemFailure
} from "../actions/invoices";
import GetInvoicesRequest from "../services/GetInvoicesRequest";
import GetInvoicesResponse from "../services/GetInvoicesResponse";
import GetExclusionRequest from "../services/GetExclusionRequest";
import GetExclusionResponse from "../services/GetExclusionResponse";
import GetInvoiceRequest from "../services/GetInvoiceRequest";
import GetInvoiceResponse from "../services/GetInvoiceResponse";
import ApproveInvoiceRequest from "../services/ApproveInvoiceRequest";
import ApproveInvoiceResponse from "../services/ApproveInvoiceResponse";
import DeleteInvoiceItemRequest from "../services/DeleteInvoiceItemRequest";
import DeleteInvoiceItemResponse from "../services/DeleteInvoiceItemResponse";
import RestoreItemRequest from "../services/RestoreItemRequest";
import RestoreItemResponse from "../services/RestoreItemResponse";

function* getInvoices() {
  try {
    const url = routeService.getInvoices();
    const request = new GetInvoicesRequest({ url });
    const response = yield fetchApiService.fetch(request, GetInvoicesResponse);
    if (response.ok) {
      const data = response.getListData();

      yield put(getInvoicesSuccess(data));
    } else {
      yield call(showError, response.statusText);
      yield put(getInvoicesFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getInvoicesFailure(e.message));
    log.error(e.message);
  }
}

function* getExclusion({ invoiceId }) {
  try {
    const url = routeService.getExclusion(invoiceId);
    const request = new GetExclusionRequest({ url });
    const response = yield fetchApiService.fetch(request, GetExclusionResponse);
    if (response.ok) {
      const data = response.getData();

      yield put(getExclusionSuccess(data));
    } else {
      yield call(showError, response.statusText);
      yield put(getExclusionFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getExclusionFailure(e.message));
    log.error(e.message);
  }
}

function* getCombinedExclusion({ pdrInvoiceId, feeInvoiceId }) {
  try {
    const pdrUrl = routeService.getExclusion(pdrInvoiceId);
    const pdrRequest = new GetExclusionRequest({ url: pdrUrl });
    const pdrResponse = yield fetchApiService.fetch(
      pdrRequest,
      GetExclusionResponse
    );

    if (pdrResponse.ok) {
      yield put(getCombinedExclusionSuccess(pdrResponse.getData()));
    } else {
      yield call(showError, pdrResponse.statusText);
      yield put(getCombinedExclusionFailure(pdrResponse.statusText));
      log.error(pdrResponse.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getCombinedExclusionFailure(e.message));
    log.error(e.message);
  }
}

function* getInvoice({ invoiceId }) {
  try {
    const url = routeService.getInvoice(invoiceId);
    const request = new GetInvoiceRequest({ url });
    const response = yield fetchApiService.fetch(request, GetInvoiceResponse);
    if (response.ok) {
      const data = response.getData();

      yield put(getInvoiceSuccess(invoiceId, data));
    } else {
      yield call(showError, response.statusText);
      yield put(getInvoiceFailure(response.statusText));
      log.error(response.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getInvoiceFailure(e.message));
    log.error(e.message);
  }
}

function* getCombinedInvoice({ id, pdrInvoiceId, feeInvoiceId }) {
  try {
    const pdrUrl = routeService.getInvoice(pdrInvoiceId);
    const feeUrl = routeService.getInvoice(feeInvoiceId);
    const pdrRequest = new GetInvoiceRequest({ url: pdrUrl });
    const feeRequest = new GetInvoiceRequest({ url: feeUrl });
    const fetches = [];
    if (pdrInvoiceId)
      fetches.push(fetchApiService.fetch(pdrRequest, GetInvoiceResponse));
    if (feeInvoiceId)
      fetches.push(fetchApiService.fetch(feeRequest, GetInvoiceResponse));
    const [pdrResponse, feeResponse] = yield all(fetches);

    let data = {};
    if (pdrResponse && pdrResponse.ok) {
      const pdrData = pdrResponse.getData();

      /** combine data */
      data = {
        ...data,
        type: "pdr_fee",
        status: pdrData.status,
        total_due: data.total_due + pdrData.total_cents,
        pdr_line_items_count: pdrData.pdr_line_items_count,
        pdr_line_items: pdrData.invoice_line_items
      };

      yield put(getCombinedInvoiceSuccess(id, data));
    }
    if (feeResponse && feeResponse.ok) {
      const feeData = feeResponse.getData();

      /** combine data */
      data = {
        ...data,
        type: "pdr_fee",
        total_due: data.total_due + feeData.total_cents,
        fee_line_items_count: feeData.fee_line_items_count,
        fee_line_items: feeData.invoice_line_items
      };
    }

    if ((pdrResponse && pdrResponse.ok) || (feeResponse && feeResponse.ok)) {
      yield put(getCombinedInvoiceSuccess(id, data));
    }

    if (pdrResponse && !pdrResponse.ok) {
      yield call(showError, pdrResponse.statusText);
      yield put(getCombinedInvoiceFailure(pdrResponse.statusText));
      log.error(pdrResponse.statusText);
    }
    if (feeResponse && !feeResponse.ok) {
      yield call(showError, feeResponse.statusText);
      yield put(getCombinedInvoiceFailure(feeResponse.statusText));
      log.error(feeResponse.statusText);
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(getCombinedInvoiceFailure(e.message));
    log.error(e.message);
  }
}

function* approveInvoice({ invoiceId }) {
  try {
    const url = routeService.approveInvoice(invoiceId);
    const request = new ApproveInvoiceRequest({ url, invoiceId });
    const response = yield fetchApiService.fetch(
      request,
      ApproveInvoiceResponse
    );
    if (response.ok) {
      yield put(approveSuccess(invoiceId));
    } else {
      yield call(showError, response.statusText);
      yield put(approveFailure(response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(approveFailure(e.message));
  }
}

function* restoreItem({ invoiceId, itemId, invoiceLineItemId }) {
  try {
    const url = routeService.restoreItem(invoiceLineItemId);
    const request = new RestoreItemRequest({ url });
    const response = yield fetchApiService.fetch(request, RestoreItemResponse);
    if (response.ok) {
      yield put(restoreSuccess(invoiceId, itemId));
    } else {
      yield call(showError, response.statusText);
      yield put(restoreFailure(itemId, response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(restoreFailure(itemId, e.message));
  }
}

function* deleteInvoiceItem({ invoiceId, invoiceItemId, pullingForId }) {
  try {
    const url = routeService.deleteInvoiceItem(invoiceItemId);
    const request = new DeleteInvoiceItemRequest({ url });
    const response = yield fetchApiService.fetch(
      request,
      DeleteInvoiceItemResponse
    );
    if (response.ok) {
      yield put(deleteItemSuccess(invoiceId, invoiceItemId, pullingForId));
    } else {
      yield call(showError, response.statusText);
      yield put(deleteItemFailure(invoiceItemId, response.statusText));
    }
  } catch (e) {
    yield call(showError, e.message);
    yield put(deleteItemFailure(invoiceItemId, e.message));
  }
}

export default function* invoiceSagas() {
  yield all([
    takeLatest(GET_INVOICES_REQUEST, getInvoices),
    takeEvery(GET_EXCLUSION_REQUEST, getExclusion),
    takeEvery(GET_INVOICE_REQUEST, getInvoice),
    takeEvery(GET_COMBINED_INVOICE_REQUEST, getCombinedInvoice),
    takeEvery(GET_COMBINED_EXCLUSION_REQUEST, getCombinedExclusion),
    takeLatest(APPROVE_REQUEST, approveInvoice),
    takeEvery(RESTORE_REQUEST, restoreItem),
    takeEvery(DELETE_ITEM_REQUEST, deleteInvoiceItem)
  ]);
}
