import { concat, keyBy } from "lodash";

import {
  CREATE_ADMIN_USER_SUCCESS,
  FETCH_ADMIN_USERS,
  FETCH_ADMIN_USERS_BY_EMAIL,
  FETCH_ADMIN_USERS_SUCCESS,
  FETCH_ADMIN_USERS_FAILURE,
  EDIT_ADMIN_USER_SUCCESS
} from "./adminUserActions";

const defaultState = {
  byID: {},
  items: [],
  isFetching: false,
  receivedAt: null,
  total: 0
};

export const adminUser = (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case FETCH_ADMIN_USERS_BY_EMAIL:
      return {
        ...previousState,
        isFetching: true
      };
    case FETCH_ADMIN_USERS:
      return {
        ...previousState,
        isFetching: true
      };
    case FETCH_ADMIN_USERS_FAILURE:
      return {
        ...previousState,
        isFetching: false
      };
    case FETCH_ADMIN_USERS_SUCCESS:
      return {
        ...previousState,
        byID: {
          ...previousState.byID,
          ...keyBy(payload.data, "id")
        },
        items: payload.data.map(item => item.id),
        total: payload.total,
        receivedAt: Date.now(),
        isFetching: false
      };
    case CREATE_ADMIN_USER_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload },
        items: concat(previousState.items, payload.id),
        total: previousState.total + 1
      };
    case EDIT_ADMIN_USER_SUCCESS:
      return {
        ...previousState,
        byID: { ...previousState.byID, [payload.id]: payload }
      };
    default:
      return previousState;
  }
};
