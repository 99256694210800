import React from "react";

import { DialogProps } from "./types";
import Container from "./Container";
import Title from "./Title";
import Actions from "./Actions";

function Dialog({
  title,
  open,
  onClose,
  onSubmit,
  loading,
  renderActionsSlot,
  children,
  ...props
}) {
  return (
    <Container
      open={open}
      onClose={onClose}
      titleSlot={<Title onClose={onClose}>{title}</Title>}
      contentSlot={children}
      actionsSlot={
        renderActionsSlot ? (
          renderActionsSlot({ loading, onClose, onSubmit, props })
        ) : (
          <Actions onSubmit={onSubmit} onCancel={onClose} loading={loading} />
        )
      }
      {...props}
    />
  );
}

Dialog.propTypes = DialogProps;

export default Dialog;
