import React from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { selectEntityIdentifiers } from "../../partners/selectors";

import { PageComponent } from "../containers/ListPage";
import withSort from "../containers/withSort";
import withSearch from "../containers/withSearch";
import withGetFetch from "../containers/withGetFetch";
import withColumns from "./withColumns";
import withProps from "./withProps";
import PageAction from "./PageAction";

function extraProps(props) {
  function handleGenerateReportCompleted() {
    props.stateProps.requestData();
  }

  return {
    title: "Reports",
    actionSlot: ({ disabled }) => (
      <PageAction
        onGenerateReportCompleted={handleGenerateReportCompleted}
        disabled={disabled}
        reports={props.stateProps.dataState[0]}
        renderGenerateForm={props.renderGenerateForm}
        entityIdentifiers={props.entityIdentifiers}
      />
    )
  };
}

function searchProps(props) {
  function getSearchFilter(v) {
    return v
      ? {
          "0": { name_cont: v }
        }
      : {};
  }

  return {
    getSearchFilter
  };
}

function mapStateToProps(state) {
  return {
    entityIdentifiers: selectEntityIdentifiers(state)
  };
}

export default compose(
  connect(mapStateToProps),
  withColumns,
  withSort,
  withProps(searchProps),
  withSearch,
  withGetFetch,
  withProps(extraProps)
)(PageComponent);
