import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { find, get, has, map } from "lodash";

import GridList from "material-ui/GridList";

import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  PARTNER_MANAGER
} from "../constants";
import permissionService from "../rest/permissionService";
import { fetchRefiVendors as fetchRefiVendorsAction } from "./refiVendorActions";
import { renameKey } from "../helpers/utils";
import RefiCard from "../components/cards/RefiCard";

export class RefiVendorsList extends Component {
  componentDidMount() {
    const allowFetch = permissionService.allowWith([
      SYSTEM_ADMIN,
      PARTNER_ADMIN,
      PARTNER_MANAGER
    ]);

    if (allowFetch) {
      this.props.fetchRefiVendors();
    }
  }

  // Merges the full refi vendors list with the partner so we can show
  // refi vendors the partner does not have and allow them to toggle on/off
  mergeRefiVendorData = () => {
    const { refiVendors, partnerRefiVendors } = this.props;

    const updatedPartnerRefiVendors = map(
      partnerRefiVendors,
      partnerVendor => {
        return renameKey(
          partnerVendor,
          "id",
          "partner_refi_vendor_id"
        );
      }
    );

    return map(refiVendors, globalRefiVendor => {
      const partnerRefiVendor = find(updatedPartnerRefiVendors, {
        refi_vendor_id: globalRefiVendor.id
      });
      return { ...globalRefiVendor, ...partnerRefiVendor };
    });
  };

  render() {
    const {
      partnerRefiVendors,
      partnerID,
      toggleRefiVendor
    } = this.props;

    const mergedVendors = this.mergeRefiVendorData();
    return (
      <div>
        <GridList cellHeight="auto" cols={3} padding={12}>
          {mergedVendors.map(vendor => {
            const refiVendorEnabled =
              !partnerRefiVendors ||
              has(vendor, "partner_refi_vendor_id");

            return (
              <RefiCard
                key={get(vendor, "id")}
                data={vendor}
                inAdvisor={refiVendorEnabled}
                withMedia={{
                  src: get(vendor, "image_url"),
                  title: get(vendor, "name")
                }}
                withContent={{
                  title: get(vendor, "name"),
                  content: get(vendor, "description")
                }}
                withDelegates={{
                  handleInAdvisorChanged: ({ toggle }) => {
                    return toggleRefiVendor(
                      partnerID,
                      get(vendor, "id"),
                      get(vendor, "partner_refi_vendor_id"),
                      toggle
                    );
                  },
                  urlButtonHref: get(vendor, "url")
                }}
              />
            );
          })}
        </GridList>
      </div>
    );
  }
}

RefiVendorsList.propTypes = {
  fetchRefiVendors: PropTypes.func,
  refiVendors: PropTypes.array,
  partnerRefiVendors: PropTypes.array,
  partnerID: PropTypes.string,
  toggleRefiVendor: PropTypes.func
};

RefiVendorsList.defaultProps = {
  refiVendors: []
};

function mapStateToProps(state) {
  return {
    refiVendors: state.refiVendors.data
  };
}

export default connect(
  mapStateToProps,
  {
    fetchRefiVendors: fetchRefiVendorsAction
  }
)(RefiVendorsList);
