import PropTypes from "prop-types";

import { SearchFormProps } from "../../forms/search/types";

const TitleProp = { title: PropTypes.string };
const DisabledProps = { disabled: PropTypes.bool };

export const HeaderProps = {
  ...TitleProp,
  ...DisabledProps,
  actionSlot: PropTypes.func
};

export const ActionsProps = {
  ...DisabledProps
};

export const SubActionsProps = {
  ...DisabledProps,
  subActionSlot: PropTypes.func
};

export const SearchProps = {
  searchFormProps: PropTypes.shape(SearchFormProps)
};

export const ContentProps = {
  contentSlot: PropTypes.func.isRequired
};

export const CardProps = {
  ...HeaderProps,
  ...ActionsProps,
  ...SubActionsProps,
  contentSlot: PropTypes.func
};

export const CardHOCProps = {
  Card: PropTypes.element,
  Header: PropTypes.element,
  Actions: PropTypes.element,
  Content: PropTypes.element
};
