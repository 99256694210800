import BaseRequest from "../../shared/BaseRequest";

export default class UltiproEnableSyncRequest extends BaseRequest {
  method = "patch";

  constructor({ url, employerId, id, sync_enabled }) {
    super({ url });

    this.data = {
      type: "employers",
      id: employerId,
      ultipro_integration_attributes: {
        id,
        sync_enabled
      }
    };
  }
}
