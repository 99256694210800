import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { TWO_FACTOR_ENABLED } from "../constants";
import { Enable, Disable } from "./";
import { hideTwoFactor as hideTwoFactorAction } from "./twoFactorActions";

export class TwoFactor extends Component {
  handleClose = () => this.props.hideTwoFactor();

  render() {
    var twoFactorEnabled = localStorage.getItem(TWO_FACTOR_ENABLED); // This is a string
    // Make sure handle undefined (no two factor)
    const twoFactor =
      twoFactorEnabled === "true" ? (
        <Disable handleClose={this.handleClose} />
      ) : (
        <Enable handleClose={this.handleClose} />
      );
    return this.props.show ? <div>{twoFactor}</div> : null;
  }
}

TwoFactor.propTypes = {
  show: PropTypes.bool,
  hideTwoFactor: PropTypes.func.isRequired
};

TwoFactor.defaultProps = {
  show: false
};

const mapStateToProps = state => ({
  show: state.twoFactor.show
});

export default connect(mapStateToProps, {
  hideTwoFactor: hideTwoFactorAction
})(TwoFactor);
