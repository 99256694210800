import React from "react";
import { Controller } from "react-hook-form";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import BaseSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormControl from "../form-control/FormControl";
import withControls from "../enhancements/withControls";

const renderItems = options =>
  options.map((option, index) => (
    <MenuItem key={option.value + index} value={option.value}>
      {option.label}
    </MenuItem>
  ));

function SelectComponent({
  name,
  label,
  helperText,
  disabled,
  required,
  error,
  options,
  loading,
  ...props
}) {
  /** hack to hide dropdown for empty options */
  const inputProps =
    !options || options.length < 1
      ? { ...props.inputProps, readOnly: true }
      : props.inputProps;

  return (
    <FormControl
      disabled={disabled}
      required={required}
      error={error}
      fullWidth
    >
      <InputLabel>{label}</InputLabel>
      <BaseSelect name={name} {...props} inputProps={inputProps}>
        {renderItems(options)}
      </BaseSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

const EnhancedSelect = withControls(SelectComponent);

export default props => <Controller as={<EnhancedSelect />} {...props} />;
