import React from "react";
import { Controller } from "react-hook-form";
import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import FormControl from "../form-control/FormControl";
import withControls from "../enhancements/withControls";
import withPrimaryColor from "../withPrimaryColor";

function CheckboxComponent({
  label,
  helperText,
  required,
  disabled,
  error,
  ...props
}) {
  return (
    <FormControl>
      <FormControlLabel
        label={label}
        disabled={disabled}
        control={<MuiCheckbox {...props} />}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

const EnhancedField = withControls(withPrimaryColor(CheckboxComponent));

export default function Checkbox(props) {
  return <Controller as={EnhancedField} {...props} valueName={false} />;
}
