import React from "react";
import * as R from "ramda";

import { toNameString } from "services/formatters";
import withEmployerIdFromRouter from "components/containers/utils/withEmployerIdFromRouter";
import withSort from "components/containers/withSort";
import withSearch from "components/containers/withSearch";
import withPagination from "components/containers/withPagination";
import withGetFetch from "components/containers/withGetFetch";
import { PageComponent, withProps } from "components/containers/ListPage";
import PageAction from "./PageAction";
import ActionsCell from "./ActionsCell";

const renderAction = props => ({ disabled }) => (
  <PageAction {...props} disabled={disabled} />
);
const columns = props => [
  { name: "first_name", label: "Name", format: toNameString },
  {
    name: "email",
    label: "Email"
  },
  {
    name: "id",
    label: "Actions",
    disableSort: true,
    render: r => <ActionsCell {...props} {...r} />
  }
];

const createGetSearchFilterWith = employerId =>
  R.applySpec({
    "0": { employer_id_eq: R.always(employerId) }
  });

function extraProps(props) {
  return {
    title: "Employer Contacts",
    hideSearch: true,
    defaultSort: { order: "desc", orderBy: "created_at" },
    columns: columns(props),
    actionSlot: renderAction(props)
  };
}

function searchProps({ employerId }) {
  return {
    path: employerId && `/employers/${employerId}/contacts`,
    getSearchFilter: createGetSearchFilterWith(employerId)
  };
}

export default R.compose(
  withEmployerIdFromRouter,
  withProps(searchProps),
  withSearch,
  withSort,
  withPagination,
  withGetFetch,
  withProps(extraProps)
)(PageComponent);
