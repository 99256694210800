import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LinkIcon from "@material-ui/icons/Link";
import Button from "@material-ui/core/Button";

import Grid from "../grids/Grid";
import SwitchComponent from "../inputs/BaseSwitch";

const styles = theme => ({
  card: {
    maxWidth: 400
  },
  disabledCard: {
    opacity: "0.4"
  },
  leftButtonIcon: {
    marginRight: theme.spacing.unit
  },
  actions: {
    display: "flex"
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    }),
    marginLeft: "auto"
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  footer: {
    display: "flex",
    justifyContent: "space-between"
  },
  media: {
    textAlign: "center",
    maxHeight: "120px",
    maxWidth: "240px"
  }
});

class RefiCard extends React.PureComponent {
  state = { expanded: true };

  handleInAdvisorChanged = () => {
    const {
      data,
      inAdvisor,
      withDelegates: { handleInAdvisorChanged }
    } = this.props;

    handleInAdvisorChanged({ row: data, toggle: !inAdvisor });
  };

  renderUrlButton() {
    const {
      classes,
      withDelegates: { urlButtonHref }
    } = this.props;
    return (
      <Button color="primary" target="_blank" href={urlButtonHref}>
        <LinkIcon className={classes.leftButtonIcon} />
        <p>URL</p>
      </Button>
    );
  }

  renderInAdvisorSwitch() {
    const { disableInAdvisorToggle, inAdvisor } = this.props;

    return (
      <SwitchComponent
        disabled={disableInAdvisorToggle}
        checked={inAdvisor}
        onChange={this.handleInAdvisorChanged}
        row={true}
        label="In Advisor"
        color="primary"
      />
    );
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const {
      classes,
      inAdvisor,
      disableInAdvisorToggle,
      withHeader: { renderAction },
      withMedia,
      withContent: { title, content }
    } = this.props;

    const mediaSrc = withMedia.src;
    const mediaTitle = withMedia.title;
    const opaque = !inAdvisor || disableInAdvisorToggle;

    return (
      <Card
        raised
        className={classnames(classes.card, {
          [classes.disabledCard]: opaque
        })}
      >
        <CardHeader action={renderAction && renderAction()} />
        <Grid container justify="center" alignItems="center">
          <div style={{ height: 120 }}>
            <CardMedia
              className={classes.media}
              component="img"
              image={mediaSrc}
              alt={mediaTitle}
            />
          </div>
        </Grid>
        <CardActions>
          <Typography variant="title">{title}</Typography>
          <IconButton
            className={classnames(classes.expand, {
              [classes.expandOpen]: this.state.expanded
            })}
            onClick={this.handleExpandClick}
            aria-expanded={this.state.expanded}
            aria-label="Show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent style={{ minHeight: 150 }}>
            <Typography variant="body1">{content}</Typography>
          </CardContent>
        </Collapse>
        <CardActions className={classes.footer}>
          {this.renderUrlButton()}
          {this.renderInAdvisorSwitch()}
        </CardActions>
      </Card>
    );
  }
}

RefiCard.propTypes = {
  data: PropTypes.object,
  disableInAdvisorToggle: PropTypes.bool,
  inAdvisor: PropTypes.bool,
  loading: PropTypes.bool,
  withHeader: PropTypes.shape({
    renderAction: PropTypes.func
  }),
  withMedia: PropTypes.shape({
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }),
  withContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
  }),
  withDelegates: PropTypes.shape({
    handleInAdvisorChanged: PropTypes.func.isRequired,
    urlButtonHref: PropTypes.string.isRequired
  })
};

RefiCard.defaultProps = {
  withHeader: {},
  withMedia: {},
  withContent: {},
  withFooter: {},
  withDelegates: {}
};

export default withStyles(styles)(RefiCard);
