import React from 'react';
import * as R from 'ramda';
import withEmployerIdFromRouter from '../../components/containers/utils/withEmployerIdFromRouter';
import withContainer from '../../components/containers/withInvoiceContainer';
import Card from '../../components/cards/basic-card/Card';
import Tabs from '../../components/tabs/InvoiceTabs';
import RowGroup from '../../components/layouts/list/Row';
import CardActions from './components/InvoicePageActions';
import LineItemsPanel from './components/LineItemsPanel';
import FeesPanel from './components/FeesPanel';
import ExclusionsPanel from './components/ExclusionsPanel';
import Summary from './components/Summary';
import DeleteLineItems from './components/DeleteLineItems';
import RestoreLineItems from './components/RestoreLineItems';

const allowEditWith = R.contains(R.__, ['pending']);
const tabs = [
  { label: 'Line Items', id: 'lineItems' },
  { label: 'Fees', id: 'fees' },
  { label: 'Exclusions', id: 'exclusions' }
];

const renderActionSlot = ({ employerId, allowApprove, onSubmit }) => () => (
  <CardActions
    employerId={employerId}
    allowApprove={allowApprove}
    onSubmit={onSubmit}
  />
);

const createTabActionSlot = ({
  lineItems,
  lineItemsSelections,
  exclusions,
  exclusionsSelections,
  onDelete,
  onRestore
}) => ({ current }) => {
  return current === 0 ? (
    lineItemsSelections.length ? (
      <DeleteLineItems
        data={lineItems}
        selections={lineItemsSelections}
        onSubmit={onDelete}
      />
    ) : null
  ) : current === 2 ? (
    exclusionsSelections.length ? (
      <RestoreLineItems
        data={exclusions}
        selections={exclusionsSelections}
        onSubmit={onRestore}
      />
    ) : null
  ) : null;
};

function InvoicePage({ useProps, employerId }) {
  const [invoice] = useProps.invoiceState;
  const title = `${invoice.invoice_name} Invoice`;
  const allowEdit = allowEditWith(invoice.status);
  const getApproval = useProps.getApproval;
  const lineItemsState = useProps.lineItemsState;
  const getLineItems = useProps.getLineItems;
  const feesState = useProps.feesState;
  const getFees = useProps.getFees;
  const exclusionsState = useProps.exclusionsState;
  const getExclusions = useProps.getExclusions;

  return (
    <Card
      title={title}
      hideSearch
      actionSlot={renderActionSlot({
        employerId,
        allowApprove: allowEdit,
        onSubmit: useProps.handleApproveSubmit
      })}
      contentSlot={() => (
        <RowGroup>
          <Summary
            invoice={invoice}
            lineItems={lineItemsState[0]}
            exclusions={exclusionsState[0]}
          />
          <Tabs
            tabs={tabs}
            actionSlot={createTabActionSlot({
              lineItems: lineItemsState[0],
              exclusions: exclusionsState[0],
              lineItemsSelections: useProps.lineItemsSelections.selected,
              exclusionsSelections: useProps.exclusionsSelections.selected,
              onDelete: useProps.handleDeleteLineItems,
              onRestore: useProps.handleRestoreLineItems
            })}
          >
            <LineItemsPanel
              id="lineItems"
              loading={
                getLineItems.loading ||
                useProps.lineItemsSelections.loading ||
                getApproval.loading
              }
              data={lineItemsState[0]}
              selections={useProps.lineItemsSelections.selected}
              onSelectChange={useProps.lineItemsSelections.handleChange}
              onSelectAllChange={
                useProps.lineItemsSelections.handleSelectAllChange
              }
              total={lineItemsState[0].length}
              onDelete={useProps.handleDeleteLineItems}
              allowEdit={allowEdit}
            />
            <FeesPanel
              id="fees"
              loading={getFees.loading || getApproval.loading}
              data={feesState[0]}
              total={feesState[0].length}
              allowEdit={allowEdit}
            />
            <ExclusionsPanel
              id="exclusions"
              loading={
                getExclusions.loading ||
                useProps.exclusionsSelections.loading ||
                getApproval.loading
              }
              selections={useProps.exclusionsSelections.selected}
              onSelectChange={useProps.exclusionsSelections.handleChange}
              onSelectAllChange={
                useProps.exclusionsSelections.handleSelectAllChange
              }
              data={exclusionsState[0]}
              total={exclusionsState[0].length}
              onRestore={useProps.handleRestoreLineItems}
              allowEdit={allowEdit}
            />
          </Tabs>
        </RowGroup>
      )}
    />
  );
}

export default withContainer(withEmployerIdFromRouter(InvoicePage));
