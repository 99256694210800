import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SLGModalForm from "components/modals/SLGModalForm";
import TRRejectForm  from "../forms/TRRejectForm";
import { rejectTR as rejectTRAction } from "../../../employees/actions";

export const TRRejectModal = ({
  trData,
  rejectTR,
  onClose,
  onSuccess
}) => {
  
  const tuition_id          = trData.attributes.id;
  const servicer_account_id = trData.servicer_account.id;
  const employerId = trData.employerId;
  const formName = "rejectTuitionReimbursement";

  return (
    <SLGModalForm
      title="Reject Tuition Reimbursement"
      formName={formName}
      submit={(...args) =>
        rejectTR.apply(null, [...args, servicer_account_id, employerId, onSuccess])
      }
      close={onClose}
    >
      <TRRejectForm
        formName={ formName }
        label="Reject Tuition Reimbursement"
        onSuccess={ onSuccess }
        servicer_account_id={ servicer_account_id }
        employerId={ employerId }
        tuition_id={ tuition_id }
      />
    </SLGModalForm>
  );
};

TRRejectModal.propTypes = {
  rejectTR: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    rejectTR: rejectTRAction
  }
)(TRRejectModal);
