import BaseRequest from "../../shared/BaseRequest";

export default class ToggleVisibilityRequest extends BaseRequest {
  method = "patch";

  constructor({ url, employerId, toggle }) {
    super({ url });

    this.data = {
      type: "employers",
      id: employerId,
      enable_own_refi_vendors: toggle
    };
  }
}
