import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";
import moment from "moment";
import FlatButton from "material-ui/FlatButton";
import EditIcon from "material-ui/svg-icons/editor/mode-edit";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import { theme } from "app";
import { openModal } from "app/uiActions";
import { SLGTableColumn, SLGList } from "components";
import { DeleteConfirmationModal } from "components/modals";
import { WithPermission } from "auth";
import {
  SYSTEM_ADMIN,
  PARTNER_ADMIN,
  EMPLOYERS_ADMIN,
  EMPLOYER_MANAGER,
  SUPPORT_REP,
  PARTNER_MANAGER
} from "constants/index";
import { NoteEditModal } from "./modals";
import { fetchEmployeeNotes, deleteEmployeeNote } from "../actions";
import { selectEmployeeNotesByEmployee } from "employees/selectors";

const styles = {
  header: { display: "flex", justifyContent: "space-between" },
  rowField: { textAlign: "center" },
  inactive: { color: "#FF0000" }
};

export const NotesList = ({
  fetchEmployeeNotes,
  total,
  isLoading,
  employeeNotes,
  openModal,
  employeeID,
  deleteEmployeeNote
}) => {
  const params = employeeID ? [employeeID] : [];
  const themePalette = getMuiTheme(theme).palette;

  return (
    <div>
      <SLGList
        fetchData={fetchEmployeeNotes}
        params={params}
        data={employeeNotes}
        total={total}
        isLoading={isLoading}
        noDataText="No Notes"
      >
        <SLGTableColumn
          field="note"
          sortable={true}
          headerStyle={{ width: "25%" }}
        />
        <SLGTableColumn
          field="author.name"
          title="Author"
          sortable={false}
          render={val =>
            `${val.admin_users.first_name} ${val.admin_users.last_name}`
          }
          headerStyle={{ width: "15%" }}
        />
        <SLGTableColumn
          field="created_at"
          title="Created On"
          sortable={true}
          render={val =>
            val.created_at ? moment(val.created_at).format("MM/DD/YYYY") : "No"
          }
          headerStyle={{ width: "15%" }}
        />
        <SLGTableColumn
          field="updated_at"
          title="Updated On"
          sortable={true}
          render={val =>
            val.updated_at ? moment(val.updated_at).format("MM/DD/YYYY") : null
          }
          headerStyle={{ width: "15%" }}
        />
        <SLGTableColumn
          rowStyle={styles.rowField}
          render={val => (
            <WithPermission
              value={[
                SYSTEM_ADMIN,
                PARTNER_ADMIN,
                PARTNER_MANAGER,
                EMPLOYERS_ADMIN,
                EMPLOYER_MANAGER,
                SUPPORT_REP
              ]}
            >
              <FlatButton
                label="Edit"
                primary={true}
                labelStyle={{ color: themePalette.flatButtonColor }}
                icon={<EditIcon color={themePalette.flatButtonColor} />}
                onClick={() => openModal(<NoteEditModal selectedNote={val} />)}
                style={{ overflow: "inherit" }}
              />
            </WithPermission>
          )}
          headerStyle={{ width: "15%" }}
        />
        <SLGTableColumn
          rowStyle={styles.rowField}
          render={val => (
            <WithPermission
              value={[
                SYSTEM_ADMIN,
                PARTNER_ADMIN,
                PARTNER_MANAGER,
                EMPLOYERS_ADMIN,
                EMPLOYER_MANAGER,
                SUPPORT_REP
              ]}
            >
              <FlatButton
                label="Delete"
                secondary={true}
                labelStyle={{ color: themePalette.deleteButtonColor }}
                icon={<DeleteIcon color={themePalette.deleteButtonColor} />}
                onClick={() =>
                  openModal(
                    <DeleteConfirmationModal
                      title="Delete Note"
                      deleteAction={() => deleteEmployeeNote(val.id)}
                      resourceName="this note"
                    />
                  )
                }
                style={{ overflow: "inherit" }}
              />
            </WithPermission>
          )}
          headerStyle={{ width: "15%" }}
        />
      </SLGList>
    </div>
  );
};

NotesList.propTypes = {
  employeeNotes: PropTypes.array,
  employeeID: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired
};

NotesList.defaultProps = {
  notes: []
};

const mapStateToProps = (state, props) => {
  return {
    employeeNotes: selectEmployeeNotesByEmployee(state, props.employeeID),
    total: state.employeeNotes.total,
    isLoading: state.employeeNotes.isFetching
  };
};
const mapDispatchToProps = (_dispatch, _ownProps) => {
  return { fetchEmployeeNotes, openModal, deleteEmployeeNote };
};
const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(NotesList);
