import { format } from "date-fns";
import * as R from "ramda";

const formatString = "MM/dd/yyyy HH:mm:ss";
const toDisplayDateString = value =>
  value ? format(new Date(value), formatString) : value;

export const toDisplayDateStringFromProp = R.curryN(
  2,
  R.compose(
    toDisplayDateString,
    R.prop
  )
);
export default toDisplayDateString;
