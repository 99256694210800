import urlService from "../../rest/urlService";

function routeService({ urlService }) {
  const _urlService = urlService;
  const _baseUrl = _urlService.getBase();
  const _collection = "invoices";

  const _prefix = () => {
    return `${_baseUrl}/${_collection}`;
  };

  const getInvoices = () => {
    return `${_prefix()}`;
  };

  const getExclusion = invoiceId => {
    return `${_prefix()}/${invoiceId}/excluded_users`;
  };

  const getInvoice = invoiceId => {
    return `${_prefix()}/${invoiceId}`;
  };

  const approveInvoice = invoiceId => {
    return `${_prefix()}/${invoiceId}`;
  };

  const restoreItem = invoiceLineItemId => {
    return `${_baseUrl}/invoice_line_items/${invoiceLineItemId}/restore`;
  };

  const deleteInvoiceItem = invoiceItemId => {
    return `${_baseUrl}/invoice_line_items/${invoiceItemId}`;
  };

  return {
    getInvoices,
    getExclusion,
    getInvoice,
    approveInvoice,
    deleteInvoiceItem,
    restoreItem
  };
}

export default routeService({ urlService });
