import React from "react";
import * as R from "ramda";
import { toPath } from "lodash";

export default WC => ({ formProps, ...props }) => {
  const errors = R.pathOr({}, ["errors"], formProps);
  const name = toPath(props.name);
  const hasError = R.hasPath(name, errors);
  const helperText = hasError
    ? R.path([...name, "message"], errors)
    : props.helperText;
  const enhancedProps = {
    helperText,
    error: hasError
  };

  return <WC {...props} {...enhancedProps} />;
};
